import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { greyHeadingColorCls } from "../../../../lib/styleConstants";
import MultiLineTextArea from "../../../global/elements/TypographyElements/MultiLineTextArea";

const OrderFieldDesc = ({
  label = undefined,
  value = undefined,
  multiLine = false,
  greyHeading = true,
}) => {
  return (
    <>
      <Typography variant="h6" sx={greyHeading && greyHeadingColorCls}>
        {label}
      </Typography>
      {value && multiLine ? (
        <MultiLineTextArea
          defaultValue={value}
          isDisabled
          variant="plain"
          minRows={1}
        />
      ) : value ? (
        <Typography variant="body2">{value}</Typography>
      ) : null}
    </>
  );
};

OrderFieldDesc.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  multiLine: PropTypes.bool,
  greyHeading: PropTypes.bool,
};
// OrderFieldDesc.defaultProps = {
//   multiLine: false,
//   greyHeading: true,
// };

export default OrderFieldDesc;
