import React from "react";
import { Box, Container, Typography } from "@mui/material";
import BackIcon from "../../../global/elements/BackIcon";
import IllustrationBlack from "src/assets/images/onePlatformGlobe.svg";
import IllustrationWhite from "src/assets/images/onePlatformGlobe_white.svg";

import PropTypes from "prop-types";
import theme from "../../../../theme";
import { totalCountry, totalPilots } from "../../../../lib/constants";
// import { ReactComponent as Illustration } from "src/assets/images/onePlatformGlobe.svg";

const styles = {
  root: {
    padding: "30px 0",
  },
  backIcon: {
    padding: "20px",
  },
  mainContainer: {
    display: "flex",
    fiexDirection: "row",
    // alignItems: "flex-start",
  },
  form: {
    maxWidth: { xs: "100vw", md: "50%" },
    flex: 1,
    overflowY: "auto",
  },
  imageContainer: {
    width: "50%",
    // height: "80vh",
    // maxHeight: "1000px",
    position: "sticky",
    top: 0,
    //objectFit: "cover",
    display: { xs: "none", md: "flex" },
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    //display: "flex",
  },
  image: {
    marginTop: "20px",
    width: "400px",
    // opacity: "30%",
  },
  text: {
    width: "400px",
    textAlign: "center",
    // opacity: "60%",
  },
  pilotText: {
    color: theme.palette.grey.white,
  },
  pilotColor: {
    backgroundColor: theme.palette.secondary.main,
  },
  clientColor: {
    backgroundColor: theme.palette.primary.main,
  },
};
const SignInFormLayout = ({ children = undefined, isClient = false }) => {
  return (
    <Container maxWidth="lg" sx={styles.root}>
      <Box sx={styles.backIcon}>
        <BackIcon />
      </Box>
      <Box sx={styles.mainContainer}>
        <Box sx={styles.form}>{children}</Box>
        <Box
          sx={{
            ...styles.imageContainer,
            ...(isClient ? styles.clientColor : styles.pilotColor),
          }}
        >
          <Box
            component="img"
            src={isClient ? IllustrationBlack : IllustrationWhite}
            sx={styles.image}
          />
          <Typography variant="h5" sx={{ ...(!isClient && styles.pilotText) }}>
            {isClient
              ? "Scale up your drone data acquisition globally"
              : "Join the world largest Crowddroning community"}
          </Typography>
          <Typography
            variant="h5"
            sx={{ ...styles.text, ...(!isClient && styles.pilotText) }}
          >
            {isClient
              ? `Get access to ${totalPilots} drone operators in ${totalCountry} countries`
              : "Scale up with international clients in need of drone data and get paid jobs where you live"}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

SignInFormLayout.propTypes = {
  children: PropTypes.node,
  isClient: PropTypes.bool,
};

// SignInFormLayout.defaultProps = {
//   children: undefined,
//   isClient: false,
// };

export default SignInFormLayout;
