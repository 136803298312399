import React from "react";
import { Box, Typography } from "@mui/material";
import StatusAlertContainer from "../../global/elements/StatusAlertContainer";
import QuotesTermsAndConditions from "../Invoices/QuotesTermsAndConditions";
import theme from "../../../theme";
import { OrderTypeConstant, quoteDataType } from "../../../lib/constants";
import {
  expectedMbSize,
  getCurrencySymbol,
  getUnit,
} from "../../../lib/helpers";
import BoxLayout from "../../global/BoxLayout";
import { getTotalCollectedData } from "../../../services/client";
import PropTypes from "prop-types";

const styles = {
  quotesDetails: {
    display: "flex",
    columnGap: "1em",
    flexDirection: { xs: "column", sm: "row" },
    rowGap: "1em",
  },
  quoteBox: { width: { xs: "100%", sm: "auto" } },

  sectionQuoteDetails: {
    boxShadow: `0px 0px 36px ${theme.palette.grey.lightWarmGrey}E6`,
    padding: "2em",
    display: "flex",
    flexDirection: "column",
    rowGap: "1em",
  },
  boxLayout: { padding: "1.5em" },
};

const QuoteBoxesAndCreate = ({
  showNoQuoteDialogue = false,
  mission = { areaSize: undefined, orderType: undefined },
  areaSize = undefined,
  currency = undefined,
  isProject = false,
  totalProjectMissionsCost = undefined,
  projectMissionCount = undefined,
  singleProjectMissionPrice = undefined,
  projectCurrency = undefined,
}) => {
  let orderUnit = getUnit(mission);
  const convertMBToKB = (mb) => {
    var bytes = mb * 1024 * 1024;
    return bytes;
  };
  const getData = () => {
    let expected = expectedMbSize(mission?.areaSize);
    let converted = convertMBToKB(expected);
    let final = getTotalCollectedData(converted);
    return final;
  };
  return (
    <Box sx={styles.sectionQuoteDetails}>
      <Typography variant="h3">Quote Details</Typography>
      {showNoQuoteDialogue && (
        <StatusAlertContainer
          severity="success"
          heading="No quote has been issued yet. We are working hard to send you a quote within 24 hours of placing your order."
        />
      )}
      {isProject ? (
        <Box sx={styles.quotesDetails}>
          <Box sx={styles.quoteBox}>
            <BoxLayout
              link=""
              text="Number of sites"
              subText={`Across ${projectMissionCount} orders`}
              data={projectMissionCount}
              additionalStyle={styles.boxLayout}
            />
          </Box>
          <Box sx={styles.quoteBox}>
            <BoxLayout
              link=""
              text="Price per site"
              data={singleProjectMissionPrice || "-"}
              inGbFlag={projectCurrency}
              collectedDataFlag={true}
              additionalStyle={styles.boxLayout}
            />
          </Box>
          <Box sx={styles.quoteBox}>
            <BoxLayout
              link=""
              text="Total cost"
              subText={`Across ${projectMissionCount} orders`}
              data={totalProjectMissionsCost || "-"}
              additionalStyle={styles.boxLayout}
              inGbFlag={projectCurrency}
              collectedDataFlag={true}
            />
          </Box>
          <Box sx={styles.quoteBox}>
            <BoxLayout
              link=""
              text="Used Currency"
              subText={""}
              data={getCurrencySymbol(currency)}
              additionalStyle={styles.boxLayout}
              showTextInDashboard={true}
            />
          </Box>
        </Box>
      ) : (
        <Box sx={styles.quotesDetails}>
          {mission.orderType != OrderTypeConstant.assets && (
            <>
              <Box sx={styles.quoteBox}>
                <BoxLayout
                  link=""
                  text="Geographic Area"
                  subText={`Across 1 orders`}
                  data={areaSize}
                  inGbFlag={orderUnit === 0 ? null : orderUnit}
                  collectedDataFlag={true}
                  unitType={quoteDataType.areaSize}
                  additionalStyle={styles.boxLayout}
                />
              </Box>
              <Box sx={styles.quoteBox}>
                <BoxLayout
                  link=""
                  text="Estimated Image Size"
                  subText={`Across 1 orders`}
                  data={getData().data || "-"}
                  inGbFlag={getData().unit}
                  collectedDataFlag={true}
                  unitType={quoteDataType.imageSize}
                  additionalStyle={styles.boxLayout}
                />
              </Box>
            </>
          )}

          <Box sx={styles.quoteBox}>
            <BoxLayout
              link=""
              text="Used Currency"
              subText={""}
              data={getCurrencySymbol(currency)}
              additionalStyle={styles.boxLayout}
              showTextInDashboard={true}
            />
          </Box>
        </Box>
      )}

      <QuotesTermsAndConditions />
    </Box>
  );
};

QuoteBoxesAndCreate.propTypes = {
  showNoQuoteDialogue: PropTypes.bool,
  mission: PropTypes.shape({
    areaSize: PropTypes.number,
    orderType: PropTypes.string,
  }),
  areaSize: PropTypes.string,
  currency: PropTypes.string,
  isProject: PropTypes.bool,
  totalProjectMissionsCost: PropTypes.string,
  projectMissionCount: PropTypes.number,
  singleProjectMissionPrice: PropTypes.number,
  projectCurrency: PropTypes.string,
};

// QuoteBoxesAndCreate.defaultProps = {
//   showNoQuoteDialogue: false,
//   mission: PropTypes.shape({
//     areaSize: undefined,
//     orderType: undefined,
//   }),
//   areaSize: undefined,
//   currency: undefined,
//   isProject: false,
//   totalProjectMissionsCost: undefined,
//   projectMissionCount: undefined,
//   singleProjectMissionPrice: undefined,
//   projectCurrency: undefined,
// };
export default QuoteBoxesAndCreate;
