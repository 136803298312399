import React from "react";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import theme from "../../../theme";
import { getFileSizeInUnits } from "../../../lib/helpers";

const styles = {
  flexContainer: {
    display: "flex",
  },
  marginAdjust: { marginRight: "10px" },
  widthAdjust: { width: "fit-content" },
  alignText: { textAlign: "right" },
  margin: { margin: "0px 5px", cursor: "pointer" },
  linkStyle: {
    textDecoration: "none",
    color: theme.palette.black.darkSeaBlack,
  },
};

const UploadFileInfoContainer = ({
  fileName = undefined,
  fileUrl = undefined,
  fileSize = undefined,
  fileId = undefined,
  deleteFile = undefined,
}) => {
  return (
    <>
      <Grid item xs={8} sx={styles.flexContainer}>
        <InsertDriveFileOutlinedIcon sx={styles.marginAdjust} />
        <Typography variant="body1" sx={styles.widthAdjust}>
          {fileName} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Size :{" "}
          {getFileSizeInUnits(fileSize)}
        </Typography>
      </Grid>
      <Grid item xs={4} sx={styles.alignText}>
        <Link to={fileUrl} target={"_blank"} style={styles.linkStyle}>
          <FileDownloadOutlinedIcon sx={styles.margin} />
        </Link>
        <DeleteForeverOutlinedIcon
          sx={styles.margin}
          onClick={() => deleteFile && deleteFile(fileId)}
        />
      </Grid>
    </>
  );
};

UploadFileInfoContainer.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired,
  fileSize: PropTypes.string.isRequired,
  fileId: PropTypes.string.isRequired,
  deleteFile: PropTypes.func,
};

// UploadFileInfoContainer.defaultProps = {
//   deleteFile: undefined,
// };

export default UploadFileInfoContainer;
