/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import TextActionSection from "./TextActionSection";
import { Box, Grid, Typography } from "@mui/material";
import theme from "../../../theme";
import { chatBoxOuter } from "../../../lib/styleConstants";
import InfiniteScroll from "react-infinite-scroller";
import { getUser } from "../../../services/auth";
import { messageType, userRoles } from "../../../lib/constants";
import {
  createCommunicationLogs,
  markAllMessageAsRead,
} from "../../../services/communication";
import firebase from "src/firebase";
import useGetMessages from "../../../hooks/useGetMessages";
import { useCollectionData } from "react-firebase-hooks/firestore";
import PropTypes from "prop-types";
import MessageText from "./MessageText";
import MissionContext from "../../app/missions/NewMissionDetails/components/MissionContext";
import { useSnackbar } from "notistack";

const styles = {
  actionContainer: {
    marginTop: "10px",
  },
  header: {
    textAlign: "center",
    padding: "15px 0",
    borderBottom: `4px solid ${theme.palette.black.coolGrey}`,
  },
  chatWrapper: {
    width: "100%",
  },
  mainBox: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    borderRadius: "0 10px 10px 0",
  },
  representativeBox: {
    width: "20%",
    minWidth: "300px",
  },
  vertical: {
    display: { xs: "none", md: "contents" },
  },
  horizontal: {
    margin: "20px 0",
    display: { xs: "block", md: "none" },
  },
};

const CommunicationBasic = ({
  talkingTo = undefined,
  mission = undefined,
  isProject = false,
  messagingTo = null,
  currentPilot = null,
}) => {
  //Getting mission context detail
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const missionContext = !isProject && useContext(MissionContext);
  // Getting info of current logged in user.
  const currentUser = getUser("userProfile");
  const isClient = currentUser?.role === userRoles.client;
  const isAdmin = currentUser?.role === userRoles.admin;
  const isPilot = currentUser?.role === userRoles.pilot;

  // Creating reference of a division.
  const scrollableDivRef = useRef(null);
  // Creating a variable to store the value of (a user is on current tab or not).
  const [isTabActive, setIsTabActive] = useState(true);
  // Calling a hook to get the message list.
  const {
    items,
    setItems,
    hasMore,
    fetchData,
    checkMessageExistsAlready,
    reload,
    setReload,
  } = useGetMessages({
    mission,
    talkingTo,
    currentUser,
    isProject,
    messagingTo,
  });
  const { enqueueSnackbar } = useSnackbar();

  // Below code is used to get new messages in real time.(Code start)
  let messagesRef;
  if (
    (isClient && talkingTo === userRoles.admin) ||
    (isAdmin && talkingTo === userRoles.client)
  ) {
    if (isProject) {
      console.log("data", mission);

      messagesRef = firebase
        .firestore()
        .collection("projects")
        .doc(mission.id)
        .collection("communicationLogs")
        .where("clientId", "==", mission.clientId)
        .where("seen", "==", false)
        .orderBy("createdAt", "desc");
    } else {
      messagesRef = firebase
        .firestore()
        .collection("missions")
        .doc(mission.id)
        .collection("communicationLogs")
        .where(
          "clientId",
          "==",
          currentUser.role === userRoles.admin
            ? mission.clientId
            : currentUser.id
        )
        .where("seen", "==", false)
        .where("viewer", "in", [userRoles.admin, userRoles.client])
        .orderBy("createdAt", "desc");
    }
  } else if (talkingTo === userRoles.all) {
    messagesRef = firebase
      .firestore()
      .collection("missions")
      .doc(mission.id)
      .collection("communicationLogs")
      .where("viewer", "==", userRoles.all);
    // .where("seenByPilot", "==", true)
    // .orderBy("createdAt", "desc");
    if (isPilot) {
      messagesRef = messagesRef.where("seenByPilot", "==", false);
    } else if (isAdmin) {
      messagesRef = messagesRef.where("seenByAdmin", "==", false);
    } else if (isClient) {
      messagesRef = messagesRef.where("seenByClient", "==", false);
    }
    messagesRef = messagesRef.orderBy("createdAt", "desc");
  } else if (
    (isPilot && !isProject) ||
    (isAdmin &&
      talkingTo === userRoles.pilot &&
      mission.assignedPilot &&
      !isProject)
  ) {
    messagesRef = firebase
      .firestore()
      .collection("missions")
      .doc(mission.id)
      .collection("communicationLogs")
      .where("viewer", "in", [userRoles.admin, userRoles.pilot])
      .where(
        "pilotId",
        "==",
        currentUser.role === userRoles.admin
          ? mission.assignedPilot
          : currentUser.id
      )
      .where("seen", "==", false)
      .orderBy("createdAt", "desc");
  } else if (
    (isPilot && isProject) ||
    (isAdmin && talkingTo === userRoles.pilot && messagingTo && isProject)
  ) {
    messagesRef = firebase
      .firestore()
      .collection("projects")
      .doc(mission.id)
      .collection("communicationLogs")
      .where(
        "pilotId",
        "==",
        currentUser.role === userRoles.admin ? messagingTo : currentUser.id
      )
      .where("seen", "==", false)
      .orderBy("createdAt", "desc");
  }
  const [newMessages] = useCollectionData(messagesRef);
  // Code end.

  const handleVisibilityChange = () => {
    // changing the variable when user changes a tab.
    setIsTabActive(!document.hidden);
  };

  useEffect(() => {
    // If user is on the current tab and there is some new message then run this.
    if (
      (isTabActive && newMessages && newMessages?.length > 0) ||
      (newMessages && talkingTo === userRoles.all)
    ) {
      // manupulating the useState function according to our need.
      setItems((prevMessages) => {
        let latestMessage = checkMessageExistsAlready(
          prevMessages,
          newMessages
        );
        return [...prevMessages, ...[...latestMessage].reverse()];
      });
      // below code is responsible for making a message as read.
      if (talkingTo === userRoles.admin) {
        markAllMessageAsRead(
          firebase,
          mission,
          currentUser.role,
          currentUser.role
        );
      } else {
        markAllMessageAsRead(firebase, mission, talkingTo, currentUser.role);
      }
    }
  }, [newMessages, isTabActive]);

  useEffect(() => {
    // To know we are on the same tab or not.
    document.addEventListener("visibilitychange", handleVisibilityChange);
  }, []);

  useEffect(() => {
    // Scroll the div to the bottom when the component mounts or content updates
    if (
      scrollableDivRef.current &&
      (items.length < 21 || newMessages?.length > 0)
    ) {
      // scrolling to the height of the div.
      scrollableDivRef.current.scrollTop =
        scrollableDivRef.current.scrollHeight;
    }
  }, [items]);

  const submitText = async (values, resetForm) => {
    // message submit function.
    let reciverInfo = {};
    let viewer = talkingTo;
    let data = {};
    // setting the recievers field with the help of conditions
    if (isAdmin && talkingTo === userRoles.client) {
      reciverInfo = {
        id: mission.clientId,
        name: mission.clientName,
        role: userRoles.client,
        email: isProject
          ? mission.clientEmail
          : missionContext.clientInfo.email,
      };
    } else if (isAdmin && talkingTo === userRoles.all && !isProject) {
      reciverInfo = {
        id: "Group chat",
        name: "Group",
        role: userRoles.all,
        email: null,
        pilotEmail: missionContext?.operatorInfo?.email || null,
        clientEmail: mission.clientEmail,
      };
    } else if (isClient && talkingTo === userRoles.all && !isProject) {
      reciverInfo = {
        id: "Group chat",
        name: "Group",
        role: userRoles.all,
        email: null,
        pilotEmail: null,
        clientEmail: mission.clientEmail,
      };
    } else if (talkingTo === userRoles.pilot) {
      if (isProject) {
        reciverInfo = {
          id: currentPilot?.id || null,
          name: currentPilot?.name || null,
          role: userRoles.pilot,
          email: currentPilot?.email || null,
        };
      } else {
        reciverInfo = {
          id: mission.assignedPilot,
          name: mission.assignedPilotName,
          role: userRoles.pilot,
          email: missionContext?.operatorInfo?.email,
        };
      }
    } else if (isPilot && talkingTo === userRoles.admin) {
      reciverInfo = {
        id: "Globhe Admin",
        name: "Globhe",
        role: userRoles.admin,
      };
    } else if (isPilot && talkingTo === userRoles.all && !isProject) {
      reciverInfo = {
        id: "Group chat",
        name: "Group",
        role: userRoles.all,
        email: null,
        pilotEmail: currentUser.email,
        clientEmail: mission.clientEmail,
      };
    } else if (isClient && talkingTo === userRoles.admin) {
      reciverInfo = {
        id: "Globhe Admin",
        name: "Globhe",
        role: userRoles.admin,
      };
    } else {
      reciverInfo = {};
    }

    if (talkingTo === userRoles.all) {
      viewer = userRoles.all;
    }

    //If we are adding images or document (for future).

    let findMessageType =
      values.message && values.document
        ? messageType.textWithDocument
        : values.message && values.image
        ? messageType.textWithImage
        : values.document
        ? messageType.document
        : values.image
        ? messageType.image
        : messageType.text;

    // gathering all the data in one object.

    data = {
      message: values.message,
      document: values.document,
      image: values.image,
      senderId: currentUser.id,
      senderInfo: {
        name: currentUser.name || "",
        profileImageURL: currentUser.profileImageURL || "",
        role: currentUser.role || "",
        location: currentUser.location || "",
        city: currentUser.city || "",
      },
      sender: currentUser.role,
      viewer: viewer,
      clientId:
        talkingTo === userRoles.client ||
        isClient ||
        talkingTo === userRoles.all
          ? mission.clientId
          : "",
      pilotId: isProject
        ? talkingTo === userRoles.pilot && isAdmin
          ? messagingTo
          : isPilot
          ? currentUser.id
          : ""
        : (talkingTo === userRoles.pilot ||
            isPilot ||
            talkingTo === userRoles.all) &&
          mission.assignedPilot
        ? mission.assignedPilot
        : "",
      reciverId: reciverInfo?.id || "",
      reciverInfo: reciverInfo,
      seen: false,
      reactions: [],
      edited: false,
      type: findMessageType || messageType.text,
    };

    if (talkingTo == userRoles.all) {
      data.seenByClient = false;
      data.seenByPilot = false;
      data.seenByAdmin = false;
    }
    // resting the messaging box
    resetForm({ message: "", document: "", image: "" });
    // sending the message to the backend using below function.
    if (!(talkingTo === userRoles.client && !mission.clientId)) {
      await createCommunicationLogs(firebase, mission, data, isProject);
    } else {
      enqueueSnackbar("Client does not exist in platform!", {
        variant: "error",
      });
    }
    // setItems((prevMessages) => [...prevMessages, messageStatus.data]);
  };
  return (
    <Box sx={styles.mainBox}>
      {/* {!isAdmin && (
        <>
          <Box sx={styles.representativeBox}>
            <GlobheContact vertical shortVersion />
          </Box>
          <Box sx={styles.vertical}>
            <Divider orientation="vertical" flexItem />
          </Box>
          <Box sx={styles.horizontal}>
            <Divider orientation="horizontal" flexItem />
          </Box>
        </>
      )} */}

      <Box sx={styles.chatWrapper}>
        <Box
          ref={scrollableDivRef}
          sx={{
            ...chatBoxOuter,
            minHeight: items?.length === 0 ? "300px" : "400px",
            height: items?.length === 0 ? "300px" : "400px",
          }}
        >
          <InfiniteScroll
            pageStart={0}
            loadMore={fetchData}
            hasMore={hasMore}
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
            isReverse={true}
            useWindow={false}
          >
            {items.length > 0 ? (
              items.map((item, i) =>
                item?.sender === userRoles.admin ? (
                  <MessageText
                    key={i}
                    content={item}
                    // senderInfo={item.senderInfo}
                    userRole={currentUser?.role}
                    reload={reload}
                    setReload={setReload}
                    isProject={isProject}
                  />
                ) : item?.sender === userRoles.pilot ? (
                  <MessageText
                    key={i}
                    content={item}
                    senderInfo={
                      currentUser?.role === userRoles.pilot
                        ? currentUser
                        : isProject || isClient
                        ? item.senderInfo
                        : missionContext?.operatorInfo || null
                    }
                    userRole={currentUser?.role}
                    reload={reload}
                    setReload={setReload}
                    isProject={isProject}
                  />
                ) : (
                  <MessageText
                    key={i}
                    content={item}
                    senderInfo={
                      isClient
                        ? currentUser
                        : isProject || isPilot
                        ? item.senderInfo
                        : missionContext?.clientInfo || null
                    }
                    userRole={currentUser?.role}
                    reload={reload}
                    setReload={setReload}
                    isProject={isProject}
                  />
                )
              )
            ) : (
              <Grid container mt={10} xs={12} md={9}>
                <Grid
                  item
                  xs={12}
                  sx={{ textAlign: "center", paddingBottom: "20px" }}
                >
                  <Typography variant="h5">No messages yet</Typography>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Typography variant="body1">
                    {isClient
                      ? "To begin a discussion, kindly type your message in the input box below. We're here to assist you with any inquiries or support you may need. We will get back to you within 24 hours."
                      : "To begin a discussion with the user, kindly type your message in the input box below. You can choose whether to talk with either the client or the operator. They will be informed by email as well."}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </InfiniteScroll>
        </Box>
        <Box sx={styles.actionContainer}>
          <TextActionSection onSubmit={submitText} currentUser={currentUser} />
        </Box>
      </Box>
    </Box>
  );
};

CommunicationBasic.propTypes = {
  talkingTo: PropTypes.string.isRequired,
  mission: PropTypes.object.isRequired,
  isProject: PropTypes.bool,
  messagingTo: PropTypes.string,
  currentPilot: PropTypes.object,
};

// CommunicationBasic.defaultProps = {
//   isProject: false,
//   messagingTo: null,
//   currentPilot: null,
// };
export default CommunicationBasic;
