import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { Link } from "gatsby";
import firebase from "src/firebase";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import AppButton from "../../../global/elements/AppButton";
import UploadIcon from "@mui/icons-material/Upload";

const ChecklistAndGuidlines = ({
  values = undefined,
  setFieldValue = undefined,
}) => {
  const [docLoading, setDocLoading] = useState(false);
  // const [fileName, setFileName] = useState("");
  const [guidelinesPdfUrl, setGuidelinesPdfUrl] = useState(
    values.guidelinesPdfUrl
  );

  const handleSubmit = (files) => {
    setDocLoading(true);
    const file = files[0];
    if (!file) return;
    const storageRef = firebase
      .storage()
      .ref()
      .child(`GuidelinesPdf/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setGuidelinesPdfUrl(downloadURL);
          setFieldValue("guidelinesPdfUrl", downloadURL);
          // setFileName(file.name);
          setDocLoading(false);
        });
      }
    );
  };

  return (
    <Box>
      <Dropzone
        accept={[".pdf", "application/pdf"]}
        onDrop={(acceptedFiles) => {
          handleSubmit(acceptedFiles);
        }}
        name="guideURL"
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} name="guideURL" />
              <AppButton
                label={
                  guidelinesPdfUrl
                    ? "Update operator's guideline"
                    : "Upload operator's  guideline."
                }
                look="green"
                submittingState={docLoading}
                customIcon={<UploadIcon />}
              />
            </div>
          </section>
        )}
      </Dropzone>

      {guidelinesPdfUrl ? (
        <Link target="_blank" to={`${guidelinesPdfUrl}`}>
          <Typography variant="body3">See the uploaded document</Typography>
        </Link>
      ) : (
        ""
      )}
    </Box>
  );
};

ChecklistAndGuidlines.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
};

// ChecklistAndGuidlines.defaultProps = {
//   setFieldValue: undefined,
//   values: undefined,
// };

export default ChecklistAndGuidlines;
