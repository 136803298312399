import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import theme from "../../../theme";
import { Box } from "@mui/material";
import AppButton from "../../global/elements/AppButton";
import GlobalTableValueConvertHandler from "../../global/elements/GlobalTableValueConverthandler";
import {
  boxShadowStyle,
  flexColumn,
  flexible,
  flexSpace,
} from "../../../lib/styleConstants";
import Divider from "@mui/material/Divider";
import TablePagination from "../../global/Table/TablePagination";
import { quoteStatus, userRoles } from "../../../lib/constants";
import MultiLineTextArea from "../../global/elements/TypographyElements/MultiLineTextArea";
import { getUser } from "../../../services/auth";

const styles = {
  tableRoot: {
    width: "100%",
    overflow: "hidden",
    margin: "auto",
    padding: { xs: 0, sm: "2%" },
    border: "none",
  },

  headerWrapper: {
    background: `${theme.palette.grey.lightWarmGrey}4D`,
    padding: "10px",
    flexDirection: { xs: "column", sm: "row" },
  },
  finalCostBackground: {
    background: `${theme.palette.primary.main}26`,
  },
  finalCost: {
    padding: "10px",
    flex: { xs: "unset", sm: 2 },
    justifyContent: { xs: "space-between", sm: "initial" },
  },
  detailContainer: {
    flexDirection: { xs: "column", sm: "row" },
    rowGap: "1em",
    columnGap: "1em",
  },
  greenText: {
    color: theme.palette.status.successDark,
    // fontWeight: 500,
  },
  detailHead: {
    // fontWeight: 500,
  },
  rowGap: { rowGap: "1em" },
  errorText: { color: theme.palette.status.errorDark },
  columnGap1: { columnGap: 1 },
  Card: {
    borderBottom: `1px solid ${theme.palette.grey.dividersColor}`,
    padding: "15px 2px",
  },
  directionResponsive: { flexDirection: { xs: "column", md: "row" } },
  detailsBox: { width: "100%", flexWrap: "wrap" },
  pricePer: { padding: { xs: "0 15px", sm: "0 0 0 15px" } },
  dateCreatedContainer: { columnGap: "10px" },
  devider: { borderRightWidth: "initial", margin: "0 10px" },
  deliveryExpiryBox: { columnGap: "4em" },
  alignCenter: { alignSelf: "center" },
};

const OrderQuotesTable = ({
  allData = undefined,
  limitHandler = undefined,
  paginationBtnClick = undefined,
  valueConvertHandler = undefined,
  limit = 10,
  currentPage = 1,
  lastPage = null,
  columns = [],
  buttonClick = undefined,
  isProject = false,
  role = undefined,
}) => {
  const isClient = getUser("user").role === userRoles.client;
  const GetGlobalTableValueHandler = ({ column, row }) => {
    GetGlobalTableValueHandler.propTypes = {
      column: PropTypes.object.isRequired,
      row: PropTypes.object.isRequired,
    };
    return (
      <>
        {column?.custom ? (
          valueConvertHandler(column.field, row)
        ) : column?.field === "button" ? (
          <AppButton
            look="black"
            onClick={() => buttonClick(row)}
            label={column?.label}
          />
        ) : (
          <GlobalTableValueConvertHandler
            column={column?.id}
            field={column?.field}
            value={row}
            statusBadge={column?.statusBadge}
            bold={column?.bold}
            mixedColumns={column?.mixedColumns ? column?.mixedColumns : []}
          />
        )}
      </>
    );
  };

  const DetailBox = ({
    text = undefined,
    value = undefined,
    valueType = undefined,
    data = {},
  }) => {
    return (
      <Box sx={{ flex: 1 }}>
        <Typography
          variant="subtitle1Med"
          sx={{ ...styles.detailHead, ...styles.greenText }}
        >
          {text} :
        </Typography>
        <Box sx={{ ...flexible }}>
          {/* <Typography variant="h6">
            {valueType === "cost" ? "$" : ""}
          </Typography> */}
          <Typography variant="h6">
            {valueType === "discount" ? (
              <GetGlobalTableValueHandler
                key={1}
                column={columns[3]}
                row={data}
              />
            ) : (
              value
            )}
          </Typography>
        </Box>
      </Box>
    );
  };
  DetailBox.propTypes = {
    text: PropTypes.string,
    value: PropTypes.any,
    valueType: PropTypes.string,
    data: PropTypes.object,
  };

  return (
    <Box sx={{ ...styles.tableRoot, ...boxShadowStyle }}>
      {allData?.map((data, key) => {
        return (
          <Box
            key={key}
            sx={{ ...flexColumn, ...styles.rowGap, ...styles.Card }}
          >
            <Box sx={{ ...flexSpace, ...styles.headerWrapper }}>
              {/* Quote name */}
              <Typography variant="h6">{data[columns[0].id]}</Typography>
              <Box
                sx={{
                  ...flexible,
                  ...styles.dateCreatedContainer,
                }}
              >
                {/* Date created */}
                <Typography variant="subtitle1Med" sx={{ ...styles.greenText }}>
                  {columns[1].label} :{" "}
                </Typography>
                <Typography variant="subtitle1Med">
                  <GetGlobalTableValueHandler
                    key={1}
                    column={columns[1]}
                    row={data}
                  />
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                ...flexColumn,
                ...styles.pricePer,
                ...styles.rowGap,
              }}
            >
              <Box sx={{ ...flexible, ...styles.detailContainer }}>
                <Box sx={{ ...flexible, flex: 2.7 }}>
                  <Box
                    sx={{
                      ...flexible,
                      ...styles.detailsBox,
                    }}
                  >
                    {/* Price per site for project */}

                    {isProject && (
                      <Box sx={{ flex: 1 }}>
                        {" "}
                        <DetailBox
                          text={columns[10]?.label}
                          value={data[columns[10]?.id] || "n/a"}
                          valueType={"cost"}
                        />
                      </Box>
                    )}
                    {isProject && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={styles.devider}
                      />
                    )}
                    {/* Total Cost */}
                    <Box sx={{ flex: 1 }}>
                      <DetailBox
                        text={columns[2].label}
                        value={data[columns[2]?.id]}
                        valueType={"cost"}
                      />
                    </Box>
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      sx={styles.devider}
                    />
                    <Box sx={{ flex: 1 }}>
                      {" "}
                      {/* Discount */}
                      <DetailBox
                        text={columns[3].label}
                        value={data[columns[3]?.id] || 0}
                        valueType={"discount"}
                        discountReason={data[columns[4]?.id] || "n/a"}
                        column={columns[9]}
                        data={data}
                      />
                    </Box>
                  </Box>
                </Box>
                {/* Final Price */}
                <Box
                  sx={{
                    ...flexible,
                    ...styles.finalCost,
                    ...styles.finalCostBackground,
                  }}
                >
                  <Box sx={styles.alignCenter}>
                    <Typography variant="body2">{columns[9].label}:</Typography>
                  </Box>
                  <Box
                    sx={{
                      ...flexible,
                      ...styles.alignCenter,
                      marginLeft: "1em",
                    }}
                  >
                    <GetGlobalTableValueHandler
                      key={1}
                      column={columns[9]}
                      row={data}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  ...flexible,
                  ...styles.deliveryExpiryBox,
                  ...styles.directionResponsive,
                }}
              >
                {/* Delivery date */}
                <Box sx={{ ...flexible, ...styles.columnGap1 }}>
                  <Typography
                    variant="subtitle1Med"
                    sx={{ ...styles.greenText }}
                  >
                    {columns[5].label}
                  </Typography>
                  {/* Delivery Date=days+dateCreated for single order */}
                  <Typography variant="subtitle1Med">
                    <GetGlobalTableValueHandler
                      key={1}
                      column={columns[5]}
                      row={data}
                    />
                  </Typography>
                </Box>
                <Box sx={{ ...flexible, ...styles.columnGap1 }}>
                  {/* Expiry Date */}
                  <Typography variant="subtitle1Med" sx={styles.errorText}>
                    {columns[6].label} :{" "}
                  </Typography>
                  <Typography variant="subtitle1Med" sx={styles.errorText}>
                    <GetGlobalTableValueHandler
                      key={1}
                      column={columns[6]}
                      row={data}
                    />
                  </Typography>
                </Box>
              </Box>
              {role == userRoles.admin && (
                <Box sx={{ ...flexible, ...styles.detailContainer }}>
                  <Box sx={{ ...flexible, flex: 2.7 }}>
                    <Box
                      sx={{
                        ...flexible,
                        ...styles.detailsBox,
                      }}
                    >
                      {/* Issued By */}
                      <Box
                        sx={{ flex: 0.57, ...flexible, ...styles.columnGap1 }}
                      >
                        <Typography
                          variant="subtitle1Med"
                          sx={{ ...styles.greenText }}
                        >
                          {isProject ? columns[11].label : columns[10].label}
                        </Typography>
                        <Typography variant="subtitle1Med">
                          <GetGlobalTableValueHandler
                            key={1}
                            column={isProject ? columns[11] : columns[10]}
                            row={data}
                          />
                        </Typography>
                      </Box>

                      {/* Cancelled By */}
                      <Box sx={{ flex: 1 }}>
                        {data?.status == quoteStatus.deleted && (
                          <Box sx={{ ...flexible, ...styles.columnGap1 }}>
                            <Typography
                              variant="subtitle1Med"
                              sx={styles.errorText}
                            >
                              {isProject
                                ? columns[12].label
                                : columns[11].label}{" "}
                              :{" "}
                            </Typography>
                            <Typography
                              variant="subtitle1Med"
                              sx={styles.errorText}
                            >
                              <GetGlobalTableValueHandler
                                key={1}
                                column={isProject ? columns[12] : columns[11]}
                                row={data}
                              />
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  {/* Delete Quote */}
                  <Box
                    sx={{
                      ...flexible,
                      ...styles.finalCost,
                    }}
                  >
                    <GetGlobalTableValueHandler
                      key={5}
                      column={isProject ? columns[13] : columns[12]}
                      row={data}
                    />
                  </Box>

                  {!isProject && data?.status == quoteStatus.accepted && (
                    <Box
                      sx={{
                        ...flexible,
                        ...styles.finalCost,
                      }}
                    >
                      <GetGlobalTableValueHandler
                        key={5}
                        column={columns[13]}
                        row={data}
                      />
                    </Box>
                  )}
                </Box>
              )}
              {/* Reject reason */}
              {data.status === quoteStatus.declined && (
                <Box
                  sx={{
                    ...flexible,
                    ...styles.directionResponsive,
                    ...styles.rowGap,
                  }}
                >
                  <Box sx={{ flex: 3 }}>
                    <Typography
                      variant="subtitle1Med"
                      sx={{ ...styles.errorText }}
                    >
                      {isClient && isProject
                        ? columns[11].label
                        : isClient
                        ? columns[10].label
                        : columns[14].label}{" "}
                      :{" "}
                    </Typography>
                    <MultiLineTextArea
                      defaultValue={
                        isClient && isProject
                          ? data[columns[11]?.id] || "n/a"
                          : isClient
                          ? data[columns[10]?.id] || "n/a"
                          : data[columns[14]?.id] || "n/a"
                      }
                      isDisabled
                      variant="plain"
                      minRows={1}
                    />
                  </Box>
                </Box>
              )}
              <Box
                sx={{
                  ...flexible,
                  ...styles.directionResponsive,
                  ...styles.rowGap,
                }}
              >
                {/* //description */}
                <Box sx={{ flex: 3 }}>
                  <Typography
                    variant="subtitle1Med"
                    sx={{ ...styles.greenText }}
                  >
                    {columns[7].label} :{" "}
                  </Typography>
                  <MultiLineTextArea
                    defaultValue={data[columns[7]?.id]}
                    isDisabled
                    variant="plain"
                    copyText
                  />
                </Box>
                <Box>
                  {/* status or action*/}
                  <GetGlobalTableValueHandler
                    key={4}
                    column={columns[8]}
                    row={data}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })}
      <TablePagination
        currentPage={currentPage}
        lastPage={lastPage}
        onClickNext={() => {
          paginationBtnClick("next");
        }}
        onClickPrevious={() => {
          paginationBtnClick("prev");
        }}
        limit={limit}
        limitOnChange={(e) => limitHandler(e.target.value)}
      />
    </Box>
  );
};

OrderQuotesTable.propTypes = {
  allData: PropTypes.array,
  limitHandler: PropTypes.func.isRequired,
  limit: PropTypes.number,
  paginationBtnClick: PropTypes.func,
  currentPage: PropTypes.number,
  lastPage: PropTypes.number,
  columns: PropTypes.array,
  valueConvertHandler: PropTypes.func,
  buttonClick: PropTypes.func,
  isProject: PropTypes.bool,
  role: PropTypes.string,
};

export default OrderQuotesTable;
