import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";

const styles = {
  infoItemContent: {
    display: "flex",
  },

  infoItemContainer: {
    justifyContent: { md: "end !important" },
  },

  marginLeft: {
    marginLeft: "5px",
  },

  extraMargin: {
    marginLeft: { xs: "20px" },
  },
};

const HeaderWithSideInfo = ({
  title = "",
  sideTitle1 = "",
  sideTitle2 = "",
  value1 = "",
  value2 = "",
}) => {
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Typography variant="h6">{title || "NO TITLE"}</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box>
          <Grid container sx={styles.infoItemContainer}>
            <Grid item>
              <Typography variant="h6" sx={styles.infoItemContent}>
                {sideTitle1}
                <Typography variant="subtitle1" sx={styles.marginLeft}>
                  {value1}
                </Typography>
              </Typography>
            </Grid>
            {value2 && (
              <Grid item>
                <Typography
                  variant="h6"
                  sx={{ ...styles.infoItemContent, ...styles.extraMargin }}
                >
                  {sideTitle2}
                  <Typography
                    variant="subtitle1"
                    sx={{ ...styles.marginLeft, ...styles.boldText }}
                  >
                    {value2}
                  </Typography>
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

HeaderWithSideInfo.propTypes = {
  title: PropTypes.string,
  sideTitle1: PropTypes.string,
  sideTitle2: PropTypes.string,
  value1: PropTypes.string,
  value2: PropTypes.string,
};

// HeaderWithSideInfo.defaultProps = {
//   title: "",
//   sideTitle1: "",
//   sideTitle2: "",
//   value1: "",
//   value2: "",
// };

export default HeaderWithSideInfo;
