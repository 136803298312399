import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SocialMediaIconHolder from "./SocialMediaIconHolder";
import { Container, Box, Grid, Divider } from "@mui/material";
import AppButton from "./AppButton";
import { claculateProgress } from "../../../services/profile";
import { statusAlertSeverity, userRoles } from "../../../lib/constants";
import ClientInfoHeader from "../../app/Profiles/components/ClientInfoHeader";
import StatusAlertContainer from "./StatusAlertContainer";
import ProfileInfoHandler from "../../app/EnterPrises/Components/ProfileInfoHandler";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckText from "./TypographyElements/CheckText";
import {
  Public,
  Apartment,
  Business,
  Email,
  PhoneAndroidOutlined,
  DirectionsCar,
  MarkunreadMailbox,
  Numbers,
  AttachMoney,
  School,
  Factory,
  Handyman,
  Webhook,
  FlightTakeoff,
  Camera,
  Sensors,
  CenterFocusWeak,
  Radar,
  TrackChanges,
  HighQuality,
  AccessTime,
  Group,
  Loyalty,
  Hearing,
  Comment,
} from "@mui/icons-material";
const styles = {
  socialMediaBox: {
    display: "flex",
    flexDirection: "row",
  },
};

const ProfileInfoPage = ({ user_data = {}, authentication = true }) => {
  const socialData = {
    instagram: `https://www.instagram.com/${user_data?.instagram}`,
    linkedin: `https://www.linkedin.com/in/${user_data?.linkedin}`,
    twitter: `https://www.twitter.com/${user_data?.twitter}`,
    email: user_data?.email,
    phone: user_data?.phone,
  };

  const [progress, setProgress] = useState(0);
  const isAdmin = user_data?.role === userRoles.admin;

  useEffect(() => {
    let profilePercent = claculateProgress(user_data);
    setProgress(profilePercent);
  }, [user_data]);

  return (
    <Container maxWidth="xl">
      <Grid container mt={3} mb={3} spacing={3}>
        <Grid item xs={12}>
          <ClientInfoHeader profile={user_data} isPilot />
        </Grid>
        <Grid item xs={12}>
          {isAdmin ? (
            <ProfileInfoHandler
              icon={<InfoOutlinedIcon />}
              heading="Admin type"
              value={authentication ? user_data?.adminType : "-"}
            />
          ) : progress === 100 ? (
            <StatusAlertContainer
              severity={statusAlertSeverity.success}
              coloredBackground
              showClose
              actionHandler={() => {}}
              heading={`Your profile is now complete. You are now eligible for missions when clients put an order in your availability area.`}
            />
          ) : (
            <StatusAlertContainer
              severity={statusAlertSeverity.warning}
              coloredBackground
              heading={`Please update your profile, only ${Math.round(
                progress
              )}% is completed`}
            />
          )}
        </Grid>
        <Grid item xs={12} />

        <Grid item xs={6} sm={3}>
          <CheckText text="Insured" success={user_data?.insurance} />
        </Grid>
        <Grid item xs={6} sm={3}>
          <CheckText
            text="Registered business"
            success={user_data?.registeredBusiness}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <CheckText text="Certified" success={user_data?.permissionToFly} />
        </Grid>
        <Grid item xs={6} sm={3}>
          <CheckText
            text="EASA certified"
            success={user_data?.EASACertified2024}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {/* --------------------personal info -------------------- */}

        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<Public />}
            heading="Available in other countries?"
            valueArray={user_data?.availableLocations}
            multiValue
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<Apartment />}
            heading="Address"
            value={authentication ? user_data?.address || "-" : "-"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<MarkunreadMailbox />}
            heading="Postal code"
            value={user_data?.postalCode || "-"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<PhoneAndroidOutlined />}
            heading="Phone number"
            value={authentication ? user_data?.phone || "-" : "-"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<Email />}
            heading="Email"
            value={authentication ? user_data?.email || "-" : "-"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<DirectionsCar />}
            heading="Max travel distance for a mission"
            value={user_data?.jobRadius + " km" || "-"}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {/* --------------------company -------------------- */}
        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<Business />}
            heading="Company name"
            value={user_data?.company || "-"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<Numbers />}
            heading="Company registration number"
            value={user_data?.registrationNo || "-"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<AttachMoney />}
            heading="Daily charge"
            value={user_data?.dailyCharge}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<Business />}
            heading="Billing address"
            value={user_data?.billingAddress || "-"}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {/* --------------------equipment and experience -------------------- */}

        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<School />}
            heading="Education"
            value={user_data?.education || "-"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<Factory />}
            heading="Industry experience"
            valueArray={user_data?.industry}
            multiValue
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<Handyman />}
            heading="Do you have other skills"
            valueArray={user_data?.skills}
            multiValue
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<Webhook />}
            heading="Processing and mapping software"
            valueArray={user_data?.mappingProcessing}
            multiValue
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<FlightTakeoff />}
            heading="Drones"
            valueArray={user_data?.equipment}
            multiValue
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<Camera />}
            heading="Sensors and cameras"
            valueArray={user_data?.sensorsCameras}
            multiValue
          />
        </Grid>
        {user_data?.sensorsCameras?.includes("Laser (LIDAR)") && (
          <Grid item xs={12} sm={6}>
            <ProfileInfoHandler
              icon={<Sensors />}
              heading="Lidar type"
              value={user_data?.lidarType || "-"}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<CenterFocusWeak />}
            heading="Ground capture equipment"
            valueArray={user_data?.groundCaptureEquipment}
            multiValue
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<Radar />}
            heading="Base stations"
            valueArray={user_data?.baseStations}
            multiValue
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<TrackChanges />}
            heading="GCP experience"
            boolValue={user_data?.gcpExperience}
            boolean
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<HighQuality />}
            heading="Camera MB"
            value={user_data?.megapixels || "_"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<AccessTime />}
            heading="Flight hours"
            value={user_data?.flightHours || "_"}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        {/* --------------------social -------------------- */}
        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<Group />}
            heading="Connections and pilot network"
            value={user_data?.associatedPilotsNb || "n/a"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<Loyalty />}
            heading="Do you want to be a GLOBHE ambassador?"
            boolValue={user_data?.ambassador}
            boolean
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProfileInfoHandler
            icon={<Hearing />}
            heading="Referral source"
            valueArray={user_data?.referralSource}
            multiValue
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={styles.socialMediaBox}>
            {user_data?.instagram && authentication && (
              <SocialMediaIconHolder
                socialData={socialData.instagram || ""}
                iconType="instagram"
              />
            )}
            {user_data?.linkedin && authentication && (
              <SocialMediaIconHolder
                socialData={socialData.linkedin || ""}
                iconType="linkedin"
              />
            )}
            {user_data?.twitter && authentication && (
              <SocialMediaIconHolder
                socialData={socialData.twitter}
                iconType="twitter"
              />
            )}
          </Box>
        </Grid>
        {/* --------------------profile note -------------------- */}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <ProfileInfoHandler
            icon={<Comment />}
            heading="Other comments"
            value={user_data?.moreDetails || "-"}
          />
        </Grid>
        {/* --------------------Download docs -------------------- */}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AppButton
            url={
              user_data?.droneLicenseURL ? `${user_data?.droneLicenseURL}` : ""
            }
            externalLink
            targetNewWidow
            look={!user_data?.droneLicenseURL ? "blackDisabled" : "black"}
            isDisabled={!user_data?.droneLicenseURL ? true : false}
            label="Download license"
            noIcon
            downloadFunction
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AppButton
            url={user_data?.cvURL ? `${user_data?.cvURL}` : ""}
            externalLink
            targetNewWidow
            look={!user_data?.cvURL ? "blackDisabled" : "black"}
            isDisabled={!user_data?.cvURL ? true : false}
            label="Download CV"
            noIcon
            downloadFunction
          />
        </Grid>
      </Grid>
    </Container>
  );
};
ProfileInfoPage.propTypes = {
  user_data: PropTypes.object,
  authentication: PropTypes.bool,
};
// ProfileInfoPage.defaultProps = {
//   user_data: {},
//   authentication: true,
// };

export default ProfileInfoPage;
