import React, { useContext, useState } from "react";
import { Box, Card, CardContent, Grid } from "@mui/material";
import firebase from "src/firebase";
import PropTypes from "prop-types";
import AddIcon from "@mui/icons-material/Add";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextFieldInput from "../../global/FormInputComponents/TextFieldInput";
import { useSnackbar } from "notistack";
import {
  defaultCurrency,
  inviteErrorType,
  unAuthorizedDomain,
  userRoles,
} from "../../../lib/constants";
import AppButton from "../../global/elements/AppButton";
import EnterPriseMember from "./EnterPriseMember";
import {
  checkEnterPriseMembers,
  getClientById,
} from "../../../services/client";
import ConfirmationPopup from "./confirmationPopup";
import { loginUserLocally } from "../../../services/auth";
import { createEnterPrise } from "../../../services/enterprise";
import AfterInvitationPopup from "./AfterInvitationPopup";
import AppContext from "../../../contexts/AppContext";

const styles = {
  buttonCls: {
    textAlign: "right",
    marginTop: "30px",
  },
  cardContentCls: {
    padding: "0px",
    cursor: "pointer",
    "&:first-child": { pt: "0px" },
    "&:last-child": { pb: "0px" },
  },
};

const EnterPriseMemberForm = ({
  onSuccess = undefined,
  client = undefined,
  showSkipBtn = undefined,
  onClose = undefined,
  useAsPopup = undefined,
  onInviteSent = undefined,
}) => {
  const { id: clientId, email } = client;
  const { enqueueSnackbar } = useSnackbar();
  const [openPopup, setOpenPopup] = useState(false);
  const [errors, setErrors] = useState({});
  const [members, setMembers] = useState([]);
  const [emailSendTo, setEmailSendTo] = useState([]);
  const [enterprise, setEnterPrise] = useState(null);
  const [inviteSent, setInviteSent] = useState(false);

  const appContext = useContext(AppContext);

  const initialData = {
    firstName: "",
    lastName: "",
    email: "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email")
      .test(
        "restricted-domains",
        "Please register with a company email. As a B2B platform, we do not accept personal emails.",
        (value) => {
          if (value) {
            const domain = value.split("@")[1];
            return !unAuthorizedDomain.includes(domain);
          }
          return true;
        }
      )
      .required("Required"),
  });

  const checkMemberInList = (data) => {
    let memberExist = false;
    if (members.length) {
      memberExist = members.find((obj) => obj.email == data.email);
    }
    return memberExist ? true : false;
  };

  const addMemberInList = (data) => {
    setMembers([...members, data]);
  };

  const onRemoveMember = (member) => {
    const filterMember = members.filter((obj) => obj.email != member?.email);
    setMembers(filterMember);
  };

  const checkMember = async (setSubmitting) => {
    try {
      setSubmitting(true);

      const client = await getClientById(firebase, clientId);
      if (!client) {
        enqueueSnackbar("Error in fetching your profile", {
          variant: "error",
        });
        return;
      }
      let enterPriseId = client?.enterPriseId;
      if (!client?.enterPriseId) {
        /**----------------Create new enterprise, if it not exists ----------------------- */
        let currency = appContext?.userData?.currency || defaultCurrency;
        let clientApiKey = client?.apiKey;
        let resp = await createEnterPrise(clientId, currency, clientApiKey);
        if (!resp || resp.status == "Error") {
          enqueueSnackbar("Error in creating an enterprise", {
            variant: "error",
          });
          return;
        }
        enterPriseId = resp?.enterprise?.id;

        // appContext?.setEnterPrise(resp?.enterprise);
        // appContext?.setUserData({
        //   ...appContext?.userData,
        //   enterPriseId,
        //   roleType: enterPriseUserRole.admin,
        // });

        // Adjust the cookies
        // setCookie("enterpriseAccount", enterPriseId, { path: "/" });
        // removeCookie("addEnterPrise", { path: "/" });
      }
      const result = await checkEnterPriseMembers({
        members,
        clientId,
        enterPriseId,
      });
      if (result.status == "Error") {
        let error = result?.errors || {};
        if (result.enterprise) {
          setEnterPrise(result.enterprise);
        }
        setErrors(error);
        let unusedEmails =
          error[inviteErrorType.unused]?.length > 0
            ? error[inviteErrorType.unused]
            : [];
        if (unusedEmails.length) {
          var filterRec = members.filter((item) =>
            unusedEmails.includes(item.email)
          );
          setEmailSendTo(filterRec);
        }
        setOpenPopup(true);
      } else if (result.status == "Success") {
        // setOpenPopup(true);
        // setErrors({});
        // setEmailSendTo([]);
        invitationSent();
      }
    } catch (e) {
      console.log("Error", e);
    } finally {
      setSubmitting(false);
    }
  };

  const skipFcn = () => {
    getClientById(firebase, client?.id)
      .then((client) => {
        if (client) {
          let clientData = {
            name: client?.name || "",
            location: client?.location || "",
            companyName: client?.companyName || "",
            jobTitle: client?.jobTitle || "",
            industry: client?.industry || "",
            phone: client?.phone || "",
            email: client?.email || "",
            id: client?.id || "",
            enterPrise: client?.enterPrise || false,
            enterPriseId: client?.enterPriseId || "",
            roleType: client?.roleType || null,
            currency: client?.currency || null,
          };
          const userData = {
            user: {
              role: userRoles.client,
              email: client.email,
              userID: client.id,
              accessToken: client.accessToken,
            },
            userProfile: { ...clientData, role: userRoles.client },
          };
          // window.localStorage.setItem("signup", true);
          loginUserLocally(userData);
        } else {
          enqueueSnackbar("Error in processing!!", {
            variant: "error",
          });
        }
      })
      .catch((e) => {
        enqueueSnackbar("Error in processing", {
          variant: "error",
        });
        console.log("Error", e);
      });
  };

  const invitationSent = () => {
    if (useAsPopup) {
      onInviteSent();
    } else {
      setOpenPopup(false);
      setInviteSent(true);
    }
  };
  return (
    <Box>
      <Formik
        initialValues={initialData}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (values.email == email) {
            enqueueSnackbar("This your email you can't enter your own email", {
              variant: "error",
            });
            setSubmitting(false);
            return;
          }
          const memberExist = checkMemberInList(values);
          if (memberExist) {
            enqueueSnackbar("This email is already added in list", {
              variant: "error",
            });
          } else {
            addMemberInList(values);
            resetForm();
          }
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue, isSubmitting, setSubmitting }) => {
          return (
            <Form id="addMemberDetails">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextFieldInput
                    name="firstName"
                    label="First Name"
                    type="text"
                    setFieldValue={setFieldValue}
                    required={true}
                    values={values}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldInput
                    name="lastName"
                    label="Last Name"
                    type="text"
                    setFieldValue={setFieldValue}
                    required={true}
                    values={values}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextFieldInput
                    name="email"
                    label="Email Address"
                    type="email"
                    setFieldValue={setFieldValue}
                    required={true}
                    values={values}
                  />
                </Grid>
                {members.length > 0 && (
                  <>
                    <Grid item xs={12} md={6} sx={{ textAlign: "left" }}>
                      <AppButton
                        type="submit"
                        form="addMemberDetails"
                        look="ghost"
                        label="Add another member"
                        customIcon={<AddIcon />}
                        noIcon
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Card>
                        {members.map((member, i) => {
                          return (
                            <CardContent key={i} sx={styles.cardContentCls}>
                              <EnterPriseMember
                                member={member}
                                onRemove={onRemoveMember}
                                showDivider={
                                  i !== members.length - 1 ? true : false
                                }
                              />
                            </CardContent>
                          );
                        })}
                      </Card>
                    </Grid>
                  </>
                )}

                <Grid item xs={12} md={8} sx={styles.buttonCls}>
                  {showSkipBtn ? (
                    <AppButton
                      look="inverted"
                      small
                      onClick={skipFcn}
                      label="Skip"
                      noIcon
                    />
                  ) : (
                    <AppButton
                      look="inverted"
                      small
                      onClick={onClose}
                      label="Cancel"
                      noIcon
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={4} sx={styles.buttonCls}>
                  {members.length == 0 ? (
                    <AppButton
                      type="submit"
                      form="addMemberDetails"
                      look="black"
                      label="Add member"
                      customIcon={<AddIcon />}
                      noIcon
                      submittingState={isSubmitting}
                    />
                  ) : (
                    <AppButton
                      // type="submit"
                      form="addMemberDetails"
                      look="black"
                      label="Send invitation"
                      submittingState={isSubmitting}
                      onClick={() => checkMember(setSubmitting)}
                    />
                  )}
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>

      {openPopup && (
        <ConfirmationPopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          errors={errors}
          emailSendTo={emailSendTo}
          clientId={clientId}
          goToDashboard={skipFcn}
          enterprise={enterprise}
          onSuccess={invitationSent}
        />
      )}

      {inviteSent && (
        <AfterInvitationPopup
          openPopup={inviteSent}
          setOpenPopup={setInviteSent}
          goToDashboard={skipFcn}
        />
      )}
    </Box>
  );
};

EnterPriseMemberForm.propTypes = {
  client: PropTypes.object.isRequired,
  showSkipBtn: PropTypes.bool,
  onClose: PropTypes.func,
  useAsPopup: PropTypes.bool,
  onInviteSent: PropTypes.func,
};

// EnterPriseMemberForm.defaultProps = {
//   showSkipBtn: true,
//   onClose: undefined,
//   useAsPopup: false,
//   onInviteSent: undefined,
// };

export default EnterPriseMemberForm;
