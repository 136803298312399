import React from "react";
import SignInUpForm from "../../../components/forms/SignInUp/SignInUpForm";
import { userRoles,formTypeConstantForLogin } from "../../../lib/constants";
import Seo from "../../../components/Seo";
import globhePng from "../../../assets/logos/globhe_logo/Globhe.png";

const ClientSignUp = () => {
  return (
    <>
      <Seo
        title={
          "Register to order drone data on demand now | Client Sign Up at Globhe"
        }
        image={globhePng}
        description={`Connect to drone operators anywhere. Sign up as a Globhe 
        client and harness the power of aerial data for your project. 
        Onboard today!`} //image={blogsImage}
      />
      <SignInUpForm formType={formTypeConstantForLogin.signup} userType={userRoles.client} />
    </>
  );
};

export default ClientSignUp;
