import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { getMission } from "../../../services/missions";
import firebase from "src/firebase";
import PropTypes from "prop-types";
import { getUser } from "../../../services/auth";
import { navigate } from "gatsby";
import UniversalLoader from "../../global/elements/UniversalLoader";
import { packageTypeConstant, userRoles, users } from "../../../lib/constants";
import { useGetDeliveryPackages } from "../../../hooks/useGetDeliveryPackages";
import BackNav from "../../global/elements/BackNav";
import MenuBar from "../../global/elements/MenuBar";
import {
  singlePageOuterContainer,
  singlePageMainContainer,
} from "../../../lib/styleConstants";
import DeliveryPackagesTab from "../missions/FilesPackages/DeliveryPackagesTab";

const styles = {
  headcontainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  bodyHeader: {
    fontWeight: 600,
    marginTop: "17px",
  },
  profileHeaderContainer: {
    width: { xs: "0px", md: "350px" },
    display: { xs: "none", md: "block" },
  },
  backBtnContainer: {
    marginTop: "15px",
  },
};

const DownloadPurchasedData = ({ missionId = "" }) => {
  const [missionData, setMissionData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { packages: pilotPackages = [] } = useGetDeliveryPackages(missionId);

  const user = getUser(users.userProfile);

  useEffect(() => {
    if (missionId) {
      setLoading(true);
      getMission(firebase, missionId)
        .then((data) => {
          /** Check if the client has ordered this mission or
           * has purchased this mission else redirect to my-purchased data list */

          if (isClientCanDownloadMission(data) || data.freeToDownload) {
            setMissionData(data);
            setLoading(false);
          } else {
            navigate(`/app/client/data-library/purchased`);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log("Error", e);
        });
    }
  }, []);

  const isClientCanDownloadMission = (mission) => {
    let check = false;
    if (user && user.id && user.role == userRoles.client) {
      if (mission) {
        if (mission.clientId && mission.clientId == user.id) {
          check = true;
        } else if (
          mission.dataPurchasedBy &&
          mission.dataPurchasedBy.length &&
          mission.dataPurchasedBy.includes(user.id)
        ) {
          check = true;
        } else if (user.enterPriseId) {
          if (
            user.enterPriseId == mission.enterPriseId ||
            mission.dataPurchasedByEnterPrise?.includes(user.enterPriseId)
          ) {
            check = true;
          }
        }
      }
    }
    return check;
  };

  return (
    <>
      {!loading ? (
        <Box sx={singlePageOuterContainer}>
          <Box sx={singlePageMainContainer}>
            <Box>
              <MenuBar
                country={user?.location || ""}
                profileImage={user?.profileImageURL || ""}
              />
              <BackNav title="Download data" />
            </Box>
            <Box sx={styles.bodyContainer}>
              <Box sx={{ marginTop: "1em" }}>
                {/* {missionData && (
                  <DropzoneGallery
                    key="1"
                    packageTitle={`Delivery Package`}
                    mission={missionData}
                    mediaPackage={pilotPackages[0]}
                    isEditable={false}
                    isDeliveryPackage={true}
                  />
                )} */}
                {missionData && (
                  <DeliveryPackagesTab
                    packageId={pilotPackages[0]?.id}
                    mission={missionData}
                    packageType={packageTypeConstant.deliveryPackage}
                    mediaPackage={pilotPackages[0]}
                    isEditable={false}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ paddingTop: "18%" }}>
          <UniversalLoader />
        </Box>
      )}
    </>
  );
};

DownloadPurchasedData.propTypes = {
  missionId: PropTypes.string.isRequired,
};

export default DownloadPurchasedData;
