import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import theme from "../../../../../theme";
import GlobalTable from "../../../admin/GlobalTable";
import {
  columnsForApiKeys,
  apiKeyStatus,
  colorStatus,
} from "../../../../../../src/lib/constants";
import StatusBadge from "../../../../global/elements/StatusBadge";
import { navigate } from "gatsby";
import { Box } from "@mui/material";
const { DateTime } = require("luxon");

const styles = {
  column: {
    float: "left",
    clear: "none",
    display: "flex",
    alignItems: "flex-start",
    padding: "0 15px",
    marginBottom: "1rem",
  },
  bold: {
    fontWeight: "500",
    fontSize: "1rem",
  },
  row: {
    position: "relative",
    width: "100%",
    display: "flex",
  },
  loadMore: {
    textAlign: "center",
  },
  loadMoreRow: {
    position: "relative",
    maxWidth: "100%",
  },
  countBadge: {
    float: "left",
    background: theme.palette.primary.pistachio,
    borderRadius: "50%",
    color: theme.palette.grey.white,
    textAlign: "center",
    padding: "5px 8.5px",
    fontWeight: "500",
    fontSize: "0.6rem",
    minWidth: "28px",
    marginTop: "7px",
    marginLeft: "12px",
    [theme.breakpoints.up("lg")]: {
      minWidth: "36px",
      fontSize: "1rem",
    },
  },
  statusWrapper: {
    marginBottom: "5px",
    maxWidth: "45%",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      maxWidth: "100%",
    },
  },
  statusIndicator: {
    float: "left",
    width: "8px",
    height: "8px",
    margin: "5px",
    borderRadius: "50%",
  },
  red: {
    background: theme.palette.status.errorDark,
  },
  yellow: {
    background: theme.palette.status.warningDark,
  },
  green: {
    background: theme.palette.primary.main,
  },
  purple: {
    background: theme.palette.primary.main,
  },
  button: {
    color: theme.palette.grey.white,
    fontWeight: "500",
    padding: "15px",
    width: "200px",
    background: theme.palette.secondary.main,
  },
};

const ApiKeysTable = ({
  loading = false,
  keys = [],
  apiKeySet = undefined,
}) => {
  const limitHandler = (value) => {
    apiKeySet.setLimit(value);
    apiKeySet.setLoadDataHandler(!apiKeySet.loadDataHandler);
    apiKeySet.setPageNumber(1);
    apiKeySet.setIsLastPage(false);
    apiKeySet.setLastLoadedPageNumber(0);
    apiKeySet.setLastPageNumber(null);
    apiKeySet.setLastLoadedApiKey(null);
  };

  const paginationBtnClick = (type) => {
    let pageNumberNew = apiKeySet.pageNumber;
    if (type == "next") {
      if (!(apiKeySet.lastPageNumber === apiKeySet.pageNumber)) {
        pageNumberNew = pageNumberNew + 1;
        apiKeySet.setLoadDataHandler(!apiKeySet.loadDataHandler);
      }
    } else {
      pageNumberNew = pageNumberNew - 1;
      apiKeySet.setLoadDataHandler(!apiKeySet.loadDataHandler);
    }
    apiKeySet.setPageNumber(pageNumberNew);
  };

  const goToPageFcn = (value) => {
    navigate(`/app/admin/api-keys/${value.id}`);
  };

  const valueConvertHandler = (column, value) => {
    // switch (column) {
    //   case "date": {
    //     return (
    //       <Typography variant="h5">
    //         {DateTime.fromISO(value["dateCreated"]).toISODate()}
    //       </Typography>
    //     );
    //   }
    //   case "status": {
    //     return (
    //       <Box sx={styles.statusWrapper}>
    //         <StatusPage
    //           name={
    //             value[column].charAt(0).toUpperCase() +
    //               value[column].slice(1).toLowerCase() || "n/a"
    //           }
    //           status={
    //             value[column].charAt(0).toUpperCase() +
    //             value[column].slice(1).toLowerCase()
    //           }
    //         />
    //       </Box>
    //     );
    //   }
    //   default:
    //     return;
    // }
    return <></>;
  };

  if (loading) return null;

  return (
    <Box>
      <GlobalTable
        columns={columnsForApiKeys}
        allData={keys}
        valueConvertHandler={valueConvertHandler}
        goToPageFcn={goToPageFcn}
        loading={loading}
        limitHandler={limitHandler}
        limit={apiKeySet.limit}
        paginationBtnClick={paginationBtnClick}
        currentPage={apiKeySet.pageNumber}
        isLastPage={apiKeySet.isLastPage}
        lastPage={apiKeySet.lastPageNumber}
      />
    </Box>
  );
};

ApiKeysTable.propTypes = {
  loading: PropTypes.bool,
  keys: PropTypes.array,
  status: PropTypes.string.isRequired,
  apiKeySet: PropTypes.object.isRequired,
};

export default ApiKeysTable;
