import PropTypes from "prop-types";
import React, { useState } from "react";
import useGetPilot from "src/hooks/useGetPilot";
import PilotNote from "./PilotNote";
import theme from "../../../theme";
import { Box, Typography } from "@mui/material";
import ProfileInfoPage from "../../global/elements/ProfileInfoPage";
import AppButton from "../../global/elements/AppButton";
import Layout from "../../../Layout";
import UniversalLoader from "../../global/elements/UniversalLoader";
import { boxShadowStyle, submitCancelBox } from "../../../lib/styleConstants";
import Dropzone from "react-dropzone";
import {
  getCurrentDateTime,
  getCurrentDateTimeMillis,
  uploadFileToStorage,
} from "../../../lib/helpers";
import firebase from "src/firebase";
import { updatePilotProfile } from "../../../services/pilots";
import { useSnackbar } from "notistack";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";

const style = {
  "@global body": {
    background: theme.palette.grey.lightWarmGrey,
  },
  noteBox: {
    padding: "30px",
    height: "100%",
    margin: "20px 0 60px 0",
    backgroundColor: theme.palette.adminBlue.paleBlue,
  },
  profileRoot: {
    background: theme.palette.grey.white,
    paddingTop: "10px",
  },
  loaderDiv: {
    paddingTop: "18%",
  },
  progress_bar_main: {
    padding: "10px 15px",
  },
  marginRight: {
    marginLeft: "20px",
  },
  floatRight: {
    float: "right",
  },
};

const SinglePilot = ({ id = undefined }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [pilot, loading, authentication, reload, setReload] = useGetPilot(id);
  const [percent, setPercent] = useState(0);

  const pilotCSVUploadHandler = async (acceptedFiles) => {
    if (acceptedFiles?.length === 1) {
      let timeStamp = getCurrentDateTimeMillis();
      let file = acceptedFiles[0];
      let path = `AssociatedPilotFiles/${id}/${timeStamp}/${file.name}`;
      const fileUploadStatus = await uploadFileToStorage(
        firebase,
        file,
        path,
        setPercent
      );
      if (fileUploadStatus.status === "Success") {
        const data = {
          associatedPilotFile: fileUploadStatus?.fileURL,
          dateUpdated: getCurrentDateTime(),
        };
        updatePilotProfile(firebase, id, data)
          .then(() => {
            enqueueSnackbar("File added successfully!", {
              variant: "success",
            });
            setReload(!reload);
          })
          .catch((err) => {
            console.log("Error", err);
          });
      } else {
        enqueueSnackbar("There were error in uploading the files!", {
          variant: "error",
        });
      }

      console.log("fileUploadStatus", fileUploadStatus);
    } else if (acceptedFiles?.length > 1) {
      enqueueSnackbar("Please upload one document at a time!", {
        variant: "warning",
      });
    }
  };

  return (
    <Layout pageTitle="Drone Operator" loading={loading} hasSideMenu>
      {!loading ? (
        <>
          <Box sx={style.profileRoot}>
            <Box sx={submitCancelBox}>
              <AppButton
                label="Back to table"
                look="inverted"
                noIcon
                url="/app/pilots/table"
                internalLink
              />
              <AppButton
                url={`/app/pilots/${id}/edit`}
                internalLink
                label="Edit profile"
                look="blue"
              />
            </Box>
            <Box mt={2} sx={{ ...boxShadowStyle, ...style.progress_bar_main }}>
              <Typography variant="h6" mb={2}>
                Upload CSV for associated drone operators.
              </Typography>
              <ul>
                <li>
                  <Typography variant="body1">
                    Please upload a CSV file to show how many certified drone
                    operators are associated with the operator.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    CSV file must contain name and email of the drone operators.
                  </Typography>
                </li>
              </ul>
              <Dropzone
                accept=".csv"
                onDrop={(acceptedFiles) => pilotCSVUploadHandler(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} style={{ ...style.floatRight }}>
                    <input {...getInputProps()} style={{ display: "none" }} />
                    <AppButton
                      label={
                        pilot?.associatedPilotFile
                          ? "Replace file"
                          : "Upload CSV"
                      }
                      look="blue"
                      addtionalStyle={{
                        ...style.marginRight,
                      }}
                    />
                  </div>
                )}
              </Dropzone>
              {pilot?.associatedPilotFile && (
                <AppButton
                  label="Download uploaded file"
                  look="pilotLink"
                  customIcon={<CloudDownloadOutlinedIcon />}
                  addtionalStyle={style.floatRight}
                  targetNewWidow
                  externalLink
                  url={pilot?.associatedPilotFile}
                />
              )}
              <br />
              <br />
            </Box>
            <Box sx={style.profileBox}>
              <ProfileInfoPage
                user_data={pilot}
                authentication={authentication}
              />
              <Box style={style.noteBox}>
                <PilotNote pilotId={id} />
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box sx={style.loaderDiv}>
          <UniversalLoader />
        </Box>
      )}
    </Layout>
  );
};
SinglePilot.propTypes = {
  id: PropTypes.string,
};

export default SinglePilot;
