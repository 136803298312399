import React, { useEffect, useState } from "react";
import ProjectPage from "./ProjectPage";
import PropTypes from "prop-types";
import { getUser } from "../../../../services/auth";
import {
  columnForAdminDraftProjects,
  columnForClientProjects,
  userRoles,
  users,
} from "../../../../lib/constants";
import Layout from "../../../../Layout";
import GuestStatusAlert from "../../AnonymousUser/GuestStatusAlert";
import OrderButton from "../../Client/Orders/OrderButton";
import { Box } from "@mui/material";
import Tips from "../../../global/elements/Tips";

const ProjectTable = ({
  status = undefined,
  pageTitle = undefined,
  tips = undefined,
}) => {
  const user = getUser("user");
  const isAdmin = user?.role === userRoles.admin;
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    let userProfile = getUser(users.userProfile);
    setCurrentUser(userProfile);
  }, []);

  const columns = isAdmin
    ? columnForAdminDraftProjects
    : columnForClientProjects;

  return (
    <Layout pageTitle={pageTitle} loading={false} hasSideMenu addBottomPadding>
      {user?.isAnonymous && <GuestStatusAlert />}
      {tips && !isAdmin && <Tips content={tips} />}
      <Box sx={{ paddingTop: "1em" }}>
        <OrderButton
          link="/app/order/create-order"
          look={isAdmin ? "blue" : "green"}
        />
      </Box>
      <ProjectPage
        status={status}
        columns={columns}
        enterpriseId={currentUser?.enterPriseId || null}
      />
    </Layout>
  );
};

ProjectTable.propTypes = {
  status: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  tips: PropTypes.string,
};
// ProjectTable.defaultProps = {
//   tips: undefined,
// };

export default ProjectTable;
