import React from "react";
import {
  Alert,
  Box,
  IconButton,
  Collapse,
  Typography,
  AlertTitle,
} from "@mui/material";
import theme from "../../../theme";
import PropTypes from "prop-types";
import { boxShadowStyle } from "../../../lib/styleConstants";
import Close from "@mui/icons-material/Close";
import { statusAlertSeverityColor } from "../../../lib/constants";

const styles = {
  root: {
    width: "100%",
    background: theme.palette.grey.white,
    border: "none",
    display: "flex",
    alignItems: "center",
  },
  mainBox: { width: "100%" },
};

// in this component, choose among three severities: "error", "warning", "success"
const StatusAlertContainer = ({
  severity = "default",
  coloredBackground = false,
  heading = undefined,
  showClose = false,
  actionHandler = undefined,
  title = undefined,
  showLeftBorder = false,
}) => {
  const [open, setOpen] = React.useState(true);

  return (
    <Box sx={styles.mainBox}>
      <Collapse in={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              {showClose && (
                <Close fontSize="inherit" onClick={() => actionHandler()} />
              )}
            </IconButton>
          }
          sx={
            coloredBackground
              ? {
                  background: coloredBackground,
                  borderLeft: showLeftBorder
                    ? `4px solid ${statusAlertSeverityColor[severity]}`
                    : "none",
                }
              : {
                  ...styles.root,
                  ...boxShadowStyle,
                  borderLeft: showLeftBorder
                    ? `4px solid ${statusAlertSeverityColor[severity]}`
                    : "none",
                }
          }
          variant={coloredBackground ? "standard" : "outlined"}
          severity={severity}
        >
          {title && (
            <AlertTitle>
              <Typography
                variant="h6"
                sx={{
                  color: coloredBackground
                    ? theme.palette.grey.deepWarmGrey
                    : theme.palette.black.darkSeaBlack,
                  marginBottom: "0px",
                }}
              >
                {title}
              </Typography>
            </AlertTitle>
          )}
          <Typography
            variant="body1"
            sx={{
              color: coloredBackground
                ? theme.palette.grey.deepWarmGrey
                : theme.palette.black.darkSeaBlack,
            }}
          >
            {heading}
          </Typography>
        </Alert>
      </Collapse>
    </Box>
  );
};

StatusAlertContainer.propTypes = {
  severity: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  coloredBackground: PropTypes.bool,
  actionHandler: PropTypes.func,
  showClose: PropTypes.bool,
  title: PropTypes.string,
  showLeftBorder: PropTypes.bool,
};

// StatusAlertContainer.defaultProps = {
//   severity: "default",
//   coloredBackground: false,
//   heading: undefined,
//   showClose: false,
//   actionHandler: undefined,
//   title: undefined,
//   showLeftBorder: false,
// };

export default StatusAlertContainer;
