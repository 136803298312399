import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Chip, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

const ChipWithCrossIcon = ({ keyword = undefined, crossClick = undefined }) => {
  const styles = {
    chipStyle: {
      direction: "rtl",
      borderRadius: 0,
      paddingRight: "12px",
      width: "100% !important",
      justifyContent: "space-between !important",
    },
    crossIcon: {
      cursor: "pointer",
    },
  };
  return (
    <Tooltip title={keyword}>
      <Chip
        label={keyword}
        icon={<CloseIcon onClick={crossClick} sx={styles.crossIcon} />}
        sx={styles.chipStyle}
      />
    </Tooltip>
  );
};

ChipWithCrossIcon.propTypes = {
  keyword: PropTypes.string.isRequired,
  crossClick: PropTypes.func,
};

// ChipWithCrossIcon.defaultProps = {
//   crossClick: undefined,
// };

export default ChipWithCrossIcon;
