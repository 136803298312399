import React from "react";
import useGetNews from "../../../hooks/useGetNews";
import EditCreateNews from "./EditCreateNews";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const UpdateNews = ({ newsId = undefined }) => {
  const [news, loading] = useGetNews(newsId);

  return (
    <Box>
      <EditCreateNews
        pageTitle="Edit News"
        news={news}
        update={true}
        newsId={newsId}
        loading={loading}
      />
    </Box>
  );
};
UpdateNews.propTypes = {
  newsId: PropTypes.string,
};
// UpdateNews.defaultProps = {
//   newsId: undefined,
// };

export default UpdateNews;
