import React from "react";
import theme from "../../../theme";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { iconsSize, textIconsSize } from "../../../lib/styleConstants";
import NotificationsIcon from "@mui/icons-material/Notifications";

const styles = {
  notificationIcon: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  addBackgroundCircleProps: {
    transform: "scale(0.5)",
    color: theme.palette.black.darkSeaBlack,
  },
  notificationIconBackground: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    zIndex: "-1",
    scale: "0.8",
  },
  ovalIconBackground: {
    position: "absolute",
    top: "0",
    left: "0",
    height: "40px",
    width: "90px",
    borderRadius: "20px 20px 20px 20px",
    zIndex: "-1",
    scale: "0.8",
  },
  textIconCls: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
};

const TopMenuIcons = ({
  onClick = undefined,
  addBackgroundCircle = false,
  addBackgroundOval = false,
  showIcon = true,
  showText = false,
  textValue = undefined,
  icon: Icon = NotificationsIcon,
}) => {
  const TopMenuIconsComp = ({ iconStyles = {}, boxStyles = {} }) => {
    return (
      <>
        {showIcon && <Icon style={iconStyles} onClick={onClick} />}
        {showText && (
          <Typography
            sx={{ ...iconStyles, ...styles.textIconCls }}
            variant="h4"
            align={"top"}
          >
            {textValue}
          </Typography>
        )}
        <Box style={boxStyles} />
      </>
    );
  };

  TopMenuIconsComp.propTypes = {
    iconStyles: PropTypes.object,
    boxStyles: PropTypes.object,
  };

  // TopMenuIconsComp.defaultProps = {
  //   iconStyles: {},
  //   boxStyles: {},
  // };

  return (
    <Box sx={showText ? textIconsSize : iconsSize}>
      {addBackgroundCircle ? (
        <>
          <TopMenuIconsComp
            iconStyles={{
              ...styles.notificationIcon,
              ...styles.addBackgroundCircleProps,
            }}
            boxStyles={{
              ...styles.notificationIconBackground,
              backgroundColor: theme.palette.black.paleSilver,
            }}
          />
        </>
      ) : addBackgroundOval ? (
        <>
          <TopMenuIconsComp
            iconStyles={{
              ...styles.notificationIcon,
              ...styles.addBackgroundCircleProps,
            }}
            boxStyles={{
              ...styles.ovalIconBackground,
              backgroundColor: theme.palette.black.paleSilver,
            }}
          />
        </>
      ) : (
        <>
          <TopMenuIconsComp
            iconStyles={{
              ...styles.notificationIcon,
              color: theme.palette.black.paleSilver,
            }}
            boxStyles={{
              ...styles.notificationIconBackground,
              backgroundColor: theme.palette.black.darkSeaBlack,
            }}
          />
        </>
      )}
    </Box>
  );
};

TopMenuIcons.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.elementType,
  addBackgroundCircle: PropTypes.bool,
  addBackgroundOval: PropTypes.bool,
  showIcon: PropTypes.bool,
  showText: PropTypes.bool,
  textValue: PropTypes.string,
};
// TopMenuIcons.defaultProps = {
//   onClick: undefined,
//   addBackgroundCircle: false,
//   addBackgroundOval: false,
//   showIcon: true,
//   showText: false,
//   textValue: undefined,
//   icon: NotificationsIcon,
// };

export default TopMenuIcons;
