import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { getDailyReportLabelData } from "../../../lib/helpers";
import theme from "../../../theme";
import {
  columnsForDailyReport,
  colorStatus,
} from "../../../../src/lib/constants";
import StatusBadge from "../../global/elements/StatusBadge";
import GlobalTable from "../admin/GlobalTable";
import { navigate } from "gatsby";
import { Box } from "@mui/material";

const styles = {
  column: {
    float: "left",
    clear: "none",
    display: "flex",
    alignItems: "flex-start",
    padding: "0 15px",
    marginBottom: "1rem",
  },
  bold: {
    fontWeight: "500",
    fontSize: "1rem",
  },
  row: {
    position: "relative",
    maxWidth: "100%",
    display: "flex",
  },
  loadMore: {
    textAlign: "center",
  },
  loadMoreRow: {
    position: "relative",
    maxWidth: "100%",
  },

  statusWrapper: {
    marginBottom: "5px",
    maxWidth: "100%",
    display: "flex",
  },
  statusIndicator: {
    float: "left",
    width: "8px",
    height: "8px",
    margin: "5px",
    borderRadius: "50%",
  },
  bold_info: {
    fontWeight: "500",
    cursor: "pointer",
  },
  red: {
    background: theme.palette.status.errorDark,
  },
  yellow: {
    background: theme.palette.status.warningDark,
  },
  green: {
    background: theme.palette.primary.main,
  },
  purple: {
    background: theme.palette.primary.main,
  },
  button: {
    color: theme.palette.grey.white,
    fontWeight: "500",
    padding: "15px",
    width: "200px",
    background: theme.palette.secondary.main,
  },
};

const DailyReportsTable = ({
  dailyReports = [],
  loading = false,
  dailyReportList = {},
}) => {
  const limitHandler = (value) => {
    dailyReportList.setLimit(value);
    dailyReportList.setLoadDataHandler(!dailyReportList.loadDataHandler);
    dailyReportList.setPageNumber(1);
    dailyReportList.setIsLastPage(false);
    dailyReportList.setLastLoadedPageNumber(0);
    dailyReportList.setLastPageNumber(null);
    dailyReportList.setLastLoadedDailyReport(null);
  };

  const paginationBtnClick = (type) => {
    let pageNumberNew = dailyReportList.pageNumber;
    if (type == "next") {
      if (!(dailyReportList.lastPageNumber === dailyReportList.pageNumber)) {
        pageNumberNew = pageNumberNew + 1;
        dailyReportList.setLoadDataHandler(!dailyReportList.loadDataHandler);
      }
    } else {
      pageNumberNew = pageNumberNew - 1;
      dailyReportList.setLoadDataHandler(!dailyReportList.loadDataHandler);
    }
    dailyReportList.setPageNumber(pageNumberNew);
  };

  const goToPageFcn = (value) => {
    navigate(`/app/admin/daily-reports/${value.id}`);
  };

  const valueConvertHandler = (column, value) => {
    switch (column) {
      case "status": {
        const statusLabel = getDailyReportLabelData(value);

        return (
          <Box sx={styles.statusWrapper}>
            <StatusBadge
              name={value["status"] || "n/a"}
              status={
                statusLabel.statusColor === "green"
                  ? colorStatus.green
                  : colorStatus.red
              }
            />
          </Box>
        );
      }
      default:
        return;
    }
  };

  const noDataMessage = "No Daily Reports";

  if (loading) return null;

  return (
    <div>
      <GlobalTable
        columns={columnsForDailyReport}
        allData={dailyReports}
        valueConvertHandler={valueConvertHandler}
        goToPageFcn={goToPageFcn}
        loading={loading}
        noDataMessage={noDataMessage}
        limitHandler={limitHandler}
        limit={dailyReportList.limit}
        paginationBtnClick={paginationBtnClick}
        currentPage={dailyReportList.pageNumber}
        isLastPage={dailyReportList.isLastPage}
        lastPage={dailyReportList.lastPageNumber}
      />
    </div>
  );
};

DailyReportsTable.propTypes = {
  loading: PropTypes.bool,
  dailyReports: PropTypes.array,
  dailyReportList: PropTypes.object,
};

// DailyReportsTable.defaultProps = {
//   dailyReports: [],
//   loading: false,
//   dailyReportList: {},
// };

export default DailyReportsTable;
