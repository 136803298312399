import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import React from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import TextFieldInput from "./TextFieldInput";
import PropTypes from "prop-types";
import theme from "../../../theme";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { userRoles } from "../../../lib/constants";
import { getUser } from "../../../services/auth";

const styles = {
  marginL: { marginRight: "45px" },
  textFieldMargin: { marginTop: "25px", marginBottom: "40px" },
  errorText: { color: theme.palette.status.errorDark },
  colorPilot: {
    "&.Mui-checked": {
      color: theme.palette.secondary.main,
    },
  },
  colorAdmin: {
    "&.Mui-checked": {
      color: theme.palette.adminBlue.main,
    },
  },
  colorClient: {
    "&.Mui-checked": {
      color: theme.palette.primary.main,
    },
  },
};

const SingleMultipleCheckbox = ({
  multiple = undefined,
  checkboxFields = undefined,
  values = undefined,
  setFieldValue = undefined,
  others = undefined,
  otherReasonFieldName = undefined,
  otherReasonLabel = undefined,
  errors = {},
  errorFieldName = "",
  vertical = false,
  disabled = false,
  circluar = false,
}) => {
  const [showOtherInputBox, setShowOtherInputBox] = useStateIfMounted(false);
  const user = getUser("user")?.role;

  const isPilot = user === userRoles.pilot;
  const isAdmin = user === userRoles.admin;

  const onChangeHandler = (e, fieldName) => {
    if (!multiple) {
      checkboxFields?.forEach((fieldInfo) => {
        if (fieldInfo.name !== fieldName) {
          setFieldValue(fieldInfo.name, false);
        }
      });
      setFieldValue(fieldName, e.target.checked);
    } else {
      setFieldValue(fieldName, e.target.checked);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: vertical ? "grid" : "flex",
          "& label": {
            paddingTop: vertical ? "15px" : "auto",
          },
          // margin: vertical ? "20px 0" : "auto",
        }}
      >
        {checkboxFields?.map((fieldInfo, index) => {
          return (
            <>
              <FormControlLabel
                key={index}
                sx={{
                  ...styles.marginL,
                  // padding: vertical ? "20px" : "auto",
                }}
                control={
                  <Checkbox
                    checked={values[fieldInfo.name]}
                    onChange={(e) => onChangeHandler(e, fieldInfo.name)}
                    name={fieldInfo.name}
                    disabled={disabled}
                    size={vertical ? "large" : "medium"}
                    icon={
                      circluar ? (
                        <RadioButtonUncheckedIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )
                    }
                    checkedIcon={
                      circluar ? <RadioButtonCheckedIcon /> : <CheckBoxIcon />
                    }
                    sx={
                      isPilot
                        ? styles.colorPilot
                        : isAdmin
                        ? styles.colorAdmin
                        : styles.colorClient
                    }
                  />
                }
                label={fieldInfo.label}
              />
            </>
          );
        })}
        {others && (
          <FormControlLabel
            sx={styles.marginL}
            control={
              <Checkbox
                checked={showOtherInputBox}
                onChange={(e) => {
                  setShowOtherInputBox(e.target.checked);
                }}
                name={"enableOther"}
                sx={
                  isPilot
                    ? styles.colorPilot
                    : isAdmin
                    ? styles.colorAdmin
                    : styles.colorClient
                }
              />
            }
            label={"Other - please describe"}
          />
        )}
      </Box>
      {showOtherInputBox && (
        <Box sx={styles.textFieldMargin}>
          <TextFieldInput
            setFieldValue={setFieldValue}
            values={values}
            name={otherReasonFieldName}
            type="text"
            required
            placeholder={otherReasonLabel}
          />
        </Box>
      )}
      {errors && errors[errorFieldName] && (
        <Typography variant="body3" sx={styles.errorText}>
          {errors[errorFieldName]}
        </Typography>
      )}
    </>
  );
};

SingleMultipleCheckbox.propTypes = {
  multiple: PropTypes.bool,
  checkboxFields: PropTypes.array,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  others: PropTypes.bool,
  otherReasonFieldName: PropTypes.string,
  otherReasonLabel: PropTypes.string,
  errors: PropTypes.object,
  errorFieldName: PropTypes.string,
  vertical: PropTypes.bool,
  disabled: PropTypes.bool,
  circluar: PropTypes.bool,
};

// SingleMultipleCheckbox.defaultProps = {
//   multiple: undefined,
//   checkboxFields: undefined,
//   values: undefined,
//   setFieldValue: undefined,
//   others: undefined,
//   otherReasonFieldName: undefined,
//   otherReasonLabel: undefined,
//   errors: {},
//   errorFieldName: "",
//   vertical: false,
//   disabled: false,
//   circluar: false,
// };

export default SingleMultipleCheckbox;
