import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../src/theme";
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";

const materialTheme = materialExtendTheme(theme);

export default function TopLayout({children=undefined}) {
  return (
    <React.Fragment>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {/* this is a new workaround that was added to integrated Joy UI together with material UI.  */}
      <ThemeProvider theme={theme}>
        <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
          <JoyCssVarsProvider>
            <CssBaseline enableColorScheme />
            {children}
          </JoyCssVarsProvider>
        </MaterialCssVarsProvider>
      </ThemeProvider>
      {/* Use the old way to call the theme in case we do not want to use Joy UI anymore. This is to reduce the bundle size. */}
      {/* <ThemeProvider theme={theme}>
        <CssBaseline />
        {props.children}
      </ThemeProvider> */}
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};

// TopLayout.defaultProps = {
//   children: undefined,
// };
