import React, { useState } from "react";
import PropTypes from "prop-types";
import { Avatar, Box } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MyProfilePopup from "./MyProfilePopup";

const styles = {
  avatar: {
    fontSize: "40px",
  },
  boxContainer: { display: "flex", flexWrap: "nowrap" },
};

const MyProfile = ({ profileImage = null }) => {
  const id = "profile-panel";
  const [anchorElProfile, setAnchorElProfile] = useState(null);

  const handleClick = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorElProfile(null);
  };

  return (
    <Box sx={styles.boxContainer}>
      {profileImage ? (
        <Avatar src={profileImage} onClick={handleClick} />
      ) : (
        <AccountCircle sx={styles.avatar} onClick={handleClick} />
      )}
      <MyProfilePopup
        anchorElProfile={anchorElProfile}
        handleClose={handleClose}
        id={id}
      />
    </Box>
  );
};

MyProfile.propTypes = {
  profileImage: PropTypes.string,
};

// MyProfile.defaultProps = {
//   profileImage: null,
// };

export default MyProfile;
