import React from "react";
import { TextField } from "@mui/material";
import PropTypes from "prop-types";
import { StyledAutocomplete } from "../../../lib/styleConstants";

const AutoCompleteFilter = ({
  filterOptions = undefined,
  filterField = undefined,
  onChangeFilterField = undefined,
  helperText = undefined,
  additionalStyle = {},
  multiple = true,
  helperTextStyle = {},
}) => {
  return (
    <StyledAutocomplete
      multiple={multiple}
      id="filter-fields"
      options={filterOptions}
      value={filterField}
      onChange={onChangeFilterField}
      disableClearable={false}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Select"
          placeholder="Filters"
          helperText={helperText}
          notchedoutline={{ borderRadius: "10px" }}
          style={additionalStyle}
          FormHelperTextProps={{
            sx: helperTextStyle,
          }}
        />
      )}
    />
  );
};

AutoCompleteFilter.propTypes = {
  filterOptions: PropTypes.array.isRequired,
  filterField: PropTypes.array.isRequired,
  onChangeFilterField: PropTypes.func.isRequired,
  helperText: PropTypes.string.isRequired,
  additionalStyle: PropTypes.object,
  multiple: PropTypes.bool,
  helperTextStyle: PropTypes.object,
};
// AutoCompleteFilter.defaultProps = {
//   additionalStyle: {},
//   multiple: true,
//   helperTextStyle: {},
// };
export default AutoCompleteFilter;
