import React from "react";
import { Box, Avatar, Typography } from "@mui/material";
import theme from "../../../theme";
import PropTypes from "prop-types";
import WbIncandescentOutlinedIcon from "@mui/icons-material/WbIncandescentOutlined";
import ApiIcon from "@mui/icons-material/Api";
import { getUser } from "../../../services/auth";
import { userRoles } from "../../../lib/constants";
import AppButton from "./AppButton";

const styles = {
  main: {
    display: "flex",
    width: "100%",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRight: { xs: "15px", lg: "50px" },
    paddingLeft: "10px",
    marginTop: "10px",
    alignItems: "center",
  },
  noBackground: {
    border: `1px solid ${theme.palette.grey.lightWarmGrey}`,
    //background: theme.palette.grey.lightWarmGrey,
  },
  colorAdmin: {
    background: theme.palette.adminBlue.paleBlue,
  },
  colorClient: {
    background: theme.palette.primary.palePistachio,
  },
  colorPilot: {
    background: theme.palette.secondary.paleLavender,
  },
  iconAdmin: {
    color: theme.palette.adminBlue.paleBlue,
  },
  iconClient: {
    color: theme.palette.black.darkSeaBlack,
  },
  iconPilot: {
    color: theme.palette.secondary.lavender,
  },
  iconStyle: {
    transform: "rotate(180deg)",
    fontSize: "30px",
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  text: {
    fontSize: { xs: "11px", lg: "13px" },
    fontWeight: 400,
    color: theme.palette.black.darkSeaBlack,
    marginLeft: "18px",
  },
};

const Tips = ({
  content = undefined,
  subContent = undefined,
  isAPI = false,
  noBackgroundColor = false,
  supportButton = false,
}) => {
  const currentUser = getUser("user");

  const isClient = currentUser.role === userRoles.client;
  const isAdmin = currentUser.role === userRoles.admin;

  return (
    <Box
      sx={{
        ...styles.main,
        ...(noBackgroundColor
          ? styles.noBackground
          : isAdmin
          ? styles.colorAdmin
          : isClient
          ? styles.colorClient
          : styles.colorPilot),
      }}
    >
      <Avatar sx={{ backgroundColor: "white !important" }}>
        {isAPI ? (
          <ApiIcon
            sx={{
              ...styles.iconStyle,
              ...(isAdmin
                ? styles.iconAdmin
                : isClient
                ? styles.iconClient
                : styles.iconPilot),
            }}
          />
        ) : (
          <WbIncandescentOutlinedIcon
            sx={{
              ...styles.iconStyle,
              ...(isAdmin
                ? styles.iconAdmin
                : isClient
                ? styles.iconClient
                : styles.iconPilot),
            }}
          />
        )}
      </Avatar>
      <Box sx={styles.textContainer}>
        <Box sx={styles.text}>
          <Typography variant="body1">{content}</Typography>
          {subContent && <Typography variant="body2">{subContent}</Typography>}
        </Box>{" "}
        {supportButton && (
          <AppButton
            label="Reach Out for Solutions"
            look={isClient ? "green" : isAdmin ? "blue" : "purple"}
            internalLink
            url="/app/support"
          />
        )}
      </Box>
    </Box>
  );
};

Tips.propTypes = {
  content: PropTypes.string.isRequired,
  subContent: PropTypes.string,
  isAPI: PropTypes.bool,
  noBackgroundColor: PropTypes.bool,
  supportButton: PropTypes.bool,
};

// Tips.defaultProps = {
//   subContent: undefined,
//   isAPI: false,
//   noBackgroundColor: false,
//   supportButton: false,
// };

export default Tips;
