import React from "react";
import SectionHeading from "../../global/elements/SectionHeading";
import Layout from "../../../Layout";
import theme from "../../../theme";
import FormComponent from "./FormComponent";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const EditCreateInspiration = ({
  pageTitle = undefined,
  inspiration = {},
  update = false,
  inspirationId = undefined,
  loading = false,
}) => {
  const myStyle = {
    tableContainer: {
      display: "flex",
      flexFlow: "wrap",
      justifyContent: "flex-start",
    },
    form_container: {
      width: "100%",
      borderRadius: theme.spacing(1),
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
      marginTop: theme.spacing(4),
      padding: { xs: "30px 20px", md: "30px 40px" },
    },
    input_container: {
      margin: theme.spacing(1),
      color: theme.palette.grey.warmGrey,
    },
    label_text: {
      marginBottom: theme.spacing(0.5),
    },
    input_box: {
      width: "100%",
      height: theme.spacing(4),
      borderRadius: theme.spacing(1),
      border: `1px solid ${theme.palette.grey.warmGrey}`,
      marginTop: theme.spacing(0.5),
      padding: theme.spacing(1),
      fontSize: theme.spacing(1.6),
      color: theme.palette.grey.warmGrey,
      "&:focus": {
        border: `1px solid ${theme.palette.grey.warmGrey}`,
      },
    },
    button: {
      height: theme.spacing(3),
      float: "right",
      width: theme.spacing(10),
      borderRadius: theme.spacing(1.5),
      background: theme.palette.primary.main,
      color: theme.palette.grey.white,
      border: "none",
      fontSize: "16px",
      fontWeight: 540,
      cursor: "pointer",
    },
    button_reject: {
      height: theme.spacing(3),
      float: "right",
      width: theme.spacing(10),
      borderRadius: theme.spacing(1.5),
      background: "none",
      color: theme.palette.status.errorDark,
      border: `1px solid ${theme.palette.status.errorDark}`,
      fontSize: "16px",
      fontWeight: 540,
      marginRight: theme.spacing(4),
      cursor: "pointer",
    },
    upload_btn: {
      padding: theme.spacing(0.5, 1),
      border: `1px solid ${theme.palette.primary.main}`,
      width: theme.spacing(11),
      color: theme.palette.primary.main,
      fontWeight: 410,
      borderRadius: theme.spacing(1.5),
    },
  };

  return (
    <Layout
      pageTitle={pageTitle || "Create Inspiration"}
      hasSideMenu
      loading={loading}
    >
      <Box sx={myStyle.mainDiv}>
        <SectionHeading
          heading="Inspiration"
          backgroundColor={theme.palette.grey.warmGrey}
        />
        <Box sx={myStyle.form_container}>
          <FormComponent
            inspiration={inspiration}
            inspirationId={inspirationId}
            update={update}
          />
        </Box>
      </Box>
    </Layout>
  );
};

EditCreateInspiration.propTypes = {
  pageTitle: PropTypes.string,
  inspiration: PropTypes.object,
  update: PropTypes.bool,
  inspirationId: PropTypes.string,
  loading: PropTypes.bool,
};

// EditCreateInspiration.defaultProps = {
//   pageTitle: undefined,
//   inspiration: {},
//   update: false,
//   inspirationId: undefined,
//   loading: false,
// };

export default EditCreateInspiration;
