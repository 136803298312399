import React from "react";
import PropTypes from "prop-types";
import { Typography, Box } from "@mui/material";
import theme from "../../../theme";

const styles = {
  root: {
    display: "flex",
  },
  asterisk: {
    color: theme.palette.status.errorDark,
  },
};

const FieldTitle = ({
  requiredFlag = false,
  variant = undefined,
  title = undefined,
}) => {
  return (
    <Box sx={styles.root}>
      {requiredFlag && (
        <Typography
          variant={variant ? variant : "subtitle1Med"}
          sx={styles.asterisk}
        >
          *
        </Typography>
      )}
      <Typography variant={variant ? variant : "subtitle1Med"}>
        {title}
      </Typography>
    </Box>
  );
};

FieldTitle.propTypes = {
  requiredFlag: PropTypes.bool,
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

// FieldTitle.defaultProps = {
//   requiredFlag: false,
//   variant: undefined,
// };

export default FieldTitle;
