import mapboxgl from "!mapbox-gl";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import theme from "../../../../theme";
import { Link, navigate } from "gatsby";
import { userRoles } from "../../../../lib/constants";
import PropTypes from "prop-types";
import "mapbox-gl/dist/mapbox-gl.css";
import { Box, Typography } from "@mui/material";
import AppButton from "../../../global/elements/AppButton";
import { guestUserSignInRoute } from "../../../../lib/constants";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZ2xvYmhlIiwiYSI6ImNrdXptNjlqdDM1dGwzMHM3bWt6ZHVkd20ifQ.IlPFrxSzNDeDB3BQUusayw";

const styles = {
  mapContainer: {
    width: "100%",
    height: "100%",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: "visible",
    position: "relative",
    marginTop: "25px",
  },

  marker: {
    border: `3px solid ${theme.palette.grey.white}`,
    color: theme.palette.grey.white,
    padding: "10px",
    textAlign: "center",
    textDecoration: "none",
    fontSize: "14px",
    borderRadius: "50%",
    cursor: "pointer",
    width: "42px",
    height: "42px",
  },
  linkStyle: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
    fontWeight: 700,
  },
  dialogueContent: { color: theme.palette.grey.dark },
  mapPopup: {
    textAlign: "center",
    marginTop: "40px",
    marginBottom: "80px",
  },
  popupContainer: {
    position: "absolute",
    zIndex: "100000000",
    width: "100%",
    height: "100%",
    backdropFilter: "blur(8px)",
  },
  popupBody: {
    position: "absolute",
    width: { xs: "80%", sm: "56%", md: "40%" },
    height: { xs: "54%", sm: "58%", md: "40%" },
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "white",
  },
  mT: { marginTop: "50px" },
  popupWidth: { width: "auto !important" },
  viewDataBtn: {
    width: "30px !important",
    padding: "5px",
    height: "30px",
    float: "right",
  },
};

const DataLibraryMap = ({
  geoJson = undefined,
  role = undefined,
  user = undefined,
}) => {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    if (geoJson && geoJson.features) {
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/dark-v10",
        center: [30.2176, 12.8628],
        zoom: 1.25,
      });

      map.on("load", () => {
        // Add a new source from our GeoJSON data and
        // set the 'cluster' option to true. GL-JS will
        // add the point_count property to your source data.
        map.addSource("earthquakes", {
          type: "geojson",
          // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
          // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
          data: geoJson,
          cluster: true,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        });

        map.addLayer({
          id: "clusters",
          type: "circle",
          source: "earthquakes",
          filter: ["has", "point_count"],
          paint: {
            // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            "circle-color": [
              "step",
              ["get", "point_count"],
              "#51bbd6",
              100,
              "#f1f075",
              750,
              "#f28cb1",
            ],
            "circle-radius": [
              "step",
              ["get", "point_count"],
              20,
              100,
              30,
              750,
              40,
            ],
          },
        });

        map.addLayer({
          id: "cluster-count",
          type: "symbol",
          source: "earthquakes",
          filter: ["has", "point_count"],
          layout: {
            "text-field": ["get", "point_count_abbreviated"],
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12,
          },
        });

        map.addLayer({
          id: "unclustered-point",
          type: "circle",
          source: "earthquakes",
          filter: ["!", ["has", "point_count"]],
          paint: {
            "circle-color": "#11b4da",
            "circle-radius": 10,
            "circle-stroke-width": 1,
            "circle-stroke-color": theme.palette.grey.white,
          },
        });

        // inspect a cluster on click
        map.on("click", "clusters", (e) => {
          const features = map.queryRenderedFeatures(e.point, {
            layers: ["clusters"],
          });
          const clusterId = features[0].properties.cluster_id;
          map
            .getSource("earthquakes")
            .getClusterExpansionZoom(clusterId, (err, zoom) => {
              if (err) return;

              map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom,
              });
            });
        });

        // When a click event occurs on a feature in
        // the unclustered-point layer, open a popup at
        // the location of the feature, with
        // description HTML from its properties.
        map.on("click", "unclustered-point", (e) => {
          const coordinates = e.features[0].geometry.coordinates.slice();
          const missionName = e?.features[0]?.properties?.title;
          const locationLabel = e?.features[0]?.properties?.description;
          const missionId = e?.features[0]?.properties?.missionId;

          // Ensure that if the map is zoomed out such that
          // multiple copies of the feature are visible, the
          // popup appears over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          const markerDiv = document.createElement("div");
          const url =
            role == userRoles.client
              ? `/app/client/data-library/${missionId}`
              : `/app/pilot/data-library/${missionId}`;
          const markerContents = (
            <Box sx={styles.popupWidth}>
              <p>
                <b>Dataset name : </b> {missionName} <br />
                <b>Location : </b> {locationLabel}
              </p>
              <Link style={styles.linkStyle} to={url}>
                view data
              </Link>
            </Box>
          );
          ReactDOM.render(markerContents, markerDiv);
          new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setDOMContent(markerDiv)
            .addTo(map);
        });

        map.on("mouseenter", "clusters", () => {
          map.getCanvas().style.cursor = "pointer";
        });
        map.on("mouseleave", "clusters", () => {
          map.getCanvas().style.cursor = "";
        });
      });

      map.addControl(new mapboxgl.NavigationControl(), "top-right");

      // Clean up on unmount
      return () => map.remove();
    }
  }, [geoJson, geoJson.features]);

  return (
    <Box component={"div"} style={styles.mapContainer} ref={mapContainerRef}>
      {user?.isAnonymous && (
        <Box sx={styles.popupContainer}>
          <Box sx={styles.popupBody}>
            <Box sx={styles.mapPopup}>
              <Typography variant="h3">Sign in</Typography>{" "}
              <Typography variant="h6">
                {" "}
                to see our extensive data library{" "}
              </Typography>
              <AppButton
                label={"Sign in"}
                look={"darkBasic"}
                onClick={() => navigate(guestUserSignInRoute)}
                addtionalStyle={styles.mT}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

DataLibraryMap.propTypes = {
  role: PropTypes.string,
  geoJson: PropTypes.string,
  user: PropTypes.object,
};

export default DataLibraryMap;
