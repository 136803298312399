import React from "react";
import theme from "../../../theme";
import { Link } from "gatsby";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import PropTypes from "prop-types";

const styles = {
  mainDiv: {
    minWidth: { xs: 275, md: 250, lg: 275 },
    maxWidth: { md: 275, lg: 320 },
    width: { xs: "100%", md: "30%", lg: "25%" },
    margin: { xs: "10px" },
    marginRight: "10px",
    marginBottom: "10px",
    "& .MuiPaper-outlined": {
      border: "none",
      borderRadius: "15px",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    },
  },
  img: {
    width: "100%",
    height: { xs: 180 },
    borderRadius: theme.spacing(1.5, 1.5, 0, 0),
  },
  content: {
    fontSize: "16px",
    fontWeight: 400,
    padding: theme.spacing(0.2, 1),
    lineHeight: "23px",
    color: "black",
    margin: 0,
    maxHeight: { xs: 50, md: 60 },
    minHeight: { xs: 50, md: 60 },
  },
  button: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
    fontWeight: 450,
    right: theme.spacing(0.5),
    top: "90%",
  },
  div_link: {
    textDecoration: "none",
    color: "black",
  },
};

const InspirationTable = ({
  blogImage = undefined,
  title = undefined,
  link = undefined,
  id = undefined,
}) => {
  return (
    <Box sx={styles.mainDiv}>
      <Card variant="outlined" sx={{ padding: 0 }}>
        <CardContent sx={{ padding: 0 }}>
          <Link to={`/app/admin/inspirations/${id}`} style={styles.div_link}>
            <Box component="img" src={blogImage} sx={styles.img} />
            <Box component="h5" sx={styles.content}>
              {title?.slice(0, 80)}
              {title?.length > 80 && "..."}
            </Box>
          </Link>
        </CardContent>
        <CardActions>
          <Link to={link} style={styles.button}>
            Update
          </Link>
        </CardActions>
      </Card>
    </Box>
  );
};

InspirationTable.propTypes = {
  blogImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  id: PropTypes.string,
};

// InspirationTable.defaultProps = {
//   id: undefined,
// };

export default InspirationTable;
