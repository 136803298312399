import React from "react";
import SignInUpForm from "../../../../components/forms/SignInUp/SignInUpForm";
import { userRoles,formTypeConstantForLogin } from "../../../../lib/constants";
import Seo from "../../../../components/Seo";
import globhePng from "../../../../assets/logos/globhe_logo/Globhe.png";
const PilotSignUp = () => {
  return (
    <>
      <Seo
        title={`Join the world's leading drone data marketplace as a 
        Drone Pilot | Sign Up with Globhe Today`}
        image={globhePng}
        description={`Ready to fly with Globhe? Register as a drone pilot, 
        access paid flying missions and make an impact with your drone. Sign up now!`}
      />
      <SignInUpForm formType={formTypeConstantForLogin.signup} userType={userRoles.pilot} />
    </>
  );
};

export default PilotSignUp;
