import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import theme from "../../theme";
import TextWithArrow from "./TextWithArrow";
import CircularProgressBar from "./CircularProgressBar";
import DashboardData from "./DashboardData";
import { boxShadowStyle } from "../../lib/styleConstants";

const styles = {
  boxLayoutStyles: {
    height: { xs: "215px", md: "215px", lg: "245px" },
    background: theme.palette.grey.white,
    display: "grid",
  },
  circularBox: { display: "flex" },
  circularProgressBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  leftQuotaStyles: {
    color: theme.palette.grey.warmGrey,
    paddingLeft: "10px",
  },
  atBottom: {
    alignSelf: "flex-end",
  },
  additionalValueBox: {
    margin: "10px",
    marginBottom: 0,
  },
};

const BoxLayout = ({
  subText = undefined,
  inGbFlag = false,
  additionalStyle = undefined,
  subPlanFlag = false,
  leftQuota = undefined,
  collectedDataFlag = false,
  noQuotaText = false,
  reversedColor = false,
  smallerText = false,
  additionalValue = undefined,
  showRightArrow = true,
  unitType = undefined,
  showTextInDashboard = false,
  link = undefined,
  text = undefined,
  data = undefined,
}) => {
  return (
    <>
      <Box
        sx={{
          ...styles.boxLayoutStyles,
          ...additionalStyle,
          ...boxShadowStyle,
        }}
      >
        <TextWithArrow
          link={link}
          text={text}
          subText={subText}
          showRightArrow={showRightArrow}
        />

        {subPlanFlag ? (
          <>
            <Box
              sx={{
                ...styles.circularProgressBox,
                height: {
                  xs: "145px",
                  md: "145px",
                  lg: `${additionalValue ? "160px" : "175px"}`,
                },
              }}
            >
              <Box sx={styles.circularBox}>
                <CircularProgressBar
                  leftQuota={leftQuota}
                  reversed={reversedColor}
                  smallerText={smallerText}
                />
              </Box>
              {!noQuotaText && (
                <Box sx={styles.atBottom}>
                  <Typography
                    variant="h6"
                    sx={styles.leftQuotaStyles}
                    style={{
                      width: {
                        xs: "50%",
                        md: `${noQuotaText ? "100%" : "80%"}`,
                      },
                    }}
                  >
                    left of quota
                  </Typography>
                </Box>
              )}
            </Box>
            <Box sx={styles.additionalValueBox}>
              <Typography variant="subtitle1" sx={{ textAlign: "right" }}>
                {additionalValue}
              </Typography>
            </Box>
          </>
        ) : (
          <DashboardData
            data={data}
            inGbFlag={inGbFlag}
            collectedDataFlag={collectedDataFlag}
            unitType={unitType}
            showTextOnly={showTextInDashboard}
          />
        )}
      </Box>
    </>
  );
};

BoxLayout.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  subText: PropTypes.string,
  data: PropTypes.object.isRequired,
  inGbFlag: PropTypes.bool,
  additionalStyle: PropTypes.object,
  subPlanFlag: PropTypes.bool,
  leftQuota: PropTypes.number,
  collectedDataFlag: PropTypes.bool,
  noQuotaText: PropTypes.bool,
  reversedColor: PropTypes.bool,
  smallerText: PropTypes.bool,
  additionalValue: PropTypes.string,
  showRightArrow: PropTypes.bool,
  unitType: PropTypes.string,
  showTextInDashboard: PropTypes.bool,
};

// BoxLayout.defaultProps = {
//   subText: undefined,
//   inGbFlag: false,
//   additionalStyle: undefined,
//   subPlanFlag: false,
//   leftQuota: undefined,
//   collectedDataFlag: false,
//   noQuotaText: false,
//   reversedColor: false,
//   smallerText: false,
//   additionalValue: undefined,
//   showRightArrow: true,
//   unitType: undefined,
//   showTextInDashboard: false,
// };

export default BoxLayout;
