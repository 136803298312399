import React, { useEffect, useState } from "react";
import Layout from "../../../Layout";
import TeamEditForm from "./TeamEditForm";
import { getClientById } from "../../../services/client";
import firebase from "src/firebase";
import ArrowHeaderContainer from "../NewDataLibrary/components/ArrowHeaderContainer";
import UniversalLoader from "../../global/elements/UniversalLoader";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const styles = {
  loaderDiv: {
    paddingTop: "18%",
  },
};

const EditTeamProfileLayout = ({ memberId = "" }) => {
  const [memberInfo, setMemberInfo] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const memberData = await getClientById(firebase, memberId);
      console.log("memberData", memberData);
      setMemberInfo(memberData);
      setLoading(false);
    }
    fetchData();
  }, [memberId]);
  return (
    <Layout hasSideMenu loading={loading}>
      <ArrowHeaderContainer
        header="Team"
        redirectURL={`/app/client/team/member/${memberId}`}
      >
        {!loading ? (
          memberInfo && (
            <TeamEditForm memberId={memberId} memberInfo={memberInfo} />
          )
        ) : (
          <Box sx={styles.loaderDiv}>
            <UniversalLoader />
          </Box>
        )}
      </ArrowHeaderContainer>
    </Layout>
  );
};

EditTeamProfileLayout.propTypes = {
  memberId: PropTypes.string.isRequired,
};

export default EditTeamProfileLayout;
