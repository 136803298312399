import React from "react";
import { Typography, Box } from "@mui/material";
import PropTypes from "prop-types";
import CheckIcon from "@mui/icons-material/Check";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import theme from "../../../../theme";

const styles = {
  flex: {
    display: "flex",
    flexDirection: "row",
  },
};
const CheckText = ({ text = undefined, success = false }) => {
  return (
    <Box sx={styles.flex}>
      {success ? (
        <CheckIcon
          sx={{
            color: theme.palette.status.successDark,
          }}
        />
      ) : (
        <HighlightOffIcon sx={{ color: theme.palette.grey.warmGrey }} />
      )}

      <Typography variant="body1" sx={{ paddingLeft: "10px" }}>
        {text}
      </Typography>
    </Box>
  );
};

CheckText.propTypes = {
  text: PropTypes.string.isRequired,
  success: PropTypes.bool,
};
// CheckText.defaultProps = {
//   success: false,
// };

export default CheckText;
