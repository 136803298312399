import * as React from "react";
import { Box, Typography, Popover, Grid, Backdrop } from "@mui/material";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import AppButton from "../../../global/elements/AppButton";
import theme from "../../../../theme";
import CloseIcon from "@mui/icons-material/HighlightOff";
import PropTypes from "prop-types";

const styles = {
  popoverBox: {
    position: "relative",
    mt: "10px",
    "&::before": {
      backgroundColor: "white",
      content: '""',
      display: "block",
      position: "absolute",
      width: 12,
      height: 12,
      top: -6,
      transform: "rotate(45deg)",
      left: "calc(50% - 6px)",
      borderLeft: `2px solid ${theme.palette.black.darkSeaBlack}`,
      borderTop: `2px solid ${theme.palette.black.darkSeaBlack}`,
    },
  },
  closeBtn: { textAlign: "end" },
  popoverGrid: {
    p: 2,
    backgroundColor: "white",
    border: `2px solid ${theme.palette.black.darkSeaBlack}`,
  },
  innerBox: { display: "flex", marginTop: "-27px" },
  iconStyles: { fontSize: "35px" },
  btnStyle: {
    width: "206px",
    height: "35px",
    marginTop: "10px",
  },
  closeIconStyle: {
    color: theme.palette.status.errorLight,
    fontSize: "15px",
    cursor: "pointer",
  },
};

const CartPopup = ({
  anchorElCart = null,
  handleCloseCart = undefined,
  cartId = undefined,
}) => {
  let openCart = Boolean(anchorElCart);
  const id = openCart ? cartId : undefined;

  return (
    <Box>
      <Backdrop open={openCart}>
        <Popover
          id={id}
          open={openCart}
          anchorEl={anchorElCart}
          onClose={handleCloseCart}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              borderRadius: 0,
              zIndex: 9999999,
            },
          }}
          sx={{ top: "5%" }}
        >
          <Box sx={styles.popoverBox} id={cartId} component={"div"} />

          <Grid container sx={styles.popoverGrid}>
            <Grid item>
              <Box sx={styles.closeBtn}>
                <CloseIcon
                  sx={styles.closeIconStyle}
                  onClick={handleCloseCart}
                />
              </Box>
              <Box sx={styles.innerBox}>
                <Box>
                  <AnalyticsOutlinedIcon sx={styles.iconStyles} />
                </Box>
                <Box ml={1}>
                  <Typography variant="subtitle1Med">
                    Added to your cart!
                  </Typography>
                  {/* <Typography variant="body2">
                    2x data ukraine oblast
                  </Typography> */}
                </Box>
              </Box>
              <AppButton
                label={"View cart & checkout"}
                look={"green"}
                addtionalStyle={styles.btnStyle}
                internalLink
                url="/app/client/data-library/wishlist"
              />{" "}
              <br />
              <AppButton
                label={"Continue shopping"}
                look={"inverted"}
                addtionalStyle={styles.btnStyle}
                internalLink
                url="/app/client/data-library"
              />
            </Grid>
          </Grid>
        </Popover>
      </Backdrop>
    </Box>
  );
};

CartPopup.propTypes = {
  anchorElCart: PropTypes.object,
  handleCloseCart: PropTypes.func.isRequired,
  cartId: PropTypes.string.isRequired,
};
// CartPopup.defaultProps = {
//   anchorElCart: null,
// };

export default CartPopup;
