import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { ToggleButton, Typography } from "@mui/material";
import { ToggleButtonGroup } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../../theme";
import { days } from "../../../lib/constants";

const StyledToggleButtonGroup = withStyles(() => ({
  grouped: {
    "&:not(:first-child)": {
      border: "1px solid !important",
      borderColor: theme.palette.black.darkSeaBlack,
      borderRadius: "50% !important",
      margin: "0 20px",
    },
    "&:first-child": {
      border: "1px solid !important",
      borderColor: theme.palette.black.darkSeaBlack,
      borderRadius: "50% !important",
      margin: "0 20px",
      marginLeft: 0,
    },
  },
}))((props) => <ToggleButtonGroup {...props} />);

const StyledToggle = withStyles({
  root: {
    color: theme.palette.black.darkSeaBlack,
    "&$selected": {
      background: `${theme.palette.primary.main} !important`,
      borderColor: `${theme.palette.primary.main} !important`,
    },
    "&:hover": {
      borderColor: theme.palette.primary.main,
      background: theme.palette.primary.palePistachio,
    },
    minWidth: 32,
    maxWidth: 32,
    height: 32,
    textTransform: "unset",
  },
  selected: {},
})((props) => <ToggleButton {...props} />);

const WeekDaySelect = ({
  fieldName = undefined,
  values = {},
  setFieldValue = undefined,
  edit = true,
}) => {
  const [day, setDay] = useState(values[fieldName] || null);

  return (
    <>
      <StyledToggleButtonGroup
        size="small"
        arial-label="Days of the week"
        exclusive
        value={day}
        disabled={!edit}
        onChange={(event, value) => {
          setDay(value);
          setFieldValue(fieldName, value);
        }}
        name={fieldName}
      >
        {days.map((day) => (
          <StyledToggle key={day.key} value={day.key} aria-label={day.key}>
            <Typography variant="h6">{day.label}</Typography>
          </StyledToggle>
        ))}
      </StyledToggleButtonGroup>
    </>
  );
};

WeekDaySelect.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object,
  fieldName: PropTypes.string.isRequired,
  edit: PropTypes.bool,
};

// WeekDaySelect.defaultProps = {
//   values: {},
//   edit: true,
// };

export default WeekDaySelect;
