import React, { useEffect, useState } from "react";
import CreateEditProjectMainLayout from "./CreateEditProjectMainLayout";
import { getBasicProjectDetails } from "../../../../services/project";
import firebase from "src/firebase";
import { getUser } from "../../../../services/auth";
import PropTypes from "prop-types";
import UniversalLoader from "../../../global/elements/UniversalLoader";
import { Box } from "@mui/material";

const styles = {
  universalLoader: {
    paddingTop: "25%",
  },
};

function EditProject({ projectId = "" }) {
  const [project, setProject] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [reloadProject, setReloadProject] = useState(false);

  useEffect(() => {
    const user = getUser("userProfile");
    if (user) {
      setCurrentUser(user);
    }
    try {
      getBasicProjectDetails(firebase, projectId).then((data) => {
        if (data.status === "Success") {
          setProject(data?.project);
        } else {
          console.log("Error in fetching project data");
        }
      });
    } catch (e) {
      console.log("Error", e);
    }
  }, [projectId, reloadProject]);
  return (
    <>
      {project ? (
        <CreateEditProjectMainLayout
          projectData={project}
          currentUser={currentUser}
          setReloadProject={setReloadProject}
          reloadProject={reloadProject}
        />
      ) : (
        <Box sx={styles.universalLoader}>
          <UniversalLoader />
        </Box>
      )}
    </>
  );
}

EditProject.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default EditProject;
