import PropTypes from "prop-types";
import React from "react";
import theme from "../../../theme";

const RejectedIcon = ({ error = false }) => {
  const colorVal = error ? "#f44336" : theme.palette.primary.main;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill={colorVal} />
      <path
        d="M16.2364 7.7625C15.1482 6.675 13.6548 6 11.9962 6C8.67917 6 6 8.685 6 12C6 15.315 8.67917 18 11.9962 18C14.7955 18 17.1295 16.0875 17.7974 13.5H16.2364C15.621 15.2475 13.955 16.5 11.9962 16.5C9.51219 16.5 7.49343 14.4825 7.49343 12C7.49343 9.5175 9.51219 7.5 11.9962 7.5C13.242 7.5 14.3527 8.0175 15.1632 8.835L12.7467 11.25H18V6L16.2364 7.7625Z"
        fill={theme.palette.grey.white}
      />
    </svg>
  );
};

RejectedIcon.propTypes = {
  error: PropTypes.bool,
};

// RejectedIcon.defaultProps = {
//   error: false,
// };

export default RejectedIcon;
