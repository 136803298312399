import React from "react";
import { Box, Modal, Grid, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import theme from "../../../../theme";
import AppButton from "../../../global/elements/AppButton";
import PropTypes from "prop-types";
import { getUser } from "../../../../services/auth";
import { userRoles } from "../../../../lib/constants";

const styles = {
  deleteModalDiv: {
    width: { xs: "335px", sm: "500px", md: "550px", lg: "600px" },
    height: { xs: "350px", sm: "300px", md: "350px", lg: "385px" },
    background: theme.palette.grey.white,
    margin: "auto",
    marginTop: { xs: "60%", sm: "25%", md: "15%", lg: "10%" },
    position: "relative",
    padding: { xs: "55px 30px", sm: "60px 60px", md: "60px 90px" },
  },
  crossIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    margin: "15px",
    color: theme.palette.status.errorLight,
    cursor: "pointer",
  },

  deleteSubText: {
    marginTop: { xs: "10px", md: "20px" },
    marginBottom: { xs: "10px", sm: "10px", md: "30px", lg: "50px" },
  },
};

const DeleteUserPopUp = ({
  deleteModal = false,
  setDeleteModal = undefined,
  deleteUser = undefined,
}) => {
  const isAdmin = getUser("user")?.role === userRoles.admin;
  return (
    <Modal
      open={deleteModal}
      onClose={() => setDeleteModal(true)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={styles.deleteModalDiv}>
        <CancelIcon
          sx={styles.crossIcon}
          onClick={() => setDeleteModal(false)}
        />
        <Box>
          {isAdmin ? (
            <Typography variant="h4">
              Are you sure you want to delete this user profile?
            </Typography>
          ) : (
            <Typography variant="h4">
              Are you sure you want to delete your user profile?
            </Typography>
          )}
          <Typography sx={styles.deleteSubText} variant="body1">
            This action is permanent and cannot be undone.
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <AppButton
              look="black"
              label="No, go back"
              onClick={() => setDeleteModal(false)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppButton
              look="negative"
              label="Yes delete"
              onClick={deleteUser}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

DeleteUserPopUp.propTypes = {
  deleteModal: PropTypes.bool,
  setDeleteModal: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
};

export default DeleteUserPopUp;
