import { Box, Typography } from "@mui/material";
import React from "react";
import { flightSpecification } from "../../../../lib/constants";
import PropTypes from "prop-types";

const FlightSpecification = ({ noTitle = false }) => {
  return (
    <Box>
      {!noTitle && (
        <Typography variant="h6">
          GLOBHE standard flight specifications if no advanced options were
          selected
        </Typography>
      )}

      <Box component={"ul"}>
        {flightSpecification?.map((item, index) => {
          return (
            <Typography variant="body3" component={"li"} key={index}>
              {item}
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
};

FlightSpecification.propTypes = {
  noTitle: PropTypes.bool,
};
// FlightSpecification.defaultProps = {
//   noTitle: false,
// };

export default FlightSpecification;
