import React from "react";
import { Typography, Grid } from "@mui/material";
import Instruction from "./Instruction";
import PropTypes from "prop-types";

const bold = {
  fontWeight: "500",
};
const StepInstruction = ({
  step = undefined,
  quickDescription = undefined,
  elements = undefined,
  endingDescription = undefined,
}) => {
  return (
    <Grid container>
      {step && (
        <Grid item xs={12}>
          <Typography variant="body1" sx={bold}>
            {step}
          </Typography>
        </Grid>
      )}
      {quickDescription && (
        <Grid item xs={12} sx={{ paddingTop: "10px" }}>
          <Typography variant="body1">{quickDescription}</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        {elements.map((instruction, i) => (
          <Instruction data={instruction} key={i} />
        ))}
      </Grid>
      {endingDescription && (
        <Grid item xs={12} sx={{ paddingTop: "10px" }}>
          <Typography variant="body1">{endingDescription}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

StepInstruction.propTypes = {
  step: PropTypes.string,
  quickDescription: PropTypes.string,
  elements: PropTypes.array.isRequired,
  endingDescription: PropTypes.string,
};
// StepInstruction.defaultProps = {
//   step: undefined,
//   quickDescription: undefined,
//   endingDescription: undefined,
// };

export default StepInstruction;
