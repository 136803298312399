import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import CollapsableBox from "../../../global/elements/CollapsableBox";
import Graph from "../../../global/elements/Graph";
import PropTypes from "prop-types";
import theme from "../../../../theme";
import ProjectLogs from "./ProjectLogs";
import { userRoles } from "../../../../lib/constants";

const styles = {
  weekReportContainer: {
    display: "flex",
    flexDirection: "column",
  },
  dataContainer: {
    display: "flex",
    padding: {
      xs: "10px 20px",
      sm: "50px 0px",
      md: "50px 15px",
      lg: "50px 40px",
    },
    borderRight: { sm: `2px solid ${theme.palette.grey.dividersColor}` },
  },
  countText: {
    margin: { xs: "auto", sm: "auto 20px", md: "auto 25px" },
    paddingRight: { xs: "40px", sm: "25px" },
    borderRight: `2px solid ${theme.palette.grey.dividersColor}`,
  },
  greyText: {
    color: theme.palette.black.coolGrey,
    marginLeft: { xs: "40px", sm: "0" },
  },
  headingText: {
    marginLeft: "20px !important",
  },
};

const UploadedCompletedWeekReport = ({
  barchartData = [],
  projectId = undefined,
  currentUser = undefined,
}) => {
  let pilotId =
    currentUser?.role === userRoles?.pilot ? currentUser?.userID : null;
  return (
    <CollapsableBox
      title="UPLOAD & COMPLETED MISSIONS"
      noUnderline
      headingStyle={styles.headingText}
    >
      <Box sx={styles.weekReportContainer}>
        <Grid container>
          <Grid
            item
            sx={{ ...styles.dataContainer, ...styles.adjustMargin }}
            xs={12}
            sm={4.8}
            m={2}
          >
            <Typography variant="h1" sx={styles.countText}>
              {barchartData?.totalMissionCountForWeek || 0}
            </Typography>
            <Typography variant="h4" sx={styles.greyText}>
              missions completed last week
            </Typography>
          </Grid>
          <Grid m={2} item xs={12} sm={5.3} sx={styles.adjustMargin}>
            <Graph
              barData={barchartData?.barChartData}
              valueField={"mission"}
              argumentField={"date"}
              // setmissionLastWeek={setMissionLastWeek}
            />
          </Grid>
        </Grid>
      </Box>
      <ProjectLogs projectId={projectId} pilotId={pilotId} />
    </CollapsableBox>
  );
};

UploadedCompletedWeekReport.propTypes = {
  barchartData: PropTypes.array,
  projectId: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
};
// UploadedCompletedWeekReport.defaultProps = {
//   barchartData: {},
// };

export default UploadedCompletedWeekReport;
