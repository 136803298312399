import { Box, Divider } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import theme from "../../../../theme";
import BackNav from "../../../global/elements/BackNav";

const styles = {
  topDivider: {
    borderColor: theme.palette.black.darkSeaBlack,
    margin: "10px 0px",
    borderWidth: "1px",
  },
  paddingCls: {
    padding: "16px",
  },
};

const ArrowHeaderContainer = ({
  header = undefined,
  children = undefined,
  redirectURL = null,
  showBottomDivider = false,
}) => {
  return (
    <Box sx={styles.paddingCls}>
      <BackNav pageName={header} routeTo={redirectURL} />
      <Box>{children}</Box>
      {showBottomDivider && (
        <Divider variant="fullWidth" sx={styles.topDivider} />
      )}
    </Box>
  );
};

ArrowHeaderContainer.propTypes = {
  header: PropTypes.string.isRequired,
  redirectURL: PropTypes.string,
  children: PropTypes.node.isRequired,
  showBottomDivider: PropTypes.bool,
};

ArrowHeaderContainer.defaultProps = {
  redirectURL: null,
  showBottomDivider: false,
};

export default ArrowHeaderContainer;
