import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../theme";
import RightArrowButton from "./RightArrowButton";
import DashboardContext from "../app/Client/Overview/Components/DashboardContext";

const styles = {
  orderTextBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  subTextStyle: {
    color: theme.palette.grey.warmGrey,
    lineHeight: "0.6",
  },
  disabledBox: {
    pointerEvents: "none",
  },
  rightBtnCls: { marginLeft: "3em" },
};

const TextWithArrow = ({
  link = undefined,
  text = undefined,
  subText = undefined,
  showRightArrow = true,
}) => {
  const context = useContext(DashboardContext);
  const disable = context?.disableButton ? true : false;

  return (
    <>
      <Box sx={styles.orderTextBox}>
        <Box>
          <Typography variant="h6">{text}</Typography>
          <Typography variant="h6" sx={styles.subTextStyle}>
            {subText}
          </Typography>
        </Box>

        {showRightArrow && (
          <Box
            sx={
              disable
                ? { ...styles.rightBtnCls, ...styles.disabledBox }
                : { ...styles.rightBtnCls }
            }
          >
            <RightArrowButton link={link} />
          </Box>
        )}
      </Box>
    </>
  );
};

TextWithArrow.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  subText: PropTypes.string,
  showRightArrow: PropTypes.bool,
};
// TextWithArrow.defaultProps = {
//   showRightArrow: true,
//   subText: undefined,
// };

export default TextWithArrow;
