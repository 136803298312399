import React, { useState } from "react";
import { Box, Grid, Typography, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../../../theme";
import TitleValue from "../../../global/elements/TitleValue";
import AppButton from "../../../global/elements/AppButton";
import {
  missionStatus,
  projectStatus,
  userRoles,
} from "../../../../lib/constants";
import { getMissionContractByProjectId } from "../../../../services/contracts";
import { getMissionInvoiceByProjectId } from "../../../../services/invoices";
import { navigate } from "gatsby";
import firebase from "src/firebase";

import CollapsableBox from "../../../global/elements/CollapsableBox";
import Dialogue from "../../../global/Dialogue";
import { updateProject } from "../../../../services/project";

const styles = {
  adminDescriptionBox: {
    background: theme.palette.adminBlue.main,
    height: "100%",
  },
  clientDescriptionBox: {
    background: theme.palette.black.darkSeaBlack,
    height: "100%",
  },
  containerRequirementBox: {
    padding: "1em",
  },
  clientContainerBox: {
    display: "flex",
    padding: "1em",
    background: theme.palette.black.darkSeaBlack,
  },
  pilotDescriptionBox: {
    background: theme.palette.secondary.main,
    height: "100%",
  },
  pilotOtherDescriptionBox: {
    background: theme.palette.black.coolGrey,
  },
  projectRequirementTitleStyle: {
    color: theme.palette.black.paragraphText,
  },
  projectRequirementTitleStylePilot: {
    color: theme.palette.black.darkSeaBlack,
  },
  projectRequirementValueStyle: {
    fontWeight: "200 !important",
  },

  showMoreBtn: {
    float: "right",
  },

  mapBtnContainer: {
    display: { xs: "block", sm: "flex" },
    justifyContent: { xs: "center", sm: "space-between" },
    textAlign: "center",
    paddingRight: { xs: 0, sm: "10px" },
  },
  headingText: {
    marginLeft: "20px !important",
  },
  progressBoxStyle: {
    width: "100%",
    textAlign: "center",
    position: "relative",
    justifyContent: "center",
  },
  progressText: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const ProjectRequirements = ({
  projectData = {},
  currentUser = undefined,
  changeRole = false,
  reloadProject = undefined,
}) => {
  const [showMoreDescription, setShowMoreDescription] = useState(false);
  const [downloadPopup, setDownloadPopup] = useState(false);
  const [progress, setProgress] = useState(0);

  const isAdmin = currentUser?.role === userRoles.admin;
  const isClient = currentUser?.role === userRoles.client;
  const isPilot = currentUser?.role === userRoles.pilot;

  const viewContract = () => {
    try {
      getMissionContractByProjectId(currentUser.userID, projectData.id).then(
        (data) => {
          if (data && data?.id) {
            navigate(`/app/my-profile/contracts/${data.id}`);
          }
        }
      );
    } catch (e) {
      console.log("error", e);
    }
  };

  const viewInvoice = () => {
    try {
      getMissionInvoiceByProjectId(currentUser.userID, projectData.id).then(
        (data) => {
          if (data && data?.id) {
            navigate(`/app/my-profile/invoices/${data.id}`);
          }
        }
      );
    } catch (e) {
      console.log("error", e);
    }
  };

  const convertToCSV = (objArray) => {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str = "";

    // Add the headers
    let headerRow = Object.keys(array[0]).join(",") + "\r\n";
    str += headerRow;

    // Add the data
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line != "") line += ",";

        let field = array[i][index];

        // Convert array fields to JSON strings
        if (Array.isArray(field)) {
          field = JSON.stringify(field);
        }

        // Escape double quotes and wrap in double quotes if necessary
        if (typeof field === "string") {
          field = field.replace(/"/g, '""');
          if (field.includes(",")) {
            field = `"${field}"`;
          }
        }

        line += field;
      }
      str += line + "\r\n";
    }
    return str;
  };

  const downloadCSV = (csv, filename) => {
    // const csvFile = new Blob([csv], { type: "text/csv" });
    // const downloadLink = document.createElement("a");
    // downloadLink.download = filename;
    // downloadLink.href = window.URL.createObjectURL(csvFile);
    // downloadLink.style.display = "none";
    // document.body.appendChild(downloadLink);
    // downloadLink.click();
    // document.body.removeChild(downloadLink);

    const csvFile = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvFile);

    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";

    xhr.onprogress = (event) => {
      if (event.lengthComputable) {
        setProgress((event.loaded / event.total) * 100);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = filename;
        downloadLink.click();
        window.URL.revokeObjectURL(url);
        setDownloadPopup(false);
      }
    };

    xhr.onerror = () => {
      console.error("Error downloading the file");
      setDownloadPopup(false);
    };

    xhr.send();
  };

  const downloadAllSites = () => {
    setDownloadPopup(true);
    firebase
      .firestore()
      .collection("missions")
      .where("projectIds", "==", projectData.id)
      .where("status", "!=", missionStatus.deleted)
      .get()
      .then((snapshot) => {
        const allData = snapshot.docs.map((doc) => {
          let singleData = doc.data();
          return {
            areaSize: singleData.areaSize || 0,
            assignedPilotId: singleData?.assignedPilot || null,
            assignedPilotName: singleData?.assignedPilotName || null,
            clientEmail: singleData?.clientEmail || null,
            clientName: singleData?.clientName || null,
            clientPhone: singleData?.clientPhone || null,
            clientCompanyName: singleData?.companyName || null,
            dateCreated: singleData.dateCreated,
            dateUpdated: singleData.dateUpdated,
            clientDeadline: singleData?.deadline || null,
            clientDescription: singleData?.description || null,
            enterPriseId: singleData?.enterPriseId || null,
            orderId: singleData.id,
            country: singleData?.location || null,
            locationLabel: singleData?.locationLabel || null,
            locationMap: singleData?.locationMap || null,
            mapTypes: singleData?.mapTypes || null,
            orderName: singleData?.missionName || null,
            missionOwnerEmail: singleData?.missionOwner || null,
            missionOwnerName: singleData?.missionOwnerName || null,
            missionType: singleData?.missionType || null,
            orderType: singleData?.orderType || null,
            otherMapType: singleData?.otherMapType || null,
            otherRequirements: singleData?.otherRequirements || null,
            pilotInstructions: singleData?.pilotInstructions || null,
            pilotPrice: singleData.pilotPrice || 0,
            projectId: singleData?.projectIds || null,
            projectName: singleData?.projectName || null,
            recurrence: singleData?.recurrence || false,
            status: singleData?.status || null,
          };
        });
        const csv = convertToCSV(allData);
        downloadCSV(csv, projectData.projectName || "data.csv");
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const markProjectAsActive = async () => {
    let projectId = projectData.id;
    let updates = {
      status: projectStatus.active,
    };
    await updateProject({ projectId, updates });
    reloadProject();
  };

  return (
    <Grid container mt={2} spacing={2}>
      {isAdmin && !changeRole && (
        <Grid item xs={12} md={6}>
          <Grid container sx={styles.clientContainerBox}>
            <Grid item xs={12} sm={3} md={6}>
              <TitleValue
                title="Client Name"
                blackBackground
                value={projectData?.clientName || "Client Name"}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={6}>
              <TitleValue
                blackBackground
                title="Company Name"
                value={projectData?.clientCompanyName || "Company Name"}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={6}>
              <TitleValue
                blackBackground
                title="Email"
                value={projectData?.clientEmail || "Email"}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={6}>
              <TitleValue
                blackBackground
                title="Telephone"
                value={projectData?.clientPhone || "Telephone"}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {!isPilot && (
        <Grid item xs={12} md={isAdmin && !changeRole ? 6 : 12}>
          <Grid
            container
            sx={
              isAdmin ? styles.adminDescriptionBox : styles.clientDescriptionBox
            }
          >
            <Grid item xs={12} sx={styles.containerRequirementBox}>
              <TitleValue
                title={
                  isClient
                    ? "Order requirements"
                    : "Order requirements from client"
                }
                value={
                  projectData?.description
                    ? !showMoreDescription
                      ? `${projectData?.description.substring(0, 120)}${
                          projectData?.description?.length > 120 ? "..." : ""
                        }`
                      : projectData?.description
                    : ""
                }
                titleStyle={styles.projectRequirementTitleStyle}
                valueStyle={styles.projectRequirementValueStyle}
                blackBackground={isClient}
              />
            </Grid>
            <Grid item xs={12}>
              {projectData?.description?.length > 120 && (
                <AppButton
                  label={showMoreDescription ? "SHOW LESS" : "SHOW MORE"}
                  look="inverted"
                  addtionalStyle={styles.showMoreBtn}
                  onClick={() => setShowMoreDescription(!showMoreDescription)}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      {!isClient && (
        <>
          <Grid item xs={12} md={6}>
            <Grid container sx={styles.pilotDescriptionBox}>
              <Grid item xs={12} sx={styles.containerRequirementBox}>
                <TitleValue
                  title="Operator requirements"
                  value={
                    projectData?.pilotInstructions
                      ? !showMoreDescription
                        ? `${projectData?.pilotInstructions.substring(0, 120)}${
                            projectData?.pilotInstructions?.length > 120
                              ? "..."
                              : ""
                          }`
                        : projectData?.pilotInstructions
                      : ""
                  }
                  titleStyle={styles.projectRequirementTitleStylePilot}
                  valueStyle={styles.projectRequirementValueStyle}
                />
              </Grid>
              <Grid item xs={12}>
                {projectData?.pilotInstructions?.length > 120 && (
                  <AppButton
                    label={showMoreDescription ? "SHOW LESS" : "SHOW MORE"}
                    look="black"
                    addtionalStyle={styles.showMoreBtn}
                    onClick={() => setShowMoreDescription(!showMoreDescription)}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container sx={styles.pilotOtherDescriptionBox}>
              <Grid item xs={12} sx={styles.containerRequirementBox}>
                <TitleValue
                  title="Other requirements"
                  value={
                    projectData?.otherRequirements
                      ? !showMoreDescription
                        ? `${projectData?.otherRequirements.substring(0, 120)}${
                            projectData?.otherRequirements?.length > 120
                              ? "..."
                              : ""
                          }`
                        : projectData?.otherRequirements
                      : ""
                  }
                  titleStyle={styles.projectRequirementTitleStylePilot}
                  valueStyle={styles.projectRequirementValueStyle}
                />
              </Grid>
              <Grid item xs={12}>
                {projectData?.otherRequirements?.length > 120 && (
                  <AppButton
                    label={showMoreDescription ? "SHOW LESS" : "SHOW MORE"}
                    look="inverted"
                    addtionalStyle={styles.showMoreBtn}
                    onClick={() => setShowMoreDescription(!showMoreDescription)}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CollapsableBox
              title="MY DOCUMENTS"
              noUnderline
              headingStyle={styles.headingText}
            >
              <Box sx={styles.mapBtnContainer}>
                <AppButton
                  label={"View invoice"}
                  look="black"
                  onClick={viewInvoice}
                />
                {isAdmin && (
                  <AppButton
                    label={"Download sites"}
                    look="black"
                    onClick={downloadAllSites}
                  />
                )}
                {isAdmin && projectData?.status !== projectStatus.active && (
                  <AppButton
                    label={"Mark as active"}
                    look="black"
                    onClick={markProjectAsActive}
                  />
                )}
                <AppButton
                  label={"View contract"}
                  look="black"
                  onClick={viewContract}
                />
              </Box>
            </CollapsableBox>
          </Grid>

          <Dialogue
            showDialogue={downloadPopup}
            setShowDialogue={setDownloadPopup}
            // showCancelButton={true}
            // sumitButtonText={"Delete"}
            // onSubmit={() => deleteMessage()}
            // submitButtonLook={"primary"}
            // bottomDeviderLine={true}
            DialogueBody={
              <>
                <Typography variant="h6" sx={{ textAlign: "center" }} mb={3}>
                  File is getting downloaded
                </Typography>
                <Box sx={styles.progressBoxStyle}>
                  <CircularProgress
                    value={progress}
                    size={100}
                    thickness={3.5}
                    variant="determinate"
                  />
                  <Typography sx={styles.progressText} variant="h6">
                    {progress}%
                  </Typography>
                </Box>
              </>
            }
            maxWidth={"sm"}
            // cancelButtonText="Cancel"
            tableType
          />
        </>
      )}
    </Grid>
  );
};

ProjectRequirements.propTypes = {
  projectData: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  changeRole: PropTypes.bool,
  reloadProject: PropTypes.func.isRequired,
};
// ProjectRequirements.defaultProps = {
//   projectData: {},
//   changeRole: false,
// };

export default ProjectRequirements;
