import PropTypes from "prop-types";
import React from "react";
import theme from "../../../theme";
import { Box, Button, Typography, Grid, Divider } from "@mui/material";
import GlobheLogo from "src/assets/images/logo-icon.png";
import EstimatedMissionIcon from "src/assets/images/estimated_mission_completion_time.svg";
import GeographicArea from "src/assets/images/geographic_area.svg";
import EstimatedTotalCost from "src/assets/images/estimated_total_cost.svg";
import EstimatedImage from "src/assets/images/estimated_image.svg";
import { getUser } from "src/services/auth";
import { userRoles } from "src/lib/constants";

const styles = {
  parent_main_container: {
    display: { xs: "block", md: "block", lg: "flex" },
    width: "100%",
    marginTop: "30px",
    alignItems: "stretch",
    justifyContent: "space-around",
    gridAutoFlow: { sm: "row", md: "column" },
  },
  leftContainer: {
    width: "100%",
    display: "grid",
    margin: "0px 30px 20px 20px",
    justifyContent: { sm: "center", md: "space-between" },
    marginTop: { xs: "300px", md: "0px", sm: "0px", lg: "0px" },
    flexDirection: "column",
    maxHeight: "100%",
  },
  rightMainContainer: {
    minHeight: "10%",
    width: "100%",
    marginRight: "30px",
    marginBottom: "20px",
  },
  informationGrid: {
    padding: "10px 10px 10px 10px",
    boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
    backgroundColor: theme.palette.grey.white,
    marginBottom: "30px",
  },

  image_name_main: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    marginBottom: "10px",
  },
  logo: {
    width: "20px",
    height: "20px",
    marginRight: "20px",
    borderRadius: "0px",
  },
  avatar: {
    width: "35px",
    height: "35px",
  },
  missionInfoHeader: {
    fontSize: "20px !important",
    fontWeight: "500 !important",
    color: theme.palette.grey.lightWarmGrey,
  },

  iconText: {
    color: theme.palette.primary.main,
  },
  estimatedImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
  },
  buttonContainer: {
    display: "grid",
    flexWrap: "wrap",
    rowGap: "20px",
    columnGap: "20px",
    gridAutoFlow: { xs: "row", sm: "column" },
  },
  ctaButton: {
    "&.MuiButton-root": {
      textTransform: "none",
      width: "100%",
      borderRadius: "0px",
      background: theme.palette.primary.main,
      color: theme.palette.grey.white,
      padding: "6px",
      "&.Mui-disabled": {
        background: theme.palette.grey.lightWarmGrey,
        color: theme.palette.grey.warmGrey,
      },
      "&:hover": {
        background: theme.palette.secondary.main,
      },
    },
  },
  ctaClearButton: {
    "&.MuiButton-root": {
      textTransform: "none",
      width: "100%",
      borderRadius: "0px",
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      borderColor: theme.palette.primary.main,
      padding: "6px",
      "&:hover": {
        border: `1px solid ${theme.palette.secondary.main}`,
      },
    },
  },
  heading: {
    marginBottom: "10px",
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  iconContain: {
    fontSize: "10px",
    color: theme.palette.black.darkSeaBlack,
    width: "60%",
  },
  textPoints: {
    marginBottom: "20px !important",
  },
};

const MissionOrderInformation = ({
  areaSize = undefined,
  polygon = null,
  expectedTime = undefined,
  displayAreaSizeInSelectedUnits = undefined,
  onCancel = undefined,
  areaSizeUnitNotation = undefined,
  expectedCost = undefined,
  expectedMbSize = undefined,
  save = undefined,
}) => {
  const currentUser = getUser("user")?.role;

  return (
    <Box sx={styles.parent_main_container}>
      <Box sx={styles.leftContainer}>
        {currentUser == userRoles.pilot ? (
          <>
            <Typography variant="h6" sx={styles.heading}>
              Earn Passive Income on Your Earth Observation Data
            </Typography>
            <Typography variant="body1">
              Did you know that drone operators earn 70% of the total cost for
              uploaded drone data? Follow the easy steps below to add your
              datasets to our growing library. When clients order your data,
              you&apos;ll be paid 70% of the price.
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h6" sx={styles.heading}>
              Custom Earth Observation Data in Just a Few Clicks
            </Typography>
            <Typography variant="body1">
              Follow the easy steps below, and we&apos;ll task a local drone
              ASAP to collect your data order. And remember, image size can vary
              depending on the type of information you need.
            </Typography>
          </>
        )}

        <Typography variant="body1" sx={styles.textPoints}>
          1. Find your location on the map, or use the search function.
          <br></br>2. Click Draw.<br></br>3. Click to create points on the map
          outlining your desired area. Use the Edit and Clear buttons as
          necessary.<br></br>4. Your Mission Order Information will update
          automatically.<br></br>5. Click Done.
        </Typography>
      </Box>
      <Box sx={styles.rightMainContainer}>
        {polygon && currentUser != userRoles.pilot && (
          <Grid container xs={12} sx={styles.informationGrid}>
            <Grid item xs={12} sx={styles.image_name_main}>
              <Box
                src={GlobheLogo}
                component="img"
                sx={styles.logo}
                alt="Globhe Logo"
              />
              <Typography variant="body2" sx={styles.missionInfoHeader}>
                Mission Order Information
              </Typography>
            </Grid>
            <Grid item xs={3} sx={styles.infoContainer}>
              <Box sx={styles.estimatedImage}>
                <Box
                  src={EstimatedMissionIcon}
                  component="img"
                  sx={styles.avatar}
                  alt="Estimated Mission"
                />
              </Box>
              <Box sx={styles.estimatedImage}>
                <Typography variant="h5" sx={styles.iconText}>
                  {expectedTime(areaSize)} days
                </Typography>
              </Box>
              <Typography
                variant="body1"
                align="center"
                style={{ wordWrap: "break-word" }}
                sx={styles.iconContain}
              >
                Estimated time to acquire data
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
            <Grid item xs={3} sx={styles.infoContainer}>
              <Box sx={styles.estimatedImage}>
                <Box
                  src={GeographicArea}
                  component="img"
                  sx={styles.avatar}
                  alt="Geographic Area"
                />
              </Box>
              <Box sx={styles.estimatedImage}>
                <Typography variant="h5" sx={styles.iconText}>
                  {displayAreaSizeInSelectedUnits(areaSize)}
                  {areaSizeUnitNotation()}
                </Typography>
              </Box>
              <Typography
                variant="body1"
                align="center"
                sx={styles.iconContain}
              >
                Geographic area
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
            <Grid item xs={3} sx={styles.infoContainer}>
              <Box sx={styles.estimatedImage}>
                <Box
                  src={EstimatedImage}
                  component="img"
                  sx={styles.avatar}
                  alt="Estimated Image"
                />
              </Box>
              <Box sx={styles.estimatedImage}>
                <Typography variant="h5" sx={styles.iconText}>
                  {expectedMbSize(areaSize)} MB
                </Typography>
              </Box>
              <Typography
                variant="body1"
                align="center"
                sx={styles.iconContain}
              >
                Estimated image size
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
            <Grid item xs={3} sx={styles.infoContainer}>
              <Box sx={styles.estimatedImage}>
                <Box
                  src={EstimatedTotalCost}
                  component="img"
                  sx={styles.avatar}
                  alt="Estimated Total Cost"
                />
              </Box>
              <Box sx={styles.estimatedImage}>
                from &nbsp;
                <Typography variant="h5" sx={styles.iconText}>
                  ${expectedCost(areaSize)}
                </Typography>
              </Box>
              <Typography
                variant="body1"
                align="center"
                sx={styles.iconContain}
              >
                Depending on geographic location
              </Typography>
            </Grid>
          </Grid>
        )}
        <Box sx={styles.buttonContainer}>
          <Button
            variant="outlined"
            sx={{
              ...styles.ctaClearButton,
              color: `${theme.palette.primary.main}`,
            }}
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={{
              ...styles.ctaButton,
              background: `${theme.palette.primary.main}`,
            }}
            disabled={!polygon}
            onClick={() => save()}
          >
            Done
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

MissionOrderInformation.propTypes = {
  areaSize: PropTypes.string,
  polygon: PropTypes.bool,
  expectedTime: PropTypes.func,
  displayAreaSizeInSelectedUnits: PropTypes.func,
  onCancel: PropTypes.func,
  areaSizeUnitNotation: PropTypes.func,
  expectedCost: PropTypes.func,
  expectedMbSize: PropTypes.func,
  save: PropTypes.func,
};

// MissionOrderInformation.defaultProps = {
//   areaSize: undefined,
//   polygon: null,
//   expectedTime: undefined,
//   displayAreaSizeInSelectedUnits: undefined,
//   onCancel: undefined,
//   areaSizeUnitNotation: undefined,
//   expectedCost: undefined,
//   expectedMbSize: undefined,
//   save: undefined,
// };

export default MissionOrderInformation;
