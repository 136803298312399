import { Box, Typography, Grid } from "@mui/material";
import React, { Fragment } from "react";
import BackNav from "../../../global/backButton.js";
import PropTypes from "prop-types";
import AppButton from "../../../global/elements/AppButton";
import ProgressBar from "../../../global/ProgressBar";
import theme from "../../../../theme";
import Switcher from "../../../global/Switcher";
import {
  projectStatus,
  userRoles,
  missionStatus,
} from "../../../../lib/constants";
import { getPercentage } from "../../../../lib/helpers";
import UserNextActionsBanner from "../../missions/NewMissionDetails/components/UserNextActionsBanner.js";

const styles = {
  normalText: {
    fontWeight: "200 !important",
  },
  marginTop: {
    marginTop: "30px",
  },
  projectIdContainer: {
    display: "flex",
  },
  textMarginLeft: {
    marginLeft: "2px",
  },
  switchContainer: {
    margin: { xs: "30px 0" },
    float: { sm: "right" },
  },
  statusBanner: {
    margin: { xs: "15px 0" },
    float: { sm: "right" },
  },
  progressBarContainer: {
    dislay: "grid",
    marginTop: { lg: "10%" },
    position: { sm: "absolute" },
    width: "-webkit-fill-available",
    bottom: { sm: "20px" },
  },
  relativePosition: { position: "relative" },
};

const ProjectHeaders = ({
  projectId = null,
  projectName = null,
  completedMissionCount = 0,
  allMissionCount = 0,
  sectionActive = 0,
  setSectionActive = undefined,
  createFlag = false,
  editFlag = false,
  backNavPath = null,
  currentProjectStatus = "draft",
  projectMissions = undefined,
  role,
}) => {
  const calculateProgress = () => {
    let totalCnt = projectMissions?.filter(
      (data) =>
        data.status ===
        (missionStatus.deleted ||
          missionStatus.archive ||
          missionStatus.rejected ||
          missionStatus.cancelled)
    );
    let validMissions = allMissionCount - totalCnt?.length;
    return getPercentage(completedMissionCount, validMissions).toFixed(1);
  };
  const isClient = role === userRoles.client;
  const isAdmin = role === userRoles.admin;
  const isPilot = role === userRoles.pilot;

  const createBackRouteForProject = () => {
    if (isPilot) {
      return "/app/pilots/all/projects";
    } else if (backNavPath) {
      return backNavPath;
    } else if (currentProjectStatus === projectStatus.completed) {
      if (isAdmin) {
        return "/app/admin/projects/completed";
      } else if (isClient) {
        return "/app/client/projects/completed";
      }
    } else if (currentProjectStatus === projectStatus.active) {
      if (isAdmin) {
        return "/app/admin/projects/active";
      } else if (isClient) {
        return "/app/client/projects/active";
      }
    } else if (currentProjectStatus === projectStatus.archived) {
      if (isAdmin) {
        return "/app/admin/projects/archive";
      } else if (isClient) {
        return "/app/client/projects/initial-project";
      }
    } else {
      if (isAdmin) {
        return "/app/admin/projects/initial-project";
      } else if (isClient) {
        return "/app/client/projects/initial-project";
      }
    }
  };

  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12} sm={6}>
        <BackNav routeTo={createBackRouteForProject()} />
        {!createFlag && (
          <>
            <Box sx={{ ...styles.projectIdContainer, ...styles.marginTop }}>
              <Typography variant="body2">PROJECT ID:</Typography>
              <Typography variant="body2" sx={styles.textMarginLeft}>
                {projectId || "project_id"}
              </Typography>
            </Box>
            <Typography variant="h4">
              {projectName || "Project Name"}
            </Typography>
          </>
        )}
        {createFlag && (
          <Typography variant="h5" sx={styles.marginTop}>
            {"Create new project"}
          </Typography>
        )}
        {!editFlag &&
          !createFlag &&
          role != userRoles.pilot &&
          currentProjectStatus != projectStatus.deleted && (
            <AppButton
              look="black"
              label="Edit"
              internalLink
              url={
                role == userRoles.client
                  ? `/app/client/project/edit/${projectId}`
                  : `/app/admin/project/edit/${projectId}`
              }
              addtionalStyle={styles.marginTop}
            />
          )}
      </Grid>

      {!editFlag && !createFlag && (
        <Grid item xs={12} sm={6} sx={styles.relativePosition}>
          {isAdmin && (
            <Box sx={styles.switchContainer}>
              <Switcher
                tabActive={sectionActive}
                setTabActive={setSectionActive}
                buttonText1="admin dashboard"
                buttonText2="client dashboard"
                activeBackGround={theme.palette.black.darkSeaBlack}
                inactiveBackground={theme.palette.grey.white}
                activeColor={theme.palette.grey.white}
                inactiveColor={theme.palette.black.darkSeaBlack}
                border={`2px solid ${theme.palette.black.darkSeaBlack}`}
              />
            </Box>
          )}
          <Box sx={styles.progressBarContainer}>
            {currentProjectStatus != projectStatus.draft &&
              currentProjectStatus != projectStatus.deleted &&
              allMissionCount > 0 && (
                <ProgressBar
                  progress={calculateProgress()}
                  textBelow={"PROJECT COMPLETED"}
                  showTextBelow={true}
                />
              )}
          </Box>
        </Grid>
      )}
      {currentProjectStatus == projectStatus.draft && (
        <Grid item xs={12}>
          <UserNextActionsBanner
            actionText="This order is not yet submitted"
            actionSubText="Your project is currently in draft mode and is not yet submitted. Feel free to make any adjustments or add more sites as needed. Once you’re ready to proceed, simply submit your order below and you’ll receive a quote within 24 hours"
            background={theme.palette.status.warningLight}
          />
        </Grid>
      )}
    </Grid>
  );
};

ProjectHeaders.propTypes = {
  role: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  projectName: PropTypes.string,
  completedMissionCount: PropTypes.number,
  allMissionCount: PropTypes.number,
  sectionActive: PropTypes.number,
  setSectionActive: PropTypes.func,
  createFlag: PropTypes.bool,
  editFlag: PropTypes.bool,
  backNavPath: PropTypes.string,
  currentProjectStatus: PropTypes.string,
  projectMissions: PropTypes.object,
};

// ProjectHeaders.defaultProps = {
//   projectId: null,
//   projectName: null,
//   completedMissionCount: 0,
//   additionalStyles: {},
//   allMissionCount: 0,
//   sectionActive: 0,
//   setSectionActive: undefined,
//   createFlag: false,
//   editFlag: false,
//   backNavPath: null,
//   currentProjectStatus: "draft",
//   projectMissions: undefined,
// };

export default ProjectHeaders;
