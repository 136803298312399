import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import firebase from "src/firebase";
import { Box, Typography } from "@mui/material";
import { getBankDetail } from "../../../../services/bank-details";
import StatusAlertContainer from "../../../global/elements/StatusAlertContainer";
import {
  pilotInvoiceType,
  statusAlertSeverity,
  userRoles,
} from "../../../../lib/constants";
import InvoicePage from "./components/InvoicePage";
import theme from "../../../../theme";
import {
  getInvoice,
  getMissionInvoiceByMissionId,
} from "../../../../services/invoices";
import UniversalLoader from "../../../global/elements/UniversalLoader";
import { getMission } from "../../../../services/missions";
import { getBasicProjectDetails } from "../../../../services/project";

const styles = {
  headingCls: {},
  bankErrorCls: {},
  pilotInvoiceContainer: {
    background: theme.palette.grey.white,
    display: "flex",
    flexDirection: "column",
    rowGap: "2em",
  },
  loaderDiv: {
    paddingTop: "5%",
  },
  bankDetailsCls: { marginTop: "2px" },
};

const PilotInvoiceTab = ({
  missionData = undefined,
  currentUser = undefined,
  refreshMission = undefined,
  invoiceId = undefined,
  showCancelButton = false,
  showHeading = true,
}) => {
  const [bankDetails, setBankDetails] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mission, setMission] = useState(null);
  const [project, setProject] = useState(null);
  const [invoiceType, setInvoiceType] = useState(
    pilotInvoiceType.missionInvoice
  ); // missionInvoice , projectInvoice

  useEffect(() => {
    if (invoiceId) {
      fetchInvoiceById();
    }
  }, []);

  useEffect(() => {
    if (invoice?.pilotId) {
      fetchBankDetails(invoice?.pilotId);
    }
  }, [invoice]);

  useEffect(() => {
    if (missionData) {
      setMission(missionData);
      fetchInvoice(missionData);
    }
  }, [missionData]);

  const fetchBankDetails = async (pilotId) => {
    try {
      const data = await getBankDetail(pilotId);
      if (data && data.bank_details) {
        setBankDetails(data.bank_details);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log("Error", e);
    }
  };

  const fetchInvoice = async (missionData) => {
    try {
      const data = await getMissionInvoiceByMissionId({
        pilotId: missionData?.assignedPilot,
        missionId: missionData?.id,
      });
      if (data && data.id) {
        setInvoice(data);
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const fetchInvoiceById = async () => {
    try {
      let invoiceDetails = await getInvoice(invoiceId);
      if (invoiceDetails?.invoice_details) {
        const invoiceData = invoiceDetails?.invoice_details;
        setInvoice(invoiceData);
        if (invoiceData?.projectId) {
          setInvoiceType(pilotInvoiceType.projectInvoice);
          fetchProjectById(invoiceData?.projectId);
        } else {
          setInvoiceType(pilotInvoiceType.missionInvoice);
          fetchMissionById(invoiceData?.missionId);
        }
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const fetchMissionById = async (id) => {
    try {
      const missionObj = await getMission(firebase, id);
      if (missionObj) {
        setMission(missionObj);
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const fetchProjectById = async (id) => {
    try {
      const projectObj = await getBasicProjectDetails(firebase, id);
      if (projectObj && projectObj.project) {
        setProject(projectObj.project);
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  return (
    <Box sx={styles.pilotInvoiceContainer}>
      {loading ? (
        <Box sx={styles.loaderDiv}>
          <UniversalLoader />
        </Box>
      ) : (
        <>
          <Box sx={styles.headingCls}>
            {showHeading && <Typography variant="h4">Send Invoice</Typography>}
            <Typography variant="body1">
              {currentUser.role === userRoles.pilot
                ? "This is an automatically generated invoice by the GLOBHE platform based on your profile (filled out bank- and company information). Please make sure that all the information is filled out correctly before sending this invoice by reading through all the details below."
                : "This invoice is automatically generated based on the pilot bank and mission details. They still need to approve and click on send before admins can process it."}
            </Typography>
          </Box>

          {!bankDetails ? (
            <Box sx={styles.bankErrorCls}>
              <StatusAlertContainer
                severity={statusAlertSeverity.error}
                heading={
                  currentUser.role === userRoles.pilot
                    ? "Your bank details are not filled correctly! They are needed to automatically generate your invoice. Please update your bank details before you can send your invoice."
                    : "The operator did not fill their bank details fully. Therefore, this invoice could not be generated."
                }
                title="Bank details needed"
                showLeftBorder={true}
              />
            </Box>
          ) : (
            <>
              <Box sx={styles.bankDetailsCls}>
                <InvoicePage
                  bankDetails={bankDetails}
                  mission={mission}
                  invoice={invoice}
                  refreshMission={refreshMission}
                  invoiceType={invoiceType}
                  project={project}
                  currentUser={currentUser}
                  showCancelButton={showCancelButton}
                />
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default PilotInvoiceTab;

PilotInvoiceTab.propTypes = {
  missionData: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  refreshMission: PropTypes.func,
  invoiceId: PropTypes.string,
  showCancelButton: PropTypes.bool,
  showHeading: PropTypes.bool,
};

PilotInvoiceTab.defaultProps = {
  invoiceId: undefined,
  missionData: undefined,
  refreshMission: undefined,
  showCancelButton: false,
  showHeading: true,
};
