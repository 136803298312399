import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import theme from "../../../../../theme";
import firebase from "src/firebase";
import { navigate } from "gatsby";
import { useSnackbar } from "notistack";
import {
  flexColumn,
  flexible,
  flexSpace,
} from "../../../../../lib/styleConstants";
import SingleLogo from "src/assets/logos/globhe_logo/Globhe_symbol_2022_black.png";
import {
  globheInvoiceAddress,
  invoicePaymentStatus,
  invoicesStatus,
  pilotInvoiceType,
  userRoles,
} from "../../../../../lib/constants";
import AppButton from "../../../../global/elements/AppButton";
import PropTypes from "prop-types";
import Dialogue from "../../../../global/Dialogue";
import EditInvoiceForm from "./EditInvoiceForm";
import {
  invoiceDetailsUpdate,
  invoiceStatusUpdate,
} from "../../../../../services/invoices";
import {
  checkIfEUCountryByLocation,
  getCurrentDate,
} from "../../../../../lib/helpers";
import { getUser } from "../../../../../services/auth";
import StatusBadge from "../../../../global/elements/StatusBadge";
import { updateMission } from "../../../../../services/missions";
import { createNotification } from "../../../../../services/notification";
import { getPilot } from "../../../../../services/pilots";
import SwitchSelect from "../../../../global/elements/SwitchSelect";

const styles = {
  outerWrap: {},
  wrapper: {
    background: theme.palette.grey.white,
    boxShadow: `0px 0px 72px #0000001F`,
    marginTop: "20px",
  },
  container: {
    padding: { xs: "2em", sm: "2.5em", lg: "4em" },
  },
  header: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    rowGap: "3em",
  },
  logo: {
    height: "80px",
    // marginLeft: "15px",
  },
  headerTab1: {
    flex: 1,
    display: "flex",
    alignSelf: { xs: "start", sm: "center" },
    columnGap: "1em",
  },
  headerTab2: {
    flex: 1,
    textAlignLast: { xs: "start", sm: "end" },
  },
  headerLine: {
    border: `1.5px solid ${theme.palette.black.coolGrey}`,
    margin: "2em 0",
  },
  body1: {
    margin: "3em 0",
  },
  headingDevider: {
    color: ` ${theme.palette.black.darkSeaBlack}`,
  },
  headingText: {
    padding: "0.5em 0",
  },
  actionContainer: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "end",
    flexDirection: { xs: "column-reverse", sm: "row" },
    rowGap: "1em",
  },
  buttons: {
    display: "flex",
    columnGap: "1em",
    flexDirection: { xs: "column", sm: "row" },
    rowGap: "1em",
    justifyContent: "flex-end",
  },
  bankDetailsContainer: {
    background: theme.palette.primary.palePistachio,
    display: "flex",
    columnGap: "1em",
    flexDirection: { xs: "column", sm: "row" },
    rowGap: "3em",
    // padding: { xs: "2em", sm: "2em", lg: "3em" },
  },
  companydescBox: {
    display: "flex",
    columnGap: "1em",
    justifyContent: { xs: "start", sm: "right" },
  },
  billBox: { display: "flex", flexDirection: "column", rowGap: "1em" },
  missionDetails: { display: "flex", columnGap: "1em" },
  vatContainer: {
    display: "flex",
    padding: "1em 0 1em 0",
    justifyContent: "end",
    flexDirection: { xs: "column", sm: "row" },
  },
  vatBox: { width: { xs: "100%", sm: "50%" } },
  alterPay: { width: { xs: "100%", sm: "30%" } },
  bankDetailBox: { display: "flex", flexWrap: "wrap", columnGap: "5px" },
  paymentLabel: {
    textTransform: "capitalize",
  },
  // bankInfo: { rowGap: "2em", width: { xs: "100%", sm: "60%" } },
  bankInfo: { width: { xs: "100%", sm: "60%" } },
  bottomMargin: { margin: "16px 0" },
  invoiceHeadingContainer: { marginTop: "auto", marginBottom: "auto" },
  marginTop20: {
    marginTop: "20px",
  },
  verticalDivider: { margin: "0 10px" },
  marginBottom20: { marginBottom: "20px" },
  hintCls: {
    lineHeight: 1.2,
    textAlignLast: "start",
    color: theme.palette.grey.warmGrey,
    textTransform: "none",
    alignSelf: "self-end",
    width: { xs: "100%", sm: "200px" },
  },
  switchRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "15px",
  },
  badgeContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: { xs: "column", sm: "row" },
    rowGap: { xs: "1em", sm: "none" },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: { xs: "column", md: "row" },
    rowGap: { xs: "1rem", md: "none" },
  },
  cancelButton: { width: { xs: "100%", md: "35%" }, display: "flex" },
  hintBoxCls: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    rowGap: "1rem",
  },
};

const DividerHead = ({ heading = undefined }) => {
  return (
    <Box>
      <Divider sx={styles.headingDevider} />
      <Typography variant="h5" sx={styles.headingText}>
        {heading}
      </Typography>
      <Divider sx={styles.headingDevider} />
    </Box>
  );
};
DividerHead.propTypes = {
  heading: PropTypes.string,
};
// DividerHead.defaultProps = {
//   heading: undefined,
// };
const PaymentDsc = ({
  head = undefined,
  value = undefined,
  color = undefined,
}) => {
  return (
    <Box sx={{ ...flexSpace, ...styles.marginBottom20 }}>
      <Typography variant="subtitle1Med" sx={color ? { color: color } : {}}>
        {head}
      </Typography>
      <Typography variant="subtitle1Med">
        {value != "N/A" && "$"}
        {value}
      </Typography>
    </Box>
  );
};
PaymentDsc.propTypes = {
  head: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.string,
};
// PaymentDsc.defaultProps = {
//   head: undefined,
//   value: undefined,
//   color: undefined,
// };
const CompanyDesc = ({ head = undefined, value = undefined }) => {
  return (
    <Box sx={styles.companydescBox}>
      <Typography variant="subtitle1Med">{head}: </Typography>
      <Typography variant="body1">{value}</Typography>
    </Box>
  );
};
CompanyDesc.propTypes = {
  head: PropTypes.string,
  value: PropTypes.string,
};
// CompanyDesc.defaultProps = {
//   head: undefined,
//   value: undefined,
// };

const initialValues = {
  invoiceNo: "",
  globheOwnerName: "",
  invoiceName: "", // can be mission name or project name
  docId: "", // can be mission id or project id
  pilotInstructions: "",
};

const InvoicePage = ({
  bankDetails = {},
  invoice = {},
  refreshMission = undefined,
  project = {},
  invoiceType = "",
  currentUser = {},
  showCancelButton = false,
  mission = undefined,
}) => {
  const [showDialogue, setShowDialogue] = useState(false);
  const [showSubmitLoader, setShowSubmitLoader] = useState(false);
  const [editInvoiceLoader, setEditInvoiceLoader] = useState(false);
  const [isEUCountry, setIsEuCountry] = useState(false);
  const [editAccess, setEditAccess] = useState(false);
  const [invoiceValues, setInvoiceValues] = useState(initialValues);
  const [isAdmin, setIsAdmin] = useState(false);
  const [pilotProfile, setPilotProfile] = useState({});
  const [country, setCountry] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  // useEffect(() => {
  //   const euCountry = checkIfEUCountry();
  //   setIsEuCountry(euCountry);
  // }, []);

  useEffect(() => {
    if (invoice?.pilotEditAccess) {
      setEditAccess(true);
    }
  }, [invoice]);

  useEffect(() => {
    if (mission) {
      setInitialValues();
    }
  }, [mission]);

  useEffect(() => {
    if (project) {
      setInitialValues();
    }
  }, [project]);

  useEffect(() => {
    if (currentUser?.role == userRoles.admin) {
      setIsAdmin(true);
      // Fetch pilot profile
      fetchPilotProfile();
    }
    if (currentUser?.role == userRoles.pilot) {
      const userProfile = getUser("userProfile");
      setPilotProfile(userProfile);
    }
  }, [currentUser]);

  useEffect(() => {
    if (pilotProfile && pilotProfile.location) {
      let location = pilotProfile.location;
      const euCountry = checkIfEUCountryByLocation(location);
      setIsEuCountry(euCountry);
      if (typeof location == "string") {
        location = location.toLowerCase();
      }
      setCountry(location);
    }
  }, [pilotProfile]);

  const setInitialValues = () => {
    let initialValues = {
      invoiceNo: "",
      globheOwnerName: "",
      invoiceName: "", // can be mission name or project name
      docId: "", // can be mission id or project id
      pilotInstructions: "",
    };
    if (invoiceType == pilotInvoiceType.missionInvoice) {
      initialValues.invoiceNo = mission?.id;
      initialValues.globheOwnerName = mission?.missionOwnerName;
      initialValues.invoiceName = mission?.missionName;
      initialValues.docId = mission?.id;
      initialValues.pilotInstructions = mission?.pilotInstructions;
    } else if (invoiceType == pilotInvoiceType.projectInvoice) {
      initialValues.invoiceNo = project?.id;
      initialValues.globheOwnerName = project?.missionOwnerName;
      initialValues.invoiceName = project?.projectName;
      initialValues.docId = project?.id;
      initialValues.pilotInstructions = project?.pilotInstructions;
    }
    setInvoiceValues(initialValues);
  };

  const fetchPilotProfile = async () => {
    try {
      if (invoice?.pilotId) {
        const pilot = await getPilot(firebase, invoice?.pilotId);
        setPilotProfile(pilot);
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const checkSwedenCountry = () => {
    if (country == "sweden") {
      return true;
    } else {
      return false;
    }
  };

  const {
    company_name,
    companyAddress,
    registration_number,
    email,
    bank_name,
    bank_address,
    bank_postal_address,
    account_number,
    account_type,
    SWIFT,
    IBAN,
    routing_number,
    alternative_payments_methods,
  } = bankDetails;

  const approveHandler = () => {
    setShowSubmitLoader(true);
    let values = {
      due_date: invoice?.due_date,
    };
    values["status"] = invoicePaymentStatus.unpaid;
    invoiceDetailsUpdate(values, invoice?.id)
      .then((status) => {
        if (status.status == "Success") {
          setShowSubmitLoader(false);
          refreshMission();
        }
      })
      .catch((e) => {
        console.log(e);
        setShowSubmitLoader(false);
      });
  };

  const grantPilotAccess = async (value) => {
    const data = {
      pilotEditAccess: value,
    };
    try {
      const statusUpdate = await invoiceStatusUpdate(data, invoice.id);
      if (statusUpdate.status == "Success") {
        refreshMission();
      } else {
        enqueueSnackbar(`Error in permission grant`, { variant: "error" });
      }
    } catch (e) {
      enqueueSnackbar(`Error in processing, ${e}`, { variant: "error" });
    }
  };

  const statusChangeFcn = async (type) => {
    const data = {};
    let message = "";

    switch (type) {
      case "rejected":
        data["status"] = "rejected";
        message = "Your invoice has been rejected";
        break;
      case "paid":
        data["status"] = "paid";
        message = "Your invoice has been paid";
        break;
      case "unpaid":
        data["status"] = "unpaid";
        message = "Your invoice has been unpaid";
        break;
      case "require_edit":
        data["status"] = "require_edit";
        message = "You have to update your invoice.";
        break;
    }
    const statusUpdate = await invoiceStatusUpdate(data, invoice?.id);

    if (statusUpdate.status == "Success") {
      // Change the status in mission collection
      try {
        let updates = {
          paymentStatusPilot: data.status,
        };
        let missionId = invoice?.missionId;
        let projectId = invoice?.projectId;
        if (missionId) {
          await updateMission(firebase, missionId, updates);
          createNotification(
            firebase,
            invoice.pilotId,
            `${message}`,
            `${message}`,
            `/app/missions/${missionId}`
          );
        } else if (projectId) {
          createNotification(
            firebase,
            invoice.pilotId,
            `${message}`,
            `${message}`,
            `/app/my-profile/invoices/${invoice?.id}`
          );
        }
        refreshMission();
      } catch (e) {
        console.log("Error", e);
      }
    }
  };

  const onCancel = () => {
    if (!isAdmin) {
      navigate("/app/my-profile/invoices");
    } else {
      navigate("/app/admin/dashboard/invoices");
    }
  };

  const AdminButtons = () => {
    return (
      <>
        <AppButton
          look="black"
          label={
            invoice?.status == invoicePaymentStatus.require_edit
              ? "Sent for editing"
              : "Require editing"
          }
          onClick={() => statusChangeFcn("require_edit")}
        />

        <AppButton
          look={
            invoice?.status != invoicePaymentStatus.paid ? "green" : "negative"
          }
          label={
            invoice?.status != invoicePaymentStatus.paid
              ? "Mark as paid"
              : "Mark as unpaid"
          }
          onClick={() =>
            invoice?.status != invoicePaymentStatus.paid
              ? statusChangeFcn("paid")
              : statusChangeFcn("unpaid")
          }
          submittingState={showSubmitLoader}
        />
      </>
    );
  };

  const PilotButtons = () => {
    return (
      <>
        {editAccess && (
          <AppButton
            look="black"
            label="Edit invoice"
            onClick={() => setShowDialogue(true)}
          />
        )}

        <AppButton
          look="green"
          label="Approve & send invoice"
          onClick={approveHandler}
          submittingState={showSubmitLoader}
        />
      </>
    );
  };

  const StatusHeaderComp = () => {
    return (
      <Box sx={styles.badgeContainer}>
        <StatusBadge
          name={
            invoicesStatus[invoice?.status]
              ? invoicesStatus[invoice?.status][0]
              : invoice?.status
          }
          status={
            invoicesStatus[invoice?.status]
              ? invoicesStatus[invoice?.status][1]
              : ""
          }
        />
        {isAdmin && (
          <Box>
            <Typography variant="h6" style={styles.label_text}>
              Allow operator to edit invoice
            </Typography>
            <Box sx={styles.switchRow}>
              <SwitchSelect
                name="paymentStatusPilot"
                handleChange={(e, value) => {
                  grantPilotAccess(value);
                }}
                checked={
                  invoice?.pilotEditAccess ? invoice.pilotEditAccess : false
                }
                textLeft="Deny"
                textRight="Allow"
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const InvoiceHeaderComp = () => {
    return (
      <Box sx={styles.header}>
        <Box sx={styles.headerTab1}>
          {" "}
          <Box component="img" src={SingleLogo} alt="Logo" sx={styles.logo} />
          <Box sx={styles.invoiceHeadingContainer}>
            <Typography variant="h4">Invoice</Typography>
            <Typography variant="subtitle1">
              INVOICE NUMBER : OP{invoiceValues?.invoiceNo}
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.headerTab2}>
          <Typography variant="h5">Issued by</Typography>
          <Box sx={styles.bottomMargin}>
            <CompanyDesc head={"Company Name"} value={company_name} />
            <CompanyDesc head={"Company Address"} value={companyAddress} />
            <CompanyDesc
              head={"Postal code"}
              value={pilotProfile?.postalCode || "N/A"}
            />
            <CompanyDesc
              head={"Registration number"}
              value={registration_number}
            />
            <CompanyDesc head={"Email Address"} value={email} />
            <CompanyDesc head={"Phone"} value={pilotProfile?.phone || "N/A"} />
            {isEUCountry && (
              <CompanyDesc
                head={"VAT Number"}
                value={
                  bankDetails?.vat_number ? bankDetails?.vat_number : "N/A"
                }
              />
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const InvoiceBodyComp = () => {
    return (
      <Box>
        <Box
          sx={{
            ...styles.body1,
            ...styles.billBox,
          }}
        >
          <Box sx={{ ...flexSpace }}>
            <Typography variant="h5">Bill to</Typography>
            <Box>
              <Typography variant="subtitle1Med">
                {getCurrentDate(invoice?.dateCreated)}
              </Typography>
              <Typography variant="subtitle1">Invoice Date</Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{ ...flexible }}>
              <Box sx={{ flex: 1 }}>
                <Typography variant="h6">{globheInvoiceAddress}</Typography>
                <Typography variant="h6">
                  VAT NUMBER: SE-5569940801-01
                </Typography>
              </Box>
              <Box sx={{ flex: 1, textAlignLast: "end" }}>
                <Typography variant="subtitle1Med">
                  {invoice?.due_date}
                </Typography>
                <Typography variant="subtitle1">Due Date</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                ...flexible,
                columnGap: "1em",
                ...styles.marginTop20,
              }}
            >
              <Typography variant="body1">Globhe Reference person:</Typography>
              <Typography variant="body1">
                {invoiceValues?.globheOwnerName}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <DividerHead heading={"Description"} />
        </Box>
        <Box sx={{ padding: "2em 0" }}>
          <Box sx={styles.missionDetails}>
            <Typography variant="subTitle1Med">
              {invoiceValues?.docId}
            </Typography>
            <Typography variant="subTitle1Med">&#x2015;</Typography>
            <Typography variant="subTitle1Med">
              {invoiceValues?.invoiceName}
            </Typography>
          </Box>
          <Typography variant="body1">
            {invoiceValues?.pilotInstructions}
          </Typography>
        </Box>
        <DividerHead heading={"Payment Information "} />
        <Box>
          <Box sx={styles.vatContainer}>
            {/* <Box sx={styles.vatBox}></Box> */}
            <Box sx={{ ...styles.vatBox }}>
              <PaymentDsc
                head="Subtotal"
                value={invoice?.amount}
                color={theme.palette.grey.warmGrey}
              />
              {checkSwedenCountry() && (
                <PaymentDsc
                  head="VAT 25%"
                  value={isEUCountry ? invoice?.amount / 4 : "N/A"}
                  color={theme.palette.grey.warmGrey}
                />
              )}
              <PaymentDsc
                head="Total Amount"
                color={theme.palette.grey.warmGrey}
                value={
                  isEUCountry && checkSwedenCountry()
                    ? invoice?.amount * 1.25
                    : invoice?.amount
                }
              />
            </Box>
          </Box>
          <Divider sx={{ ...styles.headerLine, margin: "none" }} />
          <Box sx={styles.vatContainer}>
            {/* <Box sx={styles.vatBox}></Box> */}
            <Box sx={styles.vatBox}>
              <PaymentDsc
                head={"Amount Due"}
                value={
                  isEUCountry && checkSwedenCountry()
                    ? invoice?.amount * 1.25
                    : invoice?.amount
                }
              />
            </Box>
          </Box>
        </Box>
        {isEUCountry && (
          <Typography variant="body1" sx={{ padding: "2em 0" }}>
            VAT reverse-charge. Under article 196 of Council Directive
            2006/112/EC, you may be required to account for any VAT on the
            services covered by this invoice
          </Typography>
        )}
      </Box>
    );
  };

  const BankInfoComp = () => {
    return (
      <Box sx={{ ...styles.bankDetailsContainer, ...styles.container }}>
        <Box
          sx={{
            ...flexColumn,
            ...styles.bankInfo,
          }}
        >
          <Typography variant="h6">Bank Information </Typography>
          <Box sx={styles.marginTop20}>
            <Box sx={styles.bankDetailBox}>
              <CompanyDesc head={"Bank name"} value={bank_name} /> |{" "}
              <CompanyDesc head={"Bank address"} value={bank_address} />
            </Box>
            <Box sx={styles.bankDetailBox}>
              <CompanyDesc
                head={"Bank postal address"}
                value={bank_postal_address}
              />
              | <CompanyDesc head={"Account number"} value={account_number} />
            </Box>
            <Box sx={styles.bankDetailBox}>
              <CompanyDesc head={"Account type"} value={account_type} /> |{" "}
              <CompanyDesc head={"SWIFT code"} value={SWIFT} />
            </Box>
            <Box sx={styles.bankDetailBox}>
              <CompanyDesc head={"IBAN"} value={IBAN} /> |{" "}
              <CompanyDesc head={"Routing number"} value={routing_number} />
            </Box>
          </Box>
        </Box>
        {alternative_payments_methods.length ? (
          <>
            <Box
              sx={{
                ...flexColumn,
              }}
            >
              <Divider
                orientation="vertical"
                variant="middle"
                sx={styles.verticalDivider}
              />
            </Box>
            <Box
              sx={{
                ...flexColumn,
                ...styles.alterPay,
              }}
            >
              <Typography variant="h6">Alternative payment methods</Typography>
              <Box sx={styles.marginTop20}>
                {alternative_payments_methods.length > 0 &&
                  alternative_payments_methods.map((payment, i) => (
                    <Box key={i}>
                      <Typography
                        variant="subtitle1Med"
                        sx={styles.paymentLabel}
                      >
                        {payment.label}
                      </Typography>
                      <Typography variant="body1">{payment.id}</Typography>
                    </Box>
                  ))}
              </Box>
            </Box>
          </>
        ) : (
          <></>
        )}
      </Box>
    );
  };

  const InvoiceFooterComp = () => {
    return (
      <Box
        sx={{
          ...styles.actionContainer,
          ...flexColumn,
          textAlignLast: "right",
        }}
      >
        {(isAdmin || invoice?.status != invoicePaymentStatus.paid) && (
          <>
            <Divider sx={styles.headerLine} />
            <Box sx={styles.buttonContainer}>
              <Box sx={styles.cancelButton}>
                {showCancelButton && (
                  <AppButton
                    look="inverted"
                    noIcon
                    label="Cancel"
                    onClick={() => {
                      onCancel();
                    }}
                  />
                )}
              </Box>

              <Box sx={{ ...styles.buttons, width: "100%" }}>
                {isAdmin ? <AdminButtons /> : <PilotButtons />}
              </Box>
            </Box>
            {!isAdmin && (
              <Box sx={styles.hintBoxCls}>
                <Typography variant="overline" sx={{ ...styles.hintCls }}>
                  {`By approving you are automatically creating an invoice for the
                          ${invoiceValues?.invoiceName} ${
                    invoiceType == pilotInvoiceType.missionInvoice
                      ? "mission"
                      : "project"
                  } that will be sent to our admin`}
                  .
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    );
  };

  return (
    <Box sx={styles.outerWrap}>
      <StatusHeaderComp />

      <Box sx={styles.wrapper}>
        <Box sx={styles.container}>
          <InvoiceHeaderComp />
          <Divider sx={styles.headerLine} />
          <InvoiceBodyComp />
        </Box>
        <BankInfoComp />
        {showDialogue && (
          <Dialogue
            showDialogue={showDialogue}
            setShowDialogue={setShowDialogue}
            DialogueBody={EditInvoiceForm({
              invoice: invoice,
              editAccess: editAccess,
              setShowDialogue: setShowDialogue,
              setShowSubmitLoader: setEditInvoiceLoader,
              showSubmitLoader: editInvoiceLoader,
              refreshMission: refreshMission,
            })}
            tableType={true}
            showCancelButton={true}
            cancelButtonText={"Cancel"}
            sumitButtonText={"Yes"}
            showSubmitLoader={showSubmitLoader}
            setShowSubmitLoader={setShowSubmitLoader}
            submitButtonLook={"primary"}
          />
        )}
      </Box>
      <InvoiceFooterComp />
    </Box>
  );
};

InvoicePage.propTypes = {
  mission: PropTypes.object.isRequired,
  bankDetails: PropTypes.object,
  invoice: PropTypes.object,
  refreshMission: PropTypes.func,
  project: PropTypes.object,
  invoiceType: PropTypes.string,
  currentUser: PropTypes.object,
  showCancelButton: PropTypes.bool,
};
// InvoicePage.defaultProps = {
//   bankDetails: {},
//   invoice: {},
//   refreshMission: undefined,
//   project: {},
//   invoiceType: "",
//   currentUser: {},
//   showCancelButton: false,
// };

export default InvoicePage;
