import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import {
  missionStatus,
  PilotPackageStatus,
  adminClientProgressStatus,
} from "../../../../lib/constants";
import { getUser } from "../../../../services/auth";
import MissionStepper from "../../../global/elements/MissionStepper";
import MissionContext from "../../missions/NewMissionDetails/components/MissionContext";

const styles = {
  progress_bar_main: {
    border: "none",
    width: "100%",
  },
  statusContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    marginBottom: "50px",
  },
};

const BannerAndStepperClient = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const userRole = getUser("user");

  const bannerData = useContext(MissionContext);

  React.useEffect(() => {
    if (
      bannerData?.mission?.status === missionStatus.unassigned ||
      bannerData?.mission?.status === missionStatus.confirmedMission
    ) {
      setActiveStep(1);
    } else if (
      bannerData?.mission?.status === missionStatus.active &&
      bannerData?.mission?.pilotPackageStatus !=
        PilotPackageStatus.pendingReview
    ) {
      setActiveStep(2);
    } else if (
      bannerData?.mission?.status === missionStatus.active &&
      bannerData?.mission?.pilotPackageStatus ==
        PilotPackageStatus.pendingReview
    ) {
      setActiveStep(3);
    } else if (bannerData?.mission?.status === missionStatus.completed) {
      setActiveStep(4);
    }
  }, [bannerData?.mission, bannerData?.totalQuote, userRole.role]);

  return (
    <Grid container sx={styles.progress_bar_main}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
        <MissionStepper
          activeStep={activeStep}
          progress={adminClientProgressStatus}
        />
      </Grid>
    </Grid>
  );
};

BannerAndStepperClient.propTypes = {
  missionData: PropTypes.shape({
    status: PropTypes.string,
    assignedPilot: PropTypes.string,
    declinedByPilots: PropTypes.arrayOf(PropTypes.string),
    sentToPilots: PropTypes.arrayOf(PropTypes.string),
    deadline: PropTypes.string,
    pilotPackageStatus: PropTypes.string,
  }).isRequired,
  progressStatus: PropTypes.array.isRequired,
  totalQuote: PropTypes.number,
  setViewQuote: PropTypes.func,
};

export default BannerAndStepperClient;
