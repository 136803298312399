import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";
import SectionLoadingIndicator from "src/components/global/SectionLoadingIndicator";
import { Box, Grid } from "@mui/material";
import AppButton from "../../global/elements/AppButton";

const style = {
  root: {
    "& ul": {
      listStyle: "none",
      margin: 0,
      paddingLeft: 0,
    },
    "& li": {
      width: "100%",
      height: "3rem",
      borderTop: `1px solid ${theme.palette.grey.lightWarmGrey}`,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "nowrap",
      wordBreak: "break-word",
      margin: "10px 0",
      paddingTop: "10px",
    },
    "&:last-child": {
      borderBottom: `1px solid ${theme.palette.grey.lightWarmGrey}`,
    },
    "& button": {
      marginLeft: "auto",
    },
  },
};

const AdminAccountList = ({
  loading = false,
  adminEmails = undefined,
  removeAdminHandler = undefined,
  currentUserEmail = undefined,
  styles = undefined,
  authorized = false,
}) => {
  if (loading) return <SectionLoadingIndicator align="left" />;
  return (
    <Box sx={{ ...styles, ...style.root }}>
      <ul>
        {adminEmails.map((adminData) => (
          <li key={adminData?.email}>
            <Grid container>
              <Grid item xs={6}>
                <Box>{adminData?.email}</Box>
              </Grid>
              <Grid item xs={3}>
                <Box sx={{ textAlign: "center", justifyContent: "center" }}>
                  {adminData?.adminType || "N/A"}
                </Box>
              </Grid>
              {authorized && (
                <Grid item xs={3}>
                  {adminData?.email !== currentUserEmail && (
                    <AppButton
                      look="negative"
                      onClick={() => removeAdminHandler(adminData?.email)}
                      label="Remove"
                    />
                  )}
                </Grid>
              )}
            </Grid>
          </li>
        ))}
      </ul>
    </Box>
  );
};

AdminAccountList.propTypes = {
  loading: PropTypes.bool,
  adminEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
  removeAdminHandler: PropTypes.func.isRequired,
  currentUserEmail: PropTypes.string.isRequired,
  styles: PropTypes.object.isRequired,
  authorized: PropTypes.bool,
};

export default AdminAccountList;
