import React, { useContext } from "react";
import Layout from "../../../Layout";
import PropTypes from "prop-types";
import MemberMainPage from "./MemberMainPage";
import ArrowHeaderContainer from "../NewDataLibrary/components/ArrowHeaderContainer";
import AppContext from "../../../contexts/AppContext";
import { userRoles } from "../../../lib/constants";

const TeamMemberLayout = ({ memberId = "" }) => {
  const appcontext = useContext(AppContext);
  return (
    <Layout hasSideMenu loading={false}>
      <ArrowHeaderContainer
        header="Team"
        redirectURL={
          appcontext?.userData?.role === userRoles.admin
            ? null
            : "/app/client/enterprise"
        }
        showBottomDivider={true}
      >
        <MemberMainPage memberId={memberId} />
      </ArrowHeaderContainer>
    </Layout>
  );
};

TeamMemberLayout.propTypes = {
  memberId: PropTypes.string.isRequired,
};

export default TeamMemberLayout;
