import firebase from "src/firebase";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { FaSort } from "react-icons/fa";
import SectionLoadingIndicator from "src/components/global/SectionLoadingIndicator";
import {
  invitePilotToMission,
  uninvitePilotToMission,
} from "../../../services/missions";
import PilotInviteListItem from "./PilotInviteListItem";
import theme from "../../../theme";
import { Box } from "@mui/material";
import { createNotification } from "../../../services/notification";
import TablePagination from "../../global/Table/TablePagination";
const styles = {
  root: {
    width: "100%",
    textAlign: "left",
    wordWrap: "break-word",

    "&:not(.loading)": {
      [theme.breakpoints.up("lg")]: {
        background: theme.palette.grey.white,
      },
    },
  },
  labels: {
    display: { xs: "none", lg: "flex" },
    padding: { lg: "1rem" },
    listStyle: { lg: "none" },
    margin: { lg: "0" },
    background: { lg: theme.palette.grey.white },
    borderBottom: { lg: `1px solid ${theme.palette.grey.lightWarmGrey}` },
    "& li": {
      width: { lg: "16.66%" },
      border: { lg: "none" },
      padding: { lg: "0 1rem" },
      fontWeight: { lg: "500" },
    },
  },
  sortable: { cursor: "pointer" },
  sortIcon: {
    position: "relative",
    top: "2px",
    marginLeft: "0.5rem",
    fontSize: "0.8rem",
  },

  loading: {
    padding: "2rem",
    display: "flex",
    justifyContent: "center",
  },
  noPilots: { textAlign: "center", padding: "2rem" },
  paymentStatusRow: {
    display: "flex",
    width: "50px",
    "& h6": {
      width: "auto !important",
    },
  },
  pagination_ul: {
    display: "flex",
    float: "right",
    margin: { xs: "10px", sm: "30px" },
  },
  pagination_button: {
    width: "70px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.grey.white,
    cursor: "pointer",
    height: "36px",
    margin: "4px",
    borderRadius: "4px",
    fontWeight: "500",
    border: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.lightPurple,
      color: theme.palette.black.darkSeaBlack,
    },
  },
  pagination_select: {
    width: { xs: "60px", sm: "80px" },
    height: "36px",
    fontWeight: "500",
    color: theme.palette.grey.white,
    margin: "4px 50px 4px 10px",
    borderRadius: "4px",
    textAlign: "center",
    borderColor: "unset",
    backgroundColor: theme.palette.grey.warmGrey,
  },
  pagination_span: {
    margin: "auto",
    marginRight: "4px",
    fontWeight: "500",
    fontSize: "16px",
  },
};

const PilotInviteList = ({
  missionData = undefined,
  sortByHandler = undefined,
  loading = false,
  limitHandler = undefined,
  className = "",
  pilots = [],
  paginationBtnClick = undefined,
  limit = 10,
  currentPage = 1,
  lastPage = null,
}) => {
  const isInvited = useCallback(
    (pilot) => {
      return (
        missionData.sentToPilots?.some(
          (invitedPilotID) => invitedPilotID === pilot.id
        ) || false
      );
    },
    [missionData.sentToPilots]
  );

  const hasDeclined = useCallback(
    (pilot) => {
      return (
        missionData.declinedByPilots?.some(
          (declinedPilot) => declinedPilot.id === pilot.id
        ) || false
      );
    },
    [missionData.declinedByPilots]
  );

  const initializeList = useCallback(
    (pilots) => {
      return pilots.map((pilot) => {
        var p = Object.assign({}, pilot);
        p.isInvited = isInvited(pilot);
        p.hasDeclined = hasDeclined(pilot);
        return p;
      });
    },
    [hasDeclined, isInvited]
  );

  const [pilotInviteList, setPilotInviteList] = React.useState([]);

  // const invitePilotHandler = (pilotID) => {
  //   invitePilotToMission(firebase, pilotID, missionData.id).then(() => {
  //     const updatedPilotInviteList = pilotInviteList.map((pilot) =>
  //       pilot.id === pilotID ? { ...pilot, isInvited: true } : pilot
  //     );
  //     createNotification(
  //       firebase,
  //       pilotID,
  //       `You are invited to a mission named ${missionData.missionName}.`,
  //       "You are invited to a mission.",
  //       `/app/missions/${missionData.id}`
  //     );
  //     setPilotInviteList(updatedPilotInviteList);
  //   });
  // };

  const invitePilotHandler = (pilotID) => {
    invitePilotToMission(firebase, pilotID, missionData).then(() => {
      const updatedPilotInviteList = pilotInviteList.map((pilot) =>
        pilot.id === pilotID ? { ...pilot, isInvited: true } : pilot
      );
      createNotification(
        firebase,
        pilotID,
        `You are invited to a mission named ${missionData.missionName}.`,
        "You are invited to a mission.",
        `/app/missions/${missionData.id}`
      );
      setPilotInviteList(updatedPilotInviteList);
    });
  };

  const uninvitePilotHandler = (pilotID) => {
    uninvitePilotToMission(firebase, pilotID, missionData).then(() => {
      const updatedPilotInviteList = pilotInviteList.map((pilot) =>
        pilot.id === pilotID ? { ...pilot, isInvited: false } : pilot
      );
      setPilotInviteList(updatedPilotInviteList);
    });
  };

  React.useEffect(() => {
    const list = initializeList(pilots);
    setPilotInviteList(list);
  }, [initializeList, pilots]);

  if (loading) return <SectionLoadingIndicator />;

  return (
    <Box
      sx={{ ...styles.root, ...className }}
      style={loading ? styles.loading : {}}
    >
      <Box component="ul" sx={styles.labels}>
        <Box
          component="li"
          sx={styles.sortable}
          onClick={() => sortByHandler("name")}
        >
          Name
          <FaSort style={styles.sortIcon} />
        </Box>
        <Box component="li">Email</Box>
        <Box component="li">Available Locations</Box>
        <Box component="li">Sensors / Cameras</Box>
        <Box component="li">Mapping / Processing</Box>
        <Box></Box>
      </Box>
      <Box>
        {pilotInviteList.length > 0 ? (
          pilotInviteList.map((pilot) => (
            <>
              <PilotInviteListItem
                key={pilot.id}
                pilot={pilot}
                invitePilotHandler={invitePilotHandler}
                uninvitePilotHandler={(id) => uninvitePilotHandler(id)}
                isInvited={pilot.isInvited}
                hasDeclined={pilot.hasDeclined}
              />
            </>
          ))
        ) : (
          <Box sx={styles.noPilots}>No pilots found</Box>
        )}
        {pilotInviteList.length > 0 ? (
          <TablePagination
            currentPage={currentPage}
            lastPage={lastPage}
            onClickNext={() => paginationBtnClick("next")}
            onClickPrevious={() => paginationBtnClick("prev")}
            limit={limit}
            limitOnChange={(e) => limitHandler(e.target.value)}
          />
        ) : (
          <div />
        )}
      </Box>
    </Box>
  );
};

PilotInviteList.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  pilots: PropTypes.array,
  missionData: PropTypes.object.isRequired,
  sortByHandler: PropTypes.func.isRequired,
  limitHandler: PropTypes.func.isRequired,
  limit: PropTypes.number,
  paginationBtnClick: PropTypes.func,
  currentPage: PropTypes.number,
  lastPage: PropTypes.bool,
};

export default PilotInviteList;
