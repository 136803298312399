import React from "react";
import { Box, Divider, Grid } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../../../theme";
import { EUCountriesWithCommonVATrule } from "../../../../lib/constants";
import LabelValuePair from "../../../global/elements/LabelValuePair";

const styles = {
  divider: {
    borderColor: theme.palette.grey.lightWarmGrey,
    margin: "10px 0px",
  },
};

const ShowCompanyInfoDetails = ({ values = {} }) => {
  return (
    <Box>
      <Grid container rowSpacing={2} columnSpacing={0.5}>
        <LabelValuePair label={"Address"} value={values?.companyAddress} />
        <LabelValuePair label={"Industry"} value={values?.industry} />
        <LabelValuePair label={"Postal Code"} value={values?.postalCode} />
        <LabelValuePair label={"City"} value={values?.city} />
        <LabelValuePair label={"Email"} value={values?.email} />
        <LabelValuePair label={"Country"} value={values?.location} />
        <LabelValuePair label={"Currency"} value={values?.currency} />
        <LabelValuePair label={"Phone number"} value={values?.phone} />

        {/* <Grid item container xs={12} justifyContent="flex-end">
          <LabelValuePair
            label={"Phone number"}
            value={values?.phone}
            alignRight={true}
          />
        </Grid> */}
      </Grid>
      <Divider variant="fullWidth" sx={styles.divider} />
      <Grid container rowSpacing={2} columnSpacing={1}>
        <LabelValuePair
          label={"Registration number"}
          value={values?.registrationNumber}
        />
        {EUCountriesWithCommonVATrule?.includes(values?.location) && (
          <LabelValuePair label={"VAT number"} value={values?.vatNumber} />
        )}
      </Grid>
    </Box>
  );
};

ShowCompanyInfoDetails.propTypes = {
  values: PropTypes.object,
};

// ShowCompanyInfoDetails.defaultProps = {
//   values: {},
// };

export default ShowCompanyInfoDetails;
