import { Grid, Typography } from "@mui/material";
import React from "react";
import theme from "../../../theme";
import AppButton from "./AppButton";
import PropTypes from "prop-types";

const styles = {
  containerStyle: {
    background: theme.palette.black.paleSilver,
    padding: "20px",
  },
  button: {
    textAlign: "right",
    marginTop: { xs: "10px", md: 0 },
  },
};

const ConfirmationBox = ({
  text = undefined,
  label = undefined,
  look = undefined,
  clickHandler = undefined,
  submittingState = undefined,
}) => {
  return (
    <>
      <Grid container sx={styles.containerStyle}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1">{text}</Typography>
        </Grid>
        <Grid item xs={12} md={4} />
        <Grid item xs={12} md={2} sx={styles.button}>
          <AppButton
            label={label}
            look={look}
            onClick={clickHandler}
            submittingState={submittingState}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

ConfirmationBox.propTypes = {
  text: PropTypes.string,
  label: PropTypes.string,
  look: PropTypes.string,
  clickHandler: PropTypes.func.isRequired,
  submittingState: PropTypes.bool.isRequired,
};
// ConfirmationBox.defaultProps = {
//   text: undefined,
//   label: undefined,
//   look: undefined,
// };

export default ConfirmationBox;
