import { Box, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import firebase from "src/firebase";
import {
  assignPilotToProjectMission,
  createProjectContract,
  removeSingleMissionFromProject,
} from "../../../../../services/project";
import GlobalTable from "../../../admin/GlobalTable";
import CancelIcon from "@mui/icons-material/Cancel";
import AppButton from "../../../../global/elements/AppButton";
import Tips from "../../../../global/elements/Tips";
import useGetMissionListForProject from "../../../../../hooks/useGetMissionListForProject";
import { useSnackbar } from "notistack";
import {
  draftMissionForPilotColumn,
  globheEmail,
  missionForPilotColumn,
  projectStatus,
  userRoles,
} from "../../../../../lib/constants";
import { createNotification } from "../../../../../services/notification";
import AssignPilotToMissionModal from "../../Components/AssignPilotToMissionModal";
import PropTypes from "prop-types";
import { useStateIfMounted } from "use-state-if-mounted";
import UniversalLoader from "../../../../global/elements/UniversalLoader";
import CreateMissionForProjectModal from "../../Components/CreateMissionForProjectModal";
import { getUser } from "../../../../../services/auth";

const styles = {
  cross: {
    color: "red",
    fontSize: "28px",
  },
  assignBtn: { padding: "8px 10px" },
  createMissionBtn: { float: "right", margin: "30px 0px" },
  btnFlow: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
    flexWrap: "wrap",
  },
  mTop: { marginTop: "7%" },
};

const ProjectMissionEditTab = ({
  projectData = undefined,
  onArchive = undefined,
  archived = false,
  reloadProject = false,
}) => {
  const [selected, setSelected] = useStateIfMounted([]);
  const [showDialogue, setShowDialogue] = useStateIfMounted(false);
  const [showCreateMissionDialogue, setShowCreateMissionDialogue] =
    useStateIfMounted(false);
  const { enqueueSnackbar } = useSnackbar();
  const user = getUser("user");
  const {
    missions,
    setAllMission,
    loading,
    limit,
    setLimit,
    currentPage,
    setCurrentPage,
    isLastPage,
    setIsLastPage,
    lastPageNumber,
    setlastPageNumber,
    setLastLoadedPage,
    setLastLoadedMissions,
    triggerReload,
    setTriggerReload,
  } = useGetMissionListForProject(projectData.id);

  useEffect(() => {
    clearDataAndTriggerReload();
  }, [reloadProject]);

  const clearDataAndTriggerReload = () => {
    setLastLoadedMissions(null);
    setCurrentPage(1);
    setAllMission([]);
    setlastPageNumber(null);
    setLastLoadedPage(null);
    setIsLastPage(false);
    setTriggerReload(!triggerReload);
  };

  const handleRemoveMission = async (id) => {
    removeSingleMissionFromProject(firebase, projectData.id, id)
      .then((status) => {
        if (status.status == "Success") {
          enqueueSnackbar("Mission removed successfully.", {
            variant: "success",
          });
          clearDataAndTriggerReload();
        } else {
          enqueueSnackbar(
            status && status.message
              ? status.message
              : "Error in updating the project",
            { variant: "error" }
          );
        }
      })
      .catch((e) => {
        enqueueSnackbar("Error in updating", {
          variant: "error",
        });
        console.log("error", e);
      });
  };

  const valueConvertHandler = (column, value) => {
    switch (column) {
      case "action":
        return (
          <Tooltip title="Delete Mission">
            <CancelIcon
              sx={styles.cross}
              onClick={() => handleRemoveMission(value.id)}
            />
          </Tooltip>
        );
      case "button":
        return (
          <>
            <AppButton
              label="Assign"
              look="green"
              small
              noIcon
              addtionalStyle={styles.assignBtn}
              onClick={() => onAssignHandler(value)}
            />
          </>
        );
      default:
        return;
    }
  };

  const onAssignHandler = async (value) => {
    try {
      const pilotId = value.id;
      const pilotName = value.name;
      selected.forEach(async (mission) => {
        await assignPilotToProjectMission(
          firebase,
          projectData,
          mission,
          pilotId,
          pilotName
        );
      });
      await createProjectContract({
        missions: selected,
        pilot: { id: pilotId, name: pilotName },
        project: projectData,
      });
      if (pilotId) {
        await createNotification(
          firebase,
          pilotId,
          "You have been assigned to a project",
          "You have been assigned to a project",
          `/app/pilots/project/${projectData.id}`
        );
      }

      // if (projectData.clientId) {
      //   await createNotification(
      //     firebase,
      //     projectData.clientId,
      //     "Pilot is assigned to your project.",
      //     "Pilot is assigned to some of your project's mission.",
      //     `/app/client/projects/${projectData.id}`
      //   );
      // }

      enqueueSnackbar(
        `${selected.length} mission has assigned to ${pilotName}`,
        {
          variant: "success",
        }
      );
      setShowDialogue(false);
      setSelected([]);
      clearDataAndTriggerReload();
    } catch (err) {
      console.log("Error", err);
      enqueueSnackbar(`Error in assigning mission to drone operators`, {
        variant: "success",
      });
    }
  };

  const paginationBtnClick2 = (type) => {
    if (type === "next") {
      if (!(lastPageNumber === currentPage)) {
        setCurrentPage(currentPage + 1);
        setTriggerReload(!triggerReload);
      }
    } else {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        setLastLoadedMissions(null);
        setTriggerReload(!triggerReload);
      }
    }
  };

  const limitHandler = (value) => {
    console.log(value);
    setLimit(value);
    clearDataAndTriggerReload();
  };

  const showDialog = () => {
    if (selected?.length > 0) {
      setShowDialogue(true);
    } else {
      enqueueSnackbar("Please select atleast one project", {
        variant: "error",
      });
    }
  };

  const handleSingleSelect = (event, row) => {
    if (event.target.checked) {
      let allSelectedTillNow = [...selected, row];
      let selectedData = allSelectedTillNow.filter(
        (missionData) => !missionData.assignedPilot
      );
      setSelected(selectedData);
    } else {
      const newSelected = selected?.filter((data) => data.id !== row.id);
      setSelected(newSelected);
    }
  };

  const onSelectAllClick = (event) => {
    if (event.target.checked) {
      let selectedData = missions.filter(
        (missionData) => !missionData.assignedPilot
      );
      setSelected(selectedData);
      return;
    }
    setSelected([]);
  };

  return (
    <>
      {(projectData?.status === projectStatus.draft ||
        projectData?.status === projectStatus.initial) && (
        <Tips content='You can add new sites to your project by clicking on the "upload sites" tab above.' />
      )}
      {!loading ? (
        <>
          {user.role === userRoles.admin &&
            projectData.status != projectStatus.archived && (
              <Box sx={styles.btnFlow}>
                <AppButton
                  label="Assign operators"
                  look="black"
                  onClick={showDialog}
                  isDisabled={
                    projectData.status !== projectStatus.active &&
                    projectData.clientEmail !== globheEmail
                  }
                />
              </Box>
            )}
          <Box sx={{ marginTop: "20px" }}>
            <GlobalTable
              columns={
                projectData?.status === projectStatus.draft ||
                projectData?.status === projectStatus.initial
                  ? draftMissionForPilotColumn
                  : missionForPilotColumn
              }
              allData={missions}
              valueConvertHandler={valueConvertHandler}
              checkbox={
                !(
                  projectData.status !== projectStatus.active &&
                  projectData.clientEmail !== globheEmail &&
                  user?.role === userRoles.admin
                )
              }
              goToPageFcn={() => {}}
              selected={selected}
              // setSelected={setSelected}
              handleSingleSelect={handleSingleSelect}
              onSelectAllClick={onSelectAllClick}
              limitHandler={limitHandler}
              limit={limit}
              paginationBtnClick={paginationBtnClick2}
              currentPage={currentPage}
              isLastPage={isLastPage}
              lastPage={lastPageNumber}
            />
          </Box>
        </>
      ) : (
        <Box sx={styles.mTop}>
          <UniversalLoader />
        </Box>
      )}
      {selected?.length > 0 && showDialogue && (
        <AssignPilotToMissionModal
          showDialogue={showDialogue}
          setShowDialogue={setShowDialogue}
          valueConvertHandler={valueConvertHandler}
        />
      )}

      {showCreateMissionDialogue && (
        <CreateMissionForProjectModal
          showDialogue={showCreateMissionDialogue}
          setShowDialogue={setShowCreateMissionDialogue}
          projectData={projectData}
          clearDataAndTriggerReload={clearDataAndTriggerReload}
        />
      )}
    </>
  );
};

ProjectMissionEditTab.propTypes = {
  projectData: PropTypes.object.isRequired,
  onArchive: PropTypes.func.isRequired,
  archived: PropTypes.bool,
  reloadProject: PropTypes.bool,
};

// ProjectMissionEditTab.defaultProps = {
//   archived: false,
//   reloadProject: false,
// };
export default ProjectMissionEditTab;
