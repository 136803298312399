import React, { useEffect, useState } from "react";
import Layout from "../../../Layout";
import PropTypes from "prop-types";
import PilotInvoiceTab from "../missions/PilotSingleMission/PilotInvoiceTab";
import { getUser } from "../../../services/auth";

const PilotSingleInvoice = ({ invoiceId = "" }) => {
  const [currentUser, setCurrentUser] = useState({});
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const user = getUser("user");
    setCurrentUser(user);
  }, []);

  const refreshMission = () => {
    setRefresh(!refresh);
  };

  return (
    <Layout pageTitle={"Invoice"} hasSideMenu addBottomPadding backButton>
      <PilotInvoiceTab
        invoiceId={invoiceId}
        currentUser={currentUser}
        refreshMission={refreshMission}
        showCancelButton={true}
        showHeading={false}
      />
    </Layout>
  );
};

PilotSingleInvoice.propTypes = {
  invoiceId: PropTypes.string.isRequired,
};

export default PilotSingleInvoice;
