import React from "react";
import { Grid, InputAdornment } from "@mui/material";
import { allCountries, dataLibCategory } from "../../../../../lib/constants";
import PropTypes from "prop-types";
import TextFieldInput from "../../../../global/FormInputComponents/TextFieldInput";
import SearchIcon from "@mui/icons-material/Search";
import AutoCompleteInput from "../../../../global/FormInputComponents/AutoCompleteInput";
import { autocompletStyle } from "../../../../../lib/styleConstants";

const SearchOptionsDataLib = ({
  setCountryFilter,
  setCategoryFilter,
  setMissionNameSearch,
  setFieldValue,
  values,
  triggerReload,
  setTriggerReload,
}) => {
  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12} md={6} lg={8} mt={2}>
        <TextFieldInput
          setFieldValue={setFieldValue}
          name={"missionName"}
          values={values}
          //   label="Search by name"
          type="text"
          required={true}
          placeholder="Search by name"
          variant={"standard"}
          customOnChangeHandlerFcn={(e) => {
            setFieldValue("missionName", e.target.value);
            setMissionNameSearch(e.target.value);
            setTriggerReload(!triggerReload);
          }}
          customOnChangeHandler={true}
          startAdornment={{
            startAdornment: (
              <InputAdornment position="start" style={{ outline: "none" }}>
                <SearchIcon fontSize="default"></SearchIcon>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={3} lg={2}>
        <AutoCompleteInput
          label="Choose a category"
          //   options={dataLibCategory}
          options={dataLibCategory?.map(({ label }) => label)}
          name="productTypes"
          placeholder={"Category"}
          additionalStyle={autocompletStyle}
          customOnChangeHandlerFcn={(value) => {
            setFieldValue("productTypes", value);
            setCategoryFilter(value);
            setTriggerReload(!triggerReload);
          }}
          customOnChangeHandler={true}
          multiple={true}
        />
      </Grid>
      <Grid item xs={12} md={3} lg={2}>
        <AutoCompleteInput
          label="Choose a country"
          options={allCountries}
          name="countries"
          placeholder={"Country"}
          additionalStyle={autocompletStyle}
          customOnChangeHandlerFcn={(value) => {
            setFieldValue("countries", value);
            setCountryFilter(value);
            setTriggerReload(!triggerReload);
          }}
          customOnChangeHandler={true}
          multiple={true}
        />
      </Grid>
    </Grid>
  );
};

SearchOptionsDataLib.propTypes = {
  setplaceholderVal: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setCountryFilter: PropTypes.func.isRequired,
  setCategoryFilter: PropTypes.func.isRequired,
  setMissionNameSearch: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  triggerReload: PropTypes.bool.isRequired,
  setTriggerReload: PropTypes.func.isRequired,
};

export default SearchOptionsDataLib;
