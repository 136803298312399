import PropTypes from "prop-types";
import React from "react";
import theme from "../../theme";
import { Box, Container, Typography } from "@mui/material";
import AppButton from "./elements/AppButton";
import BackIcon from "./elements/BackIcon";
import LogoIcon from "src/assets/logos/globhe_logo/Globhe_symbol_2022_black.png";
const styles = {
  centeredContent: {
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    display: "flex",
    position: "relative",
    top: "50%",
  },
  heroHeader: {
    display: "flex",
    justifyContent: "center",
    letterSpacing: "0px",
    marginTop: "1em",
  },
  button: {
    border: `4px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    background: theme.palette.grey.white,
    borderRadius: "32px",
  },
  message: {
    marginTop: "1em",
    color: theme.palette.black.darkSeaBlack,
  },
  subMessage: {
    marginTop: "20px",
    color: theme.palette.black.darkSeaBlack,
  },
  backButton: {
    marginTop: "5em",
  },
  logo: {
    width: "70px",
    height: "70px",
  },
};

const SuccessPage = ({
  pageTitle = undefined,
  message = undefined,
  subMessage = undefined,
  subMessage1 = undefined,
  buttonLink = undefined,
}) => {
  return (
    <Container sx={styles.contentWrapper}>
      <Box sx={styles.backButton}>
        <BackIcon />
      </Box>
      <Box sx={styles.centeredContent}>
        <Box sx={styles.logo}>
          {" "}
          <img src={LogoIcon} alt="Logo" style={styles.logo} />
        </Box>
        <Typography variant="h2" sx={styles.heroHeader}>
          {pageTitle}
        </Typography>
        <Typography variant="h6" sx={styles.message}>
          {message}
        </Typography>
        <Box sx={styles.subMessage}>
          <Typography variant="subtitle1">{subMessage}</Typography>
          <Typography variant="subtitle1">{subMessage1}</Typography>
        </Box>

        {buttonLink && (
          <Box sx={{ paddingTop: "30px", width: "100%", marginTop: "2em" }}>
            <AppButton
              url={buttonLink}
              internalLink
              look="black"
              label="START DRONIN’"
            />
          </Box>
        )}
      </Box>
    </Container>
  );
};

SuccessPage.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  subMessage: PropTypes.string.isRequired,
  buttonLink: PropTypes.string,
};
// SuccessPage.defaultProps = {
//   badge: undefined,
//   buttonLink: undefined,
// };

export default SuccessPage;
