import { navigate } from "gatsby";
import firebase from "src/firebase";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ErrorMessage from "src/components/global/errorMessage";
import useGetPilot from "src/hooks/useGetPilot";
import { deletePilot } from "src/services/pilots";
import Layout from "../../../Layout";
import {
  mapFormDataToAccount,
  mapFormDataToPilot,
} from "../../../lib/form-helpers";
import { getUser } from "../../../services/auth";
import { updatePilot } from "../../../services/pilots";
import PilotForm from "../pilot/PilotForm";
import UniversalLoader from "../../global/elements/UniversalLoader";
import { Box } from "@mui/material";
import StatusAlertContainer from "../../global/elements/StatusAlertContainer";
import { statusAlertSeverity } from "../../../lib/constants";
import { unauthorizedStatusAlert } from "../../../lib/styleConstants";

const style = {
  loaderDiv: {
    paddingTop: "18%",
  },
};

const EditPilot = ({ id = null }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [pilot, loading, authentication] = useGetPilot(id);
  const [deletingPilot, setDeletingPilot] = useState(false);
  const [hasError, showErrorMessage] = useState(false);
  const [errorMessageLabel, setErrorMessageLabel] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const currentUser = getUser("user");

  const isCurrentUser = currentUser?.userID === pilot?.id;

  const submitHandler = (values, setSubmitting, cb) => {
    const data = mapFormDataToPilot(values);
    const accountData = mapFormDataToAccount(values);

    if (!isCurrentUser) {
      if (!window.confirm("Are you sure you want to update this user?")) {
        return;
      }
    }

    const newEmail =
      accountData.email === pilot.email ? null : accountData.email;
    const newPassword = accountData.password;
    updatePilot(firebase, pilot.id, data, newEmail, newPassword)
      .then(() => {
        enqueueSnackbar("Information updated", { variant: "success" });
        cb(null, { success: true });
        navigate(`/app/pilots/${id}`);
        // return;
      })
      .catch((err) => {
        setSubmitting(false);
        errorHandler(err);
        cb({ error: err });
        return;
      });
  };

  const errorHandler = (error) => {
    if (error.code === "auth/weak-password") {
      showErrorMessage(true);
      setErrorMessageLabel("Weak password");
      setErrorMessage("Password should be at least 6 characters.");
    }

    if (error.code === "auth/email-already-in-use") {
      showErrorMessage(true);
      setErrorMessageLabel("Email already in use");
      setErrorMessage(
        "The email address is already in use by another account."
      );
    }

    if (error.code === "auth/requires-recent-login") {
      showErrorMessage(true);
      setErrorMessageLabel("Requires recent login");
      setErrorMessage(
        "Please sign out and sign in again in order to update your email/password"
      );
    }
  };

  const onDeletePilotClick = () => {
    if (
      !window.confirm(
        "Are you sure you want to delete this user? This action is irriversible."
      )
    ) {
      return;
    }
    setDeletingPilot(true);
    deletePilot(firebase, id)
      .then(() => {
        navigate("/app/pilots");
      })
      .catch((err) => console.log(err))
      .finally(() => setDeletingPilot(false));
  };

  const closeTriggered = () => {
    // Remove ErrorMessage component; Delay the removal because of the fade-out animation
    setTimeout(function () {
      showErrorMessage(false);
    }, 300);
  };

  return (
    <Layout
      addBottomPadding
      pageTitle="Edit Drone Operator"
      hasSideMenu
      loading={loading}
    >
      {authentication ? (
        <>
          <PilotForm
            pilot={pilot}
            errorHandler={errorHandler}
            submitHandler={submitHandler}
            isCurrentUser={isCurrentUser}
            isAdmin={currentUser?.role === "admin"}
            deletePilot={onDeletePilotClick}
            deletePilotLoading={deletingPilot}
          />
        </>
      ) : authentication == false ? (
        <Box sx={unauthorizedStatusAlert}>
          <StatusAlertContainer
            heading="Unauthorized access! Your admin type does not allow you to edit pilots. 
            Please contact Napoleon to change your access rights"
            severity={statusAlertSeverity.error}
            coloredBackground
          />
        </Box>
      ) : (
        <Box sx={style.loaderDiv}>
          <UniversalLoader />
        </Box>
      )}
      {hasError ? (
        <ErrorMessage
          show={true}
          errorMessageLabel={errorMessageLabel}
          errorMessage={errorMessage}
          closeTriggered={closeTriggered}
        />
      ) : null}
    </Layout>
  );
};

EditPilot.propTypes = {
  id: PropTypes.string,
};

export default EditPilot;
