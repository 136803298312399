import LocationOnIcon from "@mui/icons-material/LocationOn";
import PropTypes from "prop-types";
import React from "react";
import { Box, Tooltip, IconButton } from "@mui/material";

const styles = {
  root: {},
  iconButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "&:hover": {
      zIndex: 1,
    },
  },
};

const Marker = ({ text = undefined, onClick = null }) => {
  return (
    <Box sx={styles.root}>
      <Tooltip title={text} sx={styles.toolTip}>
        <IconButton
          aria-label="delete"
          color="secondary"
          onClick={onClick}
          sx={styles.iconButton}
        >
          <LocationOnIcon fontSize="large" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

// Marker.defaultProps = {
//   onClick: null,
// };

Marker.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default Marker;
