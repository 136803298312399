import { Box, AppBar } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import firebase from "src/firebase";
import theme from "../../theme";
import {
  DeliveryPackageStatus,
  missionStatus,
  packageTypeConstant,
} from "../../lib/constants";
import MissionBackNav from "../app/missions/MissionBackNav";
import OrderNotFound from "../app/Client/Order/OrderNotFound";
import DeliveryPackagesTab from "../app/missions/FilesPackages/DeliveryPackagesTab";

const styles = {
  topNav: {
    padding: "3rem 2rem 4rem 2rem",
    display: "flex",
    justifyContent: { xs: "left", md: "space-between" },
    flexFlow: { sm: "row", xs: "wrap" },
    background: theme.palette.grey.white,
    alignItems: "flex-end",
  },
  mainContent: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "auto",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
  },
  sectionContainer: {
    background: theme.palette.grey.white,
    paddingLeft: { xs: 0, md: "2rem" },
  },
  missioNDetails: {
    width: "100%",
    background: `linear-gradient(180deg, ${theme.palette.secondary.seaGreen}19 0%, rgba(255,255,255,1) 30px)`,
    padding: "30px",
  },
};

const MissionDownloadPublic = ({
  missionID = undefined,
  accessToken = undefined,
  missionName = null,
  mapTypes = "",
}) => {
  const [mission, setMission] = useState(null);
  const [mediaPackage, setMediaPackage] = useState({});
  const [loadingMission, setLoadingMission] = useState(false);
  const [showError, setShowError] = useState(null);

  useEffect(() => {
    if (!firebase) {
      return;
    }
    try {
      const decodeMissionName = missionName
        ? decodeURI(window.atob(missionName))
        : "unnamed mission";

      let decodeMapTypes = mapTypes ? decodeURI(window.atob(mapTypes)) : "";
      decodeMapTypes = decodeMapTypes.trim() ? decodeMapTypes?.split(",") : [];

      /**This is a public route we can't access mission due to which I am passing
       * the only needed parameter in this missionObj / media package
       */
      let missionObj = {
        missionName: decodeMissionName,
        id: missionID,
        status: missionStatus.completed,
        mapTypes: decodeMapTypes || [],
      };
      setMission(missionObj);
      setMediaPackage({
        id: missionID,
        status: DeliveryPackageStatus.delivered,
      });
      // getFiles();
    } catch (e) {
      console.log("Error", e);
    }
  }, []);

  return (
    <>
      {showError ? (
        <>
          <OrderNotFound
            title={showError}
            description={"It might have been removed"}
          />
        </>
      ) : !loadingMission && mission ? (
        <>
          <Box sx={styles.topNav}>
            <MissionBackNav missionData={mission} showBackButton={false} />
          </Box>

          <Box sx={styles.mainContent}>
            <Box sx={styles.sectionContainer}>
              <AppBar
                position="static"
                sx={styles.appBar}
                elevation={0}
              ></AppBar>
            </Box>
            <Box sx={{ ...styles.missioNDetails }}>
              {/* <DropzoneGallery
                key="1"
                mission={mission}
                isEditable={false}
                isDeliveryPackage={true}
                mediaPackage={mediaPackage}
                showFilesPassedFromParent={true}
                parentFiles={deliveryFiles}
              /> */}
              <DeliveryPackagesTab
                packageId={mediaPackage?.id}
                mission={mission}
                packageType={packageTypeConstant.deliveryPackage}
                mediaPackage={mediaPackage}
                isEditable={false}
                accessToken={accessToken}
              />
            </Box>
          </Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

MissionDownloadPublic.propTypes = {
  missionID: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired,
  missionName: PropTypes.string,
  mapTypes: PropTypes.string,
};

// MissionDownloadPublic.defaultProps = {
//   missionName: null,
//   mapTypes: "",
// };

export default MissionDownloadPublic;
