import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import theme from "../../theme";
import AppButton from "./elements/AppButton";
import PropTypes from "prop-types";
import ExploreDataImg from "../../assets/images/exploreData.png";
import { boxShadowStyle } from "../../lib/styleConstants";

const styles = {
  boxLayoutStyles: {
    height: { xs: "183px", md: "172px", lg: "162px" },
    position: "relative",
  },
  boxPadding: { padding: "4%" },
  btnStyle: {
    height: "39px",
  },
  marginStyle: {
    marginLeft: { xs: "20px", md: "8px" },
    marginBottom: { xs: "15px", md: "6px" },
  },
  paddingTStyle: {
    position: "absolute",
    bottom: { xs: "15px", md: "6px" },
    left: { xs: "20px", md: "8px" },
  },
};

const ButtonCards = ({
  heading = undefined,
  subText = undefined,
  btnLabel = undefined,
  link = undefined,
  exploreProductFlag = false,
  disabled = false,
}) => {
  return (
    <>
      <Grid
        container
        sx={{
          ...styles.boxLayoutStyles,
          ...boxShadowStyle,
          background: exploreProductFlag
            ? `url(${ExploreDataImg})`
            : theme.palette.grey.white,
        }}
      >
        <Box sx={styles.boxPadding}>
          <Grid item sx={styles.marginStyle} xs={12} sm={12} md={12} lg={12}>
            <Typography
              variant="h5"
              sx={{
                color: exploreProductFlag
                  ? theme.palette.grey.white
                  : theme.palette.black.darkSeaBlack,
              }}
            >
              {heading}
            </Typography>
          </Grid>
          <Grid item sx={styles.marginStyle} xs={12} sm={12} md={12} lg={12}>
            <Typography
              variant="body2"
              sx={{
                color: exploreProductFlag
                  ? theme.palette.grey.white
                  : theme.palette.black.darkSeaBlack,
              }}
            >
              {subText}
            </Typography>
          </Grid>
          <Grid
            item
            sx={{ ...styles.paddingTStyle, ...styles.marginStyle }}
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <AppButton
              label={btnLabel}
              url={link}
              internalLink={exploreProductFlag ? false : true}
              externalLink={exploreProductFlag ? true : false}
              targetNewWidow={exploreProductFlag ? true : false}
              look={
                disabled
                  ? "blackDisabled"
                  : exploreProductFlag
                  ? "ghostWhite"
                  : "black"
              }
              addtionalStyle={styles.btnStyle}
              isDisabled={disabled}
            />
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

ButtonCards.propTypes = {
  heading: PropTypes.string.isRequired,
  btnLabel: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  exploreProductFlag: PropTypes.bool,
  disabled: PropTypes.bool,
};

// ButtonCards.defaultProps = {
//   exploreProductFlag: false,
//   disabled: false,
// };

export default ButtonCards;
