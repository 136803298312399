import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import TextStructures from "../../../global/elements/TypographyElements/TextStructures";
import { orderBox } from "../../../../lib/styleConstants";
import AssignClient from "./AssignClient";
import TextWithTooltip from "../../../global/elements/TextWithTooltip";
import SwitchSelect from "../../../global/elements/SwitchSelect";
import { paymentStatus } from "../../../../lib/constants";

const ClientDetail = ({
  values = {},
  setFieldValue = undefined,
  formType = undefined,
  userType = undefined,
  clientPayment = true,
}) => {
  return (
    <Box sx={orderBox}>
      <Grid container rowSpacing={2} columnSpacing={3} mt={1}>
        <Grid item xs={12}>
          <TextStructures text="Client details" number={1} />
        </Grid>
        <AssignClient
          formType={formType}
          setFieldValue={setFieldValue}
          currentUser={userType}
          values={values}
          //setClientInfo={setClientInfo}
        />

        {/** SHOW CLIENT PAYMENT IN MISSIONS ONLY */}
        {clientPayment && (
          <>
            <Grid item xs={12}>
              <TextStructures text="Client payments" />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                When clients pay their invoice, check this switch to mark that
                it is paid.
              </Typography>
            </Grid>

            {values.breakInvoice && (
              <Grid item xs={12} md={6}>
                <TextWithTooltip
                  text="Client pre delivery payment?"
                  tooltipText="Select yes if the client has paid the pre delivery invoice."
                />
                <SwitchSelect
                  name="preDeliverypaymentStatusClient"
                  handleChange={(e, value) =>
                    setFieldValue(
                      "preDeliverypaymentStatusClient",
                      value ? paymentStatus.paid : paymentStatus.unpaid
                    )
                  }
                  checked={
                    values.preDeliverypaymentStatusClient === paymentStatus.paid
                  }
                />
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <TextWithTooltip
                text={
                  values.breakInvoice
                    ? "Client post delivery payment?"
                    : "Client payment up-to-date?"
                }
                tooltipText="Select yes if the client has paid the invoice."
              />

              <SwitchSelect
                name="paymentStatusClient"
                handleChange={(e, value) =>
                  setFieldValue(
                    "paymentStatusClient",
                    value ? paymentStatus.paid : paymentStatus.unpaid
                  )
                }
                checked={values.paymentStatusClient === paymentStatus.paid}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

ClientDetail.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object,
  formType: PropTypes.string,
  userType: PropTypes.string,
  clientPayment: PropTypes.bool,
};

// ClientDetail.defaultProps = {
//   values: {},
//   missionData: undefined,
//   formType: undefined,
//   userType: undefined,
//   clientPayment: true,
// };

export default ClientDetail;
