import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Layout from "../../../Layout.js";
import PilotInvoiceTab from "../missions/PilotSingleMission/PilotInvoiceTab.js";
import { getUser } from "../../../services/auth.js";

const SingleInvoiceAdmin = ({ invoiceId = undefined }) => {
  const [currentUser, setCurrentUser] = useState({});
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const user = getUser("user");
    setCurrentUser(user);
  }, []);

  const refreshMission = () => {
    setRefresh(!refresh);
  };

  return (
    <Layout pageTitle={"Invoice"} hasSideMenu addBottomPadding>
      <PilotInvoiceTab
        invoiceId={invoiceId}
        currentUser={currentUser}
        refreshMission={refreshMission}
        showCancelButton={true}
        showHeading={false}
      />
    </Layout>
  );
};

SingleInvoiceAdmin.propTypes = {
  invoiceId: PropTypes.string,
};

// SingleInvoiceAdmin.defaultProps = {
//   invoiceId: undefined,
// };

export default SingleInvoiceAdmin;
