import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { Link } from "gatsby";
import theme from "../../../theme";

const styles = {
  linkDecoration: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    padding: "1px 2px",
    // color: theme.palette.black.darkSeaBlack,
    "&:hover": {
      color: theme.palette.black.darkSeaBlack,
    },
  },
};

const LabelValuePair = ({
  label = undefined,
  value = "--",
  newDesign = false,
  link = false,
}) => {
  return (
    <Grid item xs={12} md={6}>
      <Grid container>
        <Grid item xs={!newDesign && 4} mr={newDesign && 1}>
          <Typography variant="subtitle1Med">
            {label}
            {newDesign && " : "}
          </Typography>
        </Grid>
        <Grid item xs={!newDesign && 8}>
          {link ? (
            <Link
              to={value}
              target={"_blank"}
              style={{ textDecoration: "none" }}
            >
              <Typography sx={styles.linkDecoration} variant="boby1">
                {value}
              </Typography>
            </Link>
          ) : (
            <Typography variant="subtitle1">{value}</Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

LabelValuePair.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  newDesign: PropTypes.bool,
  link: PropTypes.bool,
};

// LabelValuePair.defaultProps = {
//   value: "--",
//   newDesign: false,
//   link: false,
// };

export default LabelValuePair;
