import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import TextFieldInput from "../../../global/FormInputComponents/TextFieldInput";
import SingleMultipleCheckbox from "../../../global/FormInputComponents/SingleMultipleCheckbox";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField as MuiTextField } from "@mui/material";
import {
  intervalEndOption,
  recurringIntervalsArray,
  formTypeConstant,
  orderCollectionData,
} from "../../../../lib/constants";
import SwitchSelect from "../../../global/elements/SwitchSelect";
import PropTypes from "prop-types";
import WeekDaySelect from "../../../global/FormInputComponents/WeekDaySelect";
import TextStructures from "../../../global/elements/TypographyElements/TextStructures";
import theme from "../../../../theme";
import StatusAlertContainer from "../../../global/elements/StatusAlertContainer";
import { formInputField } from "../../../../lib/styleConstants";

const styles = {
  switchValue: { display: "flex" },
  errorMsg: {
    color: theme.palette.status.errorDark,
  },
  gridText: { alignContent: "center" },
};

const singleLocationText =
  "It is not possible to set up a recurring orders for already created orders. This is due to the specific setup of the recurring order system. If you want to create a recurring order, you need to place a new order.";
const recurrentText =
  "It is not possible to edit recurrent orders. This is due to the specific setup of the recurring order system. If you need to make changes, you can create a new order with the desired updates";

const RepeatOrderDetails = ({
  setFieldValue = undefined,
  values = {},
  formType = undefined,
  touched = {},
  errors = {},
  setOrderCollection = undefined,
  orderCollection = undefined,
}) => {
  var date = new Date();
  var startDateMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  var endDateMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  var startDateYear = new Date(date.getFullYear(), 0, 1);
  var endDateYear = new Date(date.getFullYear() + 1, 0, 0);

  return (
    <Box mt={3}>
      <TextStructures text="Do you want to repeat this order?" />
      <Typography variant="body1" mt={2} mb={2}>
        If you&apos;d like to have orders automatically created at regular
        intervals from the same location, simply select &quot;Yes.&quot; By
        choosing this option, we will generate recurrent orders using the
        specifications you provided, all from the designated location.
      </Typography>
      {formType === formTypeConstant.edit && (
        <StatusAlertContainer
          heading={values.recurrence ? recurrentText : singleLocationText}
          severity="warning"
          showClose
        />
      )}

      <Grid container mt={1} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1Med">Recurrence</Typography>
          <Box sx={styles.switchValue}>
            <SwitchSelect
              checked={values.recurrence}
              handleChange={(e) => {
                setFieldValue("recurrence", e.target.checked);
                if (e.target.checked) {
                  setOrderCollection("projects");
                } else {
                  setOrderCollection("missions");
                  setFieldValue("everyCount", 1);
                  setFieldValue("recursiveInterval", null);
                  setFieldValue("startDateForMission", null);
                  setFieldValue("intervalEndDate", null);
                  setFieldValue("intervalEndCount", 0);
                }
              }}
              name="recurrence"
              disabled={formType === formTypeConstant.edit}
            />
          </Box>
        </Grid>
        {values.recurrence &&
          (formType == formTypeConstant.create ||
            (formType == formTypeConstant.edit &&
              orderCollection == orderCollectionData.projects)) && (
            <>
              <Grid item xs={12}>
                <Grid container mt={2} spacing={2} columns={10}>
                  <Grid item xs={10} md={1} sx={styles.gridText}>
                    <Typography variant="subtitle1Med">Repeat every</Typography>
                  </Grid>
                  <Grid item xs={4} md={1}>
                    <TextFieldInput
                      setFieldValue={setFieldValue}
                      values={values}
                      //label={"Enter a number"}
                      name={"everyCount"}
                      type="number"
                      disabled={formType === formTypeConstant.edit}
                      required={values?.recurrence ? true : false}
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <TextFieldInput
                      setFieldValue={setFieldValue}
                      values={values}
                      label={"Interval"}
                      name={"recursiveInterval"}
                      type="text"
                      autoComplete={
                        formType === formTypeConstant.edit ? false : true
                      }
                      disabled={formType === formTypeConstant.edit}
                      autoCompleteArray={recurringIntervalsArray}
                      required={values?.recurrence ? true : false}
                      multiple={false}
                      customOnChangeHandler={true}
                      customOnChangeHandlerFcn={(e, value) => {
                        setFieldValue("recursiveInterval", value);
                        setFieldValue("startDateForMission", null);
                      }}
                    />
                  </Grid>
                  <Grid item xs={10} md={6} />
                  <Grid item xs={10} md={1} />
                  <Grid item xs={10} md={9}>
                    <Typography variant="body2">
                      For example, you can specify that you want the same order
                      repeated every week, second week, or every month.
                    </Typography>
                  </Grid>
                  {values?.recursiveInterval &&
                    values?.recursiveInterval !== "day" && (
                      <>
                        <Grid item xs={10} md={1} sx={styles.gridText} mt={2}>
                          <Typography variant="subtitle1Med">
                            Repeat on
                          </Typography>
                        </Grid>
                        <Grid item xs={10} md={3} mt={2}>
                          {values?.recursiveInterval === "month" ? (
                            <DatePicker
                              inputFormat="dd"
                              views={["day"]}
                              fullWidth
                              // disablePast
                              minDate={startDateMonth}
                              maxDate={endDateMonth}
                              disabled={formType === formTypeConstant.edit}
                              onChange={(value) => {
                                setFieldValue("startDateForMission", value);
                              }}
                              value={values.startDateForMission}
                              renderInput={(params) => (
                                <MuiTextField
                                  {...params}
                                  fullWidth
                                  variant="outlined"
                                  name="startDateForMission"
                                  label="Repeat on"
                                  error={Boolean(
                                    touched.startDateForMission &&
                                      errors.startDateForMission
                                  )}
                                  helperText={
                                    touched.startDateForMission &&
                                    errors.startDateForMission
                                  }
                                />
                              )}
                            />
                          ) : values?.recursiveInterval === "week" ? (
                            <>
                              <WeekDaySelect
                                fieldName={"startDateForMission"}
                                values={values}
                                setFieldValue={setFieldValue}
                                edit={
                                  formType == formTypeConstant.edit
                                    ? false
                                    : true
                                }
                              />
                              {errors.startDateForMission &&
                              touched.startDateForMission ? (
                                <Typography
                                  variant="body3"
                                  sx={styles.errorMsg}
                                >
                                  {errors.startDateForMission}
                                </Typography>
                              ) : null}
                            </>
                          ) : (
                            values.recursiveInterval === "year" && (
                              <DatePicker
                                inputFormat="dd-MM"
                                fullWidth
                                minDate={startDateYear}
                                maxDate={endDateYear}
                                disabled={formType === formTypeConstant.edit}
                                onChange={(value) => {
                                  setFieldValue("startDateForMission", value);
                                }}
                                value={values.startDateForMission}
                                renderInput={(params) => (
                                  <MuiTextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    name="startDateForMission"
                                    label="Repeat on"
                                    error={Boolean(
                                      touched.startDateForMission &&
                                        errors.startDateForMission
                                    )}
                                    helperText={
                                      touched.startDateForMission &&
                                      errors.startDateForMission
                                    }
                                  />
                                )}
                              />
                            )
                          )}
                        </Grid>
                        <Grid item xs={10} md={6} />
                        <Grid item xs={10} md={1} />
                        <Grid item xs={10} md={9}>
                          <Typography variant="body2">
                            {values?.recursiveInterval === "week"
                              ? "You can specify on which day of the week you want the data to be captured."
                              : values?.recursiveInterval === "month"
                              ? "You can specify on which day of the month you want the data to be captured."
                              : values.recursiveInterval === "year"
                              ? "You can specify on which date of the year you want the data to be captured."
                              : ""}
                          </Typography>
                        </Grid>
                      </>
                    )}
                </Grid>
              </Grid>

              <Grid item xs={12} mt={2}>
                <Typography variant="subtitle1Med">Ends</Typography>
              </Grid>
              <Grid item xs={2} sm={1.5} md={1.2}>
                <SingleMultipleCheckbox
                  checkboxFields={intervalEndOption}
                  values={values}
                  multiple={false}
                  setFieldValue={setFieldValue}
                  errors={
                    !values.intervalEndCount ||
                    !values.intervalEndDate ||
                    (!values.intervalEndOn && !values.intervalEndAfter)
                      ? errors
                      : {}
                  }
                  disabled={formType === formTypeConstant.edit}
                  vertical={true}
                  errorFieldName="selectBarErrorMain"
                  circluar
                />
              </Grid>
              <Grid item xs={8} sm={6} md={3} mt={1}>
                {/* {values.intervalEndOn && ( */}
                <DatePicker
                  inputFormat="dd-MM-yyyy"
                  fullWidth
                  disablePast
                  disabled={
                    !values.intervalEndOn || formType === formTypeConstant.edit
                  }
                  onChange={(value) => {
                    setFieldValue("intervalEndDate", value);
                    setFieldValue("intervalEndCount", 0);
                  }}
                  value={values.intervalEndDate}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      name="intervalEndDate"
                      label="Recurrence end date"
                      error={Boolean(
                        touched.intervalEndDate && errors.intervalEndDate
                      )}
                      helperText={
                        touched.intervalEndDate && errors.intervalEndDate
                      }
                      sx={formInputField}
                    />
                  )}
                />
                <br />
                <br />
                {/* )} */}

                {/* {values.intervalEndAfter && ( */}
                <TextFieldInput
                  setFieldValue={setFieldValue}
                  values={values}
                  label={"Occurrences"}
                  name={"intervalEndCount"}
                  type="number"
                  disabled={
                    !values.intervalEndAfter ||
                    formType === formTypeConstant.edit
                  }
                  customOnChangeHandler={true}
                  customOnChangeHandlerFcn={(e) => {
                    setFieldValue("intervalEndCount", e.target.value);
                    setFieldValue("intervalEndDate", null);
                  }}
                />
                {/* )} */}
              </Grid>
            </>
          )}
      </Grid>
    </Box>
  );
};

RepeatOrderDetails.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object,
  formType: PropTypes.string.isRequired,
  touched: PropTypes.object,
  errors: PropTypes.object,
  setOrderCollection: PropTypes.func.isRequired,
  orderCollection: PropTypes.string,
};

// RepeatOrderDetails.defaultProps = {
//   touched: {},
//   values: {},
//   errors: {},
//   orderCollection: undefined,
// };

export default RepeatOrderDetails;
