import React, { useEffect, useContext } from "react";
import { AppBar, Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useStateIfMounted } from "use-state-if-mounted";
import { useGetDeliveryPackages } from "../../../../hooks/useGetDeliveryPackages";
import { getUser } from "../../../../services/auth";
import firebase from "src/firebase";
import {
  clientName,
  userRoles,
  users,
  missionTypeConstant,
  packageTypeConstant,
  missionStatus,
  defaultCurrency,
  quoteStatus,
  missionPlanningStates,
  PilotPackageStatus,
  paymentStatus,
} from "../../../../lib/constants";
import {
  cancelOrder,
  getMission,
  getMissionPlanningStatus,
} from "../../../../services/missions";
import OrderTopNavComponent from "./components/OrderTopNavComponent";
import MissionContext from "./components/MissionContext";
import Layout from "../../../../Layout";
import theme from "../../../../theme";
import { StyledTab, StyledTabs, appBar } from "../../../../lib/styleConstants";
import TabPanel from "../../../global/elements/TabPanel";
import EmptyMessage from "../shared/EmptyMessage";
import DeliveryPackagesTab from "../FilesPackages/DeliveryPackagesTab";
import { getDateTimeWithFormat, getTabIndex } from "../../../../lib/helpers";
import QuoteDetail from "../../quotes/QuoteDetail";
import AddCollaboratorPopup from "../../Client/Order/AddCollaborator";
import AppButton from "../../../global/elements/AppButton";
import ShareIcon from "@mui/icons-material/Share";
import OrderDetailsComponent from "./components/OrderDetailsComponent";
import { clientQuoteCurrentStatus } from "../../../../services/client";
import OrderNotFound from "../../Client/Order/OrderNotFound";
import UserNextActions from "./components/UserNextActions";
import AppContext from "../../../../contexts/AppContext";
import MissionDetailsEdit from "../MissionDetailsEdit";
import MissionPlanning from "../MissionPlanning";
import CancelOrder from "./components/CancelOrder";
import TwoWayCommunicationClient from "../../../global/TwoWayCommunication/TwoWayCommunicationClient";
// import MissionPlanning from "../MissionPlanning";

const styles = {
  root: {
    "& .next-mission-step": {
      padding: "2rem",
      background: theme.palette.grey.white,
      border: `1px solid ${theme.palette.grey.lightWarmGrey}`,
      marginTop: "2rem",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .next-mission-step .remove-pilot-btn": {
      marginTop: "2rem",
    },
    "& .column-content": {
      padding: "2rem",
      background: theme.palette.grey.white,
      border: `1px solid ${theme.palette.grey.lightWarmGrey}`,
      marginTop: "2rem",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    "& .mission-info": {
      position: "relative",
      alignContent: "flex-start",
    },
    "& .mission-info .edit-button": {
      position: "absolute",
      bottom: "0",
      left: "0",
      right: "0",
      width: "100%",
    },
    "& .mission-info.admin": { paddingBottom: "100px !important" },
    "& h2": { textAlign: "center" },

    "& .single-mission": {
      paddingBottom: "2rem",
      minHeight: "calc(100vh - 170px)",
    },
    "& .pick-pilot": { padding: "4rem 0" },
  },
  mainContent: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "auto",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
  },
  sectionContainer: {
    background: theme.palette.grey.white,
  },
  appBar: {
    width: "100%",
    backgroundColor: "transparent",
    borderColor: "green",
  },
  missionDetails: {
    width: "100%",
    background: theme.palette.grey.white,
  },
  collaboratorOuterBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: { xs: "column", sm: "row" },
  },
  shareIconBoxCls: {
    display: { xs: "none", sm: "block" },
    marginRight: "20px",
  },
  alignSelfEnd: {
    alignSelf: "center",
  },
  shareIconCls: { fontSize: 40 },
  collaboratorText: {
    "&.MuiTypography-root": {
      fontSize: { xs: "16px", sm: "18px", md: "20px" },
      color: theme.palette.black.real,
    },
  },
  displayFlexCls: {
    display: "flex",
  },
  shareDataCls: {
    width: { xs: "100%", sm: "unset" },
  },
  collaboratorWrapper: {
    boxShadow: `0px 3px 66px ${theme.palette.grey.lightWarmGrey}`,
    padding: "20px",
    marginBottom: "30px",
  },
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const PageModes = Object.freeze({
  DEFAULT: 0,
  EDIT_MISSION: 1,
});

const ClientSingleMissionPage = ({ missionId = "" }) => {
  //
  const [mission, setMission] = useStateIfMounted(null);
  const [loadingMission, setLoadingMission] = useStateIfMounted(false);
  const [reason, setReason] = useStateIfMounted();
  const [tabValue, setTabValue] = useStateIfMounted(0);
  const [orderNotFound, setOrderNotFound] = useStateIfMounted(false);
  const { packages: pilotPackages = [] } = useGetDeliveryPackages(missionId);
  const [refreshMission, setRefreshMission] = useStateIfMounted(false);
  const [totalQuote, setTotalQuote] = useStateIfMounted(0);
  const [pageMode, setPageMode] = useStateIfMounted(PageModes.DEFAULT);
  const [missionPlanning, setMissionPlanning] = useStateIfMounted(null);
  const [quotesStatus, setQuoteStatus] = useStateIfMounted(null);
  const [showCancelOrder, setShowCancelOrder] = useStateIfMounted(false);
  const [loading, setLoading] = useStateIfMounted(false);

  const fetchMissionPlanning = async (id) => {
    const data = await getMissionPlanningStatus(id);
    setMissionPlanning(data);
  };

  useEffect(() => {
    if (mission && mission?.assignedPilot) {
      fetchMissionPlanning(mission.id);
    }
  }, [mission]);

  const currentUser = getUser(users.userProfile);

  const appContext = useContext(AppContext);

  const currency = appContext?.enterPrise
    ? appContext?.enterPrise?.currency || defaultCurrency
    : appContext?.userData
    ? appContext?.userData?.currency || defaultCurrency
    : defaultCurrency;

  useEffect(() => {
    setLoadingMission(true);
    getMission(firebase, missionId)
      .then((data) => {
        setMission(data);
        if (data?.reason) {
          let str = "";
          data?.reason["rejectedReason"].map((reason) => {
            str = str + reason + ". ";
          });
          str =
            str + "Contact your GLOBHE representative for more information.";
          setReason(str);
        }
        setRefreshMission(false);
        setLoadingMission(false);
      })
      .catch((e) => {
        console.log("Error", e);
        setLoadingMission(false);
        setRefreshMission(false);
        setOrderNotFound(true);
      });
  }, [missionId, refreshMission, setOrderNotFound, setRefreshMission]);

  const handleTabChange = (e, newTabValue) => {
    setTabValue(newTabValue);
  };
  useEffect(() => {
    if (window.location.hash === "#communications") {
      setTabValue(
        mission?.missionType != missionTypeConstant.projectMission ? 3 : 2
      );
    } else {
      setTabValue(0);
    }
  }, [pageMode, mission]);

  useEffect(() => {
    if (!mission?.subscriptionId) {
      if (mission?.status == missionStatus.initialClientOrder) {
        clientQuoteCurrentStatus(firebase, missionId)
          .then((data) => {
            if (data?.status) {
              setQuoteStatus(data.status);
              setTotalQuote(data.count);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setQuoteStatus(null);
        setTotalQuote(0);
      }
    }
  }, [missionId, mission]);

  const openCancelOrderPopup = () => {
    setShowCancelOrder(true);
  };

  const cancelMission = () => {
    setLoading(true);
    cancelOrder(firebase, mission?.id)
      .then(() => {
        setRefreshMission(true);
      })
      .catch((e) => {
        console.log("Error", e);
      })
      .finally(() => {
        setLoading(false);
        setShowCancelOrder(false);
      });
  };

  const MissionBanners = () => {
    let actionText = "";
    let actionSubText = ``;
    let tabNumber = 2;
    let background;
    let bigText = false;

    if (quotesStatus == quoteStatus.quoteSent) {
      actionText = "Your quote is ready !";
      actionSubText =
        "Please review and accept the price quote as soon as possible, so we can assign an operator to capture your data.";
      tabNumber = 1 + getTabIndex({ missionType: mission?.missionType });
    } else if (quotesStatus == quoteStatus.declined) {
      actionText = "Our team busy preparing a new quote";
      tabNumber = getTabIndex({ missionType: mission?.missionType }) + 1;
    } else if (
      (mission?.status == missionStatus.confirmedMission ||
        mission?.status == missionStatus.unassigned) &&
      !mission.assignedPilot
    ) {
      bigText = true;
      actionText =
        "We are looking for the best drone operator to achieve your important mission";
    } else if (missionPlanning?.status == missionPlanningStates.notFeasible) {
      actionText = "Location is not accessible";
      background = theme.palette.status.errorLight;
      tabNumber = 0;
    } else if (
      mission?.status == missionStatus.active &&
      mission?.pilotPackageStatus == PilotPackageStatus.pendingReview
    ) {
      actionText = "Mission is completed";
      actionSubText =
        "Data is uploaded by the pilot admin is reviewing the data after that it will be available to download.";
    } else if (
      mission?.status == missionStatus.active &&
      missionPlanning?.status == missionPlanningStates.reviewed
    ) {
      actionText = "Waiting for operator feedback";
      actionSubText =
        "We are waiting for the operator to review the mission again.";
      bigText = true;
    } else if (
      mission?.status === missionStatus.active &&
      mission?.pilotPackageStatus == PilotPackageStatus.rejected
    ) {
      // show Waiting for operator feedback
      actionText = `Data rejected by globhe!`;
      background = theme.palette.status.errorLight;
      actionSubText = `There were some issue with the collected data we are 
      uploading new data as soon as possible.`;
    } else if (
      mission?.status == missionStatus.active &&
      missionPlanning?.status == missionPlanningStates.reschedule
    ) {
      actionText = `Mission is rescheduled to  : ${getDateTimeWithFormat(
        missionPlanning.data.currentFlightDate,
        "date-month-year"
      )}`;
      bigText = true;
    } else if (
      mission?.status == missionStatus.active &&
      missionPlanning?.status == missionPlanningStates.readyToUpload
    ) {
      // show Waiting for operator feedback
      actionText = `Data collected!`;
      actionSubText = "Pilot has collected the drone data will upload soon.";
    } else if (
      missionPlanning?.data?.currentFlightDate &&
      new Date(missionPlanning?.data?.currentFlightDate) < new Date() &&
      mission?.status == missionStatus.active
      // && mission?.pilotPackageStatus == PilotPackageStatus.open
    ) {
      actionText = "Mission delayed";
      background = theme.palette.status.errorLight;
    } else if (
      mission?.paymentStatusClient == paymentStatus.unpaid &&
      mission?.status == missionStatus.completed &&
      mission.missionType === missionTypeConstant.clientOrder
    ) {
      actionText = "Payment is overdue";
      background = theme.palette.status.warningLight;
    } else if (
      (mission?.paymentStatusClient == paymentStatus.paid ||
        mission.missionType === missionTypeConstant.projectMission) &&
      mission?.status == missionStatus.completed
    ) {
      actionText = "Your data is ready !";
      actionSubText =
        "You can go ahead and download your data using the browser. If you have an API, your data should be delivered to your servers by now. Make sure you pay the invoice before the due date.";
      tabNumber = 1 + getTabIndex({ missionType: mission?.missionType });
    } else if (
      (missionPlanning?.status == missionPlanningStates.onTime ||
        missionPlanning?.status == missionPlanningStates.reschedule) &&
      mission?.status !== missionStatus.completed
    ) {
      actionText = `Flying date set : ${getDateTimeWithFormat(
        missionPlanning.data.currentFlightDate,
        "date-month-year"
      )}`;
      actionSubText =
        "The drone operator will fly the drone on the above date to collect the data.";
    } else if (
      mission?.status == missionStatus.active &&
      mission.assignedPilot &&
      mission?.pilotPackageStatus == PilotPackageStatus.open
    ) {
      actionText =
        "We found the best drone operator to achieve your important mission";
      bigText = true;
      actionSubText = `We have assigned a drone operator to the mission we are waiting 
      for the there confirmation on the mission that it is feasible to collected data or not.`;
    }
    if (!actionText) {
      return null;
    }

    return (
      <UserNextActions
        actionText={actionText}
        actionSubText={actionSubText}
        tabNumber={tabNumber}
        background={background}
        bigText={bigText}
      />
    );
  };

  const SharedData = () => {
    const [openCollaboratorPopup, setCollaboratorPopup] =
      useStateIfMounted(false);
    return (
      <Box sx={styles.collaboratorWrapper}>
        <Box
          sx={{
            ...styles.collaboratorOuterBox,
            ...styles.alignSelfEnd,
          }}
        >
          <Box sx={styles.displayFlexCls}>
            <Box sx={styles.shareIconBoxCls}>
              <ShareIcon sx={styles.shareIconCls} />
            </Box>
            <Box sx={styles.alignSelfEnd}>
              <Typography sx={styles.collaboratorText}>
                Share data with collaborators
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.shareDataCls}>
            <AppButton
              look="green"
              label="Share data"
              onClick={() => setCollaboratorPopup(true)}
            />
          </Box>
        </Box>
        {openCollaboratorPopup && (
          <AddCollaboratorPopup
            openPopup={openCollaboratorPopup}
            setPopupOpen={setCollaboratorPopup}
            missionId={missionId}
            clientId={currentUser?.id}
            mission={mission}
            clientName={currentUser?.name || clientName.client}
          />
        )}
      </Box>
    );
  };

  return (
    <>
      <Layout loading={loadingMission} className={styles.root}>
        {!loadingMission && mission ? (
          <MissionContext.Provider
            value={{
              mission,
              currentUser,
              reason,
              totalQuote,
              handleTabChange,
              PageModes,
              setPageMode,
              missionPlanning,
              quotesStatus,
              openCancelOrderPopup,
            }}
          >
            <OrderTopNavComponent />
            <Box mt={2}>
              {![
                missionStatus.cancelled,
                missionStatus.archive,
                missionStatus.deleted,
                missionStatus.rejected,
              ].includes(mission?.status) && <MissionBanners />}
              {showCancelOrder && (
                <CancelOrder
                  showDialog={showCancelOrder}
                  setShowDialog={setShowCancelOrder}
                  onSubmit={cancelMission}
                  loading={loading}
                  setLoading={setLoading}
                />
              )}
              {/* {totalQuote > 0 &&
                mission?.status == missionStatus.initialClientOrder &&
                currentUser?.role == userRoles.client && (
                  <UserNextActions
                    actionText={"Your quote is ready !"}
                    actionSubText={
                      "Please review and accept the price quote as soon as possible, so we can assign an operator to capture your data."
                    }
                  />
                )}
              {mission?.status == missionStatus.completed && (
                <UserNextActions
                  actionText={"Your data is ready !"}
                  actionSubText={
                    "You can go ahead and download your data using the browser. If you have an API, your data should be delivered to your servers by now. Make sure you pay the invoice before the due date."
                  }
                  tabNumber={
                    1 + getTabIndex({ missionType: mission?.missionType })
                  }
                />
              )} */}
            </Box>

            <Box sx={styles.mainContent} mt={3}>
              <Box sx={styles.sectionContainer}>
                <AppBar position="static" sx={appBar} elevation={0}>
                  <StyledTabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <StyledTab
                      key="orderDetails"
                      label="Order Details"
                      {...a11yProps(0)}
                    />
                    {mission?.missionType !==
                      missionTypeConstant.projectMission && (
                      <StyledTab
                        key="viewQuote"
                        label="quotes & invoices"
                        {...a11yProps(1)}
                      />
                    )}

                    <StyledTab
                      key="missionPlanning"
                      label="Mission planning"
                      {...a11yProps(
                        mission?.missionType !==
                          missionTypeConstant.projectMission
                          ? 1
                          : 2
                      )}
                    />

                    {!(
                      (
                        currentUser.role === userRoles.pilot ||
                        mission?.status === missionStatus.archive ||
                        mission?.status === missionStatus.rejected ||
                        mission?.status === missionStatus.cancelled
                      )
                      // mission?.missionType !== missionTypeConstant.clientOrder
                    ) && (
                      <StyledTab
                        key="communication"
                        label="Communication"
                        {...a11yProps(
                          mission?.missionType !==
                            missionTypeConstant.projectMission
                            ? 2
                            : 3
                        )}
                      />
                    )}
                    {mission?.status === missionStatus.completed && (
                      <StyledTab
                        key="deliveryPackage"
                        label="delivery package"
                        {...a11yProps(
                          getTabIndex({ missionType: mission?.missionType })
                        )}
                      />
                    )}
                  </StyledTabs>
                </AppBar>
              </Box>
              <Box sx={styles.missionDetails}>
                <TabPanel value={tabValue} index={0} key="orderDetails">
                  {pageMode === PageModes.DEFAULT && <OrderDetailsComponent />}
                  {pageMode === PageModes.EDIT_MISSION && (
                    <MissionDetailsEdit
                      missionData={mission}
                      submitMissionHandler={() =>
                        setRefreshMission(!refreshMission)
                      }
                      onEditingFinished={() => {
                        setPageMode(PageModes.DEFAULT);
                      }}
                    />
                  )}
                </TabPanel>
                {mission?.missionType !==
                  missionTypeConstant.projectMission && (
                  <TabPanel value={tabValue} index={1} key="viewQuote">
                    <QuoteDetail
                      mission={mission}
                      setRefreshMission={setRefreshMission}
                      refreshMission={refreshMission}
                      setLoadingMission={setLoadingMission}
                      loadingMission={loadingMission}
                      currency={currency}
                    />
                  </TabPanel>
                )}

                <TabPanel
                  value={tabValue}
                  index={
                    mission?.missionType !== missionTypeConstant.projectMission
                      ? 2
                      : 1
                  }
                  key="missionPlanning"
                >
                  <MissionPlanning activeTab={missionPlanning?.data?.type} />
                </TabPanel>

                {!(
                  (
                    currentUser.role === userRoles.pilot ||
                    mission?.status === missionStatus.archive ||
                    mission?.status === missionStatus.rejected ||
                    mission?.status === missionStatus.cancelled
                  )
                  // mission?.missionType !== missionTypeConstant.clientOrder
                ) && (
                  <TabPanel
                    value={tabValue}
                    index={
                      mission?.missionType !==
                      missionTypeConstant.projectMission
                        ? 3
                        : 2
                    }
                    key="communication"
                  >
                    {/* <Communication mission={mission} /> */}
                    <TwoWayCommunicationClient />
                  </TabPanel>
                )}
                {mission?.status === missionStatus.completed && (
                  <TabPanel
                    value={tabValue}
                    index={getTabIndex({ missionType: mission?.missionType })}
                    key="deliveryPackage"
                  >
                    {(mission?.missionType != missionTypeConstant.projectMission
                      ? true
                      : !mission?.allowEmptyData) && <SharedData />}

                    {mission?.allowEmptyData ? (
                      <EmptyMessage
                        currentUser={currentUser}
                        mission={mission}
                      />
                    ) : (
                      <DeliveryPackagesTab
                        packageId={pilotPackages[0]?.id}
                        mission={mission}
                        packageType={packageTypeConstant.deliveryPackage}
                        isEditable={false}
                        mediaPackage={pilotPackages[0]}
                      />
                    )}
                  </TabPanel>
                )}
              </Box>
            </Box>
          </MissionContext.Provider>
        ) : orderNotFound ? (
          <OrderNotFound
            title={`The order with id ${missionId} is not available`}
            description="It might have been removed "
            link="/app/missions"
            label="Back to order list"
          />
        ) : (
          <></>
        )}
      </Layout>
    </>
  );
};

ClientSingleMissionPage.propTypes = {
  missionId: PropTypes.string.isRequired,
};

export default ClientSingleMissionPage;
