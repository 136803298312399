import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import OrderHeaderSwitch from "./Components/OrderHeaderSwitch";
import CreateEditOrderForm from "./CreateEditOrderForm";
import {
  advancedOptionLabel,
  defaultMissionRepresentative,
  guestUserSignInRoute,
  missionStatus,
  notificationType,
  orderLocationType,
  projectStatus,
  recurringIntervals,
  userRoles,
} from "../../../lib/constants";
import { createMission } from "../../../services/missions";
import { useSnackbar } from "notistack";
import firebase from "src/firebase";
import { getUser } from "../../../services/auth";
import { missionTypeConstant } from "../../../lib/constants";
import OrderSummary from "./OrderSummary";
import {
  createRecursiveMissions,
  projectSchema,
  setProjectDatatoRef,
  updateMissionIdToProject,
} from "../../../services/project";
import {
  addDaystoTheDate,
  generateKeywordsForSearch,
  getCurrentDateTime,
  getRandomMissionName,
} from "../../../lib/helpers";
import { DateTime } from "luxon";
import Dialogue from "../../global/Dialogue";
import GuestUserDialogueBody from "../AnonymousUser";
import EditClientProfile from "../Client/Profile/EditClientProfile";
import { claculateProgressForCreatingOrder } from "../../../services/clientProfile";
import { createNotification } from "../../../services/notification";
import {
  projectCreatedByAdmin,
  projectCreatedByClient,
} from "../../../services/clientEmail";

const OrderMainContainer = ({
  formType = undefined,
  orderType = undefined,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = getUser("user");
  const [showDialogue, setShowDialogue] = useState(false);
  const [summary, setSummary] = useState(null);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState({});
  const [isGuestUser, setIsGuestUser] = useState(false);
  const [showGuestUserDialogue, setShowGuestUserDialogue] = useState(false);
  const [completeProfilePopup, setCompleteProfilePopup] = useState(false);
  //Api call will go here for edit order multi/single
  // create a services file and if formType===create we don't have to call any API.

  // variable for switch
  const [orderSwitch, setOrderSwitch] = useState(
    orderType || orderLocationType.singleLocationOrder
  );
  const [clientInfo, setClientInfo] = useState({});

  //Identity it's a project or mission.
  const [orderCollection, setOrderCollection] = useState(
    orderType && orderType === orderLocationType.multiLocationOrder
      ? "projects"
      : "missions"
  );

  useEffect(() => {
    if (currentUser?.isAnonymous) {
      setIsGuestUser(true);
    }
  }, []);

  //Create form Submit function in here
  const orderSubmitHandler = (values) => {
    let advanceOpt = getAdvancedOption(values);
    if (Object.keys(advanceOpt).length > 0) {
      values.advancedOption = advanceOpt;
    }

    let pointOfContact = getPointOfContact(values);
    if (Object.keys(pointOfContact).length > 0) {
      values.pointOfContact = pointOfContact;
    }
    if (orderCollection === "missions") {
      missionSubmit(values);
    } else if (orderCollection === "projects") {
      projectSubmit(values);
    }
  };

  const mapProjectData = (data) => {
    const projectName = data.missionName || getRandomMissionName();
    const projectSearchName = generateKeywordsForSearch(projectName);
    const date = getCurrentDateTime();
    const missionIds = data.missionData?.map((mission) => mission.id) || [];
    const repeatOnData =
      data?.recursiveInterval === recurringIntervals.week
        ? data?.startDateForMission
        : data?.recursiveInterval === recurringIntervals.month ||
          data?.recursiveInterval === recurringIntervals.year
        ? DateTime?.fromJSDate(data?.startDateForMission).toISODate()
        : null;
    const deadline = data?.recurrence
      ? null
      : data?.deadline?.isLuxonDateTime
      ? DateTime?.fromISO(data?.deadline).toISODate()
      : DateTime?.fromJSDate(data?.deadline).toISODate();
    // const startDate = data?.startDate?.isLuxonDateTime
    //   ? DateTime?.fromISO(data?.startDate).toISODate()
    //   : DateTime?.fromJSDate(data?.startDate).toISODate();
    const startDate = data?.startDate
      ? DateTime?.fromJSDate(new Date(data?.startDate)).toISODate()
      : addDaystoTheDate({ theDate: getCurrentDateTime(), days: 2 }).slice(
          0,
          10
        );

    const intervalEndDate = data?.intervalEndDate?.isLuxonDateTime
      ? DateTime?.fromISO(data?.intervalEndDate).toISODate()
      : DateTime?.fromJSDate(data?.intervalEndDate).toISODate();
    data.createdByRole = currentUser?.role || null;
    data.createdById = currentUser?.id || null;
    if (data.recurrence) {
      data.deadline = data.recurrence;
      data.locationMap = data?.locationMap || null;
      data.areaSize = data?.areaSize || null;
      data.locationLabel = data?.locationLabel || null;
      data.kmlFile = data?.kmlFile || null;
      data.orderType = data?.orderType || null;
    }
    data.subscriptionEnd = null;
    data.subscription = null;

    // if (!area && !areaSize && !kmlUrl) {
    //   setErrorDisplay(true);
    //   setSubmitting(false);
    //   return;
    // }
    // }
    let obj = {
      projectName: projectName,
      recurrence: data?.recurrence || false,
      projectSearchName: projectSearchName || [],
      mapTypes: data?.mapTypes || [],
      droneSensors: data?.droneSensors || [],
      rtkRequire: data?.rtkRequire || false,
      status: data?.status || projectStatus.initial,
      country: data?.location || "",
      startDate: startDate || null,
      clientId: data?.clientId || null,
      clientEmail: data?.clientEmail || "",
      clientPhone: data?.clientPhone || null,
      clientName: data?.clientName || "",
      searchClientName: data?.clientName
        ? generateKeywordsForSearch(data?.clientName)
        : [],
      clientCompanyName: data?.clientCompanyName,
      clientPaymentStatus: data?.clientPaymentStatus || false,
      enterPriseId: data?.enterPriseId || null,
      allMissionIds: missionIds || [],
      allAdminIds: [],
      projectCompletedDate: null,
      createdAt: date,
      lastUpdated: date,
      deadline: deadline,
      missionPrice: Number(data?.missionPrice) || 0,
      pilotPrice: data?.missionPrice ? Number(data?.missionPrice) : 0,
      missionOwner: data.missionOwner || defaultMissionRepresentative?.email,
      missionOwnerId: data.missionOwnerId || defaultMissionRepresentative?.id,
      missionOwnerName:
        data.missionOwnerName || defaultMissionRepresentative?.name,
      allowEmptyData: data?.allowEmptyData || false,
      createdById: data?.createdById || null,
      createdByRole: data?.createdByRole || null,
      droneRequirements: data?.droneRequirements || "",
      otherRequirements: data?.otherRequirements || "",
      pilotInstructions: data?.pilotInstructions || "",
      description: data?.description || null,
      dataLibrary: {
        missionTitle: data?.dataLibMissionTitle || "",
        description: data?.dataLibDescription || "",
        sdgGoals: data?.dataLibSdgGoals || [],
        categories: data?.dataLibCategories || [],
      },
      recurrenceInfo: {
        everyCount: data?.everyCount || null,
        recursiveInterval: data?.recursiveInterval || null,
        intervalEndOn: data?.intervalEndOn || false,
        intervalEndAfter: data?.intervalEndAfter || false,
        intervalEndDate: intervalEndDate || null,
        intervalEndCount: data?.intervalEndCount || null,
        area: data?.area || null,
        locationMap: data?.locationMap || null,
        areaSize: data?.areaSize || null,
        kmlFile: data?.kmlFile || null,
        orderType: data?.orderType || null,
        startDateForMission: repeatOnData,
        locationLabel: data?.locationLabel || null,
      },
      notShowInDataLibrary: data?.notShowInDataLibrary || false,
      freeToDownload: data?.freeToDownload || false,
      breakInvoice: data?.breakInvoice || false,
    };

    if (data.pointOfContact) {
      obj.pointOfContact = data.pointOfContact;
    }

    if (data?.advancedOption) {
      obj.advancedOption = data.advancedOption;
    }

    return obj;
  };

  const getAdvancedOption = (values) => {
    const advancedOptionKeys = Object.keys(advancedOptionLabel);
    let obj = {};
    for (let i = 0; i <= advancedOptionKeys.length; i++) {
      if (values && values[advancedOptionKeys[i]]) {
        obj[advancedOptionKeys[i]] = values[advancedOptionKeys[i]];
      }
    }
    return obj;
  };

  const getPointOfContact = (values) => {
    let obj = {};
    if (values?.pocName) {
      obj.pocName = values.pocName;
    }

    if (values?.pocPhone && !isNaN(values?.pocPhone)) {
      obj.pocPhone = Number(values.pocPhone);
    }
    return obj;
  };
  const sendEmailsAndNotifications = (project) => {
    if (currentUser?.role == userRoles.client) {
      // Project is created by client
      projectCreatedByClient({ project });
      createNotification(
        firebase,
        notificationType.adminNotification,
        "A project is created by client",
        "A project is created by client",
        `/app/admin/projects/${project.id}`
      );
      if (project?.enterPriseId) {
        createNotification(
          firebase,
          project?.enterPriseId,
          "A project is placed by a team member",
          "A project is placed by a team member",
          `/app/client/projects/${project.id}`
        );
      }
    } else {
      // Project is created by admin
      createNotification(
        firebase,
        project?.enterPriseId || project.clientId,
        "A project is created for you",
        "A project is created for you",
        `/app/client/projects/${project.id}`
      );
      projectCreatedByAdmin({ project });
    }
  };

  const projectSubmit = async () => {
    let values = summary;
    let projectData = mapProjectData(values);
    setLoading(true);
    if (values.recurrence) {
      projectSchema(firebase, projectData)
        .then(async (data) => {
          if (data && data.status == "Success") {
            // let recursiveMissionStatus = {};
            if (projectData?.recurrence) {
              let allMissionIds = [];
              projectData.status = missionStatus.initialProjectOrder;
              const getAllMissionData = await createRecursiveMissions(
                data.data.id,
                projectData
              );
              allMissionIds = getAllMissionData?.map((msnData) => {
                return msnData.id;
              });
              updateMissionIdToProject(firebase, data.data.id, allMissionIds);
              sendEmailsAndNotifications({
                id: data.data.id,
                ...data.data,
              });
              if (projectData.enterPriseId) {
                await createNotification(
                  firebase,
                  projectData?.enterPriseId,
                  "Recurrent order created by a team member.",
                  "Recurrent order created by a team member.",
                  `/app/client/orderDetails/${projectData.id}`
                );
              }
            }

            // setSubmitting(false);
            enqueueSnackbar("Project created successfully", {
              variant: "success",
            });
            // sendEmailsAndNotifications({ project: data.data });
            // let URL =
            //   currentUser?.role == userRoles.client
            //     ? `/app/client/project/edit/${data.data.id}`
            //     : `/app/admin/project/edit/${data.data.id}`;
            // navigate(URL);
            setOrder(data?.data);
            setOrderPlaced(true);
          } else {
            // setSubmitting(false);
            enqueueSnackbar("Error in creating the project", {
              variant: "error",
            });
          }
        })
        .catch((e) => {
          // setSubmitting(false);
          enqueueSnackbar("Error in creating the project", {
            variant: "error",
          });
          console.log("error", e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      try {
        await setProjectDatatoRef(values.projectRef.id, projectData);
        const createMissionFromFiles = await firebase
          .functions()
          .httpsCallable("createMissionFromFiles");
        const { data } = await createMissionFromFiles({
          missionLocations: values.allCreatedValidMissions,
          projectId: values.projectRef.id,
          uploadedFileId: values.docIdUploadedFiles,
        });
        if (data.status === "Success") {
          enqueueSnackbar(`${data.message}`, {
            variant: "success",
          });
          // if (
          //   values.missionType === missionTypeConstant.clientOrder &&
          //   values.enterPriseId
          // ) {
          //   await createNotification(
          //     firebase,
          //     values?.enterPriseId,
          //     "Multi location order created by a team member.",
          //     "Multi location order created by a team member.",
          //     `/app/client/orderDetails/${values.id}`
          //   );
          // }
          // if (currentUser.role === userRoles.client) {
          //   await createNotification(
          //     firebase,
          //     notificationType.adminNotification,
          //     "A multi location order is in pending state from client.",
          //     "A multi location order is in pending state from client.",
          //     `/app/admin/projects/${values.projectRef.id}`
          //   );
          // }
          sendEmailsAndNotifications({
            id: values.projectRef.id,
            ...projectData,
            location: data.country,
          });
          setOrder({
            id: values.projectRef.id,
            ...projectData,
            location: data.country,
          });
          setOrderPlaced(true);
        } else {
          enqueueSnackbar(`${data.message}.`, {
            variant: "error",
          });
        }
        setLoading(false);
      } catch (e) {
        console.log("Error", e);
        setLoading(false);
      }
    }
  };
  const missionSubmit = (values) => {
    let missionData = values;
    if (currentUser.role === userRoles.client) {
      missionData.missionType = missionTypeConstant.clientOrder;
    }
    if (
      currentUser.role === userRoles.admin &&
      missionData.missionType === missionTypeConstant.clientOrder
    ) {
      missionData.status = missionStatus.initialClientOrder;
    }
    missionData.companyName = missionData.clientCompanyName;
    missionData.pilotPrice = missionData?.missionPrice
      ? Number(missionData?.missionPrice)
      : 0;
    setLoading(true);
    createMission(firebase, missionData, null, false)
      .then(async (data) => {
        try {
          // if (
          //   missionData.subscriptionId &&
          //   subscriptionData &&
          //   subscriptionData.id
          // ) {
          //   await updateSubscription(firebase, {
          //     mission: data,
          //     subscriptionId: subscriptionData.id,
          //     status: subsMissionUpdateStatus.addOrder,
          //   });
          // }
          // setOpenCustomOrderPopup(false);
          setOrder(data);
          enqueueSnackbar("Mission created successfully !", {
            variant: "success",
          });
          setOrderPlaced(true);
        } catch (e) {
          console.log("Error", e);
          enqueueSnackbar("Error in creating mission !", {
            variant: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Error in creating mission !", { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const goToSummaryPage = (values) => {
    if (isGuestUser) {
      setShowGuestUserDialogue(true);
    } else if (currentUser.role === userRoles.client) {
      if (clientInfo?.profilePercent < 100) {
        setCompleteProfilePopup(true);
      } else {
        redirectSummaryPage(values);
      }
    } else {
      redirectSummaryPage(values);
    }
  };

  const redirectSummaryPage = (values) => {
    setSummary(values);
    setShowDialogue(true);
  };

  const updateClientProfile = (values) => {
    let profilePercent = claculateProgressForCreatingOrder(values);
    setClientInfo({ ...values, profilePercent: profilePercent });
    setCompleteProfilePopup(false);
  };

  return (
    <Box>
      {/* Header for the create/edit order */}
      <OrderHeaderSwitch
        orderSwitch={orderSwitch}
        setOrderSwitch={setOrderSwitch}
        setOrderCollection={setOrderCollection}
      />

      {/* Form to create/edit order details */}

      <CreateEditOrderForm
        orderSubmitHandler={orderSubmitHandler}
        orderDetails={order}
        formType={formType}
        orderSwitch={orderSwitch}
        setOrderCollection={setOrderCollection}
        orderCollection={orderCollection}
        setClientInfo={setClientInfo}
        goToSummaryPage={goToSummaryPage}
        isGuestUser={isGuestUser}
        // errorDisplay={errorDisplay}
        // setErrorDisplay={setErrorDisplay}
      />

      {showDialogue && (
        <OrderSummary
          showDialogue={showDialogue}
          setShowDialogue={setShowDialogue}
          summary={summary}
          orderSwitch={orderSwitch}
          orderSubmitHandler={orderSubmitHandler}
          orderPlaced={orderPlaced}
          loading={loading}
          order={order}
          orderCollection={orderCollection}
        />
      )}

      {showGuestUserDialogue && (
        <Dialogue
          showDialogue={showGuestUserDialogue}
          setShowDialogue={setShowGuestUserDialogue}
          DialogueBody={GuestUserDialogueBody()}
          showCancelButton={true}
          cancelButtonText={"Cancel"}
          sumitButtonText={"Sign in"}
          onSubmit={() => navigate(guestUserSignInRoute)}
          submitButtonLook={"primary"}
          bottomDeviderLine={true}
        />
      )}

      {completeProfilePopup && (
        <Dialogue
          showDialogue={completeProfilePopup}
          setShowDialogue={setCompleteProfilePopup}
          DialogueBody={
            <EditClientProfile
              clientId={clientInfo?.id}
              showProfileImg={false}
              onCancel={() => setCompleteProfilePopup(false)}
              onSubmit={(values) => updateClientProfile(values)}
              submitButtonText={"Save your information"}
              submitButtonLook={"green"}
            />
          }
          DialogueHeadText="Sorry,  first you need to finish your registration before tasking a drone"
          showCancelButton={true}
          cancelButtonText={"Cancel"}
          sumitButtonText={"Sign in"}
          onSubmit={() => navigate(guestUserSignInRoute)}
          submitButtonLook={"primary"}
          tableType
        />
      )}
    </Box>
  );
};

OrderMainContainer.propTypes = {
  formType: PropTypes.string.isRequired,
  orderType: PropTypes.string,
};

// OrderMainContainer.defaultProps = {
//   orderType: undefined,
// };

export default OrderMainContainer;
