import React, { useState } from "react";
import { Box } from "@mui/material";
import Tips from "../../../../global/elements/Tips";
import HeaderBox from "../../../../global/elements/HeaderBox";
import UploadData from "../../Components/UploadData";
import AppButton from "../../../../global/elements/AppButton";
import firebase from "src/firebase";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import LocationsPins from "../../Components/LocationsPins";

const styles = {
  wrapper: {},
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "3em",
  },
  banner: {},
  uploadContainer: {},
  mapContainer: {},
  submitContainer: {
    textAlignLast: "end",
  },
};

const FileUploadTab = ({ projectId = undefined, projectName = "" }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [uploadValidData, setUploadValidData] = useState([]);
  const [docId, setDocId] = useState(null);
  const [showGlobalLoad, setShowGlobalLoad] = useState(false);

  const setData = ({ missionData }) => {
    setUploadValidData(
      missionData.data.createdFileData.allMissionCoordinatesInfo
    );
    setDocId(missionData.data.createdFileData.id);
  };

  const submitHandler = async () => {
    if (uploadValidData.length > 0) {
      setShowGlobalLoad(true);
      const createMissionFromFiles = await firebase
        .functions()
        .httpsCallable("createMissionFromFiles");
      const { data } = await createMissionFromFiles({
        missionLocations: uploadValidData,
        projectId,
        uploadedFileId: docId,
      });
      if (data.status === "Success") {
        enqueueSnackbar(`${data.message}`, {
          variant: "success",
        });
        setUploadValidData([]);
        setShowGlobalLoad(false);
      } else {
        enqueueSnackbar(`${data.message}.`, {
          variant: "error",
        });
        setShowGlobalLoad(true);
      }
    }
  };

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.container}>
        <Box sx={styles.banner}>
          <Tips content="You can add sites to this project by uploading a CSV or KML file that contains all the site data. Once uploaded, any new sites will be automatically assigned to this project." />
        </Box>
        <Box sx={styles.uploadContainer}>
          <HeaderBox title="Add sites from a csv/kml file">
            <UploadData
              setData={setData}
              projectId={projectId}
              showGlobalLoad={showGlobalLoad}
              projectName={projectName}
            />
          </HeaderBox>
        </Box>
        <Box sx={styles.mapContainer}>
          <HeaderBox title="Locations map">
            <LocationsPins uploadValidData={uploadValidData} />
          </HeaderBox>
        </Box>
        <Box sx={styles.submitContainer}>
          <AppButton
            label={"Save"}
            look={uploadValidData.length > 0 ? "black" : "blackDisabled"}
            onClick={submitHandler}
            submittingState={showGlobalLoad}
            isDisabled={uploadValidData.length > 0 ? false : true}
          />
        </Box>
      </Box>
    </Box>
  );
};

FileUploadTab.propTypes = {
  projectId: PropTypes.string.isRequired,
  projectName: PropTypes.string,
};

// FileUploadTab.defaultProps = {
//   projectName: "",
// };

export default FileUploadTab;
