import React from "react";
import { Box } from "@mui/material";
import StatusAlertContainer from "../../global/elements/StatusAlertContainer";
import AppButton from "../../global/elements/AppButton";
import { guestUserSignInRoute } from "../../../lib/constants";
import { navigate } from "gatsby";
import PropTypes from "prop-types";

const styles = {
  container: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
  },
};

const GuestStatusAlert = ({ statusMessage = "" }) => {
  return (
    <Box sx={styles.container}>
      <StatusAlertContainer
        severity="warning"
        heading={
          statusMessage
            ? statusMessage
            : "Sign in to access your profile and data"
        }
        coloredBackground
      />
      <AppButton
        label="Sign in here"
        onClick={() => navigate(guestUserSignInRoute)}
        look="black"
      />
    </Box>
  );
};

GuestStatusAlert.propTypes = { statusMessage: PropTypes.string };

export default GuestStatusAlert;
