import React from "react";
import theme from "../../../theme";
import { Typography, Box } from "@mui/material";
import PropTypes from "prop-types";

const style = {
  section_heading: {
    background: theme.palette.secondary.main,
    padding: "10px 20px",
    width: "100%",
  },
  header_text: {
    color: theme.palette.grey.white,
  },
};

const SectionHeading = ({
  heading = undefined,
  backgroundColor = theme.palette.secondary.main,
}) => {
  return (
    <Box sx={style.section_heading} style={{ background: backgroundColor }}>
      <Typography variant="h5" style={style.header_text}>
        {heading}
      </Typography>
    </Box>
  );
};

SectionHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
};

// SectionHeading.defaultProps = {
//   backgroundColor: theme.palette.secondary.main,
// };

export default SectionHeading;
