import React, { useEffect, useState } from "react";
import { getUser } from "src/services/auth";
import Layout from "../../../Layout";
import ProfileInfoPage from "../../global/elements/ProfileInfoPage";
import { Box } from "@mui/material";
import AppButton from "../../global/elements/AppButton";
import { connect } from "react-redux";
import { fetchUserProfile } from "../../../redux/actions/user-action";
import PropTypes from "prop-types";

const styles = {
  editButtonBox: {
    width: "100%",
  },
};

const MyProfile = ({
  fetchUserProfile = undefined,
  userProfile = undefined,
}) => {
  const currentUser = getUser("user");

  const [userData, setUserData] = useState({});

  useEffect(() => {
    fetchUserProfile(currentUser?.userID);
  }, [currentUser?.userID, fetchUserProfile]);

  useEffect(() => {
    if (userProfile && userProfile.profile && userProfile.profile.id) {
      setUserData(userProfile.profile);
    }
  }, [userProfile, userProfile.profile]);
  return (
    <Layout
      pageTitle="My Profile"
      hasSideMenu
      loading={userData ? false : true}
    >
      <Box sx={styles.editButtonBox}>
        <AppButton
          url={`/app/my-profile/edit`}
          internalLink
          label="Edit profile"
          look="purple"
        />
      </Box>
      <Box sx={styles.profileInfo}>
        <ProfileInfoPage user_data={userData} />
      </Box>
    </Layout>
  );
};

MyProfile.propTypes = {
  fetchUserProfile: PropTypes.func,
  userProfile: PropTypes.object,
};

// MyProfile.defaultProps = {
//   fetchUserProfile: undefined,
//   userProfile: undefined,
// };

// export default MyProfile;
export default connect(({ userProfile }) => ({ userProfile }), {
  fetchUserProfile,
})(MyProfile);
