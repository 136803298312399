import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../../../../theme";
import AppButton from "../../../../global/elements/AppButton";

const styles = {
  root: {
    marginTop: "60px",
    padding: "20px",
    height: "100% !important",
  },
  logo: {
    width: { xs: "100px", sm: "120px" },
    // marginBottom: "30px",
  },
  textColorWhite: {
    color: theme.palette.grey.white,
  },
  textColorBlack: {
    color: theme.palette.black.darkSeaBlack,
  },
  discountBox: {
    display: "flex",
    flexDirection: "row",
    float: "right",
    alignItems: "center",
  },
  backgroundPurple: {
    backgroundColor: theme.palette.secondary.main,
  },
  backgroundGreen: {
    backgroundColor: theme.palette.primary.main,
  },
  backgroundBlack: {
    backgroundColor: theme.palette.black.darkSeaBlack,
  },
  backgroundLightPurple: {
    backgroundColor: theme.palette.secondary.lightPurple,
  },
  buttonStyle: {
    width: { xs: "100%", sm: "unset" },
    float: "right",
  },
  alignLogo: {
    alignSelf: "center",
  },
  rightAlign: {
    justifyContent: "right !important",
  },
};

const SmallPromotion = ({
  background = "purple",
  discount = undefined,
  btnText = "Claim offer",
  couponCode = undefined,
  logo = undefined,
  title = undefined,
  subtitle = undefined,
  link = undefined,
}) => {
  return (
    <Grid
      container
      sx={{
        ...styles.root,
        ...(background === "green"
          ? styles.backgroundGreen
          : background === "black"
          ? styles.backgroundBlack
          : background === "lightPurple"
          ? styles.backgroundLightPurple
          : styles.backgroundPurple),
      }}
      rowSpacing={1}
    >
      <Grid item xs={6} sx={styles.alignLogo}>
        <Box component="img" src={logo} alt="I-conic_Logo" sx={styles.logo} />
      </Grid>
      {discount && (
        <Grid item xs={6}>
          <Box sx={styles.discountBox}>
            <Typography
              variant="h3"
              sx={{
                ...styles.textColorWhite,
                ...(background === "green"
                  ? styles.textColorBlack
                  : styles.textColorWhite),
              }}
            >
              {discount}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                ...styles.textColorWhite,
                ...(background === "green"
                  ? styles.textColorBlack
                  : styles.textColorWhite),
              }}
            >
              &nbsp;OFF
            </Typography>
          </Box>
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography
          variant="h5"
          sx={{
            ...styles.textColorWhite,
            ...(background === "green"
              ? styles.textColorBlack
              : styles.textColorWhite),
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          sx={{
            ...styles.textColorWhite,
            ...(background === "green"
              ? styles.textColorBlack
              : styles.textColorWhite),
          }}
        >
          {subtitle}
        </Typography>
      </Grid>
      <Grid item container xs={12} sx={!couponCode ? styles.rightAlign : ""}>
        {couponCode && (
          <Grid item xs={8}>
            <Typography
              sx={{
                ...styles.textColorWhite,
                ...(background === "green"
                  ? styles.textColorBlack
                  : styles.textColorWhite),
              }}
              variant="subtitle1"
            >
              Coupon code :
            </Typography>
            <Typography
              sx={{
                ...styles.textColorWhite,
                ...(background === "green"
                  ? styles.textColorBlack
                  : styles.textColorWhite),
              }}
              variant="subtitle1Med"
            >
              {couponCode}
            </Typography>
          </Grid>
        )}
        <Grid item xs={4}>
          <AppButton
            label={btnText}
            look="inverted"
            addtionalStyle={styles.buttonStyle}
            externalLink
            url={link}
            targetNewWidow
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
SmallPromotion.propTypes = {
  logo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  background: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  discount: PropTypes.string,
  btnText: PropTypes.string,
  couponCode: PropTypes.string,
};
// SmallPromotion.defaultProps = {
//   background: "purple",
//   discount: undefined,
//   btnText: "Claim offer",
//   couponCode: undefined,
// };

export default SmallPromotion;
