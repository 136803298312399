import EditIcon from "@mui/icons-material/Edit";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import GoogleMapReact from "google-map-react";
import PropTypes from "prop-types";
import React, { useState, useRef, useEffect, useCallback } from "react";
import DataRequestAutoComplete from "../../dataRequest/DataRequestAutoComplete";
import DataRequestMarker from "../../dataRequest/DataRequestMarker";
import { OrderTypeConstant, userRoles } from "../../../../lib/constants";
import KMLFileDropZone from "../../missions/shared/KMLFileDropZone";
import theme from "../../../../theme";
import {
  getCountryCode,
  displayAreaSizeInSelectedUnits,
  areaSizeUnitNotation,
} from "../../../../lib/helpers";
import { Box, Grid, Typography } from "@mui/material";
import { getUser } from "../../../../services/auth";
import AppButton from "../../../global/elements/AppButton";
import EditRoadSharpIcon from "@mui/icons-material/EditRoadSharp";
import TextStructures from "../../../global/elements/TypographyElements/TextStructures";
import InfoBox from "../../../global/elements/InfoBox";
import CharacterDropDown from "../../../global/elements/CharacterDropDown";

const styles = {
  root: {
    width: "100%",
  },
  logo: {
    height: 23,
    width: "auto",
  },
  map: {
    width: "100%",
    minHeight: "300px",
  },

  ctaButton: {
    "&.MuiButton-root": {
      textTransform: "none",
      width: 300,
      borderRadius: "0px",
      background: theme.palette.primary.main,
      color: theme.palette.grey.white,
      padding: "6px",
      "&.Mui-disabled": {
        background: theme.palette.grey.lightWarmGrey,
        color: theme.palette.grey.warmGrey,
      },
      "&:hover": {
        background: theme.palette.secondary.main,
      },
    },
  },
  ctaClearButton: {
    "&.MuiButton-root": {
      textTransform: "none",
      width: 300,
      borderRadius: "0px",
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      borderColor: theme.palette.primary.main,
      padding: "6px",
      "&:hover": {
        border: `1px solid ${theme.palette.secondary.main}`,
      },
    },
  },
  inputField: {
    color: theme.palette.grey.white,
  },
  searchBox: {
    marginTop: 0,
  },
  toolButton: {
    width: "156px",
    height: "50px",
    textTransform: "none",
    borderRadius: 0,
  },
  editbutton: {
    height: 30,
    textTransform: "none",
    borderRadius: "21px",
    fontSize: "10px",
  },
  toolClearButton: {
    "&.MuiButton-root": {
      height: 30,
      textTransform: "none",
      borderRadius: "21px",
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      "&:hover": {
        border: `1px solid ${theme.palette.primary.main}`,
      },
      fontSize: "10px",
    },
  },

  sizeField: {
    margin: "0px 20px",
    textAlign: "right",
  },
  // topBarLeftSide: {
  //   width: { xs: "60%", sm: "40%", md: "43%" },
  //   marginBottom: { xs: "10px", sm: "0px" },
  // },
  changeUnitCls: {
    border: `1px solid ${theme.palette.black.darkSeaBlack}`,
    borderRadius: 0,

    "&.MuiInput-underline:before": {
      border: "none !important",
    },

    "&:hover": {
      border: "none !important",
      outline: "none !important",
    },
    ".css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus": {
      backgroundColor: "rgba(0, 0, 0, 0) !important",
    },
    "&.MuiInput-underline:after": {
      border: "none !important",
      outline: "none !important",
      backgroundColor: "white !important",
    },

    paddingLeft: "10px",
    outline: "none",
  },

  addScrollBar: {
    overflowY: { xs: "scroll", sm: "hidden" },
  },
  uploadKmlButton: {
    // "& .MuiButton-root": {
    //   width: { xs: "100px", sm: "130px", md: "110px", lg: "130px" },
    // },
    width: { xs: "100px", sm: "130px", md: "110px", lg: "130px" },
    height: "50px",
    background: `${theme.palette.primary.main}`,
  },
  uploadBtn: {
    borderRadius: 0,
    "& .MuiButton-fullWidth": {
      width: "100%",
    },
    "&.MuiButton-root": {
      margin: "auto 0 auto auto",
      padding: { xs: "4px 8px", lg: "5px 8px" },
      fontSize: { xs: "10px", lg: "12px" },
      width: "100%",
      borderColor: theme.palette.primary.main,
      color: theme.palette.grey.white,
      background: theme.palette.primary.main,
      borderRadius: "25px",
      fontWeight: 500,
      textTransform: "none",
      fontFamily: "NHaasGroteskDSPro",
      "&:hover": {
        borderColor: theme.palette.primary.main,
        color: theme.palette.grey.white,
        background: theme.palette.primary.main,
      },
    },
  },
  editClearBtn: { display: "flex", justifyContent: "space-between" },
  missionInfoBox: {
    border: `1px solid ${theme.palette.black.darkSeaBlack}`,
  },
  infoText: { padding: "15px" },
  marginLeftCls: { marginLeft: "15px" },
  locationCls: { display: "flex", marginRight: "20px" },
  unitDropDown: {
    display: "flex",
    justifyContent: "space-around",
    width: "90%",
  },
  marginTopCls: { marginTop: "40px" },
  saveBtnWidth: { width: "100%" },
  saveBtnBox: {
    position: "absolute",
    bottom: "0px",
    right: "0px",
    width: { xs: "unset", md: "calc(100% - 20px)" },
  },
  mapInfoBox: { position: "relative" },
  orText: { margin: "auto" },
};

const MapComponent = ({
  setArea = undefined,
  areaSize = undefined,
  setAreaSize = undefined,
  selectedUnit = undefined,
  setSelectedUnit = undefined,
  kmlUrl = undefined,
  setKmlUrl = undefined,
  polygon = undefined,
  setPolygon = undefined,
  mapApi = undefined,
  setMapApi = undefined,
  geocoder = undefined,
  setGeocoder = undefined,
  area = [],
  defaultLocation = { lat: 0, lng: 0 },
  defaultZoom = 0,
  readonly = false,
  onSave = undefined,
  mapHeight = undefined,
  minHeight = undefined,
  createdByPilot = false,
  activatePilotKml = false,
  disabled = false,
  selectedOrderType = undefined,
  setOrderTypeError = undefined,
  handleOpen = undefined,
  mapSaved = false,
  setMapSaved = undefined,
  setFieldValue = undefined,
  setSelectedCountryCode = undefined,
  setErrorDisplay = undefined,
  locDataPilot = undefined,
  orderData = undefined,
}) => {
  const mapRef = useRef(null);
  const [mapApiLoaded, setMapApiLoaded] = useState(false);
  const [mapInstance, setMapInstance] = useState(null);
  const [place, setPlace] = useState(null);
  const [inDrawMode, setInDrawMode] = useState(false);
  const [drawingManager, setDrawingManager] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [infoWindow, setInfoWindow] = useState(null);
  const [mapLocationLabel, setMapLocationLabel] = useState(null);
  const [city, setCity] = useState(null);
  const [orderType, setOrderType] = useState(null);
  const currentUser = getUser("user");
  const isClient = currentUser.role === userRoles.client;
  const isAdmin = currentUser.role === userRoles.admin;
  const isPilot = currentUser.role === userRoles.pilot;
  const setApiHasLoaded = (map, maps) => {
    setMapInstance(map);
    setMapApi(maps);
    setMapApiLoaded(true);
  };
  const setKML = (url, locData) => {
    setArea(null);
    setAreaSize(null);
    setMapData(url, locData);
  };

  const setMapData = (url, { locMap, areaSize, countryCode }) => {
    setArea(locMap);
    setAreaSize(areaSize);
    loadArea(locMap);
    setKmlUrl(url);
    setFieldValue("kmlFile", url);

    if (countryCode && typeof setSelectedCountryCode == "function") {
      setSelectedCountryCode(countryCode);
    }
  };

  const lookColors = () => {
    if (isAdmin && area) {
      return "blue";
    } else if (isAdmin && !area) {
      return "blackDisabled";
    } else if (isClient && area) {
      return "green";
    } else if (isClient && !area) {
      return "greenDisabled";
    } else if (isPilot && area) {
      return "purple";
    } else {
      return "blackDisabled";
    }
  };
  const formatContent = useCallback((address, mouseEvent) => {
    const coordinates = JSON.stringify(mouseEvent.latLng.toJSON(), null, 2);
    return `
      <div>
        <p><b>${address}</b><br>${coordinates}</p>
      </div>`;
  }, []);
  const createPolylineFromArea = (area) => {
    const coords = [];
    area.forEach((coordinate) =>
      coords.push(new mapApi.LatLng(coordinate.lat, coordinate.lng))
    );
    const pol = new mapApi.Polyline({
      map: mapInstance,
      path: coords,
      strokeColor: theme.palette.primary.main,
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: theme.palette.grey.white,
      fillOpacity: 0.35,
      draggable: !readonly,
      editable: !readonly,
      geodesic: true,
    });
    setPolygon(pol);
  };
  const polygonToArea = useCallback((polygon) => {
    const area = [];
    polygon.getPath().forEach((element) => area.push(element.toJSON()));
    return area;
  }, []);
  const clearPolygon = useCallback(() => {
    setOrderTypeError(null);
    polygon?.setMap(null);
    setPolygon(null);
    setAreaSize(null);
    setArea(null);
    setCity(null);
    setKmlUrl(null);
    setMapLocationLabel(null);
    // setSelectedOrderType(null);
    setErrorDisplay(false);
    setMapSaved(false);
    setFieldValue("locationLabel", null);
    setFieldValue("orderType", null);
    setFieldValue("locationMap", null);
    setFieldValue("areaSize", null);
    setInDrawMode(false);
    setOrderType(null);
  }, [polygon]);
  const areaToBounds = (area) => {
    const bounds = new mapApi.LatLngBounds();
    area.forEach((coord) => bounds.extend(new mapApi.LatLng(coord)));
    return bounds;
  };

  const findOrderType = useCallback(
    (area) => {
      let type = null;
      if (area) {
        if (area.length == 1) {
          // This is assest (point)
          setOrderType(OrderTypeConstant.assets);
          type = OrderTypeConstant.assets;
        } else {
          if (
            area[0].lat == area[area.length - 1].lat &&
            area[0].lng == area[area.length - 1].lng
          ) {
            // This is polygon
            setOrderType(OrderTypeConstant.area);
            type = OrderTypeConstant.area;
          } else {
            // This is linear
            setOrderType(OrderTypeConstant.linear);
            type = OrderTypeConstant.linear;
          }
        }
      }
      return type;
    },
    [setOrderType, OrderTypeConstant]
  );

  const showOrderTypeError = useCallback(() => {
    let userOrderType = findOrderType(area);
    if (
      userOrderType &&
      selectedOrderType &&
      selectedOrderType !== userOrderType
    ) {
      return `Your selected order type ${selectedOrderType} is not matching with your drawn order type ${userOrderType} `;
    } else {
      return null;
    }
  }, [area, selectedOrderType]);
  const getLocationOnClick = useCallback(
    (geocoder, mouseEvent, infoWindow) => {
      infoWindow.close();
      geocoder.geocode({ location: mouseEvent.latLng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            infoWindow.setPosition(mouseEvent.latLng);
            infoWindow.setContent(
              formatContent(results[0].formatted_address, mouseEvent)
            );
            infoWindow.open(mapInstance);
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      });
    },
    [mapInstance]
  );

  const getAreaSize = useCallback(
    (polygon) => {
      const coord = polygonToArea(polygon);
      const type = findOrderType(coord);
      let areaSize = 0;
      if (type == OrderTypeConstant.area) {
        // POLYGON
        areaSize = mapApi.geometry.spherical.computeArea(polygon.getPath());
        return areaSize / 1000000; //convert sqm to sqkm
      } else if (type == OrderTypeConstant.assets) {
        // ASSET
        return 0;
      } else {
        // LINEAR
        for (let i = 0; i < coord.length; i++) {
          if (i != coord.length - 1) {
            areaSize += mapApi.geometry.spherical.computeDistanceBetween(
              new mapApi.LatLng(coord[i]),
              new mapApi.LatLng(coord[i + 1])
            );
          }
        }

        // return distance in meters
        areaSize = areaSize / 1000; // Changing this to Km
        return areaSize;
      }
    },
    [mapApi]
  );

  // CSS workaround for safari

  const loadArea = useCallback(
    (area) => {
      // createPolygonFromArea(area);
      createPolylineFromArea(area);
      const bounds = areaToBounds(area);
      mapInstance.setCenter(bounds.getCenter());
      mapInstance.fitBounds(bounds);
    },
    [createPolylineFromArea, mapInstance]
  );

  const save = (polygon) => {
    const area = polygonToArea(polygon);
    const type = findOrderType(area);
    let locationLabel;
    let code;
    const bounds = new mapApi.LatLngBounds();
    area.forEach((coordinate) => bounds.extend(coordinate));
    geocoder.geocode({ location: bounds.getCenter() }, (results, status) => {
      if (status === "OK") {
        code = getCountryCode(results);
        if (results[0]) {
          locationLabel = results[0].formatted_address;
        }
        {
          locationLabel && setCity(locationLabel?.split(" ").slice(-4)[0]);
        }
        setMapLocationLabel(locationLabel?.split(" ").slice(-1)[0]);
        if (code && typeof setSelectedCountryCode == "function") {
          setSelectedCountryCode(code);
        }
      }
      setArea(area);
      onSave({
        area: area,
        locationLabel: locationLabel,
        type: type,
        areaSize: getAreaSize(polygon),
        countryCode: code,
      });
    });
  };
  const updateSize = useCallback(() => {
    if (polygon && !dragging) {
      setAreaSize(getAreaSize(polygon));
      if (!readonly) {
        save(polygon);
      }
    }
  }, [polygon, dragging, getAreaSize, save, readonly]);

  useEffect(() => {
    if (mapRef) {
      mapRef.current.googleMapDom_.style.minHeight = mapHeight;
    }
  }, [mapRef]);
  useEffect(() => {
    if (!mapApiLoaded) return;
    if (area) {
      loadArea(area);
    }
    const _geocoder = new mapApi.Geocoder();
    setGeocoder(_geocoder);
    let _infoWindow = new mapApi.InfoWindow({
      content: "Right Click the map to get info about the location!",
      position: null,
    });
    _infoWindow.open(mapInstance);
    setInfoWindow(_infoWindow);
    const clickInfoListener = mapApi.event.addListener(
      mapInstance,
      "rightclick",
      (mapsMouseEvent) =>
        getLocationOnClick(_geocoder, mapsMouseEvent, _infoWindow)
    );

    if (!readonly) {
      const _drawingManager = new mapApi.drawing.DrawingManager({
        drawingControl: false,
        polygonOptions: {
          draggable: true,
          editable: true,
          strokeColor: theme.palette.primary.main,
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: theme.palette.grey.white,
          fillOpacity: 0.35,
          geodesic: true,
        },
        polylineOptions: {
          draggable: true,
          editable: true,
          strokeColor: theme.palette.primary.main,
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: theme.palette.grey.white,
          fillOpacity: 0.35,
          geodesic: true,
        },
      });
      setDrawingManager(_drawingManager);
    }
    return () => {
      mapApi.event.removeListener(clickInfoListener);
      //global cleanup
      mapApi.event.clearInstanceListeners(mapInstance);
    };
  }, [
    // area,
    getLocationOnClick,
    // loadArea,
    mapApi,
    mapApiLoaded,
    mapInstance,
    readonly,
    // setGeocoder,
  ]);

  useEffect(() => {
    if (!drawingManager) return;
    drawingManager.setMap(mapInstance);

    const listener2 = mapApi.event.addListener(
      drawingManager,
      "polylinecomplete",
      (pol) => {
        setInDrawMode(false);
        pol.setMap(mapInstance);
        setPolygon(pol);
      }
    );

    return () => {
      // mapApi.event.removeListener(listener);
      mapApi.event.removeListener(listener2);
    };
  }, [drawingManager, mapInstance]);

  useEffect(() => {
    if (!drawingManager) return;
    drawingManager.setDrawingMode(
      inDrawMode ? mapApi.drawing.OverlayType.POLYLINE : null
    );
  }, [inDrawMode, drawingManager]);

  useEffect(() => {
    const polyListeners = [];
    if (polygon) {
      setAreaSize(getAreaSize(polygon));
      polyListeners.push(
        mapApi.event.addListener(polygon.getPath(), "insert_at", updateSize)
      );
      polyListeners.push(
        mapApi.event.addListener(polygon.getPath(), "remove_at", updateSize)
      );
      polyListeners.push(
        mapApi.event.addListener(polygon.getPath(), "set_at", updateSize)
      );
      polyListeners.push(
        mapApi.event.addListener(polygon, "dragend", () => setDragging(false))
      );
      polyListeners.push(
        mapApi.event.addListener(polygon, "dragstart", () => setDragging(true))
      );
      polyListeners.push(
        mapApi.event.addListener(polygon, "rightclick", (mouseEvent) =>
          getLocationOnClick(geocoder, mouseEvent, infoWindow)
        )
      );

      save(polygon);
    }
    return () => {
      polyListeners.forEach((listener) =>
        mapApi.event.removeListener(listener)
      );
    };
  }, [
    geocoder,
    getAreaSize,
    getLocationOnClick,
    infoWindow,
    polygon,
    // updateSize,
    // save,
  ]);

  useEffect(() => {
    if ((kmlUrl, locDataPilot)) {
      const url = kmlUrl;
      setKML(url, locDataPilot);
    }
  }, [activatePilotKml]);
  useEffect(() => {
    const error = showOrderTypeError();
    setOrderTypeError(error);
  }, [
    area,
    areaSize,
    setOrderTypeError,
    showOrderTypeError,
    selectedOrderType,
  ]);

  return (
    <Box
      sx={
        readonly
          ? { ...styles.root }
          : { ...styles.root, ...styles.addScrollBar }
      }
    >
      {!readonly && (
        <>
          <TextStructures text="Enter your location" noUnderline marginTop />
          <Grid container sx={styles.topBar} mt={1} columnSpacing={2}>
            <Grid item xs={12} md={7} sx={styles.topBarLeftSide}>
              {mapApiLoaded && (
                <>
                  <DataRequestAutoComplete
                    map={mapInstance}
                    mapApi={mapApi}
                    addplace={(newPlace) => setPlace(newPlace)}
                  />
                  <Typography variant="body3">
                    Depends of operator availability and acceptance of quote
                  </Typography>
                </>
              )}
            </Grid>
            {!readonly && (
              <Grid item xs={12} md={5}>
                {!(
                  (currentUser.role == userRoles.admin ||
                    currentUser.role == userRoles.client) &&
                  orderData?.subscriptionId
                ) &&
                  !mapSaved &&
                  area && (
                    <Box sx={styles.editClearBtn}>
                      <AppButton
                        label={"Edit Draw"}
                        onClick={() => setInDrawMode(false)}
                        customIcon={<EditIcon />}
                        isDisabled={inDrawMode || kmlUrl || disabled}
                        look={
                          inDrawMode || kmlUrl || disabled
                            ? "blackDisabled"
                            : "black"
                        }
                      />

                      <AppButton
                        label={"Clear"}
                        onClick={() => clearPolygon()}
                        isDisabled={disabled}
                        noIcon
                        look={"inverted"}
                      />
                    </Box>
                  )}

                {!(
                  (currentUser.role == userRoles.admin ||
                    currentUser.role == userRoles.client) &&
                  // orderData?.subscriptionId
                  area
                ) && (
                  <Box sx={styles.editClearBtn}>
                    <AppButton
                      label={"Draw"}
                      onClick={() => setInDrawMode(true)}
                      customIcon={<ArchitectureIcon />}
                      isDisabled={
                        polygon
                          ? true
                          : !selectedOrderType
                          ? true
                          : mapSaved
                          ? true
                          : disabled
                          ? true
                          : false
                      }
                      look={
                        inDrawMode ||
                        polygon ||
                        !selectedOrderType ||
                        mapSaved ||
                        disabled
                          ? "blackDisabled"
                          : "black"
                      }
                    />

                    <Typography variant="subtitle1Med" sx={styles.orText}>
                      or
                    </Typography>
                    {!createdByPilot && (
                      <KMLFileDropZone
                        onUploaded={(url, locData) => {
                          setKML(url, locData);
                          setInDrawMode(false);
                        }}
                        currentFileUrl={kmlUrl}
                        disabled={!selectedOrderType ? true : false}
                        additionalStyle={{
                          ...styles.uploadKmlButton,
                          marginTop: {
                            xs: areaSize ? "10px" : "0px",
                            sm: "0px",
                            lg: "0px",
                          },
                        }}
                        user={currentUser.role}
                      />
                    )}
                  </Box>
                )}

                {mapSaved && (
                  <Box sx={styles.editClearBtn}>
                    <AppButton
                      label={"Draw"}
                      onClick={() => setInDrawMode(true)}
                      customIcon={<ArchitectureIcon />}
                      isDisabled={mapSaved}
                      look={mapSaved ? "blackDisabled" : "black"}
                    />
                  </Box>
                )}
              </Grid>
            )}
          </Grid>
        </>
      )}

      <Grid container mt={3} columnSpacing={2}>
        <Grid item xs={12} md={7}>
          <Box
            sx={{
              width: "100%",
              minHeight: minHeight,
              height: mapHeight,
              borderRadius: "0px",
              overflow: "hidden",
            }}
          >
            <GoogleMapReact
              bootstrapURLKeys={{
                libraries: ["drawing", "geometry", "places"],
                key: process.env.GATSBY_FIREBASE_API_KEY,
              }}
              defaultCenter={defaultLocation}
              defaultZoom={defaultZoom}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => setApiHasLoaded(map, maps)}
              options={{
                mapTypeControl: true,
                mapTypeId: "satellite",
              }}
              ref={mapRef}
            >
              {place && (
                <DataRequestMarker
                  key={place.id}
                  text={place.name}
                  lat={place.geometry.location.lat()}
                  lng={place.geometry.location.lng()}
                />
              )}
            </GoogleMapReact>
          </Box>
        </Grid>
        <Grid item xs={12} md={5} sx={styles.mapInfoBox}>
          <InfoBox
            title={"Mission order information"}
            subText={
              "For area projects, a minimum order of 100 hectares is required. Any orders below this minimum requirement will be charged at the rate of 100 hectares"
            }
          />

          <Box
            sx={{
              ...styles.locationCls,
              ...styles.marginLeftCls,
              ...styles.marginTopCls,
            }}
          >
            <LocationOnOutlinedIcon />

            <Box component="span" sx={styles.areaLabel}>
              {city && city} {mapLocationLabel}
              {!city && !mapLocationLabel && "Location"}
            </Box>
          </Box>

          {orderType != OrderTypeConstant.assets && areaSize && (
            <Box sx={{ ...styles.unitDropDown, ...styles.marginTopCls }}>
              <EditRoadSharpIcon />
              <Typography variant="subtitle1">
                {displayAreaSizeInSelectedUnits(
                  areaSize,
                  orderType,
                  selectedUnit
                ) || "Surface"}
                {areaSizeUnitNotation(orderType, selectedUnit) || "km2"}
              </Typography>
              <Box>
                <CharacterDropDown
                  orderType={orderType}
                  selectedUnit={selectedUnit}
                  setSelectedUnit={setSelectedUnit}
                />
              </Box>
            </Box>
          )}

          <Box sx={styles.saveBtnBox}>
            {!mapSaved && (
              <AppButton
                label={"Save location"}
                look={lookColors()}
                noIcon
                isDisabled={!area}
                onClick={handleOpen}
                addtionalStyle={styles.saveBtnWidth}
              />
            )}
            {area && mapSaved && (
              <AppButton
                label={"Change my location"}
                onClick={() => {
                  clearPolygon();
                }}
                noIcon
                addtionalStyle={{
                  ...styles.saveBtnWidth,
                }}
                look={"black"}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

MapComponent.propTypes = {
  area: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    })
  ),
  setArea: PropTypes.func,
  areaSize: PropTypes.number,
  setAreaSize: PropTypes.func,
  selectedUnit: PropTypes.string,
  setSelectedUnit: PropTypes.func,
  kmlUrl: PropTypes.string,
  setKmlUrl: PropTypes.func,
  polygon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setPolygon: PropTypes.func,
  mapApi: PropTypes.object,
  setMapApi: PropTypes.func,
  geocoder: PropTypes.string,
  setGeocoder: PropTypes.func,
  defaultLocation: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  defaultZoom: PropTypes.number,
  readonly: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  minHeight: PropTypes.string,
  mapHeight: PropTypes.object,
  createdByPilot: PropTypes.bool,
  activatePilotKml: PropTypes.bool,
  disabled: PropTypes.bool,
  selectedOrderType: PropTypes.string,
  setOrderTypeError: PropTypes.func,
  orderTypeError: PropTypes.string,
  handleOpen: PropTypes.func,
  mapSaved: PropTypes.bool,
  setMapSaved: PropTypes.func,
  setFieldValue: PropTypes.func,
  // setSelectedOrderType: PropTypes.func,
  orderData: PropTypes.object.isRequired,
  setSelectedCountryCode: PropTypes.func,
  setErrorDisplay: PropTypes.func,
  locDataPilot: PropTypes.object,
};

// MapComponent.defaultProps = {
//   setArea: undefined,
//   areaSize: undefined,
//   setAreaSize: undefined,
//   selectedUnit: undefined,
//   setSelectedUnit: undefined,
//   kmlUrl: undefined,
//   setKmlUrl: undefined,
//   polygon: undefined,
//   setPolygon: undefined,
//   mapApi: undefined,
//   setMapApi: undefined,
//   geocoder: undefined,
//   setGeocoder: undefined,
//   area: [],
//   defaultLocation: { lat: 0, lng: 0 },
//   defaultZoom: 0,
//   readonly: false,
//   onSave: undefined,
//   onCancel: undefined,
//   mapHeight: undefined,
//   minHeight: undefined,
//   createdByPilot: false,
//   activatePilotKml: false,
//   disabled: false,
//   selectedOrderType: undefined,
//   setOrderTypeError: undefined,
//   orderTypeError: null,
//   handleOpen: undefined,
//   mapSaved: false,
//   setMapSaved: undefined,
//   setFieldValue: undefined,
//   // setSelectedOrderType: undefined,
//   setSelectedCountryCode: undefined,
//   setErrorDisplay: undefined,
// };

export default MapComponent;
