import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, Checkbox, Typography } from "@mui/material";
import theme from "../../../theme";
import { FaSort } from "react-icons/fa";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CardContent,
  Collapse,
} from "@mui/material";
import AppButton from "../../global/elements/AppButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import GlobalTableValueConvertHandler from "../../global/elements/GlobalTableValueConverthandler";
import { boxShadowStyle } from "../../../lib/styleConstants";
import TablePagination from "../../global/Table/TablePagination";

const style = {
  tableRoot: {
    width: "100%",
    overflow: "hidden",
    margin: "auto",
    //boxShadow: { xs: "none", sm: "0px 0px 4px rgba(0, 0, 0, 0.25)" },
    padding: { xs: 0, sm: "2%" },
    border: "none",
  },
  tableContainer: {
    maxHeight: 620,
    display: { xs: "none", sm: "block", md: "block" },
  },
  mobileTableContainer: {
    display: { sm: "none" },
    padding: "5px",
  },
  bold_info: {
    fontWeight: "500",
    cursor: "pointer",
  },
  checkBoxColor: {
    "&.Mui-checked": {
      color: theme.palette.primary.main,
    },
  },
  label: {
    minWidth: "100px",
    alignItems: "center",
    color: theme.palette.black.coolGrey,
    display: "flex",
  },
  table_cell: {
    minWidth: 110,
    wordWrap: "break-word",
    padding: { md: 5 },
  },
  table_head: {
    display: "none",
  },
  link_row: {
    cursor: "pointer",
  },
  single_field_div: {
    display: "flex",
    padding: "15px",
    borderBottom: `1px solid ${theme.palette.grey.lightWarmGrey}`,
  },
  single_field_heading_div: {
    width: "40%",
  },
  single_field_heading: {
    fontWeight: 500,
    color: theme.palette.black.coolGrey,
    float: "left",
    wordWrap: "break-word",
  },
  single_field_data_div: {
    width: "55%",
  },
  single_field_data: {
    float: "right",
    wordWrap: "anywhere",
    textAlign: "-webkit-right",
  },
  sortIcon: {
    position: "relative",
    marginLeft: "0.5rem",
    cursor: "pointer",
  },
  cursorChange: {
    cursor: "pointer",
  },
  labelStyles: {
    color: theme.palette.black.darkSeaBlack,
    padding: "5px",
  },
  collapseCard: {
    margin: 1,
    display: "flex",
    border: "none",
    boxShadow: "none",
  },
  subcolInfo: { paddingLeft: "30%" },
  tableCellBorder: {
    borderBottom: "none",
  },
  outerContainer: {
    width: "100%",
    overflow: "hidden",
    margin: "auto",
    borderRadius: "15px",
    // padding: { xs: 0, sm: "2%" },
    border: "none",
  },
  tableCellCls: { overflowWrap: "anywhere", lineHeight: "1" },
};

const GlobalTable = ({
  allData = undefined,
  limitHandler = undefined,
  paginationBtnClick = undefined,
  valueConvertHandler = undefined,
  goToPageFcn = undefined,
  limit = 10,
  currentPage = 1,
  lastPage = null,
  columns = [],
  sortByHandler = null,
  buttonClick = undefined,
  collapseFlag = false,
  subcolumns = [],
  checkbox = false,
  selected = [],
  handleSingleSelect = undefined,
  onSelectAllClick = undefined,
}) => {
  const [openCollapse, setOpenCollapse] = useState(null);

  const handleCollapse = (index) => {
    if (openCollapse === index) {
      setOpenCollapse(null);
    } else {
      setOpenCollapse(index);
    }
  };

  const GetGlobalTableValueHandler = ({ column, row }) => {
    GetGlobalTableValueHandler.propTypes = {
      column: PropTypes.object.isRequired,
      row: PropTypes.object.isRequired,
    };
    return (
      <>
        {column.custom ? (
          valueConvertHandler(column.field, row)
        ) : column.field === "button" ? (
          <AppButton
            look="black"
            onClick={() => buttonClick(row)}
            label={column.label}
          />
        ) : (
          <GlobalTableValueConvertHandler
            column={column.id}
            field={column.field}
            value={row}
            statusBadge={column.statusBadge}
            bold={column.bold}
            mixedColumns={column.mixedColumns ? column.mixedColumns : []}
          />
        )}
      </>
    );
  };

  const isSelected = (row) => {
    return selected?.filter((data) => data.id === row.id).length > 0 || false;
  };

  return (
    <Box sx={{ ...style.tableRoot, ...boxShadowStyle }}>
      <TableContainer sx={style.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead id={style.table_head}>
            <TableRow>
              {checkbox && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={
                      allData?.length > 0 &&
                      selected?.length === allData?.length
                    }
                    onChange={onSelectAllClick}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                  />
                </TableCell>
              )}
              {columns?.map((column, i) => (
                <TableCell
                  key={i || column.id}
                  align={column.align}
                  style={
                    column.sort
                      ? { ...style.cursorChange, ...style.table_cell }
                      : style.table_cell
                  }
                >
                  <Typography
                    variant="subtitle1Med"
                    sx={style.label}
                    onClick={() => {
                      if (column.sort) {
                        sortByHandler(column.id);
                      }
                    }}
                  >
                    {column.label}
                    {column.sort && <FaSort style={style.sortIcon} />}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {allData?.map((row, index) => {
              const isItemSelected = isSelected(row);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <>
                  <TableRow
                    key={index}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    sx={{
                      "& > *": {
                        height: 120,
                        paddingTop: 3,
                        paddingBottom: 3,
                      },
                    }}
                    onClick={() => goToPageFcn && goToPageFcn(row)}
                    style={style.link_row}
                  >
                    {checkbox && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleSingleSelect(event, row)}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          sx={style.checkBoxColor}
                          disabled={row.disabled || false}
                        />
                      </TableCell>
                    )}

                    {columns.map((column) => {
                      // const value = row[column.id] || "n/a";
                      return (
                        <>
                          <TableCell
                            sx={style.tableCellCls}
                            key={column.id}
                            align={column.align}
                          >
                            <GetGlobalTableValueHandler
                              column={column}
                              row={row}
                            />
                          </TableCell>
                        </>
                      );
                    })}
                    {collapseFlag && (
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => handleCollapse(index)}
                        >
                          {openCollapse === index ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>

                  <TableRow key={index}>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        in={index === openCollapse}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box sx={{ margin: 1 }}>
                          <Table size="small" aria-label="purchases">
                            <TableHead>
                              <TableRow>
                                {subcolumns?.map((column, i) => {
                                  return (
                                    <>
                                      <TableCell
                                        key={i}
                                        sx={style.tableCellBorder}
                                      >
                                        <Typography
                                          variant="body1"
                                          sx={style.labelStyles}
                                        >
                                          {column.label}
                                        </Typography>
                                      </TableCell>
                                    </>
                                  );
                                })}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {subcolumns?.map((item, i) => {
                                return (
                                  <>
                                    <TableCell sx={style.tableCellBorder}>
                                      <GetGlobalTableValueHandler
                                        key={i}
                                        column={item}
                                        row={row}
                                      />
                                    </TableCell>
                                  </>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={style.mobileTableContainer}>
        {checkbox && (
          <Box>
            <Checkbox
              color="primary"
              checked={
                allData?.length > 0 && selected?.length === allData?.length
              }
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
            <Typography variant="h6" style={style.label}>
              {"Select All"}
            </Typography>
          </Box>
        )}
        {allData?.map((row, i) => {
          const isItemSelected = isSelected(row);
          const labelId = `enhanced-table-checkbox-${i}`;
          return (
            <Box
              sx={{
                margin: "15px 0px",
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
              }}
              onClick={() => goToPageFcn && goToPageFcn(row)}
              key={i}
            >
              <CardContent>
                {columns.map((column, key) => {
                  return (
                    <Box sx={style.single_field_div} key={key}>
                      <Box
                        sx={{
                          ...style.single_field_heading_div,
                        }}
                      >
                        <Typography
                          variant="h6"
                          style={{
                            ...style.single_field_heading,
                            ...style.label,
                          }}
                        >
                          {column.label}
                        </Typography>
                      </Box>
                      <Box sx={style.single_field_data_div}>
                        <Box style={style.single_field_data}>
                          <GetGlobalTableValueHandler
                            column={column}
                            row={row}
                          />
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
                {checkbox && (
                  <Box sx={style.single_field_div}>
                    <Box
                      sx={{
                        ...style.single_field_heading_div,
                      }}
                    >
                      <Typography
                        variant="h6"
                        style={{
                          ...style.single_field_heading,
                          ...style.label,
                        }}
                      >
                        {"Select"}
                      </Typography>
                    </Box>
                    <Box sx={style.single_field_data_div}>
                      <Box style={style.single_field_data}>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleSingleSelect(event, row)}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          sx={style.checkBoxColor}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
                {collapseFlag && (
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => handleCollapse(i)}
                  >
                    {openCollapse === i ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                )}
              </CardContent>

              <CardContent key={i}>
                <Collapse in={i === openCollapse} timeout="auto" unmountOnExit>
                  <Card sx={style.collapseCard}>
                    <Box>
                      {subcolumns?.map((column, i) => {
                        return (
                          <Box key={i}>
                            <Typography
                              variant="h6"
                              sx={{ ...style.labelStyles, ...style.label }}
                            >
                              {column.label}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>

                    <Box sx={style.subcolInfo}>
                      {subcolumns?.map((item, i) => {
                        return (
                          <Box key={i}>
                            <Typography
                              variant="h6"
                              sx={{ ...style.labelStyles, ...style.label }}
                            >
                              <GetGlobalTableValueHandler
                                key={i}
                                column={item}
                                row={row}
                              />
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </Card>
                </Collapse>
              </CardContent>
            </Box>
          );
        })}
      </Box>
      <TablePagination
        currentPage={currentPage}
        lastPage={lastPage}
        onClickNext={() => {
          paginationBtnClick("next");
          if (collapseFlag) {
            setOpenCollapse(null);
          }
        }}
        onClickPrevious={() => {
          paginationBtnClick("prev");
          if (collapseFlag) {
            setOpenCollapse(null);
          }
        }}
        limit={limit}
        limitOnChange={(e) => limitHandler(e.target.value)}
      />
    </Box>
  );
};

GlobalTable.propTypes = {
  allData: PropTypes.array,
  limitHandler: PropTypes.func.isRequired,
  limit: PropTypes.number,
  paginationBtnClick: PropTypes.func,
  currentPage: PropTypes.number,
  lastPage: PropTypes.number,
  columns: PropTypes.array,
  valueConvertHandler: PropTypes.func,
  goToPageFcn: PropTypes.func,
  sortByHandler: PropTypes.func,
  buttonClick: PropTypes.func,
  collapseFlag: PropTypes.bool,
  subcolumns: PropTypes.array,
  checkbox: PropTypes.bool,
  selected: PropTypes.array,
  handleSingleSelect: PropTypes.func,
  onSelectAllClick: PropTypes.func,
};

export default GlobalTable;
