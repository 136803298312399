import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  getPilotsCountFromCountry,
  inviteAllPilotsOfCountry,
} from "../../services/missions";
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
} from "@mui/material";
import AppButton from "../global/elements/AppButton";
import { shadowBox, textFieldStyle } from "../../lib/styleConstants";
import React, { useEffect, useState } from "react";

const styles = {
  buttonContainer: {
    padding: "0 20px 1em 20px",
    alignSelf: { xs: "unset", sm: "self-end" },
  },
  dialogContent: { padding: "0 20px 20px 20px" },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2, color: (theme) => theme.palette.grey[500] }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function CountryPopup({
  CountryPopupOpen = undefined,
  handlePopupClose = undefined,
  mission = undefined,
  missionID = undefined,
  countries = undefined,
  refreshMission = undefined,
}) {
  const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [PilotCount, setPliotCount] = useState(0);

  useEffect(() => {
    async function fetchData() {
      let count = await getPilotsCountFromCountry(value);
      setPliotCount(count);
      return count;
    }
    fetchData();
  }, [value]);
  async function fetchData() {
    let res = await inviteAllPilotsOfCountry(mission, missionID, value);

    if (res) {
      handlePopupClose();
      refreshMission();
      return res;
    }
  }
  return (
    <Box>
      <BootstrapDialog
        onClose={handlePopupClose}
        aria-labelledby="customized-dialog-title"
        open={CountryPopupOpen}
        fullWidth={true}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handlePopupClose}
        >
          Invite all pilots from country
        </BootstrapDialogTitle>
        <Box sx={shadowBox}></Box>
        <Box sx={styles.dialogContent}>
          <Typography gutterBottom variant="body1">
            We could not identify the country. Please select country for this
            mission.
          </Typography>
          <br />
          <Typography gutterBottom>
            <Autocomplete
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              options={countries}
              renderInput={(params) => (
                <TextField {...params} label="Countries" />
              )}
              sx={{
                ...styles.inputField,
                ...styles.textFieldWidth,
                width: { xs: "100%", sm: 300 },
                ...textFieldStyle,
              }}
            />
            <br />
          </Typography>
          <Typography gutterBottom variant="body2">
            {inputValue
              ? `There are ${PilotCount} pilots available in ${value}.`
              : ""}
            {PilotCount > 0 ? " Are you sure to invite all of them?" : ""}
          </Typography>
        </Box>
        <Box sx={styles.buttonContainer}>
          <AppButton
            look={PilotCount > 0 ? "green" : "greenDisabled"}
            autoFocus
            onClick={() => PilotCount && fetchData()}
            isDisabled={PilotCount > 0 ? false : true}
            label="Invite All"
          />
        </Box>
      </BootstrapDialog>
    </Box>
  );
}

CountryPopup.propTypes = {
  mission: PropTypes.object,
  missionID: PropTypes.string,
  handlePopupClose: PropTypes.func,
  refreshMission: PropTypes.func,
  countries: PropTypes.array,
  CountryPopupOpen: PropTypes.func,
};
// CountryPopup.defaultProps = {
//   mission: undefined,
//   missionID: undefined,
//   handlePopupClose: undefined,
//   refreshMission: undefined,
//   countries: undefined,
//   CountryPopupOpen: undefined,
// };

export default CountryPopup;
