import React from "react";
import { Box, Typography, styled } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import theme from "../../theme";
import PropTypes from "prop-types";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  width: "40%",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
}));
const styles = {
  progressContainer: {
    columnGap: "1em",
    textAlignLast: { xs: "center" },
    alignSelf: "center",
    marginTop: { xs: "20px", lg: "unset" },
    width: { xs: "100%" },
    justifyContent: "flex-start",
    marginLeft: { xs: "unset", sm: "10px", md: "20px" },
  },
  linearProgress: {
    width: "100%",
    alignSelf: "center",
    "& .MuiLinearProgress-bar": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  uploadedText: {
    alignSelf: { sm: "end" },
    textAlignLast: { xs: "center" },
  },
  progressText: { alignSelf: "end" },
  borderDiv: { display: "flex", columnGap: "10px" },
  boldText: {
    fontWeight: "500 !important",
  },
};

const ProgressBar = ({
  origFileName = "",
  progress = 0,
  showTextBelow = false,
  textBelow = "",
}) => {
  return (
    <Box
      sx={{
        ...styles.progressContainer,
        display: !showTextBelow ? { xs: "block", sm: "flex" } : { xs: "block" },
      }}
    >
      {origFileName && !showTextBelow && (
        <Typography variant="body2" sx={styles.uploadedText}>
          {origFileName} Uploaded{" "}
        </Typography>
      )}
      {((origFileName && progress && progress > 0) || progress) && (
        <Box
          sx={{
            ...styles.progressText,
            width: !showTextBelow
              ? { xs: "94%", sm: "200px" }
              : { xs: "100%", sm: "86%" },
            display: showTextBelow ? "inline-grid" : "unset",
          }}
        >
          <Box sx={styles.borderDiv}>
            <BorderLinearProgress
              variant="determinate"
              value={progress}
              sx={styles.linearProgress}
            />
            <Typography variant="body1">{`${
              (origFileName && progress > 0 && progress) || progress
            }%`}</Typography>
          </Box>
        </Box>
      )}
      {showTextBelow && textBelow && (
        <Typography variant="body1" sx={styles.boldText}>
          {textBelow}
        </Typography>
      )}
    </Box>
  );
};

ProgressBar.propTypes = {
  origFileName: PropTypes.string,
  progress: PropTypes.number,
  showTextBelow: PropTypes.bool,
  textBelow: PropTypes.string,
};
// ProgressBar.defaultProps = {
//   origFileName: "",
//   progress: 0,
//   showTextBelow: false,
//   textBelow: "",
// };

export default ProgressBar;
