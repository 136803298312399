import React from "react";
import { Box, Typography } from "@mui/material";
import theme from "../../theme";
import PropTypes from "prop-types";
import ClientProfileProgressBar from "./elements/ClientProfileProgressBar";
import OnboardingHeader from "./OnboardingHeader";
import CardButton from "./CardButton";

const styles = {
  boxDimension: {
    height: { xs: "215px", md: "215px", lg: "245px" },
    background: theme.palette.secondary.lavender,
  },
  barPadding: { paddingTop: { xs: "8%", sm: "1%", md: "4%" } },
  btnBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardPadding: { padding: "10px" },
  text: {
    color: theme.palette.grey.white,
    paddingTop: { xs: "5%", md: 0 },
    width: "80%",
  },
  footer: {
    color: theme.palette.grey.white,
    height: "23px",
    paddingTop: { xs: "10%", sm: "4%", lg: "5%" },
  },
};

const OnboardingSteps = ({
  footerText = undefined,
  skipFcn = undefined,
  stepperFlag = false,
  showSkip = false,
  progress = undefined,
  cardText = undefined,
  btnText = undefined,
  buttonlabel = undefined,
  redirectUrl = undefined,
  count = undefined,
}) => {
  return (
    <>
      <Box sx={styles.boxDimension}>
        <Box sx={styles.cardPadding}>
          <OnboardingHeader
            skipFcn={skipFcn}
            count={count}
            showSkip={showSkip}
          />
          <Typography variant="h2" sx={styles.text}>
            {cardText}
          </Typography>{" "}
          {stepperFlag ? (
            <Box sx={styles.barPadding}>
              <ClientProfileProgressBar
                // userData={userData}
                progress={progress}
                // setProgress={setProgress}
              />
            </Box>
          ) : (
            <Typography variant="h5" sx={styles.footer}>
              {footerText}
            </Typography>
          )}
        </Box>
      </Box>
      <Box sx={styles.btnBox}>
        <CardButton
          btnText={btnText}
          buttonlabel={buttonlabel}
          redirectUrl={redirectUrl}
        />
      </Box>
    </>
  );
};

OnboardingSteps.propTypes = {
  cardText: PropTypes.string.isRequired,
  footerText: PropTypes.string,
  stepperFlag: PropTypes.bool,
  skipFcn: PropTypes.func,
  btnText: PropTypes.string.isRequired,
  buttonlabel: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  showSkip: PropTypes.bool,
  progress: PropTypes.number,
  // tourFlag: PropTypes.bool,
};

// OnboardingSteps.defaultProps = {
//   footerText: undefined,
//   skipFcn: undefined,
//   stepperFlag: false,
//   showSkip: false,
//   progress: undefined,
//   // tourFlag: false,
// };

export default OnboardingSteps;
