import { FormControl, Select, MenuItem } from "@mui/material";
import React from "react";
import { OrderTypeConstant, areaUnits } from "../../../lib/constants";
import theme from "../../../theme";
import PropTypes from "prop-types";

const styles = {
  toolButton: {
    width: "156px",
    height: "50px",
    textTransform: "none",
    borderRadius: 0,
  },
  changeUnitCls: {
    border: `1px solid ${theme.palette.black.darkSeaBlack}`,
    borderRadius: 0,

    "&.MuiInput-underline:before": {
      border: "none !important",
    },

    // "&:hover": {
    //   border: "none !important",
    //   outline: "none !important",
    // },
    ".css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus": {
      backgroundColor: `${theme.palette.grey.white} !important`,
    },
    "&.MuiInput-underline:after": {
      border: "none !important",
      outline: "none !important",
      backgroundColor: `${theme.palette.grey.white} !important`,
    },

    paddingLeft: "10px",
    outline: "none",
  },
};

function CharacterDropDown({
  orderType = "area",
  selectedUnit = undefined,
  setSelectedUnit = undefined,
}) {
  return (
    <>
      {orderType && orderType != OrderTypeConstant.area ? null : (
        <FormControl variant="standard" sx={styles.toolButton}>
          <Select
            label="unit"
            onChange={function (e) {
              setSelectedUnit(e.target.value);
            }}
            sx={styles.changeUnitCls}
            title="Enter a location"
            value={selectedUnit}
          >
            <MenuItem value={areaUnits.sqkm}>{areaUnits.sqkm}</MenuItem>
            <MenuItem value={areaUnits.sqm}>{areaUnits.sqm}</MenuItem>
            <MenuItem value={areaUnits.hectare}>{areaUnits.hectare}</MenuItem>
            <MenuItem value={areaUnits.sqmiles}>{areaUnits.sqmiles}</MenuItem>
          </Select>
        </FormControl>
      )}
    </>
  );
}

CharacterDropDown.propTypes = {
  orderType: PropTypes.string,
  selectedUnit: PropTypes.string.isRequired,
  setSelectedUnit: PropTypes.func.isRequired,
};

// CharacterDropDown.defaultProps = {
//   orderType: "area",
// };

export default CharacterDropDown;
