import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import CheckIcon from "@mui/icons-material/Check";
import {
  flexColumn,
  greyHeadingColorCls,
} from "../../../../lib/styleConstants";
import theme from "../../../../theme";
import { getCurrentDateTimeWithFormat } from "../../../../lib/helpers";
import { Link } from "gatsby";
import { orderCollectionData, userRoles } from "../../../../lib/constants";
import { getUser } from "../../../../services/auth";

const styles = {
  checkMarkColorCls: {
    backgroundColor: theme.palette.primary.main,
    fontSize: "2.5rem",
    borderRadius: "50%",
  },
  name: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontWeight: 500,
    "&:hover": {
      color: `${theme.palette.black.darkSeaBlack} !important`,
    },
  },
};

const ThankYouComp = ({ order = {}, orderCollection = "" }) => {
  const currentUser = getUser("user");
  const currentDateTime = getCurrentDateTimeWithFormat("fff");
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography variant="h4" sx={greyHeadingColorCls}>
            {orderCollection === orderCollectionData.missions
              ? "Your order was placed successfully"
              : "Your order has been reserved"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <CheckIcon style={styles.checkMarkColorCls} />
        </Grid>
        {orderCollection === orderCollectionData.missions && (
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Check your email for your order confirmation
            </Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <Box sx={flexColumn}>
            <Typography variant="subtitle1">
              {orderCollection === orderCollectionData.missions
                ? "Order : "
                : "Reservation : "}
              <Link
                style={styles.name}
                to={
                  orderCollection === orderCollectionData.missions
                    ? currentUser?.role === userRoles.admin
                      ? `/app/missions/${order.id}`
                      : `/app/client/orderDetails/${order.id}`
                    : currentUser?.role === userRoles.admin
                    ? `/app/admin/projects/${order.id}`
                    : `/app/client/projects/${order.id}`
                }
              >
                {orderCollection === orderCollectionData.missions
                  ? order.missionName
                  : order?.projectName}
              </Link>
            </Typography>
            <Typography variant="subtitle1">
              {orderCollection === orderCollectionData.missions
                ? "Order date : "
                : "Reservation date : "}
              {currentDateTime}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1">
            {orderCollection === orderCollectionData.missions
              ? ` We have sent the order confirmation details to :
            ${order?.clientEmail}`
              : `We will sent the order confirmation details to :
            ${order?.clientEmail} once you confirm the order`}
          </Typography>
        </Grid>
        {orderCollection !== "missions" && (
          <Grid item xs={12}>
            <Typography variant="body1">
              After you confirm this order, you will receive a quote within 24
              hours.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

ThankYouComp.propTypes = {
  order: PropTypes.object,
  orderCollection: PropTypes.string,
};

// ThankYouComp.defaultProps = {
//   order: {},
//   orderCollection: "",
// };

export default ThankYouComp;
