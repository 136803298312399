import { Box, Typography } from "@mui/material";
import React from "react";
// import teamImg from "../../../../assets/images/Team group.png";
import AppButton from "../../../global/elements/AppButton";
import AddIcon from "@mui/icons-material/Add";
import theme from "../../../../theme";
import PropTypes from "prop-types";

const styles = {
  teamIcon: {
    background: theme.palette.adminBlue.lightBlue,
    padding: "20px",
  },
  noTeamBox: {
    width: { xs: "100%", lg: "70%" },
    textAlign: "center",
    margin: "auto",
  },
};

const NoTeam = ({ openPopup = undefined }) => {
  return (
    <Box sx={styles.noTeamBox}>
      {/* <Box component={"img"} src={teamImg} sx={styles.teamIcon} mt={2} /> */}
      <Typography variant="h3" mt={6}>
        Start your enterprise account by adding your first team member
      </Typography>
      <Typography variant="h6" mt={4} mb={3}>
        {`Once you add members, you can then use your enterprise account to tasking drones. All the members will be able to cooperate on your data orders`}
      </Typography>
      <AppButton
        onClick={() => openPopup()}
        label="Add members"
        look="green"
        customIcon={<AddIcon />}
      />
    </Box>
  );
};

NoTeam.propTypes = {
  openPopup: PropTypes.func.isRequired,
};

//   NoTeam.defaultProps = {
//     openPopup: undefined,
//   };

export default NoTeam;
