import { Field } from "formik";
import { TextField } from "formik-mui";
import { Box } from "@mui/material";
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  formInputField,
  StyledAutocomplete,
} from "../../../lib/styleConstants";
import theme from "../../../theme";

const styles = {
  basicStyle: {
    ...formInputField,
  },
  redColorCls: {
    color: theme.palette.status.errorDark,
  },
};
const TextFieldInput = ({
  autoComplete = false,
  label = undefined,
  type = undefined,
  values = {},
  customOnChangeHandler = false,
  customOnChangeHandlerFcn = undefined,
  required = false,
  rows = 1,
  multiline = false,
  disabled = false,
  placeholder = "",
  variant = "outlined",
  autoCompleteArray = undefined,
  multiple = false,
  helperText = undefined,
  additionalStyle = {},
  startAdornment = undefined,
  endAdornment = undefined,
  setFieldValue = undefined,
  name = undefined,
}) => {
  const onChangeHandler = (e, value) => {
    if (customOnChangeHandler) {
      customOnChangeHandlerFcn(e, value);
    } else {
      if (autoComplete) {
        setFieldValue(name, value);
      } else {
        setFieldValue(name, e.target.value);
      }
    }
  };
  return autoComplete ? (
    // <Box sx={formInputField}>
    <StyledAutocomplete
      onChange={(e, value) => onChangeHandler(e, value)}
      multiple={multiple}
      options={autoCompleteArray}
      noPadding
      noBoxShadow
      disabled={disabled}
      renderInput={(params) => (
        <Field
          {...params}
          type={type}
          name={name}
          value={values[name]}
          defaultValue={values[name]}
          label={
            label && (
              <Fragment>
                {label}
                {required && <span style={styles.redColorCls}> *</span>}
              </Fragment>
            )
          }
          component={TextField}
          // onChange={(e, value) => onChangeHandler(e, value)}
          variant="outlined"
          style={{ width: "100%" }}
          multiline={multiline}
        />
      )}
    />
  ) : (
    // </Box>
    <Box sx={formInputField}>
      <Field
        type={type}
        name={name}
        value={values[name]}
        defaultValue={values[name]}
        label={
          label && (
            <Fragment>
              {label}
              {required && <span style={styles.redColorCls}> *</span>}
            </Fragment>
          )
        }
        component={TextField}
        onChange={(e, value) => onChangeHandler(e, value)}
        variant={variant}
        style={{ width: "100%", ...additionalStyle, textAlign: "center" }}
        disabled={disabled}
        placeholder={placeholder}
        helperText={helperText}
        rows={rows}
        multiline={multiline}
        InputProps={
          startAdornment ? startAdornment : endAdornment && endAdornment
        }
      />
    </Box>
  );
};

TextFieldInput.propTypes = {
  type: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  customOnChangeHandler: PropTypes.bool,
  customOnChangeHandlerFcn: PropTypes.func,
  required: PropTypes.bool,
  autoComplete: PropTypes.bool,
  autoCompleteArray: PropTypes.array,
  multiline: PropTypes.bool,
  multiple: PropTypes.bool,
  helperText: PropTypes.string,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  additionalStyle: PropTypes.object,
  startAdornment: PropTypes.object,
  endAdornment: PropTypes.object,
};

// TextFieldInput.defaultProps = {
//   //setFieldValue: undefined,
//   autoComplete: false,
//   label: undefined,
//   type: undefined,
//   values: {},
//   customOnChangeHandler: false,
//   customOnChangeHandlerFcn: undefined,
//   required: false,
//   rows: 1,
//   multiline: false,
//   disabled: false,
//   placeholder: "",
//   variant: "outlined",
//   autoCompleteArray: undefined,
//   multiple: false,
//   helperText: undefined,
//   additionalStyle: {},
//   startAdornment: undefined,
//   endAdornment: undefined,
// };

export default TextFieldInput;
