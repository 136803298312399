import React from "react";
import SingleContract from "./SingleContract";
import PropTypes from "prop-types";

const AdminSingleContract = ({ id = undefined }) => {
  return <SingleContract id={id} />;
};

AdminSingleContract.propTypes = {
  id: PropTypes.string,
};

export default AdminSingleContract;
