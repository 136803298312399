import { Link } from "gatsby";
import React from "react";
import SingleLogo from "src/assets/logos/globhe_logo/Globhe_symbol_2022_black.png";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { userRoles } from "../../../lib/constants";

const styles = {
  logo: {
    width: { xs: "20px", lg: "30px" },
    marginLeft: "15px",
  },
};

const GlohbeMenuIcon = ({ role = undefined }) => {
  return (
    <Link to={role == userRoles.client ? "/app/client/my-account" : "/app"}>
      <Box component="img" src={SingleLogo} alt="Logo" sx={styles.logo} />
    </Link>
  );
};

GlohbeMenuIcon.propTypes = {
  role: PropTypes.string,
};

// GlohbeMenuIcon.defaultProps = {
//   role: undefined,
// };

export default GlohbeMenuIcon;
