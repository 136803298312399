import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import SwitchSelect from "../../global/elements/SwitchSelect";
import PropTypes from "prop-types";
import { boxShadowStyle } from "../../../lib/styleConstants";
import {
  splitInvoice,
  getBreakInvoiceStatus,
  getProjectBreakInvoiceStatus,
  splitProjectInvoice,
} from "../../../services/invoices";
import ErrorMessage from "src/components/global/errorMessage";
import firebase from "src/firebase";
const styles = {
  root: {
    marginBottom: "20px",
    padding: "20px",
  },
  title: {
    marginBottom: "20px",
  },
  switchRow: {
    marginTop: "10px",
  },
};

const AdminInvoiceSplitSelection = ({
  missionID = undefined,
  refreshMission = undefined,
  isProject = false,
}) => {
  const [checked, setChecked] = useState(false);
  const [hasError, showErrorMessage] = useState(false);
  const [errorMessageLabel, setErrorMessageLabel] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!firebase) return;
    if (isProject) {
      getProjectBreakInvoiceStatus(firebase, missionID).then((status) => {
        setChecked(status);
      });
    } else {
      getBreakInvoiceStatus(firebase, missionID).then((status) => {
        setChecked(status);
      });
    }
  }, [isProject, missionID]);

  const switchSplitInvoice = (firebase, missionID, checked) => {
    const status = checked ? true : false;
    if (isProject) {
      splitProjectInvoice(firebase, missionID, status)
        .then(() => {
          refreshMission();
        })
        .catch((error) => {
          console.error("Error updating invoice status:", error);
          setErrorMessageLabel("Something went wrong");
          setErrorMessage("It might be that this invoice does not exist");
        });
    } else {
      splitInvoice(firebase, missionID, status)
        .then(() => {
          refreshMission();
        })
        .catch((error) => {
          console.error("Error updating invoice status:", error);
          setErrorMessageLabel("Something went wrong");
          setErrorMessage("It might be that this invoice does not exist");
        });
    }
  };

  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
    switchSplitInvoice(firebase, missionID, event.target.checked);
  };

  const closeTriggered = () => {
    setTimeout(function () {
      showErrorMessage(false);
    }, 300);
  };

  return (
    <Box sx={{ ...styles.root, ...boxShadowStyle }}>
      <Typography sx={styles.title} variant="h5">
        Split invoice
      </Typography>
      <Typography variant="body2">
        If you wish that the client will pay 50% upon accepting the quote and
        50% after delivery, then switch to Yes. You can create the quote with
        the full amount as usual and the invoice will simply calculate 50% of
        that quote.
      </Typography>
      <Box sx={styles.switchRow}>
        <SwitchSelect handleChange={handleSwitchChange} checked={checked} />
      </Box>
      {hasError ? (
        <ErrorMessage
          show={true}
          errorMessageLabel={errorMessageLabel}
          errorMessage={errorMessage}
          closeTriggered={closeTriggered}
        />
      ) : null}
    </Box>
  );
};

AdminInvoiceSplitSelection.propTypes = {
  handleChange: PropTypes.func.isRequired,
  refreshMission: PropTypes.func.isRequired,
  initialValues: PropTypes.arrayOf(
    PropTypes.shape({
      initialValues: PropTypes.string,
    })
  ).isRequired,
  missionID: PropTypes.string.isRequired,
  isProject: PropTypes.bool,
};
// AdminInvoiceSplitSelection.defaultProps = {
//   isProject: false,
// };
export default AdminInvoiceSplitSelection;
