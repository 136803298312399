import React, { useState } from "react";
import theme from "../../../theme";
import Dropzone from "react-dropzone";
import { Box, Typography, LinearProgress } from "@mui/material";
import PropTypes from "prop-types";
import LoadingEllipsis from "src/components/loading-ellipsis";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { preventEnterSubmit } from "src/lib/form-helpers";
import firebase from "src/firebase";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  createInspiration,
  singleInspirationUpdate,
} from "../../../services/news-inspiration";
import { getUser } from "../../../services/auth";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { navigate } from "gatsby";
import { singleInspirationDelete } from "../../../services/news-inspiration";
import AppButton from "../../global/elements/AppButton";

const FormComponent = ({
  inspiration = {},
  inspirationId = undefined,
  update = undefined,
}) => {
  const myStyle = {
    tableContainer: {
      display: "flex",
      flexFlow: "wrap",
      justifyContent: "flex-start",
    },
    form_container: {
      width: "100%",
      borderRadius: theme.spacing(1),
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
      marginTop: theme.spacing(4),
      padding: theme.spacing(3, 4),
    },
    input_container: {
      color: theme.palette.grey.warmGrey,
    },
    label_text: {
      marginBottom: theme.spacing(0.5),
    },
    input_box: {
      width: "100%",
      margin: "5px",
      boxShadow: `0px 0px 4px ${theme.palette.black.coolGrey}`,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(0.5, 2),
      color: theme.palette.grey.warmGrey,
      fontSize: theme.spacing(1.6),
      fontWeight: 450,
    },
    button: {
      float: "right",
    },

    upload_btn: {
      padding: theme.spacing(0.5, 1),
      border: `1px solid ${theme.palette.primary.main}`,
      width: theme.spacing(11),
      color: theme.palette.primary.main,
      fontWeight: 410,
      borderRadius: theme.spacing(1.5),
    },
    error: {
      color: "red",
    },
    imageStyle: {
      width: theme.spacing(25),
      height: theme.spacing(13),
      borderRadius: theme.spacing(1),
      marginTop: theme.spacing(0.5),
    },
    success: {
      border: `1px solid ${theme.palette.status.successLight}`,
      padding: theme.spacing(1, 2),
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(1),
      textAlign: "center",
    },
    fail: {
      border: `1px solid ${theme.palette.status.warningDark}`,
      padding: theme.spacing(1, 2),
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(1),
      textAlign: "center",
    },
  };

  const [profileImageLoading, setProfileImageLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState(inspiration?.blogImage);
  const [progresspercent, setProgresspercent] = useState(0);
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [imgError, setImgError] = useState(false);
  const [statusMessage, setStatusMessage] = useState({
    status: false,
    message: "",
    type: "Success",
  });

  const formInitialValues = {
    title: inspiration?.title || "",
    description: inspiration?.description || "",
    blogImage: inspiration?.blogImage || "",
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
    blogImage: Yup.string(),
  });

  const [btn_loading, setBtn_loading] = useState(false);

  const submitHandler = async (values, { setSubmitting }) => {
    setLoading(true);
    if (!imgUrl) {
      setSubmitting(false);
      setImgError(true);
      setLoading(false);
      return;
    } else {
      let userId = getUser("user")?.userID || "";
      let data = { ...values, blogImage: imgUrl };
      if (!update) {
        let status = await createInspiration(data, userId);
        if (status.status == "Success") {
          setStatusMessage({
            status: true,
            message: "Inspiration Created Successfully!",
            type: "Success",
          });
        }
      } else {
        let status = await singleInspirationUpdate(data, inspirationId);
        if (status.status == "Success") {
          setStatusMessage({
            status: true,
            message: "Inspiration Updated Successfully!",
            type: "Success",
          });
        }
      }
    }
    setLoading(false);
  };

  const handleSubmit = (files) => {
    setProfileImageLoading(true);
    const file = files[0];
    if (!file) return;
    const storageRef = firebase
      .storage()
      .ref()
      .child(`inspirationBlogImages/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImgUrl(downloadURL);
          setFileName(file.name);
          setProfileImageLoading(false);
        });
      }
    );
  };
  const inspirationDeleteHandler = async () => {
    setBtn_loading(true);
    const status = await singleInspirationDelete(inspirationId);
    if (status.status == "Success") {
      setBtn_loading(false);
      navigate("/app/admin/inspirations");
    }
  };

  return (
    <React.Fragment>
      {statusMessage.status && (
        <React.Fragment>
          {
            <div
              style={
                statusMessage.type == "Success" ? myStyle.success : myStyle.fail
              }
            >
              {statusMessage?.type == "Success" ? (
                <DoneAllIcon
                  style={{ color: theme.palette.status.successLight }}
                />
              ) : (
                <WarningAmberIcon
                  style={{ color: theme.palette.status.warningDark }}
                />
              )}
              <Typography
                style={{
                  color:
                    statusMessage.type == "Success"
                      ? theme.palette.status.successLight
                      : theme.palette.status.warningDark,
                }}
                variant="h5"
              >
                {" "}
                {statusMessage.message}
              </Typography>
            </div>
          }
        </React.Fragment>
      )}
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {({ isSubmitting, errors, values, setFieldValue, submitCount }) => {
          return (
            <Form onKeyDown={preventEnterSubmit} id="inspirationForm">
              <div style={myStyle.input_container}>
                <label style={myStyle.label_text}>
                  <Typography variant="h5">TITLE</Typography>
                  <Field
                    style={myStyle.input_box}
                    type="text"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    name="title"
                    component={TextField}
                  />
                </label>
              </div>
              <div style={myStyle.input_container}>
                <label style={myStyle.label_text}>
                  <Typography variant="h5">BODY TEXT</Typography>
                  <Field
                    style={myStyle.input_box}
                    multiline
                    type="text"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    minRows={8}
                    name="description"
                    component={TextField}
                  />
                </label>
              </div>
              <div style={{ ...myStyle.input_container, width: "150px" }}>
                <label style={myStyle.label_text}>
                  <Typography variant="h5">
                    {fileName || "BANNER IMAGE"}
                  </Typography>
                  <img src={imgUrl} style={myStyle.imageStyle} />
                  <Dropzone
                    accept={["image/jpeg", ".pdf", "application/pdf"]}
                    onDrop={(acceptedFiles) => {
                      handleSubmit(acceptedFiles);
                    }}
                    name="imgURL"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} name="imgURL" />
                          <p style={myStyle.upload_btn}>
                            {!profileImageLoading ? (
                              "Upload Image"
                            ) : (
                              <LinearProgress
                                style={myStyle.loadingIndicator}
                              />
                            )}
                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {imgError && (
                    <span style={myStyle.error}>Image is required</span>
                  )}
                </label>
              </div>
              <Box>
                <AppButton
                  addtionalStyle={myStyle.button}
                  label={
                    loading ? <LoadingEllipsis /> : update ? "Update" : "Submit"
                  }
                  form="inspirationForm"
                  type="submit"
                  look="black"
                />
                {update && (
                  <AppButton
                    addtionalStyle={myStyle.button}
                    label={btn_loading ? <LoadingEllipsis /> : "Delete"}
                    onClick={inspirationDeleteHandler}
                    marginRight
                    look="negative"
                  />
                )}
                <AppButton
                  addtionalStyle={{ ...myStyle.button, float: "none" }}
                  label="Cancel"
                  look="inverted"
                  noIcon
                  url={`/app/admin/inspirations`}
                  internalLink
                />
              </Box>
              <br />
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

FormComponent.propTypes = {
  inspiration: PropTypes.object,
  inspirationId: PropTypes.string,
  update: PropTypes.bool.isRequired,
};

// FormComponent.defaultProps = {
//   inspiration: {},
//   inspirationId: undefined,
// };
export default FormComponent;
