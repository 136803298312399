import { Typography, Grid, Divider } from "@mui/material";
import React from "react";
import theme from "../../../theme";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const styles = {
  iconStyle: {
    cursor: "pointer",
  },
  topDivider: {
    borderColor: theme.palette.black.darkSeaBlack,
    margin: "10px 0px",
    borderWidth: "1px",
  },
};

const BackNav = ({ pageName = undefined, routeTo = undefined }) => {
  const getBack = () => {
    return routeTo ? navigate(routeTo) : window.history.back();
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <ArrowBackIosNewIcon
            onClick={() => getBack()}
            sx={styles.iconStyle}
          />
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography variant="h2">{pageName}</Typography>
        </Grid>
      </Grid>
      <Divider variant="fullWidth" sx={styles.topDivider} />
    </>
  );
};
BackNav.propTypes = {
  pageName: PropTypes.string,
  routeTo: PropTypes.string,
};
// BackNav.defaultProps = {
//   pageName: undefined,
//   routeTo: undefined,
// };

export default BackNav;
