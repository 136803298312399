import React from "react";
import PropTypes from "prop-types";
import LoadingEllipsis from "src/components/loading-ellipsis";

const SectionLoadingIndicator = ({ className = "", align = "center" }) => {
  const styles = {
    root: {
      padding: "1rem 0",
      display: "flex",
      justifyContent: align === "left" ? "flex-start" : "center",
    },
  };

  return (
    <div className={`loading ${styles.root} ${className}`}>
      <LoadingEllipsis />
    </div>
  );
};

SectionLoadingIndicator.propTypes = {
  className: PropTypes.string,
  align: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

// SectionLoadingIndicator.defaultProps = {
//   className: "",
//   align: "center",
// };

export default SectionLoadingIndicator;
