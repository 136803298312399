import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Tooltip, IconButton } from "@mui/material";
import { getUser } from "../../../services/auth";
import { userRoles } from "../../../lib/constants";
import theme from "../../../theme";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const styles = {
  root: {
    display: "flex",
    alignItems: "center",
  },
};

const TextWithTooltip = ({
  text = undefined,
  tooltipText = undefined,
  bold = false,
}) => {
  const user = getUser("user")?.role;

  const isPilot = user === userRoles.pilot;
  const isAdmin = user === userRoles.admin;

  const color = {
    color: isAdmin
      ? theme.palette.adminBlue.main
      : isPilot
      ? theme.palette.secondary.main
      : theme.palette.black.darkSeaBlack,
  };
  return (
    <Box sx={styles.root}>
      <Typography variant={bold ? "h6" : "body1"}>{text}</Typography>
      <span>
        <Tooltip title={tooltipText}>
          <IconButton size="small">
            <InfoOutlinedIcon fontSize="inherit" sx={color} />
          </IconButton>
        </Tooltip>
      </span>
    </Box>
  );
};

TextWithTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  bold: PropTypes.bool,
};
// TextWithTooltip.defaultProps = {
//   bold: false,
// };
export default TextWithTooltip;
