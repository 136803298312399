import React from "react";
import ProjectMainLayout from "./ProjectMainLayout";
import PropTypes from "prop-types";

const PilotProjectLayout = ({ projectId = "" }) => {
  return <ProjectMainLayout projectId={projectId} />;
};

PilotProjectLayout.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default PilotProjectLayout;
