import React, { useState } from "react";
import {
  Box,
  Tooltip,
  Grid,
  Dialog,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
} from "@mui/material";
import { Block } from "@mui/icons-material";
import PropTypes from "prop-types";
import firebase from "src/firebase";
import AppButton from "../../../../../global/elements/AppButton";
import { rejectPackageReason } from "../../../../../../lib/constants";
import { TextField } from "formik-mui";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { createNotification } from "../../../../../../services/notification";
import theme from "../../../../../../theme";
import { formStyle } from "../../../../../../lib/styleConstants";

const styles = {
  rejectBoxCss: {
    display: "flex",
    justifyContent: { xs: "center", sm: "flex-end" },
    marginTop: "30px",
  },
  mT: { marginTop: "20px" },
  rejectButton: {
    backgroundColor: "red",
    color: theme.palette.grey.white,
    padding: "10px 15px",
  },
  dialogueButtons: {
    width: "100%",
    display: "flex",
    padding: "0.7em 0 0 23px",
    justifyContent: "space-between",
    flexWrap: { xs: "wrap", sm: "nowrap" },
    margin: "20px 0 5px 0",
  },
};

const RejectModal = ({ onPackageReject = undefined, mission = undefined }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showRejectDialog, setShowRejectDialog] = useState(false);

  const renderChips = (value, getTagProps) =>
    value.map((option, index) => (
      <Chip key={index} label={option} {...getTagProps({ index })} />
    ));

  const rejectedformInitialValues = {
    rejectedReason: [],
    otherRejectedReason: "",
  };
  const validationSchema = Yup.object().shape({
    rejectedReason: Yup.array()
      .min(1, "Rejected reason required")
      .required("required"),
  });

  const rejectPackage = async (values) => {
    setIsSubmitting(true);
    let rejectedObj = {
      pilotId: mission?.assignedPilot,
      missionId: mission?.id,
      rejectedFeedback: {
        ...values,
        pilotId: mission?.assignedPilot,
      },
    };
    await createNotification(
      firebase,
      mission?.assignedPilot,
      `Your package got rejected by the admin.`,
      `Your package got rejected by the admin`,
      `/app/missions/${mission?.id}`
    );
    await onPackageReject(rejectedObj);
    setIsSubmitting(false);
  };

  return (
    <>
      <Box sx={styles.rejectBoxCss}>
        <Tooltip title="Reject Drone Operator Package">
          <AppButton
            look={"alert"}
            label={"Reject Package"}
            startIcon={<Block />}
            onClick={() => setShowRejectDialog(true)}
            addtionalStyle={styles.rejectButton}
            isDisabled={isSubmitting}
          />
        </Tooltip>
      </Box>

      <Dialog
        open={showRejectDialog}
        onClose={() => setShowRejectDialog(false)}
        aria-labelledby="submit-packages-dialog-title"
        aria-describedby="submit-packages-dialog-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="submit-packages-dialog-title">
          Reject this drone operator package?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="submit-packages-dialog-description">
            This cannot be undone.
          </DialogContentText>

          <Box sx={styles.mT}>
            <Formik
              initialValues={rejectedformInitialValues}
              validationSchema={validationSchema}
              onSubmit={rejectPackage}
            >
              {({ isSubmitting, setFieldValue }) => {
                return (
                  <Form id="packageRejectForm">
                    <Grid container spacing={2}>
                      <Grid item sm={4} xs={12}>
                        <Autocomplete
                          multiple
                          options={rejectPackageReason}
                          onChange={(e, value) =>
                            setFieldValue("rejectedReason", value)
                          }
                          renderTags={renderChips}
                          style={formStyle}
                          variant="outlined"
                          renderInput={(params) => (
                            <Field
                              {...params}
                              type="text"
                              name="rejectedReason"
                              component={TextField}
                              label="Reasons for rejection"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item sm={8} xs={12}>
                        <Box>
                          <Field
                            type="text"
                            name="otherRejectedReason"
                            component={TextField}
                            label="Other comments or more information"
                            style={formStyle}
                            variant="outlined"
                          />
                        </Box>
                      </Grid>
                      <DialogActions sx={styles.dialogueButtons}>
                        <AppButton
                          isDisabled={isSubmitting}
                          onClick={() => setShowRejectDialog(false)}
                          look="inverted"
                          label="Cancel"
                          noIcon
                        />
                        <AppButton
                          type="submit"
                          look="negative"
                          form="packageRejectForm"
                          label="Reject package"
                          submittingState={isSubmitting}
                        />
                      </DialogActions>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

RejectModal.propTypes = {
  mission: PropTypes.object.isRequired,
  onPackageReject: PropTypes.func.isRequired,
};

// RejectModal.defaultProps = {
//   isDisabled: false,
// };

export default RejectModal;
