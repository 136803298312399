import { Box, Typography } from "@mui/material";
import { getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { Link } from "gatsby";
import React, { useState } from "react";
import firebase from "src/firebase";
import AppButton from "../../../global/elements/AppButton";
import Dropzone from "react-dropzone";
import UploadIcon from "@mui/icons-material/Upload";
import PropTypes from "prop-types";
import theme from "../../../../theme";

const styles = {
  root: {
    // width: "100%",
    float: "right",
  },
};

const UploadFlightSpecifications = ({
  values = undefined,
  setFieldValue = undefined,
}) => {
  const [docLoading, setDocLoading] = useState(false);
  const [flightSpecificationPdfUrl, setFlightSpecificationPdfUrl] = useState(
    values.flightSpecificationPdfUrl
  );

  const handleSubmit = (files) => {
    setDocLoading(true);
    const file = files[0];
    if (!file) return;
    const storageRef = firebase
      .storage()
      .ref()
      .child(`FlightSpecificationPdf/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFlightSpecificationPdfUrl(downloadURL);
          setFieldValue("flightSpecificationPdfUrl", downloadURL);
          // setFileName(file.name);
          setDocLoading(false);
        });
      }
    );
  };

  return (
    <Box sx={styles.root}>
      <Dropzone
        accept={[".pdf", "application/pdf"]}
        onDrop={(acceptedFiles) => {
          handleSubmit(acceptedFiles);
        }}
        name="flightSpecificationPdfUrl"
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} name="guideURL" />
              <AppButton
                label={
                  flightSpecificationPdfUrl
                    ? "Update flight specifications"
                    : "Upload flight specifications"
                }
                look="green"
                submittingState={docLoading}
                customIcon={<UploadIcon />}
              />
            </div>
          </section>
        )}
      </Dropzone>

      {flightSpecificationPdfUrl ? (
        <Link target="_blank" to={`${flightSpecificationPdfUrl}`}>
          <Typography
            variant="body3"
            sx={{ color: theme.palette.adminBlue.main }}
          >
            See the uploaded document
          </Typography>
        </Link>
      ) : (
        ""
      )}
    </Box>
  );
};

UploadFlightSpecifications.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
};

// UploadFlightSpecifications.defaultProps = {
//   setFieldValue: undefined,
//   values: undefined,
// };

export default UploadFlightSpecifications;
