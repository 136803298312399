import firebase from "src/firebase";
import { useCallback, useEffect, useState } from "react";
import { getDeliveryPackages } from "../services/delivery-packages";

export const useGetDeliveryPackages = (missionID) => {
  const [packages, setPackages] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const refreshPackages = useCallback(() => {
    setRefresh(true);
  }, []);

  useEffect(() => {
    if (!firebase || !refresh) {
      return;
    }
    setRefresh(false);
    setLoading(true);

    if (refresh && loading) {
      setRefresh(false);
      return;
    }

    getDeliveryPackages(firebase, missionID)
      .then((data) => {
        setPackages(
          data.sort(function (a, b) {
            return new Date(a.dateCreated) - new Date(b.dateCreated);
          })
        );
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        setError(e);
      });
  }, [missionID, loading, refresh, refreshPackages]);

  useEffect(() => {
    if (!packages) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [packages]);

  return { packages, loading, refreshPackages, error };
};
