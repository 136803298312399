import pt from "prop-types";
import React from "react";
import { Typography, Alert, AlertTitle } from "@mui/material";

const styles = {
  root: {
    textAlign: "left",
  },
  error: {
    marginBottom: "10px",
  },
};

const FormErrors = ({
  show = true,
  errors = [],
  title = "Invalid fields",
  className = undefined,
}) => {
  if (!show || errors.length === 0) {
    return null;
  }

  return (
    <Alert severity="error" sx={{ ...styles.root, ...className }}>
      <AlertTitle>{title}</AlertTitle>
      {/* <Box component="ul"> */}
      {typeof errors === "string" ? (
        <Typography variant="body2" sx={styles.error}>
          {errors}
        </Typography>
      ) : (
        errors.map((e) => (
          <Typography key={e} variant="body2" sx={styles.error}>
            {e}
          </Typography>
        ))
      )}
      {/* </Box> */}
    </Alert>
  );
};

// FormErrors.defaultProps = {
//   show: true,
//   errors: [],
//   title: "Invalid fields",
//   className: undefined,
// };

FormErrors.propTypes = {
  show: pt.bool,
  errors: pt.oneOfType([pt.string, pt.arrayOf(pt.string)]),
  title: pt.string,
  className: pt.object,
};

export default FormErrors;
