import React, { useEffect, useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import theme from "../../../../../theme";
import AppButton from "../../../../global/elements/AppButton";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import Dialogue from "../../../../global/Dialogue";
import AcceptTerms from "../../../../global/elements/AcceptTerms";
import { checkFilesInAllMApTypes } from "../../../../../services/missions";
import { packageTypeConstant } from "../../../../../lib/constants";

const styles = {
  LinkWrapper: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    justifyContent: { sm: "end" },
    rowGap: { xs: "1em", sm: "unset" },
    margin: "2em 0",
    width: "100%",
    borderTop: `0.7px solid ${theme.palette.grey.lightWarmGrey}`,
    paddingTop: "1em",
  },
  popupCls: {
    marginBottom: "30px",
    textAlign: "center",
  },
  headStyle: {
    display: "flex",
    alignItems: "center",
  },
  submitPackageButton: {
    marginLeft: { xs: "unset", sm: "20px" },
  },
};

const SubmitPackage = ({
  isDisabled = false,
  onSubmit,
  packageId = undefined,
  missionId = undefined,
  mapTypes = undefined,
}) => {
  const [showSubmitDialog, setShowSubmitDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogueLoading, setDialogueLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    return () => {
      setShowSubmitDialog(false);
      setDialogueLoading(false);
    };
  }, []);

  const DialogueBody = () => {
    return (
      <Box sx={styles.popupCls}>
        <Typography variant="h3">Submit Package for Review?</Typography>
        <br />
        <Typography variant="subtitle1">
          Once submitted, the files of this package can no longer be changed or
          edited.
        </Typography>
      </Box>
    );
  };

  const checkPackage = async () => {
    try {
      setLoading(true);
      const getPackageStatus = await checkFilesInAllMApTypes(
        missionId,
        mapTypes,
        packageId,
        packageTypeConstant.pilotPackage
      );
      if (getPackageStatus?.status === "Success") {
        setShowSubmitDialog(true);
      } else if (getPackageStatus?.status === "Data Not Filled") {
        enqueueSnackbar(
          `Please add the files to ${getPackageStatus?.message}`,
          {
            variant: "error",
          }
        );
      } else {
        enqueueSnackbar(`${getPackageStatus?.message}`, {
          variant: "error",
        });
      }
      setLoading(false);
    } catch (e) {
      console.log("Error", e);
      setLoading(false);
    }
  };

  const submitPackage = async () => {
    setDialogueLoading(true);
    onSubmit();
  };

  return (
    <>
      <Box sx={styles.LinkWrapper}>
        <Box sx={styles.headStyle}>
          <AcceptTerms additionalMarginTop={false} />
        </Box>
        <Tooltip title="Once submitted, GLOBHE will review the data before sending it to the client">
          <span>
            <AppButton
              isDisabled={isDisabled}
              look={isDisabled ? "blackDisabled" : "purple"}
              // look={"primary"}
              disableElevation={true}
              aria-label="Submit Package for review"
              addtionalStyle={styles.submitPackageButton}
              onClick={() => checkPackage()}
              label="Submit Data"
              submittingState={loading}
            />
          </span>
        </Tooltip>
      </Box>
      {showSubmitDialog && (
        <Dialogue
          showDialogue={showSubmitDialog}
          setShowDialogue={setShowSubmitDialog}
          DialogueHeadText={""}
          DialogueBody={DialogueBody()}
          showCancelButton={true}
          cancelButtonText={"Cancel"}
          sumitButtonText={"Yes let's do this"}
          showSubmitLoader={dialogueLoading}
          setShowSubmitLoader={setDialogueLoading}
          onSubmit={() => submitPackage()}
          submitButtonLook={"purple"}
        />
      )}
    </>
  );
};

SubmitPackage.propTypes = {
  isDisabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  packageId: PropTypes.string.isRequired,
  missionId: PropTypes.string.isRequired,
  mapTypes: PropTypes.array.isRequired,
};

// SubmitPackage.defaultProps = {
//   isDisabled: false,
// };

export default SubmitPackage;
