import { Grid, Typography } from "@mui/material";
import React from "react";
import AppButton from "./AppButton";
import PropTypes from "prop-types";

const styles = {
  btnStyles: { textAlign: "end" },
};

const AdminActions = ({
  heading = "",
  text = "",
  look = undefined,
  label = undefined,
  noicon = true,
  clickHandler = undefined,
  submittingState = false,
}) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography variant="h6">{heading}</Typography>
          <Typography variant="subtitle1">{text}</Typography>
        </Grid>
        <>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={styles.btnStyles}>
            <AppButton
              look={look}
              label={label}
              noIcon={noicon}
              onClick={clickHandler}
              submittingState={submittingState && submittingState}
            />
          </Grid>
        </>
      </Grid>
    </>
  );
};

AdminActions.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  look: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  noicon: PropTypes.bool,
  clickHandler: PropTypes.func.isRequired,
  submittingState: PropTypes.bool,
};

// AdminActions.defaultProps = {
//   heading: "",
//   text: "",
//   noicon: true,
//   submittingState: false,
// };

export default AdminActions;
