import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Autocomplete,
  Chip,
  Card,
  Divider,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import PropTypes from "prop-types";
import { preventEnterSubmit } from "src/lib/form-helpers";
import * as Yup from "yup";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { getUser } from "../../../services/auth";
import BackNav from "../../global/elements/BackNav";
import theme from "../../../theme";
import {
  missionMapTypes,
  packageTypeConstant,
  users,
} from "../../../lib/constants";
import AppButton from "../../global/elements/AppButton";
import MapsModal from "../../global/elements/MapsModal";
import DataRequestMap from "../dataRequest/DataRequestMap";
import TwoDOrthomosiacMap from "../../../assets/images/icon_2D_orthomosiac_map.svg";
import ThreeDMapsPointClouds from "../../../assets/images/icon_3D_maps_point_clouds.svg";
import ThreeDModel from "../../../assets/images/3d.png";
import DigitalTerrainModels from "../../../assets/images/icon_digital_terrain_models.svg";
import DigitalSurfaceModels from "../../../assets/images/digital_surface_models.svg";
import TopographicMap from "../../../assets/images/icon_topographic_map.svg";
import ThermalMap from "../../../assets/images/icon_thermal_map-01.svg";
import PlantHealthOld from "../../../assets/images/icon_plant_health_1.svg";
import PlantHealth from "../../../assets/images/multispectral_ndvi.png";
import lidar from "../../../assets/images/tower2.svg";
import { areaUnits, missionTypeConstant } from "../../../lib/constants";
import {
  checkFilesInAllMApTypes,
  createBasicMission,
  updatePilotSubmission,
} from "../../../services/missions";
import Panorama from "../../../assets/images/icon_360_panorama.svg";
import StreamData from "../../../assets/images/icon_live_stream_data.svg";
import RawData from "../../../assets/images/icon_raw_data_footage2.svg";
import firebase from "src/firebase";
import { useSnackbar } from "notistack";
import { navigate } from "gatsby";
import {
  singleMissionCard,
  textFieldStyle,
  singlePageOuterContainer,
  singlePageMainContainerNoLayout,
} from "../../../lib/styleConstants";
import SmallStatusBanner from "../../global/elements/SmallStatusBanner";
import TooltipInfo from "../../global/elements/TooltipInfo";
import KMLFileDropZone from "../missions/shared/KMLFileDropZone";
import { sdgGoals, dataLibCategory } from "../../../lib/constants";
import FormErrors from "../../global/forms/FormErrors";
import MenuBar from "../../global/elements/MenuBar";
import PackageLayout from "../missions/FilesPackages/PackageLayout";

const styles = {
  profileHeaderContainer: {
    width: { xs: "0px", md: "350px" },
  },
  descriptionColumn: {
    display: { xs: "block" },
    marginTop: "50px",
  },
  missionDetails: {
    marginRight: "10px",
    height: "100%",
    width: { xs: "100%", lg: "50%" },
  },
  requirementDetails: {
    marginRight: "10px",
    height: "100%",
    width: { xs: "100%" },
    padding: { xs: "10px", md: "40px 20px" },
  },
  heightStyles: {
    minHeight: 500,
  },
  missionTitleSection: {
    display: "flex",
    justifyContent: "space-between",
  },
  titlePadding: {
    padding: "22px",
  },
  missionTitle: {
    "&.MuiTypography-root": {
      fontSize: { xs: "15px", sm: "21px" },
    },
  },
  requiredNote: {
    fontWeight: 500,
  },
  asterisk: {
    fontSize: "17px",
    fontWeight: 500,
    color: theme.palette.status.errorDark,
  },
  backgroundGradient: {
    background: `linear-gradient(180deg, ${theme.palette.primary.main}19 0%, rgba(255,255,255,1) 30px)`,
  },
  textFieldTopSpace: {
    padding: "15px",
  },
  textFieldPadding: {
    padding: { xs: "5px 10px 5px 10px", sm: "15px 15px 15px 15px" },
  },
  inputField: {
    width: "100%",
  },
  deadlineDatePicker: {
    width: { xs: "100%" },
  },
  bottomPadding: {
    paddingBottom: { xs: "7%", lg: "5%" },
  },
  gridField: {
    "&.MuiFormControl-root": {
      width: "100%",
    },
    "& fieldset": {
      borderStyle: "groove",
    },
  },
  descriptionInputField: {
    width: "100%",
  },
  paddingDecBottom: {
    paddingBottom: "6px",
  },
  topMargin: {
    marginTop: { xs: "25px", lg: "0" },
  },
  allContainer: {
    display: { sm: "block", lg: "flex" },
    justifyContent: "space-between",
  },
  AlignContainer: {
    display: "flex",
    paddingTop: { xs: "25px", sm: "25px", lg: "0px" },
  },
  iconContainer: {
    padding: "15px",
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: `0px 0px 4px ${theme.palette.primary.pistachio}`,
    borderRadius: "10px",
  },
  iconInfoContainer: {
    padding: "40px",
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: `0px 0px 4px ${theme.palette.primary.pistachio}`,
    borderRadius: "10px",
    textAlign: "center",
  },

  submitOrder: {
    marginBottom: "3%",
    margin: "auto 0",
    marginTop: { xs: "10px", md: "auto" },
  },
  button_style: {
    marginTop: { xs: "10px", md: 0 },
  },
  button_style_disable: {
    width: { xs: "100%", md: "156px" },
    marginTop: { xs: "10px", md: 0 },
    cursor: "not-allowed !important",
    "&:hover": {
      background: theme.palette.grey.warmGrey,
      cursor: "not-allowed",
    },
  },
  cancel_style: {
    width: { xs: "143px", md: "156px" },
    padding: "12px 24px",
    borderRadius: "30px",
    fontSize: { xs: "12px", md: "15px" },
    marginRight: "10px",
  },

  infoMapTitle: { fontWeight: 500, color: theme.palette.black.coolGrey },
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  initialInfoMore: {
    direction: "rtl",
  },
  backmoreInfoBtn: {
    border: "none",
    borderRadius: "none",
    color: theme.palette.primary.main,
    background: "none",
    boxShadow: "none",
    width: "136px",
    "&:hover": {
      border: "none",
      borderRadius: "none",
      color: theme.palette.primary.main,
      background: "none",
      boxShadow: "none",
    },
  },
  mapIcons: {
    height: "45px",
    width: "45px",
  },
  mapIconsDisplay: {
    height: "80px",
    width: "80px",
  },
  infoMapBoxContainer: {
    display: "flex",
    justifyContent: "center",
  },
  modalMapIcon: {
    height: "212px",
    width: "190px",
  },
  uploadKmlButton: {
    "&.MuiButton-root": {
      marginRight: "7px",
      width: "100% !important",
    },
    fontSize: "12px",
    fontWeight: 500,
  },
  titleAndHelp: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  helpIcon: {
    fontSize: "12px",
    marginLeft: "5px",
    color: theme.palette.primary.main,
  },
  errorSpan: {
    color: theme.palette.status.errorDark,
    margin: 0,
    fontSize: "0.75rem",
    marginTop: "3px",
    textAlign: "left",
    fontFamily: "NHaasGroteskDSPro",
    fontWeight: 400,
    lineHeight: 1.66,
  },
  mapBoxesStyle: {
    padding: "15px",
    display: "flex",
    justifyContent: "space-between",
    flexFlow: "wrap",
  },
  submitBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginTop: { xs: "40px", sm: "50px", md: "60px" },
  },
  boxPlacement: {
    marginBottom: { xs: "10px", sm: "20px" },
  },
  orthomosicMapFile: {
    fontSize: { xs: "12px", sm: "14px" },
    fontWeight: 500,
  },
  deleteButton: {
    color: theme.palette.grey.deepWarmGrey,
    boxShadow: "none",
    padding: "0px 20px",
    width: "fit-content",
    marginTop: 0,
  },
  uploadedImage: {
    width: "127px !important",
    height: "127px !important",
    borderRadius: "10px",
    margin: "20px",
  },
  deletePopup: {
    justifyContent: "center",
  },
  popupCancel: {
    width: "fit-content",
    marginTop: 0,
    padding: "5px 20px",
    margin: "10px",
  },
  formErrorsAlert: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  backNavBox: { width: { xs: "950px", md: "350px" } },
};

const SellYourData = ({
  orderData = undefined,

  load = undefined,
}) => {
  const [display, setDisplay] = useState(false);
  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);
  const [area, setArea] = useState(orderData ? orderData?.locationMap : null);
  const [areaSize, setAreaSize] = useState(orderData?.areaSize);
  const [selectedUnit, setSelectedUnit] = React.useState(areaUnits.sqkm);
  const [kmlUrl, setKmlUrl] = React.useState(orderData?.kmlFile);
  // const [guidelinesPdfUrl, setGuidelinesPdfUrl] = useState(
  //   orderData?.guidelinesPdfUrl
  // );
  const [polygon, setPolygon] = React.useState(null);
  const [mapApi, setMapApi] = React.useState(null);
  const [geocoder, setGeocoder] = React.useState(null);
  const [label, setLabel] = React.useState(orderData?.locationLabel);
  const [clientId, setClientId] = React.useState(null);
  const [clientEmail, setClientEmail] = React.useState(null);
  const [clientName, setClientName] = React.useState(null);
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [selectedMap, setSelectedMap] = useState(false);
  const [selectedMapId, setSelectedMapId] = useState(null);
  const [locDataPilot, setLocDataPilot] = useState(null);
  const [activatePilotKml, setActivatePilotKml] = useState(false);
  const [orderType, setOrderType] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [missionDatas, setMissionData] = useState(null);
  const [userData, setUserData] = useState({});
  const [mapTypesData, setMapTypesData] = useState(null);

  useEffect(() => {
    const userInfo = getUser(users.userProfile);
    setUserData(userInfo);
    setClientId(userInfo?.id);
    setClientEmail(userInfo?.email);
    setClientName(userInfo?.name);
  }, [load]);

  const handleOpen = () => setOpen(true);
  const initialHandleOpen = () => {
    setOpen(true);
  };

  const goBack = () => {
    setDisplay(false);
    setSelectedMap(false);
  };

  const onMapSave = ({ locationLabel, type }) => {
    setLabel(locationLabel);
    setOrderType(type);
  };

  const getMapIcon = (mapId, display, selectedMap) => {
    switch (mapId) {
      case "2D Orthomosaic Map":
        return (
          <Box
            component="img"
            src={TwoDOrthomosiacMap}
            sx={
              display || selectedMap ? styles.mapIconsDisplay : styles.mapIcons
            }
          />
        );
      case "3D model or point cloud":
        return (
          <Box
            component="img"
            src={ThreeDMapsPointClouds}
            sx={
              display || selectedMap ? styles.mapIconsDisplay : styles.mapIcons
            }
          />
        );
      case "3D model":
        return (
          <Box
            component="img"
            src={ThreeDModel}
            sx={
              display || selectedMap ? styles.mapIconsDisplay : styles.mapIcons
            }
          />
        );
      case "Digital terrain model":
        return (
          <Box
            component="img"
            src={DigitalTerrainModels}
            sx={
              display || selectedMap ? styles.mapIconsDisplay : styles.mapIcons
            }
          />
        );
      case "Digital surface model":
        return (
          <Box
            component="img"
            src={DigitalSurfaceModels}
            sx={
              display || selectedMap ? styles.mapIconsDisplay : styles.mapIcons
            }
          />
        );
      case "Topographic map with contour lines":
        return (
          <Box
            component="img"
            src={TopographicMap}
            sx={
              display || selectedMap ? styles.mapIconsDisplay : styles.mapIcons
            }
          />
        );
      case "Thermal Map":
        return (
          <Box
            component="img"
            src={ThermalMap}
            sx={
              display || selectedMap ? styles.mapIconsDisplay : styles.mapIcons
            }
          />
        );
      case "Multispectral":
        return (
          <Box
            component="img"
            src={PlantHealth}
            sx={
              display || selectedMap ? styles.mapIconsDisplay : styles.mapIcons
            }
          />
        );

      case "Plant health (NDVI maps)":
        return (
          <Box
            component="img"
            src={PlantHealthOld}
            sx={
              display || selectedMap ? styles.mapIconsDisplay : styles.mapIcons
            }
          />
        );

      case "Live streamed data (video footage)":
        return (
          <Box
            component="img"
            src={StreamData}
            sx={
              display || selectedMap ? styles.mapIconsDisplay : styles.mapIcons
            }
          />
        );
      case "Raw data/photos":
        return (
          <Box
            component="img"
            src={RawData}
            sx={
              display || selectedMap ? styles.mapIconsDisplay : styles.mapIcons
            }
          />
        );
      case "360 panoramas":
        return (
          <Box
            component="img"
            src={Panorama}
            sx={
              display || selectedMap ? styles.mapIconsDisplay : styles.mapIcons
            }
          />
        );
      case "LiDAR":
        return (
          <Box
            component="img"
            src={lidar}
            sx={
              display || selectedMap ? styles.mapIconsDisplay : styles.mapIcons
            }
          />
        );
      default:
        return (
          <Box
            component="img"
            src={TwoDOrthomosiacMap}
            sx={styles.modalMapIcon}
          />
        );
    }
  };

  const InfoMapBox = ({ imgIcon, id }) => {
    if (!id) {
      setDisplay(false);
      setSelectedMap(false);
    }
    const toShow = id?.description?.substring(0, 300) + "...";
    let index = missionMapTypes.findIndex((x) => x?.value === id?.value);
    return (
      <>
        <Box sx={styles.infoMapBoxContainer}>
          <Box>
            <Box sx={styles.iconInfoContainer}>{imgIcon}</Box>
            <br />
            <Box sx={{}}>
              <Typography sx={styles.infoMapTitle}>
                Delivery file formats:
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                {" "}
                {id?.fileFormat}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ paddingLeft: "25px" }}>
            <Typography sx={{ ...styles.infoMapTitle, fontSize: "15px" }}>
              {id?.label}
            </Typography>

            <Box sx={{ textAlign: "left" }}>{toShow}</Box>
          </Box>
        </Box>
        <Box sx={{ ...styles.btnContainer, width: "100%" }}>
          <AppButton
            label={"BACK"}
            onClick={goBack}
            addtionalStyle={{ ...styles.backmoreInfoBtn, width: "55px" }}
          />
          <AppButton
            label={"MORE INFO >"}
            onClick={handleOpen}
            addtionalStyle={styles.backmoreInfoBtn}
          />
        </Box>
        <MapsModal
          open={open}
          setOpen={setOpen}
          modalData={id}
          mapTypes={missionMapTypes}
          index={index}
          mapIcon={getMapIcon(id?.label)}
        />
      </>
    );
  };

  InfoMapBox.propTypes = {
    imgIcon: PropTypes.func.isRequired,
    id: PropTypes.object.isRequired,
  };

  const renderChips = (value, getTagProps) =>
    value.map((option, index) => (
      <Chip key={index} label={option} {...getTagProps({ index })} />
    ));

  const DataRequestSchema = Yup.object().shape({
    missionTitle: Yup.string().trim().required("Mission title is required"),
    missionName: Yup.string()
      .trim()
      .max(50, "Must be maximum 50 characters long")
      .required("Mission name is required"),
    mapTypes: Yup.array().required("Map types are required"),
    description: Yup.string().trim().required("Description is required"),
    sdgGoals: Yup.array()
      .min(1, "SDGs Goals are required ")
      .required("required "),
    categories: Yup.array()
      .min(1, "Category is required")
      .required("required "),
  });

  const formInitialValues = {
    missionName: "",
    missionTitle: "",
    mapTypes: [],
    description: "",
    sdgGoals: [],
    categories: [],
  };

  const finalSubmitMission = async (values) => {
    try {
      let missionId = missionDatas.mission.id;
      let packageId = missionDatas.package.id;
      const getPackageStatus = await checkFilesInAllMApTypes(
        missionId,
        mapTypesData,
        packageId,
        packageTypeConstant.pilotPackage
      );
      if (area) {
        values.locationMap = area;
      }
      if (areaSize) {
        values.areaSize = areaSize;
      }
      if (label) {
        values.locationLabel = label;
      }
      if (kmlUrl) {
        values.kmlFile = kmlUrl;
      }
      if (!area && !areaSize && !kmlUrl) {
        setErrorDisplay(true);
        return;
      }
      if (getPackageStatus?.status === "Success") {
        const updateStatus = await updatePilotSubmission(
          firebase,
          values,
          missionId,
          packageId
        );
        if (updateStatus.status === "Success") {
          enqueueSnackbar("Your data has been uploaded successfully", {
            variant: "success",
          });
          navigate(`/app/missions/${missionDatas.mission.id}`);
        } else {
          enqueueSnackbar("Error in saving the data", { variant: "error" });
        }
      } else if (getPackageStatus?.status === "Data Not Filled") {
        enqueueSnackbar(
          `Please add the files to ${getPackageStatus?.message}`,
          {
            variant: "error",
          }
        );
      }
    } catch (error) {
      enqueueSnackbar("Error in saving the data", { variant: "error" });
      console.log("Error", error);
    }
  };

  const onSubmitOrder = (values) => {
    if (area) {
      values.locationMap = area;
    }
    if (areaSize) {
      values.areaSize = areaSize;
    }
    if (label) {
      values.locationLabel = label;
    }
    if (kmlUrl) {
      values.kmlFile = kmlUrl;
    }
    // if (guidelinesPdfUrl) {
    //   values.guidelinesPdfUrl = guidelinesPdfUrl;
    // }
    if (clientId) {
      values.clientId = clientId;
    }
    if (clientEmail) {
      values.clientEmail = clientEmail;
    }
    if (clientName) {
      values.clientName = clientName;
    } else {
      values.clientName = "client name";
    }
    values.subscriptionEnd = null;
    values.subscription = null;
    if (!area && !areaSize && !kmlUrl) {
      setErrorDisplay(true);
      return;
    }
    values.missionType = missionTypeConstant.operatorSubmission;
    let missionData = values;

    if (orderType) {
      values.orderType = orderType;
    }

    createBasicMission(firebase, missionData)
      .then((snapshot) => {
        if (snapshot.status === "Success") {
          enqueueSnackbar("Basic data has been saved ", { variant: "success" });
          setMissionData(snapshot.data);
        }
      })
      .catch((err) => {
        enqueueSnackbar("Error in saving the data", { variant: "danger" });
        console.log(err);
      });
  };

  return (
    <Box sx={singlePageOuterContainer}>
      <Box sx={singlePageMainContainerNoLayout}>
        <Box>
          <MenuBar
            country={userData?.location || ""}
            profileImage={userData?.profileImageURL || ""}
          />
          <BackNav pageName="Sell your data" />
        </Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Formik
            initialValues={formInitialValues}
            validationSchema={DataRequestSchema}
            onSubmit={onSubmitOrder}
            style={{ width: "100%" }}
          >
            {({ values, setFieldValue, errors, submitCount }) => {
              return (
                <Form id="orderSubmitForm" onKeyDown={preventEnterSubmit}>
                  {
                    <>
                      <Box sx={{ marginTop: "5%" }}>
                        <Box
                          sx={{
                            display: { xs: "block", lg: "flex" },
                            justifyContent: "space-between",
                          }}
                        >
                          <Card
                            sx={{
                              ...singleMissionCard,
                              width: { xs: "100%", lg: "48.5%" },
                            }}
                            variant="outlined"
                          >
                            <Box
                              sx={{
                                ...styles.missionTitleSection,
                                ...styles.titlePadding,
                              }}
                            >
                              <Typography variant="h6" sx={styles.missionTitle}>
                                enter details
                              </Typography>
                              <Typography sx={styles.requiredNote}>
                                <Box component="span" sx={styles.asterisk}>
                                  *
                                </Box>
                                Indicates required field
                              </Typography>
                            </Box>

                            <Box sx={styles.backgroundGradien}>
                              <Box
                                sx={{
                                  ...styles.backgroundGradient,
                                  height: "30px",
                                  width: "100%",
                                }}
                              ></Box>
                              <Box
                                sx={{
                                  textFieldStyle,
                                  ...styles.textFieldTopSpace,
                                  ...styles.textFieldPadding,
                                }}
                              >
                                <Box component="div" sx={styles.titleAndHelp}>
                                  <Box component="span" sx={styles.asterisk}>
                                    *
                                  </Box>
                                  <Box
                                    component="span"
                                    sx={{
                                      color: theme.palette.black.darkSeaBlack,
                                    }}
                                  >
                                    Mission Name
                                  </Box>
                                  <TooltipInfo
                                    additionalStyle={{
                                      color: theme.palette.primary.main,
                                    }}
                                    info="Please provide a mission name of maximum 20 characters"
                                  />
                                </Box>
                                <Field
                                  type="text"
                                  name="missionName"
                                  value={values.missionName}
                                  placeholder="Mission Name"
                                  component={TextField}
                                  disabled={false}
                                  variant="outlined"
                                  sx={{ ...styles.inputField }}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "missionName",
                                      e.target.value
                                    );
                                  }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  textFieldStyle,
                                  ...styles.textFieldTopSpace,
                                  ...styles.textFieldPadding,
                                }}
                              >
                                <Box component="div" sx={styles.titleAndHelp}>
                                  <Box component="span" sx={styles.asterisk}>
                                    *
                                  </Box>
                                  <Box
                                    component="span"
                                    sx={{
                                      color: theme.palette.black.darkSeaBlack,
                                    }}
                                  >
                                    Map name
                                  </Box>
                                  <TooltipInfo
                                    additionalStyle={{
                                      color: theme.palette.primary.main,
                                    }}
                                    info="This is the name you will see on your order list. Choose a name of maximum 20 characters"
                                  />
                                </Box>
                                <Field
                                  type="text"
                                  name="missionTitle"
                                  value={values.missionTitle}
                                  placeholder="Order Name"
                                  component={TextField}
                                  disabled={false}
                                  variant="outlined"
                                  sx={{ ...styles.inputField }}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "missionTitle",
                                      e.target.value
                                    );
                                  }}
                                />
                              </Box>
                              <Box sx={styles.missionDetailsFields}>
                                <Box
                                  sx={{
                                    ...styles.deadlineDatePicker,
                                    textFieldStyle,
                                    ...styles.textFieldPadding,
                                  }}
                                >
                                  <Box component="div" sx={styles.titleAndHelp}>
                                    <Box component="span" sx={styles.asterisk}>
                                      *
                                    </Box>
                                    <Box
                                      component="span"
                                      sx={{
                                        color: theme.palette.black.darkSeaBlack,
                                      }}
                                    >
                                      Product type
                                    </Box>
                                    <TooltipInfo
                                      additionalStyle={{
                                        color: theme.palette.primary.main,
                                      }}
                                      info="What product do you require? You can find info about each delivered product below. You can choose more than one product."
                                    />
                                  </Box>

                                  <Autocomplete
                                    multiple
                                    value={values.mapTypes}
                                    options={missionMapTypes.map(
                                      ({ label }) => label
                                    )}
                                    freeSolo
                                    onChange={(e, value) => {
                                      setFieldValue("mapTypes", value);
                                      setSelectedMapId(value);
                                      setSelectedMap(true);
                                      setMapTypesData(value);
                                    }}
                                    renderTags={renderChips}
                                    renderInput={({ inputProps, ...rest }) => (
                                      <Field
                                        {...rest}
                                        type="text"
                                        name="mapTypes"
                                        placeholder="Choose Product"
                                        component={TextField}
                                        inputProps={{
                                          ...inputProps,
                                          readOnly: true,
                                        }}
                                        variant="outlined"
                                        style={{ ...styles.inputField }}
                                      />
                                    )}
                                  />
                                </Box>
                              </Box>

                              <Box sx={styles.mapBoxesStyle}>
                                {missionMapTypes?.map((item, i) => {
                                  return (
                                    <Box
                                      key={i}
                                      sx={{
                                        // display: "inline-grid",
                                        flexDirection: "row",
                                        padding: "2%",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        verticalAlign: "top",
                                        display: "inline-block",
                                        "*display": "inline",
                                      }}
                                      onClick={() => {
                                        setId(item);
                                        setDisplay(true);
                                      }}
                                    >
                                      {!display && !selectedMap && (
                                        <>
                                          <Box sx={styles.iconContainer}>
                                            {getMapIcon(item.label, display)}
                                          </Box>
                                          <Box
                                            sx={{
                                              width: "83px",
                                              fontSize: "10px",
                                              paddingTop: "3px",
                                              color:
                                                theme.palette.black.coolGrey,
                                            }}
                                          >
                                            {item.label}
                                          </Box>
                                        </>
                                      )}
                                    </Box>
                                  );
                                })}
                                <Box
                                  component="span"
                                  sx={{
                                    width: "100%",
                                    display: "inline-block",
                                    fontSize: 0,
                                    lineHeight: 0,
                                  }}
                                ></Box>
                                {!display && !selectedMap && (
                                  <Box sx={styles.initialInfoMore}>
                                    <AppButton
                                      label={"< MORE INFO"}
                                      onClick={initialHandleOpen}
                                      addtionalStyle={styles.backmoreInfoBtn}
                                    />
                                    <MapsModal
                                      open={open}
                                      setOpen={setOpen}
                                      modalData={missionMapTypes[0]}
                                      mapTypes={missionMapTypes}
                                      index={0}
                                      mapIcon={getMapIcon(id.label)}
                                    />
                                  </Box>
                                )}
                                {display && !selectedMap && (
                                  <InfoMapBox
                                    imgIcon={getMapIcon(id.label, display)}
                                    id={id}
                                  />
                                )}
                                {selectedMap && (
                                  <>
                                    <InfoMapBox
                                      imgIcon={getMapIcon(
                                        selectedMapId[selectedMapId.length - 1],
                                        selectedMap
                                      )}
                                      id={missionMapTypes.find((obj) => {
                                        return (
                                          obj.label ===
                                          selectedMapId[
                                            selectedMapId.length - 1
                                          ]
                                        );
                                      })}
                                    />
                                  </>
                                )}
                              </Box>
                              <Box sx={styles.dataLibraryBox}>
                                <Box
                                  sx={{
                                    ...styles.gridField,
                                    ...styles.textFieldTopSpace,
                                    width: "100%",
                                  }}
                                >
                                  <Box sx={styles.gridField}>
                                    <Box
                                      component="div"
                                      sx={{ display: "flex" }}
                                    >
                                      <Box
                                        component="span"
                                        sx={styles.asterisk}
                                      >
                                        *
                                      </Box>
                                      <Box
                                        component="span"
                                        sx={{
                                          color:
                                            theme.palette.black.darkSeaBlack,
                                        }}
                                      >
                                        Choose SDG goals
                                      </Box>
                                      <TooltipInfo
                                        additionalStyle={{
                                          color: theme.palette.primary.main,
                                        }}
                                        info="Choose the United Nations SDG goals that correspond to your data based on what does your data contributes to. For example, if captured data for solar panel inspection, choose the goal 'Affordable and clean energy'. You can choose multiple SDG goals."
                                      />
                                    </Box>
                                    <Box>
                                      <Autocomplete
                                        onChange={(e, value) => {
                                          setFieldValue("sdgGoals", value);
                                        }}
                                        value={values.sdgGoals}
                                        options={sdgGoals}
                                        multiple
                                        freeSolo
                                        renderInput={({
                                          inputProps,
                                          ...rest
                                        }) => (
                                          <Field
                                            {...rest}
                                            type="text"
                                            name="sdgGoals"
                                            component={TextField}
                                            inputProps={{
                                              ...inputProps,
                                              readOnly: true,
                                            }}
                                            variant="outlined"
                                            sx={{
                                              ...styles.inputField,
                                              ...styles.textFieldWidth,
                                              textFieldStyle,
                                              width: "100%",
                                            }}
                                          />
                                        )}
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                              <Box sx={styles.dataLibraryBox}>
                                <Box
                                  sx={{
                                    ...styles.gridField,
                                    ...styles.textFieldTopSpace,
                                    width: "100%",
                                  }}
                                >
                                  <Box sx={styles.gridField}>
                                    <Box
                                      component="div"
                                      sx={{ display: "flex" }}
                                    >
                                      <Box
                                        component="span"
                                        sx={styles.asterisk}
                                      >
                                        *
                                      </Box>
                                      <Box
                                        component="span"
                                        sx={{
                                          color:
                                            theme.palette.black.darkSeaBlack,
                                        }}
                                      >
                                        Choose the data categories
                                      </Box>
                                      <TooltipInfo
                                        additionalStyle={{
                                          color: theme.palette.primary.main,
                                        }}
                                        info="The categories will help the client filter and find your data faster based on what your data represents. Choose the categories that correspond to your data. For example if your data is about forests, choose 'forestry'"
                                      />
                                    </Box>
                                    <Autocomplete
                                      onChange={(e, value) => {
                                        setFieldValue("categories", value);
                                      }}
                                      value={values.categories}
                                      options={dataLibCategory}
                                      multiple
                                      freeSolo
                                      renderInput={({
                                        inputProps,
                                        ...rest
                                      }) => (
                                        <Field
                                          {...rest}
                                          type="text"
                                          name="categories"
                                          component={TextField}
                                          variant="outlined"
                                          inputProps={{
                                            ...inputProps,
                                            readOnly: true,
                                          }}
                                          sx={{
                                            ...styles.inputField,
                                            ...styles.textFieldWidth,
                                            textFieldStyle,
                                            width: "100%",
                                          }}
                                        />
                                      )}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Card>

                          <Box
                            sx={{
                              width: { xs: "100%", lg: "50%" },
                              marginTop: { xs: "25px", lg: "0px" },
                            }}
                          >
                            <Box
                              sx={{
                                paddingBottom: "10px",
                                ...singleMissionCard,
                              }}
                            >
                              <Box
                                sx={{
                                  ...styles.titleAndHelp,
                                  padding: "0 15px",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  sx={{
                                    ...styles.missionTitle,
                                    padding: "22px 0",
                                  }}
                                >
                                  draw the location of this data
                                </Typography>
                                <TooltipInfo
                                  additionalStyle={{
                                    color: theme.palette.primary.main,
                                  }}
                                  info="You can either draw on the map or upload a kml file below"
                                />
                              </Box>
                              <Box
                                sx={{
                                  ...styles.backgroundGradient,
                                  height: "30px",
                                  width: "100%",
                                }}
                              ></Box>
                              <Box sx={{ padding: "15px" }}>
                                <DataRequestMap
                                  area={area}
                                  setArea={setArea}
                                  areaSize={areaSize}
                                  setAreaSize={setAreaSize}
                                  selectedUnit={selectedUnit}
                                  setSelectedUnit={setSelectedUnit}
                                  kmlUrl={kmlUrl}
                                  setKmlUrl={setKmlUrl}
                                  // guidelinesPdfUrl={guidelinesPdfUrl}
                                  // setGuidelinesPdfUrl={setGuidelinesPdfUrl}
                                  polygon={polygon}
                                  setPolygon={setPolygon}
                                  mapApi={mapApi}
                                  setMapApi={setMapApi}
                                  geocoder={geocoder}
                                  setGeocoder={setGeocoder}
                                  label={label}
                                  onSave={onMapSave}
                                  mapHeight={{
                                    height: "608px",
                                    borderRadius: "15px",
                                    overflow: "hidden",
                                  }}
                                  createdByPilot={true}
                                  locDataPilot={locDataPilot}
                                  activatePilotKml={!activatePilotKml}
                                />
                                {errorDisplay && (
                                  <Box component="span" sx={styles.errorSpan}>
                                    Please draw a map or upload kml file
                                  </Box>
                                )}
                              </Box>
                            </Box>
                            <Divider sx={{ margin: "20px 0" }}>OR</Divider>
                            <Box>
                              <KMLFileDropZone
                                onUploaded={(url, locData) => {
                                  // setKML(url, locData);
                                  setKmlUrl(url);
                                  setLocDataPilot(locData);
                                  setActivatePilotKml(!activatePilotKml);
                                }}
                                currentFileUrl={kmlUrl}
                                additionalStyle={styles.uploadKmlButton}
                              />
                            </Box>
                            <Box
                              sx={{
                                ...styles.gridField,
                                ...styles.textFieldTopSpace,
                              }}
                            >
                              <Box component="div" sx={{ display: "flex" }}>
                                <Box component="span" sx={styles.asterisk}>
                                  *
                                </Box>
                                <Box
                                  component="span"
                                  sx={{
                                    color: theme.palette.black.darkSeaBlack,
                                  }}
                                >
                                  Description and more information about your
                                  data
                                </Box>
                                <TooltipInfo
                                  additionalStyle={{
                                    color: theme.palette.primary.main,
                                  }}
                                  info="The clients will read this description. Therefore, write what your data represents, what did you capture, how it can be used, and what is the purpose to increase your chances of selling this data. Write as much details as possible."
                                />
                              </Box>
                              <Field
                                type="text"
                                name="description"
                                placeholder="Please enter"
                                disabled={false}
                                value={values.description}
                                component={TextField}
                                variant="outlined"
                                multiline
                                rows={5}
                                sx={{
                                  ...styles.descriptionInputField,
                                  textFieldStyle,
                                  ...styles.paddingDecBottom,
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Box sx={{ marginTop: "2em" }}>
                          <FormErrors
                            style={styles.formErrorsAlert}
                            show={submitCount > 0}
                            errors={Object.values(errors)}
                          />
                        </Box>
                        {!missionDatas ? (
                          <Box
                            sx={{
                              ...styles.submitOrder,
                              marginTop: "25px",
                              display: { sm: "block", md: "flex" },
                              width: { xs: "100%", md: "inherit" },
                              padding: { xs: "10px 30px", md: "inherit" },
                              textAlign: { xs: "center", md: "inherit" },
                            }}
                          >
                            <Box
                              sx={{
                                margin: "auto",
                                fontSize: { xs: "12px", sm: "14px" },
                                marginRight: "20px",
                              }}
                              style={{
                                fontWeight: 500,
                                color: theme.palette.grey.deepWarmGrey,
                              }}
                            >
                              By submitting this form you accept our
                              <a
                                style={{
                                  color: theme.palette.secondary.main,
                                  fontWeight: 500,
                                }}
                                href="https://www.globhe.com/terms-and-conditions"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Terms & Conditions
                              </a>{" "}
                              and{" "}
                              <a
                                style={{
                                  color: theme.palette.secondary.main,
                                  fontWeight: 500,
                                }}
                                href="https://www.globhe.com/privacy-policy"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Privacy Policy
                              </a>
                            </Box>
                            <Box sx={styles.submitOrder}>
                              <AppButton
                                type="submit"
                                label={"Continue with submission"}
                                look="black"
                                addtionalStyle={styles.button_style}
                                form="orderSubmitForm"
                              />
                            </Box>
                          </Box>
                        ) : (
                          <Box sx={styles.descriptionColumn}>
                            <Box
                              sx={{
                                ...styles.requirementDetails,
                                ...singleMissionCard,
                                ...styles.topMargin,
                              }}
                            >
                              <PackageLayout
                                packageId={missionDatas?.package?.id}
                                mission={{
                                  ...missionDatas?.mission,
                                  mapTypes: mapTypesData,
                                }}
                                packageType={packageTypeConstant.pilotPackage}
                                mediaPackage={missionDatas?.package}
                                isEditable={true}
                              />
                            </Box>
                            <Box sx={styles.submitBox}>
                              <Box sx={styles.boxPlacement}>
                                <SmallStatusBanner
                                  title="The crowddroning community is growing…"
                                  content="…which means we’re continually processing a lot of dataset uploads. Our team at GLOBHE is small-but-growing – so for now it can take up to 30 days for quality assurance and approval of your submission. We appreciate your patience while we scale up to meet your needs. Thanks for being a part of the Crowddroning community!"
                                />
                              </Box>
                              <Box
                                sx={{
                                  ...styles.submitOrder,
                                  marginTop: "25px",
                                  display: { sm: "block", md: "flex" },
                                  width: { xs: "100%", md: "inherit" },
                                  padding: { xs: "10px 30px", md: "inherit" },
                                  textAlign: { xs: "center", md: "inherit" },
                                }}
                              >
                                <Box
                                  sx={{
                                    margin: "auto",
                                    fontSize: { xs: "12px", sm: "14px" },
                                    marginRight: "20px",
                                  }}
                                  style={{
                                    fontWeight: 500,
                                    color: theme.palette.grey.deepWarmGrey,
                                  }}
                                >
                                  By submitting this form you accept our
                                  <a
                                    style={{
                                      color: theme.palette.primary.main,
                                      fontWeight: 500,
                                    }}
                                    href="https://www.globhe.com/terms-and-conditions"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Terms & Conditions
                                  </a>{" "}
                                  and{" "}
                                  <a
                                    style={{
                                      color: theme.palette.primary.main,
                                      fontWeight: 500,
                                    }}
                                    href="https://www.globhe.com/privacy-policy"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Privacy Policy
                                  </a>
                                </Box>
                                <Box sx={styles.submitOrder}>
                                  <AppButton
                                    label={"SUBMIT ORDER"}
                                    look={"primary"}
                                    addtionalStyle={styles.button_style}
                                    onClick={() => finalSubmitMission(values)}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Box>
                      <br />
                    </>
                  }
                </Form>
              );
            }}
          </Formik>
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

SellYourData.propTypes = {
  mapApi: PropTypes.object,
  map: PropTypes.object,
  addplace: PropTypes.func,
  orderData: PropTypes.object,
  load: PropTypes.bool,
  title: PropTypes.string,
  id: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    })
  ),
};

// SellYourData.defaultProps = {
//   addplace: undefined,
//   orderData: undefined,
//   mapApi: undefined,
//   map: undefined,
//   load: undefined,
//   title: "tasking a drone",
//   id: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: undefined,
//     })
//   ),
// };

export default SellYourData;
