import React, { useState, useEffect } from "react";
import { Box, Badge, Typography, Divider } from "@mui/material";
import theme from "../../../theme";
import AppButton from "./AppButton";
import { getUser } from "../../../services/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import firebase from "src/firebase";
import { markAllRead, markRead } from "../../../services/notification";
import { navigate } from "gatsby";
import UniversalLoader from "./UniversalLoader";
import { useSnackbar } from "notistack";
import { notificationType, userRoles } from "../../../lib/constants";
import {
  daysOnDelay,
  minutesFromToday,
  hoursFromToday,
  secondFromToday,
} from "../../../lib/helpers";
import TopMenuIcons from "./TopMenuIcons";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { boxShadowStyle } from "../../../lib/styleConstants";
import PropTypes from "prop-types";

const styles = {
  notificationBox: {
    position: "relative",
    color: theme.palette.black.coolGrey,
  },
  notificationContainer: {
    width: "320px",
    maxHeight: "70vh",
    minHeight: "50vh",
    position: "absolute",
    right: { xs: "-120px", sm: "-100px", md: "10px" },
    top: { xs: "45px", sm: "50px", md: "40px" },
    background: theme.palette.grey.white,
    zIndex: 10000,
  },
  markAsReadLink: {
    height: "27px",
    float: "right",
    margin: "5px 10px",
  },
  divider: {
    margin: "5px",
  },
  notificationSubContainer: {
    textAlign: "left",
    overflowY: "auto",
    height: "auto",
    maxHeight: "65vh",
  },
  singleNotificationContainer: {
    marginBottom: "2px",
    display: "flex",
    padding: "5px",
    paddingLeft: "20px",
    overflowWrap: "break-word",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  noNotiBox: {
    margin: "auto",
    width: "100%",
    textAlign: "center",
    marginTop: "17vh",
  },
  iconBox: { display: "flex", justifyContent: "flex-start" },
  totalStyle: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
    background: theme.palette.primary.main,
    fontSize: "10px",
    minWidth: "16px",
    minHeight: "16px",
    margin: "auto",
    fontWeight: 500,
  },
  menuIconCls: {
    marginLeft: { xs: "0px", md: "10px" },
  },
};
const Notification = ({ enterpriseId = null }) => {
  const currentUser = getUser("user");
  const { enqueueSnackbar } = useSnackbar();

  let messagesRef;

  if (currentUser.role === userRoles.admin) {
    messagesRef = firebase
      .firestore()
      .collection("notifications")
      .where("recieverId", "in", [
        notificationType.adminNotification,
        currentUser.userID,
      ])
      .orderBy("dateCreated", "desc")
      .limit(20);
  } else if (currentUser.role === userRoles.pilot) {
    messagesRef = firebase
      .firestore()
      .collection("notifications")
      .where("recieverId", "in", [
        notificationType.pilotNotification,
        currentUser.userID,
      ])
      .orderBy("dateCreated", "desc")
      .limit(20);
  } else if (currentUser.role === userRoles.client) {
    messagesRef = firebase
      .firestore()
      .collection("notifications")
      .where("recieverId", "in", [
        notificationType.clientNotification,
        currentUser.userID,
        enterpriseId,
      ])
      .orderBy("dateCreated", "desc")
      .limit(20);
  }
  const [notifications, loading, error, snapshot] = useCollectionData(
    messagesRef,
    {
      idField: "id",
    }
  );
  const [showNotification, setShowNotification] = useState(false);
  const [unreadNotifications, setUnReadNotifications] = useState(false);
  const [unreadNotificationsCount, setUnReadNotificationsCount] = useState(0);
  const [triggerReload, setTriggerReload] = useState(false);

  useEffect(() => {
    setUnReadNotifications(false);
    (async () => {
      let unReadNotificationCount = 0;
      let read = false;
      await snapshot?.docs?.forEach((noti) => {
        if (!noti.data().read) {
          setUnReadNotifications(true);
          unReadNotificationCount = unReadNotificationCount + 1;
          read = true;
        }
      });
      setUnReadNotificationsCount(unReadNotificationCount);
      if (read) {
        let newNotification = sessionStorage.getItem("notificationCount") || 0;
        if (unReadNotificationCount > newNotification) {
          enqueueSnackbar(
            `You have ${unReadNotificationCount} unread notifications`,
            { variant: "success" }
          );
          sessionStorage.setItem("notificationCount", unReadNotificationCount);
        }
      }
    })();
  }, [notifications, triggerReload]);

  const markAllAsRead = async () => {
    await markAllRead(
      firebase,
      currentUser.userID,
      currentUser.role,
      enterpriseId
    );
    setTriggerReload(!triggerReload);
  };

  const triggerNotification = () => {
    setShowNotification(!showNotification);
  };

  const goToAction = async (notificationData) => {
    await markRead(firebase, notificationData?.id);
    if (notificationData?.data()?.action) {
      navigate(notificationData?.data()?.action);
    }
  };

  const getNotificationColor = (notification) => {
    const daysDelay = daysOnDelay(notification?.data()?.dateCreated);
    if (daysDelay > 4) {
      return theme.palette.status.errorDark;
    } else if (daysDelay > 2) {
      return theme.palette.secondary.lavender;
    } else {
      return theme.palette.black.coolGrey;
    }
  };
  const getBackgroundColor = (notification) => {
    if (notification?.data()?.read) {
      return theme.palette.grey.white;
    } else {
      return `${theme.palette.black.darkSeaBlack}0F`;
    }
  };
  const formatTimeAgo = (date) => {
    const delayInDays = daysOnDelay(date);
    const delayInHours = hoursFromToday(date);
    const delayInMinutes = minutesFromToday(date);
    const delayInSeconds = secondFromToday(date);

    if (delayInDays > 0) {
      return `${delayInDays} days ago`;
    } else if (delayInHours > 0) {
      return `${delayInHours} hours ago`;
    } else if (delayInMinutes > 0) {
      return `${delayInMinutes} minutes ago`;
    } else {
      return `${delayInSeconds} seconds ago`;
    }
  };
  return (
    <Box sx={styles.notificationBox}>
      {/* {unreadNotifications ? (
        <Badge badgeContent={unreadNotificationsCount} color="error">
          <TopMenuIcons
            icon={NotificationsNoneIcon}
            onClick={() => triggerNotification()}
            addBackgroundOval
          />
        </Badge>
      ) : (
        <TopMenuIcons
          icon={NotificationsNoneIcon}
          onClick={() => triggerNotification()}
          addBackgroundOval
        />
      )} */}

      <Box sx={styles.iconBox} onClick={() => triggerNotification()}>
        <Box component={"span"} sx={styles.menuIconCls}>
          <TopMenuIcons icon={NotificationsNoneIcon} addBackgroundOval />
        </Box>
        <Box sx={styles.totalStyle}>
          <Badge
            badgeContent={unreadNotificationsCount}
            color="primary"
            showZero
          ></Badge>
        </Box>
      </Box>

      {showNotification && (
        <Box sx={{ ...styles.notificationContainer, ...boxShadowStyle }}>
          {unreadNotifications && (
            <AppButton
              addtionalStyle={styles.markAsReadLink}
              label="Mark all as read"
              noIcon
              small
              onClick={markAllAsRead}
              look="green"
            />
          )}
          <br />
          <Divider sx={styles.divider} />
          <Box sx={styles.notificationSubContainer}>
            {!loading ? (
              snapshot?.docs?.length > 0 ? (
                snapshot?.docs?.map((notification, i) => {
                  return (
                    <Box
                      style={{ background: getBackgroundColor(notification) }}
                      sx={styles.singleNotificationContainer}
                      key={i}
                      onClick={() => goToAction(notification)}
                    >
                      <Box sx={{ width: "70%" }}>
                        <Typography variant="body2">
                          {notification?.data()?.title}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body3"
                        sx={{ color: getNotificationColor(notification) }}
                      >
                        {formatTimeAgo(notification?.data()?.dateCreated)}
                      </Typography>
                    </Box>
                  );
                })
              ) : (
                <Typography variant="body1" sx={styles.noNotiBox}>
                  No notification available.
                </Typography>
              )
            ) : (
              <Box sx={{ marginTop: "25%" }}>
                <UniversalLoader />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

Notification.propTypes = {
  enterpriseId: PropTypes.string,
};
// Notification.defaultProps = {
//   enterpriseId: null,
// };

export default Notification;
