import React, { useEffect, useState, useRef } from "react";
import Layout from "../../../Layout";
import { Box, Typography } from "@mui/material";
import theme from "../../../theme";
import { getUser } from "src/services/auth";
import MissionNameBox from "../../global/elements/MissionNameBox";
import ProfileContainer from "../../global/elements/ProfileContainer";
import { getPilot } from "src/services/pilots";
import firebase from "src/firebase";
import { getContract } from "../../../services/contracts";
import MissionDetailContent from "./MissionDetailContent";
import FormComponent from "./FormComponent";
import PropTypes from "prop-types";
import {
  getContractMissions,
  getMission,
  updateMission,
} from "../../../services/missions";
import { contractStatusUpdate } from "../../../services/contracts";
import { createNotification } from "../../../services/notification";
import { notificationType, userRoles } from "../../../lib/constants";
import { arrayUnion } from "firebase/firestore";
import { getBasicProjectDetails } from "../../../services/project";
// import { getCurrentDateTimeMillis } from "../../../lib/helpers";
// import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import PrintHtmlToPDF from "./printHtmlToPdf";
import ParagraphHeading from "./ParagraphHeading";
import PilotContractVersion1 from "./ContractVersions/PilotContractVersion1";
import PilotContractVersion2 from "./ContractVersions/PilotContractVersion2";
import { getCurrentDateTimeMillis } from "../../../lib/helpers";
const styles = {
  main: {
    paddingTop: "10px",
  },
  agreement_heading: {
    padding: "20px 0",
    marginBottom: "10px",
  },
  section_heading: {
    color: theme.palette.grey.warmGrey,
  },

  section_data_container: {
    padding: "20px 25px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  },
  assignment_section: {
    marginTop: "30px",
    paddingTop: "20px",
  },
  linkStyle: {
    margin: "2px 8px",
    color: theme.palette.secondary.main,
    textDecoration: "none",
  },
  sideLine: {
    borderRight: `1px solid ${theme.palette.black.coolGrey}`,
  },
  boldText: {
    fontWeight: 500,
  },
};

const SingleContract = ({ id = undefined }) => {
  const [loading, setLoading] = useState(false);
  const [contract, setContract] = useState(null);
  const [pilotData, setPilotData] = useState(null);
  const [missionDetails, setMissionDetails] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [contractPrice, setContractPrice] = useState(0);
  const [projectData, setProjectData] = useState(null);
  const [signedMissionsByPilot, setSignedMissionsByPilot] = useState([]);
  const [unSignedMissionByPilot, setUnSignedMissionByPilot] = useState([]);
  const [signed, setSigned] = useState(false);
  const [signedDate, setSignedDate] = useState(null);
  const [oldContractReq, setOldContractReq] = useState(false);

  const ref = useRef(null);

  const version2 = "2023-06-02";

  useEffect(() => {
    setLoading(true);
    const user = getUser("userProfile");
    setCurrentUser(user);
    (async () => {
      const contractData = await getContract(id);
      setSigned(contractData?.contract_details?.iUnderstandAll || false);
      setSignedDate(contractData?.contract_details?.signedByOperator);
      setContract(contractData?.contract_details);
      if (
        contractData &&
        contractData.contract_details &&
        contractData.contract_details.missionId
      ) {
        const missionData = await getMission(
          firebase,
          contractData?.contract_details?.missionId
        );
        setMissionDetails(missionData);
        if (missionData?.pilotPrice)
          setContractPrice(Number(missionData.pilotPrice));
      } else {
        //project missions
        getProjectMission({
          contract: contractData?.contract_details,
        });
        const projectData = await getBasicProjectDetails(
          firebase,
          contractData?.contract_details?.projectId
        );
        setProjectData(projectData?.project);
        // console.log("projectData", projectData?.project);
      }

      if (user?.role == "admin") {
        const pilot = await getPilot(
          firebase,
          contractData?.contract_details?.pilotId
        );
        setPilotData(pilot);
      } else {
        setPilotData(user);
      }
      setLoading(false);
    })();
  }, [id, signed]);

  useEffect(() => {
    if (signedDate) {
      const oldContract = isContractSignedBeforeNewVersionDate();
      if (oldContract) {
        setOldContractReq(true);
      }
    }
  }, [signedDate]);

  const isContractSignedBeforeNewVersionDate = () => {
    const versionDate = new Date(version2);

    if (signedDate === null) {
      return false;
    } else {
      const signedDateObj = new Date(signedDate);
      return signedDateObj.getTime() < versionDate.getTime();
    }
  };

  const submitHandler = async (values, setSubmitting) => {
    setSubmitting(true);
    let obj = {};
    if (contract.projectId) {
      if (!contract?.projectMissionIds) {
        contract.projectMissionIds = [];
      }
      if (!contract.projectMissions) {
        contract.projectMissions = [];
      }
      obj = {
        projectMissionIds: arrayUnion(
          ...contract.newAddedMissionsIds,
          ...contract.projectMissionIds
        ),
        projectMissions: arrayUnion(
          ...contract.newAddedMissions,
          ...contract.projectMissions
        ),
        newAddedMissions: [],
        newAddedMissionsIds: [],
      };
    }
    const status = await contractStatusUpdate({ ...values, ...obj }, id);

    if (contract.missionId) {
      const updates = {
        contractSignedByPilotOn: getCurrentDateTimeMillis(),
      };
      await updateMission(firebase, contract.missionId, updates);
    }
    await createNotification(
      firebase,
      notificationType.adminNotification,
      `An operator has signed the contract.`,
      "An operator has signed the contract.",
      `/app/admin/dashboard/contracts/${id}`
    );
    if (status.status == "Success") {
      setSignedMissionsByPilot([
        ...signedMissionsByPilot,
        ...unSignedMissionByPilot,
      ]);
      setUnSignedMissionByPilot([]);
      return true;
    } else {
      return false;
    }
    // const print = await PrintHtmlToPDF.getPdfData(ref.current, pdfOption);
    // if (print) {
    //   uploadContractPdf(print, id, async (err, downloadURL) => {
    //     if (err || !downloadURL) {
    //       const errorMsg = err || "Error in getting upload contract url";
    //       cb(errorMsg);
    //       return;
    //     } else {
    //       const status = await contractStatusUpdate(
    //         { ...values, signedContractPdf: downloadURL },
    //         id
    //       );
    //       if (status.status == "Success") {
    //         cb(null, { downloadURL });
    //         return;
    //       } else {
    //         cb("Error in signing contract");
    //         return;
    //       }
    //     }
    //   });
    // } else {
    //   cb("Error in taking uploading contract");
    //   return;
    // }
  };

  // const uploadContractPdf = async (files, id, cb) => {
  //   let timeStamp = getCurrentDateTimeMillis();
  //   if (!files) return;
  //   const storageRef = firebase
  //     .storage()
  //     .ref()
  //     .child(`signedContractPdf/${id}/${timeStamp}`);
  //   const uploadTask = uploadBytesResumable(storageRef, files);
  //   uploadTask.on(
  //     "state_changed",
  //     (snapshot) => {
  //       const progress = Math.round(
  //         (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //       );
  //     },
  //     (error) => {
  //       alert(error);
  //       cb(`Error in uploading contract ${error}`);
  //       return;
  //     },
  //     () => {
  //       getDownloadURL(uploadTask.snapshot.ref)
  //         .then(async (downloadURL) => {
  //           cb(null, downloadURL);
  //           return;
  //         })
  //         .catch((err) => {
  //           cb(`Error in generating contract pdf url ${err}`);
  //           return;
  //         });
  //     }
  //   );
  // };

  const getProjectMission = async ({ contract }) => {
    let price = 0;
    let allMissions = [];
    let signedMission = [];
    let unSignedMission = [];
    allMissions = await getContractMissions(
      contract.projectId,
      contract.pilotId
    );
    // console.log("contract", contract);
    if (allMissions?.length) {
      allMissions.map((mission) => {
        price = price + (mission.pilotPrice ? Number(mission?.pilotPrice) : 0);
        if (contract?.projectMissionIds?.find((id) => mission.id === id)) {
          // setSignedMissionsByPilot((prev) => [...prev, mission]);
          signedMission.push(mission);
        }
        if (contract?.newAddedMissionsIds?.find((id) => mission.id === id)) {
          // setUnSignedMissionByPilot((prev) => [...prev, mission]);
          unSignedMission.push(mission);
        }
      });
      setUnSignedMissionByPilot(unSignedMission);
      setSignedMissionsByPilot(signedMission);
      setContractPrice(price);
    }
  };

  // const pdfOption = {
  //   jsPDF: {
  //     unit: "px",
  //     format: "a4",
  //   },
  //   spin: true,
  //   fileName: "Crowddroning_Operator_Agreement.pdf",
  //   fitToPage: false,
  //   margin: {
  //     top: 0,
  //     bottom: 180,
  //     left: 20,
  //     right: 20,
  //   },
  //   padding: {
  //     bottom: 20,
  //   },
  // };

  const siteNames = (sites) => {
    return (
      <>
        {sites.map((site) => {
          return (
            <>
              <Box
                key={site.id}
                component="a"
                // href={
                //   currentUser?.role === userRoles.pilot
                //     ? `/app/client/orderDetails/${site.id}`
                //     : `/app/missions/${site.id}`
                // }
                href={`/app/missions/${site?.id}`}
                sx={styles.linkStyle}
              >
                {site.missionName}
              </Box>
              <Box component="span" sx={styles.sideLine}></Box>
            </>
          );
        })}
      </>
    );

    // console.log("siteNameStr", siteNameStr);
  };

  const contractHeaderOld = () => (
    <Typography sx={styles.agreement_heading} variant="body1">
      This agreement outlines the scope of work and expectations set forth
      between GLOBHE DRONES AB, reg. No 556994-0801, c/o Epicenter,
      Malmskillnadsgatan 44A, 111 57 Stockholm, Sweden (the “Company”), and{" "}
      <span style={{ fontWeight: 500 }}>{contract?.pilotName}</span> (the
      “Operator”)
    </Typography>
  );
  const contractHeaderNew = () => (
    <Typography sx={styles.agreement_heading} variant="body1">
      This agreement outlines the contractual arrangement between GLOBHE DRONES
      AB, reg. No 556994-0801, c/o Epicenter, Mäster Samuelsgatan 36, 111 57
      Stockholm, Sweden (&quot;Globhe&quot;), and{" "}
      <span style={{ fontWeight: 500 }}>{contract?.pilotName}</span>{" "}
      (&quot;Drone Operator&quot;)
    </Typography>
  );
  return (
    <Layout
      pageTitle={"Crowddroning Operator Agreement "}
      hasSideMenu
      loading={loading}
    >
      {!loading && (
        <Box sx={{ paddingLeft: "30px", paddingRight: "30px" }} ref={ref}>
          <Box sx={styles.main} id="contract">
            <MissionNameBox
              name={contract?.missionName}
              id={contract?.missionId}
              flex={true}
              page="contract"
              missionNumber={contract?.contractId}
            />
            <br />
            <br />
            <Box sx={styles.profile_container}>
              <ProfileContainer
                pilotData={pilotData}
                missionId={contract?.missionId || contract?.projectId}
                width="100%"
                page="contract"
                type={contract?.missionId ? "mission" : "project"}
              />
            </Box>
            <br />
            <br />
            {oldContractReq ? contractHeaderOld() : contractHeaderNew()}
            {contract?.missionId && (
              <Box>
                <ParagraphHeading text="Mission Details" />
                <Box sx={{ ...styles.section_data_container, p: 2 }}>
                  <MissionDetailContent
                    title="Scope :"
                    content={
                      missionDetails?.pilotInstructions ||
                      `As specified in the mission details.`
                    }
                  />
                  <MissionDetailContent
                    title="Location :"
                    content={
                      missionDetails?.locationLabel?.split(",")[
                        missionDetails?.locationLabel?.split(",").length - 1
                      ] || "As specified in the mission details"
                    }
                  />
                  <MissionDetailContent
                    title="Deadline :"
                    content={
                      missionDetails?.deadline ||
                      "As specified in the mission details"
                    }
                  />
                  <MissionDetailContent
                    title="Delivery :"
                    content={
                      (missionDetails?.mapTypes?.length > 0 &&
                        `${missionDetails?.mapTypes?.join(", ")}`) ||
                      `All files must be uploaded to the crowddroning platform 
                  before the deadline as specified in the mission details. `
                    }
                  />
                  <MissionDetailContent
                    title="Other requirements :"
                    content={
                      missionDetails?.otherRequirements ||
                      `No qualification requirements added`
                    }
                  />
                  <MissionDetailContent
                    title="Payment amount :"
                    content={
                      contractPrice
                        ? `${contractPrice} USD`
                        : `No payment amount has been specified`
                    }
                  />
                </Box>
              </Box>
            )}
            {contract?.projectId && (
              <>
                <Box>
                  <ParagraphHeading text="Project Details" />
                  {/* <Box component="p" sx={styles.section_inside_heading}>
                    {`Please refer to the information for an overview of the project`}
                  </Box> */}
                  <Box sx={{ ...styles.section_data_container, p: 2 }}>
                    <MissionDetailContent
                      title="Scope :"
                      content={
                        projectData?.pilotInstructions ||
                        `As specified in the project details.`
                      }
                    />

                    <MissionDetailContent
                      title="Deadline :"
                      content={
                        projectData?.deadline ||
                        "As specified in the project details"
                      }
                    />
                    <MissionDetailContent
                      title="Delivery :"
                      content={
                        (projectData?.mapTypes?.length > 0 &&
                          `${projectData?.mapTypes?.join(", ")}`) ||
                        `All files must be uploaded to the crowddroning platform 
                  before the deadline as specified in the project details. `
                      }
                    />
                    <MissionDetailContent
                      title="Other requirements :"
                      content={
                        projectData?.otherRequirements ||
                        `No qualification requirements added`
                      }
                    />
                    <MissionDetailContent
                      title="Payment amount :"
                      content={
                        contractPrice
                          ? `${contractPrice} USD`
                          : `No payment amount has been specified`
                      }
                    />
                  </Box>
                </Box>
                <br />
                <br />
                <>
                  <ParagraphHeading text="Site Details" />
                  <Typography variant="body1" sx={{ marginBottom: "10px" }}>
                    Sites assigned to the Operator
                  </Typography>
                  <Box sx={{ ...styles.section_data_container, p: 2 }}>
                    {signedMissionsByPilot?.length > 0 && (
                      <>
                        <Typography variant="subtitle1Med">
                          List of sites
                        </Typography>
                        {siteNames(signedMissionsByPilot)}
                        <br />
                      </>
                    )}

                    {unSignedMissionByPilot?.length > 0 && (
                      <>
                        <Typography variant="subtitle1Med">
                          Additional sites not signed in the contract yet
                        </Typography>
                        {siteNames(unSignedMissionByPilot)}
                      </>
                    )}
                  </Box>
                </>
              </>
            )}
            {oldContractReq ? (
              <PilotContractVersion1 />
            ) : (
              <PilotContractVersion2 />
            )}

            <FormComponent
              signed={signed}
              submitHandler={submitHandler}
              // id={id}
              contract={contract}
              role={currentUser?.role}
              setSigned={setSigned}
            />
          </Box>
        </Box>
      )}
    </Layout>
  );
};

SingleContract.propTypes = {
  id: PropTypes.string,
};

export default SingleContract;
