import React from "react";
import { Box } from "@mui/material";
import theme from "../../../theme";
import PropTypes from "prop-types";

const styles = {
  main: {
    marginTop: "10px",
    display: "flex",
    position: "relative",
  },
  nameBox: {
    marginTop: "5px",
    fontWeight: 450,
    color: theme.palette.black.darkSeaBlack,
    fontSize: { xs: "12px", sm: "14px", md: "16px", lg: "18px" },
  },
  idBox: {
    fontSize: { xs: "9px", sm: "10px", md: "12px" },
    color: theme.palette.grey.warmGrey,
    paddingLeft: { md: "1px" },
  },
};

const MissionNameBox = ({
  name = undefined,
  id = undefined,
  flex = false,
  page = "",
  missionNumber = "",
}) => {
  return (
    <Box sx={styles.main}>
      <Box
        sx={{ display: { xs: "inline-grid" } }}
        style={{ display: flex && "inline-grid" }}
      >
        <Box sx={styles.nameBox} component="span">
          {name}
        </Box>
        <Box sx={styles.idBox} component="span">
          {id}
        </Box>
      </Box>
      {page === "contract" && (
        <Box
          sx={{
            display: "inline-grid",
            right: "0",
            position: "absolute",
            textAlign: "right",
          }}
        >
          <Box sx={{ ...styles.idBox, marginTop: "5px" }} component="span">
            Contract Number
          </Box>
          <Box sx={{ ...styles.nameBox, marginTop: 0 }} component="span">
            {missionNumber}
          </Box>
        </Box>
      )}
    </Box>
  );
};

MissionNameBox.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  flex: PropTypes.bool,
  borderBox: PropTypes.bool,
  page: PropTypes.string,
  missionNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

// MissionNameBox.defaultProps = {
//   name: undefined,
//   id: undefined,
//   flex: false,
//   borderBox: false,
//   page: "",
//   missionNumber: "",
// };

export default MissionNameBox;
