import React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../../../theme";
import { userRoles } from "../../../../lib/constants";
import UserRoleTag from "../../../global/SideMenu/UserRoleTag";

const styles = {
  typeContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: { xs: 0, sm: "1vh", xl: "6vh" },
    textAlign: "left",
    width: { xs: "fit-content", lg: "473px" },
  },
  formType: {
    color: theme.palette.status.greenText,
    margin: "auto",
  },
  title: {
    marginBottom: "20px",
  },
  subtitle: {
    color: theme.palette.grey.warmGrey,
  },
};

const SignInFormHeader = ({
  title = undefined,
  subtitle = undefined,
  user = undefined,
}) => {
  const userString = () => {
    if (user === userRoles.client) {
      return "Client page";
    } else {
      return "Drone operator";
    }
  };
  return (
    <Box sx={styles.typeContainer}>
      {user && <UserRoleTag userType={userString()} />}
      <Typography variant="h2" sx={styles.title}>
        {title}
      </Typography>
      <Typography variant="h6" sx={styles.subtitle}>
        {subtitle}
      </Typography>
      {user === userRoles.client && (
        <Typography mt={2} variant="h6">
          No credit card required
        </Typography>
      )}
    </Box>
  );
};

SignInFormHeader.propTypes = {
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  user: PropTypes.string,
};
// SignInFormHeader.defaultProps = {
//   user: undefined,
// };

export default SignInFormHeader;
