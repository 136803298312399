import React, { useState } from "react";
import PropTypes from "prop-types";
import OrderDetails from "./Components/OrderDetails";
import SpecifyLocationMultiOrder from "./Components/SpecifyLocationMultiiOrder";
import SpecifyLocationSingleOrder from "./Components/SpecifyLocationSingleOrder";
import AdvanceOptions from "./Components/AdvanceOptions";
import ObjAndDesc from "./Components/ObjAndDesc";
import { Formik, Form } from "formik";
import AppButton from "../../global/elements/AppButton";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getUser } from "../../../services/auth";
import {
  defaultMissionRepresentative,
  missionTypeConstant,
  orderLocationType,
  otherRequirementsString,
  userRoles,
} from "../../../lib/constants";
import ChooseProducts from "./Components/ChooseProducts";
import TransparentBlackSavedScreen from "../../global/elements/TransparentBlackSavedScreen";
import { Box } from "@mui/material";
import * as Yup from "yup";
import FormErrors from "../../global/forms/FormErrors";
import { addDaystoTheDate, getCurrentDateTime } from "../../../lib/helpers";
import ChooseSensors from "./Components/ChooseSensors";

const styles = {
  btnContainer: {
    textAlign: "end",
    paddingBottom: "3%",
  },
  cancelBtn: {
    marginRight: "15px",
  },
};

const CreateEditOrderForm = ({
  orderDetails = undefined,
  formType = undefined,
  orderSwitch = undefined,
  orderCollection = undefined,
  setOrderCollection = undefined,
  setClientInfo = undefined,
  goToSummaryPage = undefined,
  isGuestUser = false,
}) => {
  /* import formik in here and then create all of the individual form 
   section file present in local Component folder and import all those
   component here. */
  const [projectRef, setProjectRef] = useState(null);
  const [uploadValidData, setUploadValidData] = useState([]);
  // const [docId, setDocId] = useState(null);
  const [open, setOpen] = useState(false);
  const [mapSaved, setMapSaved] = useState(false);
  const [errorDisplay, setErrorDisplay] = useState(false);

  const currentUser = getUser("user");
  const isAdmin = currentUser.role === userRoles.admin;
  const isClient = currentUser.role === userRoles.client;

  const checkClientValidation = (recurrence, missionType) => {
    if (
      recurrence ||
      missionType == missionTypeConstant.clientOrder ||
      orderSwitch == orderLocationType.multiLocationOrder
    ) {
      return true;
    } else {
      return false;
    }
  };

  const CreateEditOrderSchema = Yup.object()
    .shape({
      missionName: Yup.string()
        .trim()
        .max(50, "Must be maximum 50 characters long")
        .required("Order or site name is required"),
      // location: Yup.string().nullable().required("Country is required"),
      location: Yup.string().nullable(),
      droneSensors: Yup.array()
        .min(1, "Please select a minimum of 1 sensors.")
        .required("Sensors are required"),
      rtkRequire: Yup.bool().required("Do you require RTK?"),
      mapTypes: Yup.array(),
      startDate: Yup.date()
        .nullable()
        .typeError("Start date must be a valid date")
        .when("recurrence", {
          is: true,
          then: Yup.date().nullable().required("Start date is required"),
        }),
      intervalEndOn: Yup.bool(),
      deadline: Yup.date()
        .nullable()
        .typeError("Deadline must be a valid date")
        .when(["recurrence", "startDate"], (recurrence, startDate) => {
          if (startDate && !recurrence) {
            return Yup.date()
              .required("End date is required")
              .min(
                startDate,
                "The deadline should be later than the starting date"
              )
              .nullable();
          }
        }),
      pocName: Yup.string().trim(),
      description: Yup.string().trim(),

      guidelinesPdfUrl: Yup.string().when("description", {
        is: (description) => !description, // If field1 is empty, then field2 is required
        then: Yup.string().required(
          "Please fill the description or upload guideline pdf"
        ),
        otherwise: Yup.string(),
      }),
      pocPhone: Yup.string(),
      recurrence: Yup.bool(),
      recursiveInterval: Yup.string()
        .nullable()
        .when("recurrence", {
          is: true,
          then: Yup.string()
            .nullable()
            .required("Select an interval for recurence"),
        }),
      everyCount: Yup.number()
        .nullable()
        .when("recurrence", {
          is: true,
          then: Yup.number()
            .min(1, "Select a minimum of 1")
            .nullable()
            .required("Select a recurence count"),
        }),
      intervalEndDate: Yup.date()
        .nullable()
        .when(
          ["recurrence", "intervalEndOn", "startDate"],
          (recurrence, intervalEndOn, startDate) => {
            if (recurrence && intervalEndOn && startDate) {
              return Yup.date()
                .typeError("Please select a valid end date")
                .required("End date is required")
                .min(startDate, "End date should be greater than start date");
            }
          }
        ),
      intervalEndCount: Yup.number()
        .nullable()
        .when(
          ["recurrence", "intervalEndAfter", "startDate"],
          (recurrence, intervalEndAfter) => {
            if (recurrence && intervalEndAfter) {
              return Yup.number()
                .min(1, "Select a minimum of 1 occurrence")
                .required("Recurence end date/ occurrence is required");
            }
          }
        ),

      startDateForMission: Yup.string()
        .nullable()
        .when(
          ["recurrence", "recursiveInterval"],
          (recurrence, recursiveInterval) => {
            if (
              recurrence &&
              recursiveInterval &&
              recursiveInterval !== "day"
            ) {
              return Yup.string()
                .nullable()
                .required(
                  "Please select a when you want to repeat your order."
                );
            }
          }
        ),

      ...(currentUser?.role === userRoles.admin && {
        clientName: Yup.string()
          .nullable()
          .when(["recurrence", "missionType"], (recurrence, missionType) => {
            if (checkClientValidation(recurrence, missionType)) {
              return Yup.string().required("Client name is required");
            }
          }),
        notShowInDataLibrary: Yup.bool(),
        clientEmail: Yup.string()
          .nullable()
          .when(["recurrence", "missionType"], (recurrence, missionType) => {
            if (checkClientValidation(recurrence, missionType)) {
              return Yup.string()
                .email("Invalid email")
                .required("Email is required");
            }
          }),
        clientPhone: Yup.number()
          .nullable()
          .when(["recurrence", "missionType"], (recurrence, missionType) => {
            if (checkClientValidation(recurrence, missionType)) {
              return Yup.number()
                .positive()
                .required("Client phone is required");
            }
          }),
        clientCompanyName: Yup.string()
          .nullable()
          .when(["recurrence", "missionType"], (recurrence, missionType) => {
            if (checkClientValidation(recurrence, missionType)) {
              return Yup.string().required("Company name is required");
            }
          }),
        missionPrice: Yup.number()
          .positive()
          .min(1, "Price should be greater than 0")
          .nullable()
          .required("Mission price is required"),
        allowEmptyData: Yup.bool(),
        ...(orderCollection === "missions" && {
          missionType: Yup.string()
            .required("Mission type is required")
            .nullable(),
        }),

        dataLibDescription: Yup.string().when(
          ["notShowInDataLibrary"],
          (notShowInDataLibrary) => {
            if (!notShowInDataLibrary) {
              return Yup.string().required(
                "The data description for the data library is required"
              );
            }
          }
        ),
        dataLibMissionTitle: Yup.string().when(
          ["notShowInDataLibrary"],
          (notShowInDataLibrary) => {
            if (!notShowInDataLibrary) {
              return Yup.string().required(
                "The data title for the data library is required"
              );
            }
          }
        ),
        dataLibSdgGoals: Yup.array().when(
          ["notShowInDataLibrary"],
          (notShowInDataLibrary) => {
            if (!notShowInDataLibrary) {
              return Yup.array()
                .min(1, "The SDGs Goals for this order are required ")
                .required("required ");
            }
          }
        ),
        dataLibCategories: Yup.array().when(
          ["notShowInDataLibrary"],
          (notShowInDataLibrary) => {
            if (!notShowInDataLibrary) {
              return Yup.array()
                .min(1, "The category of this order is required")
                .required("required ");
            }
          }
        ),
      }),
    })
    .test("myCustomTest", null, (obj) => {
      let errorMessage = "";
      if (obj.recurrence && !(obj.intervalEndOn || obj.intervalEndAfter)) {
        errorMessage = "Please select when the order is going to stop";
      } else {
        errorMessage = "";
        return;
      }
      return new Yup.ValidationError(errorMessage, null, "selectBarErrorMain");
    });

  const initialValues = {
    pocName: orderDetails?.pocName || "",
    pocPhone: orderDetails?.pocPhone || "",
    location: orderDetails?.location || "",
    dataLibMissionTitle: orderDetails?.dataLibMissionTitle || "",
    dataLibSdgGoals: orderDetails?.dataLibSdgGoals || [],
    dataLibCategories: orderDetails?.dataLibCategories || [],
    dataLibDescription: orderDetails?.dataLibDescription || "",
    description: orderDetails.description || "",
    otherRequirements:
      orderDetails.otherRequirements || otherRequirementsString,
    guidelinesPdfUrl: orderDetails.guidelinesPdfUrl | "",
    missionName: orderDetails?.missionName || "",
    missionType: orderDetails.missionType || null,
    missionPrice: orderDetails.missionPrice || 0,
    mapTypes: orderDetails?.mapTypes || [],
    droneSensors: orderDetails?.droneSensors || [],
    rtkRequire: orderDetails.rtkRequire || false,
    locationMap: orderDetails?.locationMap || null,
    deadline: orderDetails?.deadline
      ? DateTime.fromISO(orderDetails?.deadline)
      : null,
    startDate: orderDetails?.startDate
      ? DateTime.fromISO(orderDetails?.startDate)
      : addDaystoTheDate({ theDate: getCurrentDateTime(), days: 2 }).slice(
          0,
          10
        ),

    missionOwnerName:
      orderDetails?.missionOwnerName || defaultMissionRepresentative.name,

    missionOwner:
      orderDetails?.missionOwner || defaultMissionRepresentative?.email,
    missionOwnerId:
      orderDetails?.missionOwnerId || defaultMissionRepresentative?.id,
    recurrence: orderDetails?.recurrence || false,
    everyCount:
      orderDetails?.recurrenceInfo && orderDetails?.recurrenceInfo?.everyCount
        ? orderDetails?.recurrenceInfo?.everyCount
        : 0,
    startDateForMission:
      orderDetails?.recurrenceInfo &&
      orderDetails?.recurrenceInfo?.startDateForMission
        ? orderDetails?.recurrenceInfo?.startDateForMission
        : null,

    recursiveInterval:
      orderDetails?.recurrenceInfo &&
      orderDetails?.recurrenceInfo?.recursiveInterval
        ? orderDetails?.recurrenceInfo?.recursiveInterval
        : "",
    intervalEndAfter:
      (orderDetails?.recurrenceInfo &&
        orderDetails?.recurrenceInfo?.intervalEndAfter) ||
      false,
    intervalEndOn:
      (orderDetails?.recurrenceInfo &&
        orderDetails?.recurrenceInfo?.intervalEndOn) ||
      false,
    intervalEndDate:
      (orderDetails?.recurrenceInfo &&
        orderDetails?.recurrenceInfo?.intervalEndDate) ||
      null,
    intervalEndCount:
      (orderDetails?.recurrenceInfo &&
        orderDetails?.recurrenceInfo?.intervalEndCount) ||
      "",
    areaSize: null,
    clientName: "",
    clientEmail: "",
    clientPhone: "",
    clientCompanyName: "",
    freeToDownload: false,
    // frontOverlap: 75,
    // sideOverlap: 70,
  };

  const handleOpen = () => {
    setOpen(!open);
    setMapSaved(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik
        initialValues={initialValues}
        validationSchema={CreateEditOrderSchema}
        onSubmit={(values) => {
          // orderSubmitHandler(values);
          if (!isGuestUser) {
            if (orderCollection === "missions" || values.recurrence) {
              if (!values.locationMap && !values.areaSize && !values.kmlUrl) {
                setErrorDisplay(true);
                return;
              }
            } else if (orderCollection === "projects") {
              if (values.allCreatedValidMissions?.length === 0) {
                setErrorDisplay(true);
                return;
              }
            }
          }

          if (!errorDisplay) {
            goToSummaryPage(values);
          }
        }}
      >
        {({ values, setFieldValue, touched, errors, submitCount }) => {
          return (
            <Form id="orderForm">
              <OrderDetails
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                formType={formType}
                touched={touched}
                currentUser={currentUser}
                orderCollection={orderCollection}
                orderSwitch={orderSwitch}
                setOrderCollection={setOrderCollection}
                setClientInfo={setClientInfo}
              />

              <ChooseSensors
                numberCount={3}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                hideJpg
              />

              <ChooseProducts
                mapTypes={values.mapTypes}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                numberCount={4}
                values={values}
              />

              {orderSwitch === orderLocationType.multiLocationOrder ? (
                <SpecifyLocationMultiOrder
                  projectRef={projectRef}
                  setProjectRef={setProjectRef}
                  uploadValidData={uploadValidData}
                  setUploadValidData={setUploadValidData}
                  // setDocId={setDocId}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  errorDisplay={errorDisplay}
                  isGuestUser={isGuestUser}
                />
              ) : (
                <SpecifyLocationSingleOrder
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  setOpen={setOpen}
                  handleOpen={handleOpen}
                  mapSaved={mapSaved}
                  setMapSaved={setMapSaved}
                  touched={touched}
                  errorDisplay={errorDisplay}
                  setErrorDisplay={setErrorDisplay}
                  serialNo={5}
                />
              )}
              <AdvanceOptions
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                serialNo={6}
              />
              <ObjAndDesc
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                serialNo={7}
              />
              <Box sx={styles.btnContainer}>
                <AppButton
                  look="inverted"
                  small
                  noIcon
                  // type="submit"
                  label="Cancel"
                  addtionalStyle={styles.cancelBtn}
                />
                <AppButton
                  look={isClient ? "green" : isAdmin ? "blue" : "purple"}
                  type="submit"
                  label="Next"
                  form="orderForm"
                  isDisabled={errorDisplay}
                  // onClick={() => goToSummaryPage(values)}
                />
              </Box>

              <FormErrors
                className={styles.formErrorsAlert}
                show={submitCount > 0}
                errors={Object.values(errors)}
              />

              <>
                <TransparentBlackSavedScreen
                  open={open}
                  textToShow="Your location has been saved"
                />
              </>
            </Form>
          );
        }}
      </Formik>
    </LocalizationProvider>
  );
};
CreateEditOrderForm.propTypes = {
  orderSubmitHandler: PropTypes.func.isRequired,
  orderDetails: PropTypes.object,
  formType: PropTypes.string,
  orderCollection: PropTypes.string.isRequired,
  orderSwitch: PropTypes.string.isRequired,
  setOrderCollection: PropTypes.func.isRequired,
  setClientInfo: PropTypes.func.isRequired,
  goToSummaryPage: PropTypes.func.isRequired,
  isGuestUser: PropTypes.bool,
};

// CreateEditOrderForm.defaultProps = {
//   orderDetails: undefined,
//   formType: undefined,
//   isGuestUser: false,
// };
export default CreateEditOrderForm;
