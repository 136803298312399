import React from "react";
import PropTypes from "prop-types";
import AppButton from "../../../global/elements/AppButton";

const OrderButton = ({
  link = undefined,
  name = "Task a drone",
  look = "green",
}) => {
  return <AppButton url={link} internalLink look={look} label={name} />;
};

OrderButton.propTypes = {
  link: PropTypes.string.isRequired,
  name: PropTypes.string,
  look: PropTypes.string,
};

export default OrderButton;
