import { Grid } from "@mui/material";
import React from "react";
import BoxLayout from "../../../../global/BoxLayout";
import PropTypes from "prop-types";
import {
  onBoardingText,
  // subPlanDiscount,
  // subsStatus,
} from "../../../../../lib/constants";
import OnboardingSteps from "../../../../global/OnboardingSteps";
// import { getRemainingQuotaForSubscription } from "../../../../../services/SubscriptionPlans";

const styles = {
  marginForGrid: {
    margin: { xs: "10px 3px", sm: "15px 0px" },
  },
  marginClass: {
    marginRight: { xs: 0, lg: "8px" },
    marginTop: { xs: "16px", lg: 0 },
  },
  mRight: {
    marginLeft: { xs: "16px", lg: 0 },
    marginTop: { xs: "16px", lg: 0 },
  },
  boxLayout: { padding: "6px" },
};

const SubscriptionOrderDataContainer = ({
  number = null,
  collectedData = null,
  clientInfo = null,
}) => {
  // const getUsedLimit = () => {
  //   let remainingLimit = 0;
  //   let leftDataPercent = 0;
  //   remainingLimit = getRemainingQuotaForSubscription(
  //     subData?.subTerm,
  //     subData?.totalSubLimit,
  //     subData?.dateCreated,
  //     subData?.usedSubLimit,
  //     subData?.missionList
  //   );
  //   // return (Number(subPlan?.totalSubLimit) - (remainingLimit || 0)).toFixed(2);
  //   if (remainingLimit > 0) {
  //     leftDataPercent =
  //       (Number(remainingLimit) / Number(subData?.totalSubLimit)) * 100;
  //   }

  //   return Math.round(leftDataPercent);
  // };

  return (
    <Grid container>
      {/* <Typography variant="h5">text</Typography> */}
      <Grid item xs={12} lg={6.5} sx={styles.marginClass}>
        {
          /* !clientInfo?.tourFlag ? (
          <OnboardingSteps
            cardText="Take the Guided Tour"
            footerText="Learn how to order drone data from anywhere."
            btnText={onBoardingText.guideTour}
            buttonlabel="Tour"
            redirectUrl="#"
            count={1}
            skipFcn={skipFcn}
            showSkip={true}
          />
          */
          clientInfo.isAnonymous ? (
            <OnboardingSteps
              cardText="Request access to Globhe's marketplace"
              btnText={onBoardingText.signUp}
              buttonlabel="Request access"
              stepperFlag={false}
              redirectUrl="https://www.globhe.com/request-access"
              count={0}
              userData={clientInfo}
              progress={0}
            />
          ) : clientInfo?.profilePercent < 100 ? (
            <OnboardingSteps
              cardText="Complete Your Profile"
              btnText={onBoardingText.profileComplete}
              buttonlabel="Settings"
              stepperFlag={true}
              redirectUrl="/app/client/setting/profile/edit"
              count={2}
              userData={clientInfo}
              progress={clientInfo?.profilePercent}
            />
          ) : (
            // : subData?.subStatus === subsStatus.active ? (
            //   <BoxLayout
            //     link="/app/client/subscription"
            //     text="Your Subscription"
            //     subText="Globhe Advanced"
            //     subPlanFlag
            //     leftQuota={getUsedLimit()}
            //   />
            // ) : (
            //   <OnboardingSteps
            //     cardText="Subscribe to a Plan"
            //     footerText={subPlanDiscount.subDiscount}
            //     btnText={onBoardingText.subPlan}
            //     buttonlabel="Plans"
            //     redirectUrl="/app/client/subscription"
            //     count={3}
            //   />
            // )
            <OnboardingSteps
              cardText="Task a Drone"
              footerText={onBoardingText.placeOrder}
              buttonlabel="Task a Drone"
              redirectUrl="/app/order/create-order"
              count={3}
            />
          )
        }
      </Grid>

      <Grid item xs={5.7} sm={5.8} lg={2.6} sx={styles.marginClass}>
        <BoxLayout
          link="/app/client/orders/inProgress"
          text="Orders"
          subText={new Date().toLocaleDateString()}
          data={number}
          additionalStyle={styles.boxLayout}
        />
      </Grid>
      <Grid item xs={5.7} sm={5.8} lg={2.6} sx={styles.mRight}>
        <BoxLayout
          link="/app/client/orders/completed"
          text="Collected Data"
          subText={`Across ${
            collectedData?.totalMissionOfDataCollected || 0
          } orders`}
          data={collectedData?.totalMBs || 0}
          inGbFlag={collectedData?.dataUnit || "MB"}
          collectedDataFlag={true}
          additionalStyle={styles.boxLayout}
        />
      </Grid>
    </Grid>
  );
};

SubscriptionOrderDataContainer.propTypes = {
  number: PropTypes.number,
  collectedData: PropTypes.number,
  inGbFlag: PropTypes.bool,
  subData: PropTypes.object,
  clientInfo: PropTypes.object,
  skipFcn: PropTypes.func,
};

export default SubscriptionOrderDataContainer;
