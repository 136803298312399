import firebase from "src/firebase";
import { useCallback, useEffect, useState } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { getPilotPackages } from "../services/pilot-packages";
import { getUser } from "../services/auth";
import { PilotPackageStatus, userRoles, users } from "../lib/constants";

export const useGetPilotPackages = (missionID) => {
  const [packages, setPackages] = useStateIfMounted();
  const [error, setError] = useStateIfMounted();
  const [loading, setLoading] = useStateIfMounted(false);
  const [refresh, setRefresh] = useStateIfMounted(true);
  const currentUser = getUser(users.user);
  const isAdmin = currentUser.role === userRoles.admin;
  const refreshPackages = useCallback(() => {
    setRefresh(true);
  }, []);
  useEffect(() => {
    if (!firebase || !refresh) {
      return;
    }

    if (refresh && loading) {
      setRefresh(false);
      return;
    }

    setRefresh(false);
    setLoading(true);

    getPilotPackages(firebase, missionID)
      .then((data) => {
        let pilotPackages = data
          .filter((p) => !isAdmin || p.status !== PilotPackageStatus.open)
          .sort(function (a, b) {
            return new Date(a.dateCreated) - new Date(b.dateCreated);
          });
        setPackages(pilotPackages);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        setError(e);
      });
  }, [missionID, loading, refresh, refreshPackages]);

  useEffect(() => {
    if (!packages) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [packages]);

  return { packages, loading, refreshPackages, error };
};
